import { Button, Empty, Modal, Switch, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SkeltorLoader from "./SkeltorLoader";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaRegCopy } from "react-icons/fa";

function ActiveBotTable() {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [record, setRecord] = useState();
    const [visible, setVisible] = useState(false);
    const jwt = localStorage.getItem("jwt");

    const showModal = (record) => {
        setVisible(true);
        setRecord(record);
    };

    const handleOk = () => {
        setVisible(false);
        handleDelete();
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const simpleData = [];

    const simpleColumns = [
        {
            title: "Date Time",
            dataIndex: "Created_at",
        },
        {
            title: "Bot ID",
            dataIndex: "Bot_ID",
        },
        {
            title: "API Name",
            dataIndex: "API_name",
        },
        {
            title: "API key",
            dataIndex: "API_key",
            width: 200,
            render: (text, record) => (
                <span className="flex gap-4">
                    {text && `${text.slice(0, 4)}...${text.slice(-4)}`}
                    <button onClick={() => copyToClipboard(text)}>
                        <FaRegCopy />
                    </button>
                </span>
            ),

        },
        // {
        //     title: "Exchange Type",
        //     dataIndex: "Exchange_Type",
        //     width: 200,
        // },
        {
            title: "Trade Value",
            dataIndex: "Trade_value",
        },
        {
            title: "Take Profit",
            dataIndex: "Take_profit",
        },
        {
            title: "Trade Type",
            dataIndex: "Trade_type",
        },
        {
            title: "Bot Type",
            dataIndex: "Bot_type",
        },
        {
            title: "Status",
            dataIndex: "Status",
            key: "Status",
            editable: true,
            className: "w-7",
            width: 200,
            render: (text, record) => (
                <>
                    <div className="justify-center items-center ">
                        <div>

                            <Switch
                                className={record.Status === "Active" ? "off" : "on"}
                                checked={record?.Status === "Active"}
                                checkedChildren={<div className=" "> Start</div>}
                                unCheckedChildren={<div className="text-white  ">Pause</div>}
                                onChange={(checked) => handleSwitchChange(record, checked)}
                            />

                        </div>
                    </div>
                </>
            ),
        },
        {
            title: "Delete",
            dataIndex: "Delete",
            key: "Delete",
            width: 100,
            render: (text, record) => (

                <Button
                    type="danger"
                    onClick={() => showModal(record)}
                //   onClick={() => handleDelete(record)}
                >
                    <MdDelete className=" text-lg" />
                </Button>

            ),
        },
    ];

    // if (filteredData) {
    //     let filteredRows = filteredData;

    //     for (let i = 0; i < filteredRows?.length; i++) {
    //         simpleData?.push({
    //             key: i,
    //             Bot_ID: filteredRows[i]?.Bot_ID,
    //             API_key: filteredRows[i]?.API_key,
    //             Trade_value: filteredRows[i]?.Trade_value,
    //             Take_profit: filteredRows[i]?.Take_profit,
    //             Bot_type: filteredRows[i]?.Bot_type,
    //             Trade_type: filteredRows[i]?.Trade_type,
    //             Created_at: filteredRows[i]?.Created_at,
    //             API_name: filteredRows[i]?.API_name,
    //             Status: filteredRows[i]?.Status,
    //         });
    //     }
    // }

    const rows = [];

    if (filteredData) {
        for (let i = 0; i < filteredData.length; i++) {

            const row = {
                key: i,
                Bot_ID: filteredData[i]?.Bot_ID,
                API_key: filteredData[i]?.API_key,
                Trade_value: filteredData[i]?.Trade_value,
                Take_profit: filteredData[i]?.Take_profit,
                Bot_type: filteredData[i]?.Bot_type.replace("_", " "),
                Trade_type: filteredData[i]?.Trade_type,
                Created_at: filteredData[i]?.Created_at,
                API_name: filteredData[i]?.API_name,
                Status: filteredData[i]?.Status,
            };

            rows.push(row);
        }
    }

    const copyToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        textArea.setAttribute("readonly", "");
        textArea.style.position = "absolute";
        textArea.style.left = "-9999px";

        document.body.appendChild(textArea);
        textArea.select();

        try {
            const success = document.execCommand("copy");
            if (success) {
                toast("Copied");
            } else {
                message.error("Copy failed. Please try again.");
            }
        } catch (err) {
            message.error("Copy failed. Please try again.");
        }

        document.body.removeChild(textArea);
    };


    const onChange = (pagination) => {
        // setpagesize(pagination.pageSize);
        setPage(pagination.current);
        setSize(pagination.size);
    };

    const handleDShowData = () => {
        setLoading(true);

        const postData = {
            jwt: jwt
        }
        const response = axios({
            method: "POST",
            headers: {
                accept: "application/json",
            },
            data: postData,
            // url: "http://192.168.0.223:8000/arbitrage_trade/show_arbitrage_bot",
            url: process.env.REACT_APP_API_TOOL + "/arbitrage_trade/show_arbitrage_bot",
        })
            .then((res) => {
                if (res?.data?.Success === "Bot does not exist") {
                    setFilteredData([]);
                } else {
                    setFilteredData(res?.data?.Success);
                }
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    const handleSwitchChange = (record, checked) => {
        const newStatus = checked ? "Active" : "Inactive";

        if (checked) {
            botStart(record);
        } else {
            botStop(record);
        }

        const updatedDataSource1 = filteredData.map((item) =>
            item.API_Name === record.API_Name && item.VarBotID === record.VarBotID
                ? { ...item, Status: newStatus }
                : item
        );
        setFilteredData(updatedDataSource1);
    };

    const botStart = (record) => {
        const postData = {
            project: process.env.REACT_APP_PROJECT,
            jwt: jwt,
            botid: record?.Bot_ID,
            status: "Active",
        };
        const response = axios({
            method: "POST",
            headers: {
                accept: "application/json",
            },
            url: process.env.REACT_APP_API_TOOL + "/arbitrage_trade/change_arbitrage_bot_status",
            data: postData,
        })
            .then((res) => {
                toast(res?.data?.Success);
            })
            .catch((err) => console.log(err));
    };

    const botStop = (record) => {
        const postData = {
            project: process.env.REACT_APP_PROJECT,
            jwt: jwt,
            botid: record?.Bot_ID,
            status: "Inactive",
        };
        const response = axios({
            method: "POST",
            headers: {
                accept: "application/json",
            },
            url: process.env.REACT_APP_API_TOOL + "/arbitrage_trade/change_arbitrage_bot_status",
            data: postData,
        })
            .then((res) => {
                toast(res?.data?.Success);
            })
            .catch((err) => console.log(err));
    };


    const handleDelete = () => {
        const token = localStorage.getItem("jwt");
        const postData = {
            jwt: token,
            botid: record?.Bot_ID,
        };

        const response = axios({
            method: "POST",
            headers: {
                accept: "application/json",
            },
            url: process.env.REACT_APP_API_TOOL + "/arbitrage_trade/delete_bot",
            data: postData,
        })
            .then((res) => {
                toast(res?.data?.Success);
                setVisible(false);
                handleDShowData();

            })
            .catch((err) => {
                console.log(err)
                toast.error(err?.response.data?.Error)
            });
    };

    const isMobile = useMediaQuery({ maxWidth: 1050 });

    const pageSizeOptions = [30, 50, 100];

    const onPageChange = (page) => {
        setPage(page);
    };

    const handlePageSizeChange = (size) => {
        setSize(size);
    };

    useEffect(() => {
        handleDShowData();
    }, []);

    // const anyUnchecked =
    //   (Array.isArray(coin) && coin.length === 0) ||
    //   (Array.isArray(selectedBotID) && selectedBotID.length === 0);

    // if (anyUnchecked || filteredData.length === 0) {
    //   return <Empty description="No active bot!" />;
    // }

    return (
        <div>
            <div className="mt-2 w-full container table-newres">
                {simpleData.length > 0 && (
                    <CustomPagination
                        totalPages={totalPages}
                        currentPage={page}
                        onPageChange={onPageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                )}
                <Modal
                    title="Delete Confirmation"
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={<span className="text-black">OK</span>}
                    cancelText="Cancel"
                    footer={false}
                >
                    <p>Are you sure you want to delete this Bot?</p>
                    <div className="flex justify-end mt-4">
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out"
                            onClick={handleDelete}
                        >
                            OK
                        </button>
                    </div>
                </Modal>
                <div className="my-2 table-newres">
                    {loading ? (
                        <div className="w-full h-40 rounded-tr-3xl rounded-tl-3xl bg-colorPrimary shadow-neumorphic">
                            <div className="w-full h-14 bg-colorPrimary">
                                <SkeltorLoader />
                            </div>
                        </div>
                    ) : simpleData.length > 0 ? (
                        <Table
                            columns={simpleColumns}
                            dataSource={rows}
                            pagination={false}
                            className="mt-2 md:mt-14 lg:mt-2"
                        />
                    ) : (
                        <Table
                            columns={simpleColumns}
                            dataSource={rows}
                            pagination={false}
                            className="mt-2 md:mt-14 lg:mt-2"
                        />
                    )}
                </div>
                {simpleData.length > 0 && (
                    <CustomPagination
                        totalPages={totalPages}
                        currentPage={page}
                        onPageChange={onPageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                )}
            </div>
        </div>

    );
}

export default ActiveBotTable;
