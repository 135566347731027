import { Card, Input, Modal, Switch } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { TbArrowUp } from 'react-icons/tb';
import QRCode from 'react-qr-code';
import { Button, message, Steps, theme } from 'antd';
import { copy } from 'clipboard';
import { toast } from 'react-toastify';
import { CardContent } from '@material-ui/core';
import { FaCopy } from 'react-icons/fa6';

function Auth({ profile, View_Profile }) {

  const [changeOpt, setChangeOtp] = useState("");
  const [twofaDeatil, setTwofaDeatil] = useState('');
  const jwt = localStorage.getItem("jwt");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [copyText, setCopyText] = useState("");

  const handleCopyText = (e) => {
    setCopyText(e.target.value);
  };

  const copyToClipboard = () => {
    copy(

      (twofaDeatil?.Key ? twofaDeatil?.Key : "")
    );
    toast(
      `You have copied the key
      ${twofaDeatil?.Key ? twofaDeatil?.Key : ""}
      }"`
    );
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const handle2fa = (e) => {
    setChangeOtp(e.target.value);
  };


  const steps = [

    {
      title: 'Scan QR',
      content: <div>
        <div className='leading-none mt-4'>
          <h1 className='font-semibold text-center'>Scan the QR Code using the Authenticator App</h1>
          <div className='flex justify-center items-center'>
            <div className="mb-4 w-full sm:w-2/3 flex justify-center items-center pt-5 px-8 flex-row rounded-3xl gap-4">
              <Card className="shadow-neumorphicnew bg-colorPrimary">

                <QRCode value={twofaDeatil ? twofaDeatil?.URI : ''} renderAs="canvas" />
              </Card>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center mx-2">
          <CardContent className="copy py-0 px-0">
            <div className="flex align-center border border-[#ccc] border-dotted py-1 px-2 rounded-2xl w-full max-w-xl">
              <input
                className="text-sm font-semibold w-full"
                value="Click here to copy the key"
                onChange={handleCopyText}
                color="#fff"
              />
              <button
                onClick={copyToClipboard}
                className="border-none hover:bg-active"
              >
                <FaCopy className="text-neonPurple" />
              </button>
            </div>
          </CardContent>
        </div>

      </div>,
    },
    {
      title: 'Enter TOTP',
      content: <div className='py-4'>
        <div className="text-start  leading-none flex flex-col items-center justify-center w-full ">
          <div className=" my-3 w-full md:w-1/2">
            <Input
              placeholder="Enter TOTP"
              value={changeOpt}
              onChange={handle2fa}
              className="shadow-neumorphicnew px-2 bg-colorPrimary rounded-full"
            />
          </div>
        </div>
      </div>
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const handleSwitchChange = (checked) => {
    if (!checked) {
      setIsModalVisible1(true);
    } else {
      setIsModalVisible(true);
      activeTotp();
    }
  };


  const activeTotp = () => {
    const postdata = {
      jwt: jwt
    }

    const response = axios({
      method: 'POST',
      headers: {
        'accept': 'application/json'
      },
      url: process.env.REACT_APP_API_PATH_NEW + "/user/active_totp",
      data: postdata
    }).then(response => {
      setTwofaDeatil(response?.data?.Success)

    })
      .catch(err => console.log(err))

  }

  const changeTotp = (value, otp) => {
    const postdata = {
      jwt: jwt,
      status: value,
      totp: changeOpt
    }
    const response = axios({
      method: 'POST',
      headers: {
        'accept': 'application/json'
      },
      url: process.env.REACT_APP_API_PATH_NEW + "/user/change_totp_status",
      data: postdata
    }).then(res => {
      toast.success(res.data.Success)
      setIsModalVisible(false)
      setIsModalVisible1(false)
      View_Profile()
    })
      .catch(err => {
        toast.error(err.response.data.Error)
      }
      )
  }

  return (
    <>
      <Switch onChange={handleSwitchChange} checked={profile} className={`${profile ? "bg-green-500" : "bg-red-500"}`} />
      <Modal
        title={<div className='md:text-base text-sm'>Activate Two-Factor Authentication</div>}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        footer={false}
      >


        <Steps current={current} items={items} />
        <div style={contentStyle}>{steps[current].content}</div>
        <div
          className='flex justify-end items-center'
          style={{
            marginTop: 24,
          }}
        >
          {current < steps.length - 1 && (
            <button type="primary" className='bg-neonPurple px-7 py-2 rounded-2xl text-sm text-white' onClick={() => next()}>
              Next
            </button>
          )}
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}

          {current === steps.length - 1 && (
            <button
              className='bg-neonPurple px-7 py-2 rounded-2xl text-sm text-white'
              onClick={() => changeTotp(true)}
            >
              <span>Done</span>
            </button>

          )}


        </div>
      </Modal >
      <Modal
        title={<div className='md:text-base text-sm'>Activate Two-Factor Authentication</div>}
        visible={isModalVisible1}
        onCancel={() => setIsModalVisible1(false)}
        onOk={() => setIsModalVisible1(false)}
        footer={false}
      >
        <div className='py-4'>
          <div className="text-start  leading-none flex flex-col items-center justify-center w-full ">
            <div className=" my-3 w-full md:w-1/2">
              <Input
                placeholder="Enter TOTP"
                value={changeOpt}
                onChange={handle2fa}
                className="shadow-neumorphicnew px-2 bg-colorPrimary rounded-full"
              />

            </div>
          </div>
          <div className='flex justify-end items-center'>
            <button
              className='bg-neonPurple px-7 py-2 rounded-2xl text-sm text-white'
              onClick={() => changeTotp(false)}
            >
              <span>Done</span>
            </button>
          </div>
        </div>
      </Modal>

    </>
  );
}

export default Auth;