import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SkeltorLoader from "./SkeltorLoader";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";
import { render } from "@testing-library/react";

function TradesTable(props) {
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);

  const { selectedBotID, type, coin, filter1 } = props;

  const [
    filterexchange,
    filter,
    typedata,
    apiname,
    botname,
    filterbotData,
    filterData,
  ] = props.data;


  const columns = [
    {
      title: "DateTime",
      dataIndex: "DateTime",
       width: undefined,
        className: 'whitespace-nowrap', 
      render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Exchange",
      dataIndex: "Exchange",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Type",
      dataIndex: "Type",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "API Name",
      dataIndex: "API_Name",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Exchange Type",
      dataIndex: "Exchange_Type",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "BOT Name",
      dataIndex: "BotName",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Symbol",
      dataIndex: "Symbol",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Order Type",
      dataIndex: "Order_Type",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "OrderID",
      dataIndex: "OrderID",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Trade Direction",
      dataIndex: "Trade_Direction",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Side",
      dataIndex: "Side",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Price",
      dataIndex: "Price",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
    {
      title: "Status",
      dataIndex: "Status",
       width: undefined,
        className: 'whitespace-nowrap', 
        render: (text) => {
        return <span>{text ? text : "--"}</span>;
      }
    },
  ];

  // const filteredData = data.filter((trade) => {
  //   return coin.length === 0 || coin.includes(trade.symbol);
  // });


  const rows = [];

  if (filteredData) {
    let filteredRows = filteredData;


    for (let i = 0; i < filteredRows.length; i++) {
      const unixTimestamp = filteredRows[i]?.DateTime;
      const date = new Date(unixTimestamp);

      const formattedDateTime = date?.toLocaleString();
      rows.push({
        key: i,
        DateTime: formattedDateTime,
        API_Name: filteredRows[i]?.API_Name,
        Trade_Direction: filteredRows[i]?.Trade_Direction,
        OrderID: filteredRows[i]?.OrderID,
        Side: filteredRows[i]?.Side,
        Type: filteredRows[i]?.Type,
        Quantity: filteredRows[i]?.Quantity,
        Price: filteredRows[i]?.Price,
        Order_Type: filteredRows[i]?.Order_Type,
        Exchange: filteredRows[i]?.Exchange,
        Symbol: filteredRows[i]?.Symbol,
        Status: filteredRows[i]?.Status,
        Exchange_Type: filteredRows[i]?.Exchange_Type,
        BotName: filteredRows[i]?.BotName,
      });
    }
  }

  const onChange = (pagination) => {
    // setpagesize(pagination.pageSize);
    setPage(pagination.current);
    setSize(pagination.size);
  };

  async function handleFilterpage() {
    const jwt = localStorage.getItem("jwt");

    let postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      page_name: "Trades",
      page_size: size,
      // exchange: filter,
      // exchange_type: type,
      // type: typedata,
      // api_name: apiname,
      // bot_id: botname,
      // bot_name: filterbotData,
      // // bot_name: filterbotData.length > 0 ? filterbotData : [],
      // bot_name: selectedBotID.length > 0 ? selectedBotID : filterbotData,
      // // symbol: coin,
      // symbol: coin.length > 0 ? coin : filterData,
      // symbol: coin.length > 0 ? coin : [],
    };

    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_FIL + "total_pages",
      data: postData,
    })
      .then((res) => {
        //  const filteredSymbols = response?.data?.Data?.filter(
        //    (item) => item.Exchange_Type === filterexchange
        //  );
        // setFilteredData(res?.data?.Success);
        setTotalPages(res?.data?.Success);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // toast.error(err.response.data.Error);
      });
  }

  const trades_transactions = async () => {
    const jwt = localStorage.getItem("jwt");

    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      // exchange: filter,
      // exchange_type: type,
      // type: typedata,
      // api_name: apiname,
      // bot_id: botname,
      // // bot_name: filterbotData.length > 0 ? filterbotData : [],
      // bot_name: selectedBotID.length > 0 ? selectedBotID : filterbotData,
      // // bot_name: filterbotData,
      // // symbol: coin,
      // symbol: coin.length > 0 ? coin : filterData,
      // symbol: coin.length > 0 ? coin : [],
      page_no: page,
      page_size: size,
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT + "order_book",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data?.Data;
        setFilteredData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
        setLoading(false);
      });
  };

  useEffect(() => {
   
      trades_transactions();
      handleFilterpage();
  
  }, [
   
  ]);

  
  const isMobile = useMediaQuery({ maxWidth: 1050 });

  const pageSizeOptions = [30, 50, 100];

  if (isMobile) {
    // Render card view for mobile
    return (
      <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">
        {rows.map((record) => (
          <div
            key={record.TransID}
            className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
          >
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">Date Time:</div>
              <div className="card-data">{record.DateTime || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
              <div className="card-title font-semibold ">Exchange:</div>
              <div className="card-data">{record.Exchange || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
              <div className="card-title font-semibold ">API Name:</div>
              <div className="card-data">{record.API_Name || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">Exchange Type:</div>
              <div className="card-data">{record.Exchange_Type || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">BOT Name:</div>
              <div className="card-data">{record.BotName || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">Symbol:</div>
              <div className="card-data">{record.Symbol || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">Order Type:</div>
              <div className="card-data">{record.Order_Type || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">OrderID:</div>
              <div className="card-data">{record.OrderID || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">Trade Direction:</div>
              <div className="card-data">{record.Trade_Direction || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">Side:</div>
              <div className="card-data">{record.Side || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">Price:</div>
              <div className="card-data">{record.Price || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold">Quantity:</div>
              <div className="card-data">{record.Quantity || "--"}</div>
            </div>
            <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
              <div className="card-title font-semibold ">Status:</div>
              <div className="card-data">{record.Status || "--"}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  const onPageChange = (page) => {

    setPage(page);
    //  setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    // Your logic to handle page size change
    setSize(size);
  };


  return (
    <div className="container mx-auto">
      <div className="mt-2   table-newres ">
       
        {loading ? (
          <div className="w-full h-40 rounded-tr-3xl rounded-tl-3xl bg-colorPrimary shadow-neumorphic ">
            <div className="w-full h-14 bg-neonPurple ">
              <SkeltorLoader />
            </div>
          </div>
        ) : (
          <Table
            className="overflow-x-scroll my-2 2xl:overflow-x-hidden overflow-y-hidden"
            columns={columns}
            dataSource={rows}
            // onChange={onChange}
            pagination={false}
            pageSize={size}
            currentPage={page}
        
          />
        )}
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
}

export default TradesTable;
