import React, { useEffect, useState, useRef } from "react";
import StaticImg1 from "../assets/Static/bitfinex.svg";
import StaticImg2 from "../assets/Static/bybit.svg";
import StaticImg3 from "../assets/Static/crypto-com.svg";
import StaticImg4 from "../assets/Static/gate-io.svg";
import StaticImg5 from "../assets/Static/HTX.svg";
import StaticImg6 from "../assets/Static/kraken.svg";
import StaticImg7 from "../assets/Static/kucoin.svg";
import StaticImg8 from "../assets/Static/mexc.svg";
import StaticImg9 from "../assets/Static/okx.svg";
import StaticImg10 from "../assets/Static/binance.svg";
import { Tooltip } from "antd";
import { Form, Modal, message } from "antd";
import { copy } from "clipboard";
import { toast } from "react-toastify";
import { CardContent } from "@material-ui/core";
import { FaCopy } from "react-icons/fa6";
import axios from "axios";
import UpdateApi from "./UpdateApi";
import { FaLock } from "react-icons/fa";

const SyntaxGenerator = () => {
  const [exchange, setExchange] = useState("Binance");
  const projectName = process.env.REACT_APP_DEMO || "Default Project";
  const [orderType, setOrderType] = useState("");
  const [showOrderType, setShowOrderType] = useState();
  const [name, setName] = useState();
  const [symbol, setSymbol] = useState();
  const [quantity, setQuantity] = useState();
  const [transiction, setTransiction] = useState();
  const [order, setOrder] = useState();
  const [tradSym, setTradSym] = useState();
  const [mode, setMode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api_name, setApi_Name] = useState("");
  const jwt = localStorage.getItem("jwt");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [optionsForSelect2, setOptionsForSelect2] = useState([]);
  const [errors, setErrors] = useState({});
  const [showCopyButton, setShowCopyButton] = useState(false);
  const { profile } = UpdateApi();
  const username = profile.Username;
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkBotPackage = checkBotFeature.Trading_View_Bot;

  const divStyle = {
    wordWrap: "break-word",
    overflowWrap: "break-word",
    wordBreak: "break-word",
  };

  const futureData = [
    { Img: StaticImg1 },
    { Img: StaticImg2 },
    { Img: StaticImg3 },
    { Img: StaticImg4 },
    { Img: StaticImg5 },
    { Img: StaticImg6 },
    { Img: StaticImg7 },
    { Img: StaticImg8 },
    { Img: StaticImg9 },
    {
      Img: StaticImg10,
      exchange: "Binance",
    },
  ];
  useEffect(() => {
    setOrderType();
    setSymbol();
    setQuantity();
    setTransiction();
    setName();
    setTradSym();
    setMode();
    setOrder();
    setSelectedOption();
    //setPS()
  }, [exchange]);

  const handleExchangeClick = (selectedExchange) => {
    if (selectedExchange === "Binance") {
      setExchange("Binance");
    } else {
      setExchange(selectedExchange);
    }
  };

  const optionsForSelect1 = [
    "BNBUSDT",
    "BTCUSDT",
    "ETHUSDT",
    "OCEANUSDT",
    "SOLUSDT",
    "ADAUSDT",
    "MANAUSDT",
    "GALAUSDT",
    "DOGEUSDT",
    "XRPUSDT",
  ];

  const syntax = {
    SY: symbol,
    Q: quantity,
    S: transiction,
    OT: orderType,
    TT: showOrderType,
    TIF: tradSym,
    PR: order,
    API: selectedOption,
    PS: mode,
  };

  const jsonString = JSON.stringify(syntax);
  const displayText = jsonString === "{}" ? "No Syntax Generated" : jsonString;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setOrderType();
    setSymbol();
    setQuantity();
    setTransiction();
    setTradSym();
    setMode();
    setSelectedOption();
    setSelectedSymbol();
    setName();
    setOrder();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setOrderType();
    setSymbol();
    setQuantity();
    setTransiction();
    setTradSym();
    setMode();
    setSelectedOption();
    setSelectedSymbol();
    setName();
    setOrder();
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const textareaRef = useRef(null);

  const copyToClipboard = () => {
    textareaRef.current.select();
    document.execCommand("copy");
    message.success("Successfully Coppied");
    setIsModalOpen(false);
  };

  const clearInputs = () => {
    setOrderType();
    setSymbol();
    setQuantity();
    setTransiction();
    setTradSym();
    setMode();
    setName();
    setSelectedOption();
    setOrder();
  };

  const [copyTextRight, setCopyTextRight] = useState("");
  const handleCopyTextRight = (e) => {
    setCopyTextRight(e.target.value);
  };

  const copyToClipboardRight = () => {
    copy(`https://uapi3.odecents.com/webhook/${username}`);
    toast(`You have copied the webhook url`);
  };

  const [isComingSoon, setIsComingSoon] = useState(true);


  const exchage_bot_list = async (orderType) => {
    try {
      const postdata = {
        project: process.env.REACT_APP_PROJECT,
        jwt: jwt,
        exchange_type: orderType,
        exchange: "BINANCE",
      };
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_API + "apiname_options",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setOptionsForSelect2(response?.data?.Success);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    setErrors((prevErrors) => {
      const { selectedOption, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const handleOptionChangeSymbol = (event) => {
    const selectedValue = event.target.value;
    setSymbol(selectedValue);

    setErrors((prevErrors) => {
      const { symbol, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const handleExchange = (type) => {
    setShowOrderType(type);
    // clearInputs();
    exchage_bot_list(type);
    setErrors((prevErrors) => {
      const { showOrderType, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const handleOrderTypeChange = (type) => {
    setOrderType(type);

    // Clear specific error for orderType when a new type is selected
    setErrors((prevErrors) => {
      const { orderType, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const handleModeChange = (mode) => {
    setMode(mode);

    // Clear specific error for mode when a new mode is selected
    setErrors((prevErrors) => {
      const { mode, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const handleQuantityChange = (e) => {
    const newValue = e.target.value;
    setQuantity(newValue);

    // Clear specific error for quantity when a new value is entered
    setErrors((prevErrors) => {
      const { quantity, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const handleTradSymClick = (sym) => {
    setTradSym(sym);

    // Clear specific error for tradSym when a new symbol is selected
    setErrors((prevErrors) => {
      const { tradSym, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const handleOrderChange = (e) => {
    const newValue = e.target.value;
    setOrder(newValue);

    // Clear specific error for order when a new value is entered
    setErrors((prevErrors) => {
      const { order, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const handleMode = (modeType) => {
    setMode(modeType);
  };

  const handleTransaction = (transactionType) => {
    setTransiction(transactionType);
    setErrors((prevErrors) => {
      const { transiction, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset errors
    setErrors({});

    // Validation checks
    const newErrors = {};

    // Validate Exchange Type
    if (!showOrderType) {
      newErrors.showOrderType = "Please select an exchange type";
    }

    // Validate Order Type
    if (!orderType) {
      newErrors.orderType = "Please select an order type";
    }

    // Validate Mode
    if (showOrderType === "FUTURES" && !mode) {
      newErrors.mode = "Please select a mode type";
    }

    // Validate API
    if (!selectedOption) {
      newErrors.selectedOption = "Please select an API";
    }

    // Validate Symbol
    if (!symbol) {
      newErrors.symbol = "Please select a trading symbol";
    }

    // Validate Quantity
    if (!quantity) {
      newErrors.quantity = "Please enter a quantity";
    }

    // Validate Transaction
    if (!transiction) {
      newErrors.transiction = "Please select a transaction type";
    }

    // Validate Order Price (only for LIMIT orders)
    if (orderType === "LIMIT" && !order) {
      newErrors.order = "Please enter a price";
    }

    if (orderType === "LIMIT" && !tradSym) {
      newErrors.tradSym = "Please select an tradSym";
    }

    // Update errors state
    setErrors(newErrors);


    const isValid = Object.keys(newErrors).length === 0;

    setShowCopyButton(isValid);

    if (isValid) {
      console.log(newErrors.length);

      console.log("Form submitted successfully!");
    } else {
      console.log(newErrors.length);

      console.log("Form validation failed!");
    }
  };

  const [form] = Form.useForm();

  return (
    <div className="flex justify-center flex-col mx-4 relative container">
      {/* <div> */}
      {checkBotPackage === false ? (
        <div
          className="absolute syntax inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
        >
          <FaLock className="text-gray-600 text-6xl mb-4" />
          <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
          <p className="mb-4">
            To access this feature, please upgrade your Package.
          </p>
        </div>
      ) : (
        <div className="">
          <div className="md:text-lg text-sm text-center font-bold">
            <h6>Choose an Exchange</h6>
          </div>

          <div className=" grid  grid-cols-1  w-full gap-10 ">
            {/* // left side starts  */}
            <div className=" flex  w-auto gap-1 justify-between shadow-lg p-[10px]  ">
              <div className="grid lg:grid-cols-5 xl:grid-cols-10 gap-4 w-full">
                {futureData?.reverse().map((item, index) => {
                  const isActive =
                    exchange === item.exchange ||
                    (exchange === 0 && index === futureData.length - 1);
                  const isClickable =
                    item.exchange && !item.exchange.includes("Coming Soon");

                  const handleClick = () => {
                    if (isClickable) {
                      handleExchangeClick(item.exchange);
                    }
                  };

                  return (
                    <div
                      onClick={handleClick}
                      key={index}
                      className={`bg-white border-2 shadow-lg hover:scale-105 transition-all transition-5s w-full cursor-pointer  p-[20px] flex  justify-center lg:justify-start items-center rounded-lg ${isActive ? "shadow-md border-neonPurple" : ""
                        }`}
                    >
                      {isClickable ? (
                        <img
                          src={item.Img}
                          className="w-28"
                          alt={`Exchange ${item.exchange}`}
                        />
                      ) : (
                        <Tooltip title={`Coming soon...`}>
                          <div>
                            <img
                              src={item.Img}
                              className="w-28"
                              alt="Coming soon"
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="grid  lg:grid-cols-2 xl:grid-cols-3 bg--200  grid-cols-1 gap-5  mt-[10px]">

              <div className="shadow-lg p-6 rounded-lg bg-white">
                <form onSubmit={handleSubmit}>
                  <div className="md:mx-3 sm:text-xs">
                    {exchange === "Binance" && (
                      <>
                        <div>
                          <label className="block text-black text-sm font-bold mb-2">
                            Exchange Type
                          </label>
                          <div className="flex space-x-2">
                            <button
                              className={`border-2 border-black py-1 px-2 text-xs md:text-sm rounded-md cursor-pointer uppercase ${showOrderType === "FUTURES" && "bg-neonPurple text-white"
                                }`}
                              onClick={() => handleExchange("FUTURES")}
                              type="button"
                            >
                              Binance Future
                            </button>
                            <button
                              className={`border-2 border-black py-1 px-2 text-xs md:text-sm rounded-md cursor-pointer uppercase ${showOrderType === "SPOT" && "bg-neonPurple text-white"
                                }`}
                              onClick={() => handleExchange("SPOT")}
                              type="button"
                            >
                              Binance Spot
                            </button>
                          </div>
                          {errors.showOrderType && (
                            <div className="text-red-500 mt-2">{errors.showOrderType}</div>
                          )}
                        </div>

                        <div className="mt-4">
                          <label className="block text-black text-sm font-bold mb-2">
                            Order Type
                          </label>
                          <div className="flex space-x-2">
                            <button
                              className={`border-2 border-black py-1 px-2 text-xs md:text-sm rounded-md cursor-pointer uppercase ${orderType === "MARKET" && "bg-neonPurple text-white"
                                }`}
                              onClick={() => handleOrderTypeChange("MARKET")}
                              type="button"
                            >
                              Market Order
                            </button>
                            <button
                              className={`border-2 border-black py-1 px-2 text-xs md:text-sm rounded-md cursor-pointer uppercase ${orderType === "LIMIT" && "bg-neonPurple text-white"
                                }`}
                              onClick={() => handleOrderTypeChange("LIMIT")}
                              type="button"
                            >
                              Limit Order
                            </button>
                          </div>
                          {errors.orderType && (
                            <div className="text-red-500 mt-2">{errors.orderType}</div>
                          )}
                        </div>

                        {showOrderType === "FUTURES" && (
                          <div className="mt-4">
                            <label className="block text-black text-sm font-bold mb-2">
                              Position Side
                            </label>
                            <div className="flex space-x-2">
                              <button
                                className={`border-2 border-black py-1 px-4 text-xs md:text-sm rounded-md cursor-pointer uppercase ${mode === "SHORT" && "bg-neonPurple text-white"
                                  }`}
                                onClick={() => handleModeChange("SHORT")}
                                type="button"
                              >
                                Short
                              </button>
                              <button
                                className={`border-2 border-black py-1 px-4 text-xs md:text-sm rounded-md cursor-pointer uppercase ${mode === "LONG" && "bg-neonPurple text-white"
                                  }`}
                                onClick={() => handleModeChange("LONG")}
                                type="button"
                              >
                                Long
                              </button>
                              <button
                                className={`border-2 border-black py-1 px-4 text-xs md:text-sm rounded-md cursor-pointer uppercase ${mode === "BOTH" && "bg-neonPurple text-white"
                                  }`}
                                onClick={() => handleModeChange("BOTH")}
                                type="button"
                              >
                                Both
                              </button>
                            </div>
                            {errors.mode && (
                              <div className="text-red-500 mt-2">{errors.mode}</div>
                            )}
                          </div>
                        )}

                        <div className="grid lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 grid-cols-1 gap-4 mt-4">
                          <div className="flex flex-col">
                            <label className="font-semibold text-sm mb-2">
                              Select API
                            </label>
                            <select
                              id="APINAME"
                              className="shadow  border w-full py-2 px-3 bg-white text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                              onChange={handleOptionChange}
                              value={selectedOption}
                            >
                              <option value="">Select...</option>
                              {optionsForSelect2?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            {errors.selectedOption && (
                              <div className="text-red-500 mt-2">{errors.selectedOption}</div>
                            )}
                          </div>

                          <div className="flex flex-col">
                            <label className="font-semibold text-sm mb-2">
                              Trading Symbol
                            </label>
                            <select
                              id="SYMBOL"
                              name="SYMBOL"
                              className="shadow border w-full py-2 px-3 bg-white text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                              onChange={handleOptionChangeSymbol}
                              value={symbol}
                            >
                              <option value="">Select...</option>
                              {optionsForSelect1?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            {errors.symbol && (
                              <div className="text-red-500 mt-2">{errors.symbol}</div>
                            )}
                          </div>

                          <div className="flex flex-col">
                            <label className="font-semibold text-sm mb-2">
                              Select Quantity
                            </label>
                            <input
                              id="Quantity"
                              className="shadow  border w-full py-2 px-3 bg-white text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                              type="number"
                              name="Quantity"
                              placeholder="Quantity"
                              value={quantity}
                              onChange={handleQuantityChange}
                            />
                            {errors.quantity && (
                              <div className="text-red-500 mt-2">{errors.quantity}</div>
                            )}
                          </div>
                        </div>

                        <div className="mt-4">
                          <label className="block text-black text-sm font-bold mb-2">
                            Transaction Type
                          </label>
                          <div className="flex space-x-2">
                            <button
                              className={`border-2 border-black py-2 px-6 text-sm rounded-md cursor-pointer ${transiction === "BUY" && "bg-neonPurple text-white"
                                }`}
                              onClick={() => handleTransaction("BUY")}
                              type="button"
                            >
                              Buy
                            </button>
                            <button
                              className={`border-2 border-black py-2 px-6 text-sm rounded-md cursor-pointer ${transiction === "SELL" && "bg-neonPurple text-white"
                                }`}
                              onClick={() => handleTransaction("SELL")}
                              type="button"
                            >
                              Sell
                            </button>
                          </div>
                          {errors.transiction && (
                            <div className="text-red-500 mt-2">{errors.transiction}</div>
                          )}
                        </div>

                        {orderType === "LIMIT" && (
                          <>
                            <div className="mt-4">
                              <label className="block text-black text-sm font-bold mb-2">
                                Time In Force Order
                              </label>
                              <div className="flex flex-wrap gap-2">
                                {["GTC", "GTT", "IOC", "FOK", "GTX", "STP", "DTX", "FAK"].map(
                                  (sym) => (
                                    <button
                                      key={sym}
                                      className={`border-2 border-black py-1 px-4 text-sm rounded-md cursor-pointer ${tradSym === sym && "bg-neonPurple text-white"
                                        }`}
                                      onClick={() => handleTradSymClick(sym)}
                                      type="button"
                                    >
                                      {sym}
                                    </button>
                                  )
                                )}
                              </div>
                              {errors.tradSym && (
                                <div className="text-red-500 mt-2">{errors.tradSym}</div>
                              )}
                            </div>

                            <div className="mt-4">
                              <label className="block text-black text-sm font-bold mb-2">
                                Price
                              </label>
                              <input
                                id="PRICE"
                                className="shadow appearance-none border w-full py-2 px-3 bg-white text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                                type="number"
                                name="PRICE"
                                placeholder="Ex. 1"
                                value={order}
                                onChange={handleOrderChange}
                              />
                              {errors.order && (
                                <div className="text-red-500 mt-2">{errors.order}</div>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {/* <Tooltip title="Coming Soon"> */}
                  <div className="text-right mt-6">
                    <button
                      disable
                      className="bg-neonPurple text-white py-2 px-6 rounded-md"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                  {/* </Tooltip> */}
                </form>
              </div>

              <div className="grid grid-cols-1 gap-14  w-auto shadow-lg h-[300px]  ">




                <div className="border-2  border-neonPurple w-full h-auto right-0  p-6 bg-white rounded-xl shadow ">
                  <div
                    className="text-black  w-full h-full  font-bold flex flex-wrap  "
                    style={divStyle}
                  >
                    <p className="text-sm "> {displayText}</p>

                    <textarea
                      readOnly
                      ref={textareaRef}
                      style={{ position: "absolute", left: "-9999px" }}
                      value={jsonString}
                    />
                  </div>
                  {showCopyButton && (
                    <Tooltip title="Coming Soon">
                      <div className="flex justify-center mt-8">
                        <button
                          // onClick={copyToClipboard}
                          className=" w-4/4 md:ml-3 bg-neonPurple py-1 px-4 rounded-lg text-white font-semibold text-base"
                        >
                          COPY SYNTAX
                        </button>
                      </div>
                    </Tooltip>
                  )}

                </div>

                <div className="     w-full bg-neonPurple/80 h-[100px]  border-[1px] border-black  rounded-2xl py-1 px-4 flex justify-between flex-col">
                  <div>
                    <p className="text-sm text-white font-semibold pb-1  text-center">
                      WebHook
                    </p>
                    <div className="">
                      <CardContent className="copy py-0 px-0">
                        <div className="bg-white border-[1px] border-black text-sm py-3 px-6 rounded-xl text-gray-500 flex justify-between">
                          <input
                            className="text-sm w-[200px] md:w-[450px]"
                            value={`https://uapi3.odecents.com/webhook/${username}`}
                            onChange={handleCopyTextRight}
                            color="#fff"
                          />
                          <Tooltip title="Coming Soon">
                            <button
                              // onClick={copyToClipboardRight}
                              className="border-none hover:bg-active"
                            >
                              <FaCopy className="text-neonPurple" />
                            </button>
                          </Tooltip>
                        </div>
                        <p className="text-xs mt-1 text-white animate-pulse text-center">
                          !! Never Share Above URL Anywhere
                        </p>
                      </CardContent>
                    </div>
                  </div>
                </div>




              </div>

              <div className="relative flex justify-center shadow-lg p-10">
                {/* {isComingSoon && (
        <div className="absolute inset-0 overlay">
          Coming Soon
        </div>
      )} */}
                <div className={`relative ${isComingSoon ? 'blurred-bg' : ''}`}>

                  <ol className="relative border-l border-gray-200 dark:border-gray-700 text-gray-500 dark:text-gray-400">


                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                        1
                      </span>
                      <h3 className="font-medium leading-tight text-lg">WebHook</h3>
                      <p className="text-sm mt-1">Copy your WebHook URL</p>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                        2
                      </span>
                      <h3 className="font-medium leading-tight text-lg">Account Info</h3>
                      <p className="text-sm mt-1">Step details here</p>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                        3
                      </span>
                      <h3 className="font-medium leading-tight text-lg">Review</h3>
                      <p className="text-sm mt-1">Step details here</p>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                        4
                      </span>
                      <h3 className="font-medium leading-tight text-lg">Review</h3>
                      <p className="text-sm mt-1">Step details here</p>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                        5
                      </span>
                      <h3 className="font-medium leading-tight text-lg">Review</h3>
                      <p className="text-sm mt-1">Step details here</p>
                    </li>
                  </ol>
                </div>
              </div>




            </div>
          </div>
        </div>
      )}

      {/* </div> */}
      <div></div>
    </div>
  );
};

export default SyntaxGenerator;
