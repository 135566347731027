import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'

import MultiBot from '../Components/MultiBot';


const Backtest = () => {
    const [index, setIndex] = useState(0);
    
    const handleChangeIndex = (newIndex) => {
        setIndex(newIndex);
    };

    const item = [
        // "Algo Backtest",
        // "Range Backtest",
        "Scalping Bot",
        "Multi Indicator Bot"
    ]
    
    return (
        <div>
            <div className="mt-5">
          <div className="flex border-b-2 border-[#ccc6] mobilescroll mx-5">
            {item?.map((tabName, tabIndex) => (
              <div
                key={tabIndex}
                className={`tab py-1 w-auto min-w-fit uppercase px-6 mx-3 text-center cursor-pointer text-sm ${index === tabIndex
                    ? "active-tab border-gradientbottom text-black font-semibold"
                    : "text-[#666666] "
                  }`}
                  onClick={() => setIndex(tabIndex)}
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>

            <div className="">
                <SwipeableViews
                    index={index}
                    onChangeIndex={handleChangeIndex}
                    className=""
                >
                    <div className='container mx-auto  rounded-md p-2'>
                        <MultiBot />
                    </div>
                    <div className='container mx-auto  rounded-md p-2'>
                        <MultiBot />
                    </div>
                    {/* <div className='container mx-auto  rounded-md p-2'>
                        <RangeBacktest/>
                    </div>
                    <div className='container mx-auto  rounded-md p-2'>
                        <ScalpingBot />
                    </div>
                    <div className='container mx-auto  rounded-md p-2'>
                        <MultiIndicatorBot />
                    </div>
                    <div className='container mx-auto  rounded-md p-2'>
                        <CommingSoon />
                    </div>
                    <div className='container mx-auto  rounded-md p-2'>
                        <CommingSoon />
                    </div>
                    <div className='container mx-auto  rounded-md p-2'>
                        <CommingSoon />
                    </div>
                    <div className='container mx-auto  rounded-md p-2'>
                        <CommingSoon />
                    </div> */}
                </SwipeableViews>
            </div>
        </div>
    )
}

export default Backtest
