import React, { useState } from 'react';
// import Modal from 'react-modal';
import FilterFormArb from '../Components/FilterFormArb';
import { Modal } from 'antd';

const Filter = ({
    isOpen,
    setIsOpen,
    handleSubmit,
    handleClearFilters,
    exchangeOptions,
    currencyOptions,
    trackedExchanges,
    setTrackedExchanges,
    whitelistCurrencies,
    setWhitelistCurrencies,
    blacklistCurrencies,
    setBlacklistCurrencies,
    buyExchanges,
    setBuyExchanges,
    sellExchanges,
    setSellExchanges
}) => {
    return (
        <Modal
            visible={isOpen}
            title="Filter Form"
            onCancel={() => setIsOpen(false)}
            footer={[
                <button
                    key="save"
                    onClick={handleSubmit}
                    className="bg-neonPurple text-white px-4 py-1 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
                >
                    Save
                </button>,
                <button
                    key="clear"
                    onClick={handleClearFilters}
                    className="bg-gray-400 text-white px-4 py-1 rounded-md shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                    Clear Filters
                </button>
            ]}
        >
            <div className="p-4 w-full mx-auto">

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                    <FilterFormArb
                        label="Tracked exchanges"
                        options={exchangeOptions}
                        selectedOptions={trackedExchanges}
                        handleChange={setTrackedExchanges}
                    />
                    <FilterFormArb
                        label="Whitelist of currencies"
                        options={currencyOptions}
                        selectedOptions={whitelistCurrencies}
                        handleChange={setWhitelistCurrencies}
                    />
                    <FilterFormArb
                        label="Blacklist of currencies"
                        options={currencyOptions}
                        selectedOptions={blacklistCurrencies}
                        handleChange={setBlacklistCurrencies}
                    />
                    <FilterFormArb
                        label="Exchanges for buying"
                        options={exchangeOptions.filter(option => trackedExchanges.includes(option.label))}
                        selectedOptions={buyExchanges}
                        handleChange={setBuyExchanges}
                    />
                    <FilterFormArb
                        label="Exchanges for selling"
                        options={exchangeOptions.filter(option => trackedExchanges.includes(option.label))}
                        selectedOptions={sellExchanges}
                        handleChange={setSellExchanges}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default Filter;
