import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin, Table, Input, Select, Button } from "antd";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import Apisame from "./Apisame";
import UpdateApi from "./UpdateApi";

const { Option } = Select;

const SalesPrices = ({ refresh }) => {
  const [datas, setDatas] = useState(null);
  const [address, setAddress] = useState("0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D")
  const [chain, setChain] = useState("eth");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.NFT_Analyitics;

  const fetchData = () => {
    setLoading(true);
    const url =
      `https://deep-index.moralis.io/api/v2.2/nft/${address}/price?chain=${chain}`;
    const headers = {
      Accepts: "application/json",
      "X-API-Key":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MjI2MTZmLWM4MzItNDJmZS04YzI1LTA4NGZiNTAyY2ZmOCIsIm9yZ0lkIjoiMzcwMjAxIiwidXNlcklkIjoiMzgwNDY5IiwidHlwZUlkIjoiYTM5MmYyMTItZGQ1OC00NjY2LWEyMGMtN2E2MDdlZGU3YmZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDM5MzEwMjMsImV4cCI6NDg1OTY5MTAyM30.1ik9oveoExEKuAyXdSIWTKWo3i22Yp59_37ZDIJl8ew",
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const result = response.data;

        const dataArray = Array.isArray(result) ? result : [result];
        setDatas(dataArray);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (checkTradePackage === true) {
      fetchData();
    }
  }, [checkTradePackage, refresh]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleChainChange = (value) => {
    setChain(value);
  };

  const columns = [
    {
      title: "Token ID",
      dataIndex: ["last_sale", "token_id"],
      key: "token_id",
    },
    {
      title: "Payment Token Name",
      dataIndex: ["last_sale", "payment_token", "token_name"],
      key: "payment_token_name",
    },
    {
      title: "Payment Token Symbol",
      dataIndex: ["last_sale", "payment_token", "token_symbol"],
      key: "payment_token_symbol",
    },
    {
      title: "Payment Token Logo",
      dataIndex: ["last_sale", "payment_token", "token_logo"],
      key: "payment_token_logo",
      render: (token_logo) => (
        <img src={token_logo} alt="Token Logo" style={{ width: 20, height: 20 }} />
      ),
    },

    {
      title: "Last Sale Price (ETH)",
      dataIndex: ["last_sale", "price_formatted"],
      key: "last_sale_price",
    },
    {
      title: "Last Sale USD Value",
      dataIndex: ["last_sale", "usd_price_at_sale"],
      key: "last_sale_usd_value",
    },
    {
      title: "Lowest Sale Price (ETH)",
      dataIndex: ["lowest_sale", "price_formatted"],
      key: "lowest_sale_price",
    },
    {
      title: "Lowest Sale USD Value",
      dataIndex: ["lowest_sale", "usd_price_at_sale"],
      key: "lowest_sale_usd_value",
    },
  ];

  return (
    <>
      <div className="mx-5 mt-10">
        <div className="my-5 flex gap-5">
        <Input value={address} onChange={handleAddressChange} style={{ width: 250 }} />
        <Select value={chain} onChange={handleChainChange} style={{ width: 120 }}>
          <Option value="eth">ETH</Option>
          <Option value="bsc">BSC</Option>
          {/* Add more options as needed */}
        </Select>
        <Button className="" type="primary" onClick={fetchData}>Search</Button>
        </div>
        {loading ? (
          <Spin
            size="large"
            style={{ display: "block", margin: "100px auto" }}
          />
        ) : (
          <Table dataSource={datas} columns={columns} pagination={false} rowKey="token_id" />
        )}
      </div>
    </>
  );
};

export default SalesPrices;
