import React, { useState } from 'react';
import { Table } from 'antd';
import { SlEnvolope } from "react-icons/sl";
import { CiFileOn } from "react-icons/ci";
import { LuMailCheck } from "react-icons/lu";
import { BsTrash } from "react-icons/bs";
import { IoIosPrint } from "react-icons/io";
import { BsExclamationLg } from "react-icons/bs";
import { FaFolder } from "react-icons/fa";
import { FaTag } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import img from '../assets/coins/BYBITSPOT.png'
import { PiLink } from "react-icons/pi";
import ComposeMail from '../Components/ComposeMail';


const SupportPage = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            width: 250
        },
        {
            title: 'Message',
            dataIndex: 'message',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
    ];
    const data = [];
    for (let i = 0; i < 46; i++) {
        data.push({
            key: i,
            // profile: `Edward King ${i}`,
            profile:
                <div className=' flex items-center gap-4'>
                    <img src={img} className=' w-9 h-19 ' />
                    <div>
                        <p className=' text-sm font-semibold'>Whitney Peter</p>
                        <p className=' text-gray-400 text-xs'>23 Threads</p>
                    </div>
                </div>,
            message:
                <div className='text-gray-400 text-xs'>
                    <span className='font-medium text-sm text-black'> Re: New mail settings – </span>
                    Will you answer him asap?
                </div>,
            date:
                <div className='flex items-center gap-4'>
                    <PiLink size={16} /> 2:26 PM
                </div>,
        });
    }


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'odd',
                text: 'Select Odd Row',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
            {
                key: 'even',
                text: 'Select Even Row',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };

    return (
      <div>
        <div className=" container grid grid-cols-5 gap-4">
          <div className="col-span-1 ">
            <div className="  rounded-xl">
              <div
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className=" bg-neonRed cursor-pointer text-white text-center py-3 rounded-xl "
              >
                Compose
              </div>
              <div className="shadow-xl border-[1px] border-gray-200 rounded-xl mt-4">
                <div className=" flex bg-neonBlue items-center gap-7 pl-4 py-3 rounded-t-xl">
                  <SlEnvolope
                    className=" text-neonPurpleShadow font-medium"
                    size={18}
                  />
                  <div>
                    <p className=" text-neonPurpleShadow text-sm font-semibold">
                      All Inbox
                    </p>
                    {/* <p className=" text-gray-400 text-sm">
                      How to Boost Website
                    </p> */}
                  </div>
                  <span className=" text-sm bg-neonPurpleShadow rounded-full text-white px-2 py-1">
                    18
                  </span>
                </div>
              
                <div className=" border-b-[1px] border-gray-200 flex items-center gap-7 pl-4 py-3">
                  <LuMailCheck className="  font-medium" size={18} />
                  <div>
                    <p className="  text-sm font-medium">Sent Mail</p>
                    {/* <p className=" text-gray-400 text-sm">
                      Successfully messages
                    </p> */}
                  </div>
                  <span className=" text-sm bg-neonGreenShadow rounded-full text-white px-2 py-1">
                    08
                  </span>
                </div>
                <div className=" flex items-center gap-7 pl-4 py-3 ">
                  <BsTrash className=" font-medium" size={18} />
                  <div>
                    <p className=" text-sm font-medium">Trash</p>
                    {/* <p className=" text-gray-400 text-sm">Removed messages</p> */}
                  </div>
                  <span className=" text-sm bg-neonPurpleShadow rounded-full text-white px-2"></span>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-span-4 shadow-neumorphic rounded-xl px-4">
            <div className="flex justify-start py-4">
              <div></div>
              {/* <div className="flex items-center gap-8"> */}
               
                <div className="flex items-start gap-4 font-medium text-black  py-2 px-4 rounded-xl">
                  Inbox{" "}
                {/* </div> */}
              </div>
            </div>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              showHeader={false}
            />
          </div>
        </div>

        {/* Compose Mail Modal  */}
        <ComposeMail data={{ handleCancel, isModalOpen, setIsModalOpen }} />
      </div>
    );
}

export default SupportPage
