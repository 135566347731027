import React, { useEffect, useState } from "react";

import axios from "axios";
import img1 from "../assets/Api/BINANCEFUTURES.png";
import img2 from "../assets/Api/BINANCESPOT.png";
import img5 from "../assets/Api/BYBITFUTURES.png";
import img6 from "../assets/Api/BYBITSPOT.png";
import img3 from "../assets/Api/KUCOINFUTURES.png";
import img4 from "../assets/Api/KUCOINSPOT.png";

import { Empty } from "antd";
import { ViewReport } from "../Components/ViewReport";

function KucoinReport() {


  const [filterBot, setFilterBot] = useState([]);
  console.log(filterBot);
  
  const [loading, setLoading] = useState(true);

  const images = {
    BINANCEFUTURES: img1,
    BINANCESPOT: img2,
    KUCOINSPOT: img4,
    KUCOINFUTURES: img3,
    BYBITFUTURES: img5,
    BYBITSPOT: img6,
  };
  const handleSubmit = async () => {
    const jwt = localStorage.getItem("jwt");
    const postData = {
     
      jwt: jwt,
     
    };
    try {
      const response = await axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_BOT + "/report/get_report",
        data: postData,
      });

      const res = response?.data;
      if (res?.Success) {
        setFilterBot(res.Success);
        setLoading(false);
      } else {
        console.log(res?.Error);
      }
    } catch (err) {
      console.log(err?.response?.data?.Error);
      setLoading(false);
      if (err?.response?.data?.Error === "No report yet!") {
        setFilterBot([]);
      }
    }
  };

  useEffect(() => {
  handleSubmit()
  }, [])
  
 

 

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4   gap-3 lg:gap-5">
        {loading
          ? Array.from({ length: 8 }).map((_, index) => (
              <div
                className="bg-colorPrimary h-auto shadow-neumorphic rounded-lg shadow-2xl py-2 "
                key={index}
              >
                <div className="mx-4 ">
                  <div className="pt-2">
                    <div className="flex justify-around bg-colorSecondary  h-20 items-center text-white shadow-neumorphic py-[10px] rounded-[10px]">
                      <div className="flex flex-col justify-around w-1/2 px-2 gap-1"></div>
                    </div>
                    <div className="flex justify-around h-20 bg-colorPrimary py-1 shadow-neumorphicnew shadow-md text-[#212121] items-center rounded-[10px] pb-2 my-2"></div>
                    <div className="flex flex-col gap-2 h-32 bg-colorPrimary py-1 shadow-neumorphicnew rounded-md px-1 my-2">
                      <div className="pt-2 w-full ">
                        {/* <SkeltorLoader /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : filterBot.map((item, index) => {
              const imageKey = `${item?.Exchange}${item?.Exchange_Type}`;
              const imageToDisplay = images[imageKey];
              return (
                <ViewReport
                  key={index}
                  data={{
                    id: index,
                    API_Name: item?.API_Name,
                    BotName: item?.BotName,
                    Exchange: item?.Exchange,
                    Exchange_Type: item?.Exchange_Type,
                    Val_Total_Investment: item?.Val_Total_Investment,
                    Val_Balance: item?.Val_Balance,
                    Val_Profit: item?.Val_Profit,
                    Val_Position: item?.Val_Position,
                    Val_Brokerage: item?.Val_Brokerage,
                    Rel_Val_Balance: item?.Rel_Val_Balance,
                    Rel_Val_Profit: item?.Rel_Val_Profit,
                    Rel_Val_Position: item?.Rel_Val_Position,
                    Rel_Val_Brokerage: item?.Rel_Val_Brokerage,
                    Val_Days: item?.Val_Days,
                    Val_Total_Trades: item?.Val_Total_Trades,
                    Val_Winning_Trades: item?.Val_Winning_Trades,
                    Val_Losing_Trades: item?.Val_Losing_Trades,
                    Val_Hit_Rate: item?.Val_Hit_Rate,
                    Rel_Winning_Trades: item?.Rel_Winning_Trades,
                    Rel_Losing_Trades: item?.Rel_Losing_Trades,
                    Symbol: item?.Symbol,
                    imageToDisplay,
                  }}
                />
              );
            })}
      </div>
      
    </div>
  );
}

export default KucoinReport;
