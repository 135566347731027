import React, { useEffect, useState } from 'react'
import { Collapse, Button, Checkbox, Form, Input, Select, Space, message, Drawer, Spin } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
// import { error } from 'jquery';
import { CloseOutlined } from "@ant-design/icons"
import { Doughnut } from "react-chartjs-2";
import { LoadingOutlined } from '@ant-design/icons';
const { Option } = Select;


const MultiBot = () => {
    const [symb, setSymb] = useState("BTCUSDT")
    const [TrdType, setTrdType] = useState("Future")
    const [interval, setInterval] = useState("1m")
    const [prcType, setPrcType] = useState("Open")
    const [prcData, setPrcData] = useState("Binance")
    const [trdDirc, setTrdDirc] = useState("LONG")
    const [conditType, setConditType] = useState('')
    const [sellType, setSellType] = useState('Price_change')
    const [indicator, setIndicator] = useState([])
    const [slctIdic, setSlctIdic] = useState('')
    const [conditionData, setConditionData] = useState([])
    const [getParmtData, setGetParmtData] = useState([])
    const [conditionDataSell, setConditionDataSell] = useState([])
    const [getParmtDataSell, setGetParmtDataSell] = useState([])
    const [mainSetting, setMainSetting] = useState()
    const [buyCondt, setBuyCondt] = useState()
    const [report, setReport] = useState()
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)

    const required = mainSetting && buyCondt

    const handleMainSetting = (values) => {
        setMainSetting({
            ...values
        })
        message.success("Main setting data saved")
    };

    const onFinish = (values) => {
        setBuyCondt(values)
        message.success("Buy Condition data saved")
    };

    const onClose = () => {
        setOpen(false);
    };

    const donutOptions = {
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (context) => {
                        const value = context.parsed || 0;
                        return `${value}`;
                    },
                },
            },
            legend: {
                display: false,
            },
        },
        animation: {
            duration: 2000,
            easing: "easeInOutQuad",
        },
    };


    const handleSell = (values) => {
        setLoading(true)
        const Price_change = sellType == "Price_change" ? [
            {
                // ...values,
                Stop_Loss: Number(values?.Stop_Loss),
                Take_Profit: Number(values?.Take_Profit)
            }
        ] : []

        const conditions = sellType == "conditions" ? values?.sell_conditions : []

        const jwt = localStorage.getItem('jwt')
        const postData = {
            jwt: jwt,
            sell_conditions: conditions,
            sell_percent_conditions: Price_change,
            ...buyCondt,
            ...mainSetting
        }

        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_MULTIINDI + 'backtest',
            data: postData
        }).then(res => {
            setReport(res?.data?.Success)
            setOpen(true)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            message.error(err?.response?.data?.Error)
        })

    }

    const getIndicator = () => {
        const jwt = localStorage.getItem('jwt')
        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_MULTIINDI + 'indicator',
            data: { jwt: jwt }
        }).then(res => {
            const responseData = res?.data?.Success
            const trnsForm = responseData.map(item => ({
                value: item,
                label: item,
            }))
            setIndicator(trnsForm)
        })
            .catch(err => { console.log("getIndicator", err) })
    }


    useEffect(() => {
        getIndicator()
    }, [])

    const getCondition = (value, key) => {
        const jwt = localStorage.getItem('jwt')
        const postData = {
            jwt: jwt,
            indicator: value
        }

        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_MULTIINDI + 'conditions',
            data: postData
        }).then(res => {
            const responseData = res?.data?.Success
            const trnsForm = responseData.map(item => ({
                value: item,
                label: item,
            }))

            if (conditionData.length >= key) {
                let data = [...conditionData]
                data[key] = trnsForm
                setConditionData(data)
            } else {
                setConditionData([...conditionData, trnsForm])
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const getParameters = (value, key) => {
        const jwt = localStorage.getItem('jwt')
        const postData = {
            jwt: jwt,
            indicator: value
        }

        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_MULTIINDI + 'parameters',
            data: postData
        }).then(res => {
            const responseData = res?.data?.Success
            if (responseData.length >= key) {
                let data = [...getParmtData]
                data[key] = responseData
                setGetParmtData(data)
            } else {
                setGetParmtData([...getParmtData, responseData])
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const getConditionSell = (value, key) => {
        const jwt = localStorage.getItem('jwt')
        const postData = {
            jwt: jwt,
            indicator: value
        }

        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_MULTIINDI + 'conditions',
            data: postData
        }).then(res => {
            const responseData = res?.data?.Success
            const trnsForm = responseData.map(item => ({
                value: item,
                label: item,
            }))

            if (conditionDataSell.length >= key) {
                let data = [...conditionDataSell]
                data[key] = trnsForm
                setConditionDataSell(data)
            } else {
                setConditionDataSell([...conditionDataSell, trnsForm])
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const getParametersSell = (value, key) => {
        const jwt = localStorage.getItem('jwt')
        const postData = {
            jwt: jwt,
            indicator: value
        }

        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_MULTIINDI + 'parameters',
            data: postData
        }).then(res => {
            const responseData = res?.data?.Success
            if (responseData.length >= key) {
                let data = [...getParmtDataSell]
                data[key] = responseData
                setGetParmtDataSell(data)
            } else {
                setGetParmtDataSell([...getParmtDataSell, responseData])
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <>
            <div>
                <Collapse
                    className='indiactorCollapse border-4 border-neonPurple mt-10'
                    items={[
                        {
                            key: '1',
                            label: <div className=' text-black font-medium text-sm'>Step 1 :&nbsp;Main settings</div>,
                            children: <div className=' bg-colorPrimary px-10 rounded-lg py-4'>

                                <Form
                                    name="basic"
                                    labelCol={{
                                        span: 6,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={handleMainSetting}
                                    autoComplete="off"
                                >

                                    <div className=' grid grid-cols-5 gap-x-4'>
                                        <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <label
                                                    className="block capitalize text-colorText text-xs font-bold mb-1"
                                                    htmlFor="Symbol">
                                                    Symbol <span className="text-[red]">*</span>
                                                </label>
                                            </div>

                                            <Form.Item
                                                name="symbol"
                                                className=' mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing symbol',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    name="symbol"
                                                    id="symbol"
                                                    className="Hello shadow appearance-none border-none rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    onChange={(value) => setSymb(value)}
                                                    placeholder={<div className=' text-gray-400'>Select Symbol</div>}
                                                    options={[
                                                        {
                                                            value: 'BTCUSDT',
                                                            label: 'BTCUSDT',
                                                        },
                                                        {
                                                            value: 'ETHUSDT',
                                                            label: 'ETHUSDT',
                                                        },
                                                        {
                                                            value: 'XRPUSDT',
                                                            label: 'XRPUSDT',
                                                        },
                                                        {
                                                            value: 'BNBUSDT',
                                                            label: 'BNBUSDT',
                                                        },
                                                        {
                                                            value: 'ADAUSDT',
                                                            label: 'ADAUSDT',
                                                        },
                                                        {
                                                            value: 'ADAUSDT',
                                                            label: 'ADAUSDT',
                                                        },
                                                        {
                                                            value: 'OCEANUSDT',
                                                            label: 'OCEANUSDT',
                                                        },
                                                        {
                                                            value: 'SOLUSDT',
                                                            label: 'SOLUSDT',
                                                        },
                                                        {
                                                            value: 'DOGEUSDT',
                                                            label: 'DOGEUSDT',
                                                        },
                                                        {
                                                            value: 'MANAUSDT',
                                                            label: 'MANAUSDT',
                                                        },
                                                        {
                                                            value: 'GALAUSDT',
                                                            label: 'GALAUSDT',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <label
                                                    className="block capitalize text-colorText text-xs font-bold mb-1"
                                                    htmlFor="trade_type">
                                                    Trade Type <span className="text-[red]">*</span>
                                                </label>
                                            </div>
                                            {/* 
                                            <Form.Item
                                                name="trade_type"
                                                className='mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing Trade Type',
                                                    },
                                                ]}
                                            >
                                                <input
                                                    id="trade_type"
                                                    className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    name="trade_type"
                                                    placeholder='Enter Trade Type'
                                                />
                                            </Form.Item> */}

                                            <Form.Item
                                                name="trade_type"
                                                className='mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing Price Data',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="shadow Hello appearance-none border-none rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    onChange={(value) => { setPrcData(value) }}
                                                    placeholder={<div className=' text-gray-400'>Enter Trade Type</div>}
                                                    options={[
                                                        // {
                                                        //     value: 'FUTURES',
                                                        //     label: 'FUTURES',
                                                        // },
                                                        {
                                                            value: 'SPOT',
                                                            label: 'SPOT',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <label
                                                    className="block capitalize text-colorText text-xs font-bold mb-1"
                                                    htmlFor="days">
                                                    Days <span className="text-[red]">*</span>
                                                </label>
                                            </div>

                                            <Form.Item
                                                name="days"
                                                className='mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing Days',
                                                    },
                                                ]}
                                            >
                                                <input
                                                    id="days"
                                                    className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    name="says"
                                                    placeholder='Enter Days'
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <label
                                                    className="block capitalize text-colorText text-xs font-bold mb-1"
                                                    htmlFor="interval">
                                                    Interval <span className="text-[red]">*</span>
                                                </label>
                                            </div>

                                            <Form.Item
                                                name="interval"
                                                className='mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing interval',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="shadow Hello appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    onChange={(value) => { setInterval(value) }}
                                                    placeholder={<div className=' text-gray-400'>Select Interval</div>}
                                                    options={[
                                                        {
                                                            value: '1m',
                                                            label: '1m',
                                                        },
                                                        {
                                                            value: '1h',
                                                            label: '1H',
                                                        },
                                                        {
                                                            value: '1d',
                                                            label: '1D',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <label
                                                    className="block capitalize text-colorText text-xs font-bold mb-1"
                                                    htmlFor="balance">
                                                    Balance <span className="text-[red]">*</span>
                                                </label>
                                            </div>

                                            <Form.Item
                                                name="balance"
                                                className='mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing Balance',
                                                    },
                                                ]}
                                            >
                                                <input
                                                    id="balance"
                                                    className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    name="balance"
                                                    placeholder='Enter Balance'
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <label
                                                    className="block capitalize text-colorText text-xs font-bold mb-1"
                                                    htmlFor="trade_value">
                                                    Trade value <span className="text-[red]">*</span>
                                                </label>
                                            </div>

                                            <Form.Item
                                                name="trade_value"
                                                className='mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing trade value',
                                                    },
                                                ]}
                                            >
                                                <input
                                                    id="trade_value"
                                                    className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    name="trade_value"
                                                    placeholder='Enter trade value'
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <label
                                                    className="block capitalize text-colorText text-xs font-bold mb-1"
                                                    htmlFor="price_type">
                                                    Price Type <span className="text-[red]">*</span>
                                                </label>
                                            </div>

                                            <Form.Item
                                                name="price_type"
                                                className='mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing Price Type',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="shadow Hello appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    onChange={(value) => { setPrcType(value) }}
                                                    placeholder={<div className=' text-gray-400'>Price Type</div>}
                                                    options={[
                                                        {
                                                            value: 'OPEN',
                                                            label: 'OPEN',
                                                        },
                                                        {
                                                            value: 'HIGH',
                                                            label: 'HIGH',
                                                        },
                                                        {
                                                            value: 'LOW',
                                                            label: 'LOW',
                                                        },
                                                        {
                                                            value: 'CLOSE',
                                                            label: 'CLOSE',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <label
                                                    className="block capitalize text-colorText text-xs font-bold mb-1"
                                                    htmlFor="price_data">
                                                    Price Data <span className="text-[red]">*</span>
                                                </label>
                                            </div>

                                            <Form.Item
                                                name="price_data"
                                                className='mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing Price Data',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="shadow Hello appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    onChange={(value) => { setPrcData(value) }}
                                                    placeholder={<div className=' text-gray-400'>Price Data</div>}
                                                    options={[
                                                        {
                                                            value: 'BINANCE',
                                                            label: 'BINANCE',
                                                        },
                                                        {
                                                            value: 'JSON',
                                                            label: 'JSON',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div>

                                        {/* <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <label
                                                    className="block capitalize text-colorText text-xs font-bold mb-1"
                                                    htmlFor="Trade_Direction">
                                                    Trade Direction <span className="text-[red]">*</span>
                                                </label>
                                            </div>

                                            <Form.Item
                                                name="Trade_Direction"
                                                className='mb-0'
                                                validateTrigger="onBlur"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing Trade Direction',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="shadow appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                    onChange={(value) => { setTrdDirc(value) }}
                                                    placeholder={<div className=' text-gray-400'>Trade Direction</div>}
                                                    options={[
                                                        {
                                                            value: 'Long',
                                                            label: 'Long',
                                                        },
                                                        {
                                                            value: 'Short',
                                                            label: 'Short',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div> */}
                                    </div>

                                    <div>
                                        <button type="primary" htmlType="submit" className=' bg-neonPurple text-white font-semibold py-2 px-6 rounded-3xl block mx-auto'>
                                            save
                                        </button>
                                    </div>
                                </Form>
                            </div>,
                        },
                    ]}
                />

                <Collapse
                    className=' indiactorCollapse border-4 border-neonPurple mt-6'
                    items={[
                        {
                            key: '1',
                            label: <div className=' text-black font-medium text-sm'>Step 2 :&nbsp;Buy Condition</div>,
                            children: <div className=' bg-colorPrimary px-10 rounded-lg py-4'>

                                <Form
                                    name="dynamic_form_nest_item"
                                    onFinish={onFinish}
                                    autoComplete="off"
                                >
                                    <Form.List name="buy_conditions">
                                        {(fields, { add, remove }) => {
                                            return (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => {
                                                        let includesSignalValue = getParmtData[key]?.find(item => item.Name === 'Signal_Value') !== undefined;

                                                        return (
                                                            <div
                                                                key={key}
                                                                className=' flex items-center gap-4'
                                                                align="baseline">

                                                                <div className=' grid grid-cols-4 gap-4'>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'Indicator']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing Indicator',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            placeholder={<div className=' text-gray-400'>Select Condition Type</div>}
                                                                            className="shadow Hello appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                            options={indicator}
                                                                            onChange={(value) => {
                                                                                getCondition(value, key)
                                                                                getParameters(value, key)
                                                                            }}
                                                                        />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'Condition']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing Condition',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            placeholder={<div className=' text-gray-400'>Select Condition</div>}
                                                                            className="shadow Hello appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                            options={conditionData[key]}
                                                                        />
                                                                    </Form.Item>

                                                                    {
                                                                        includesSignalValue ?
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'Signal_Value']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: 'Missing Signal Value',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <input
                                                                                    id="Signal_Value"
                                                                                    className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                                    type="text"
                                                                                    name="Signal_Value"
                                                                                    placeholder='Enter Signal Value'
                                                                                />
                                                                            </Form.Item>
                                                                            :
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'Long_Window']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: 'Missing Long Window',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <input
                                                                                    id="Long_Window"
                                                                                    className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                                    type="text"
                                                                                    name="Long_Window"
                                                                                    placeholder='Enter Long Window'
                                                                                />
                                                                            </Form.Item>
                                                                    }


                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'Short_Window']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing Short Window',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <input
                                                                            id="Short_Window"
                                                                            className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                            type="text"
                                                                            name="Short_Window"
                                                                            placeholder='Enter Short Window'
                                                                        />
                                                                    </Form.Item>
                                                                </div>

                                                                <div>
                                                                    <MinusCircleOutlined className=' text-2xl pb-5 text-red-500' onClick={() => {
                                                                        remove(name)
                                                                        // getParmtData.pop()
                                                                    }} />
                                                                </div>

                                                            </div>
                                                        )
                                                    })}

                                                    <Form.Item>
                                                        <Button type="dashed" className=' text-black' onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Add field
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )
                                        }}
                                    </Form.List>
                                    <div>
                                        <button type="primary" htmlType="submit" className=' bg-neonPurple text-white font-semibold py-2 px-6 rounded-3xl block mx-auto'>
                                            save
                                        </button>
                                    </div>
                                </Form>
                            </div>,
                        },
                    ]}
                />


                <Collapse
                    className='indiactorCollapse border-4 border-neonPurple mt-6'
                    items={[
                        {
                            key: '1',
                            label: <div className=' text-black font-medium text-sm'>Step 3 :&nbsp;Sell Condition</div>,
                            children: <div className=' bg-colorPrimary px-10 rounded-lg py-4'>
                                <div>
                                    <div className=' grid grid-cols-2 text-center text-white'>
                                        <div className={`${sellType == "Price_change" ? "bg-neonPurple" : "text-black border border-[#ccc]"} mb-4 font-medium py-1 rounded-l-lg`} onClick={() => { setSellType("Price_change") }}>Price change %</div>
                                        <div className={`${sellType == "conditions" ? "bg-neonPurple" : "text-black border border-[#ccc]"} mb-4 font-medium py-1 rounded-r-lg`} onClick={() => { setSellType("conditions") }}>Conditions</div>
                                    </div>

                                    <Form
                                        name="dynamic_form_nest_item"
                                        onFinish={handleSell}
                                        autoComplete="off"
                                    >
                                        {
                                            sellType == "Price_change" ?

                                                <div className=' grid grid-cols-5 gap-x-4'>
                                                    <div className="mb-4">
                                                        <div className="flex justify-between items-center">
                                                            <label
                                                                className="block capitalize text-colorText text-xs font-bold mb-1"
                                                                htmlFor="Stop_Loss">
                                                                Stop Loss <span className="text-[red]">*</span>
                                                            </label>
                                                        </div>

                                                        <Form.Item
                                                            name="Stop_Loss"
                                                            className='mb-0'
                                                            validateTrigger="onBlur"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Missing Stop Loss',
                                                                },
                                                            ]}
                                                        >
                                                            <input
                                                                id="Stop_Loss"
                                                                className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                type="text"
                                                                name="Stop_Loss"
                                                                placeholder='Enter last name'
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="mb-4">
                                                        <div className="flex justify-between items-center">
                                                            <label
                                                                className="block capitalize text-colorText text-xs font-bold mb-1"
                                                                htmlFor="Take_Profit">
                                                                Take Profit <span className="text-[red]">*</span>
                                                            </label>
                                                        </div>

                                                        <Form.Item
                                                            name="Take_Profit"
                                                            className='mb-0'
                                                            validateTrigger="onBlur"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Missing Take Profit',
                                                                },
                                                            ]}
                                                        >
                                                            <input
                                                                id="Take_Profit"
                                                                className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                type="text"
                                                                name="Take_Profit"
                                                                placeholder='Enter Take Profit'
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                :
                                                <Form.List name="sell_conditions">
                                                    {(fields, { add, remove }) => {
                                                    
                                                        return (
                                                            <>
                                                                {fields.map(({ key, name, ...restField }) => {
                                                                    let includesSignalValue = getParmtDataSell[key]?.find(item => item.Name === 'Signal_Value') !== undefined;

                                                                    return (
                                                                        <div
                                                                            key={key}
                                                                            className=' flex items-center gap-4'
                                                                            align="baseline">

                                                                            <div className=' grid grid-cols-4 gap-4'>
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    name={[name, 'Indicator']}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: 'Missing Indicator',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        placeholder={<div className=' text-gray-400'>Select Condition Type</div>}
                                                                                        className="shadow Hello appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                                        options={indicator}
                                                                                        onChange={(value) => {
                                                                                            getConditionSell(value, key)
                                                                                            getParametersSell(value, key)
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>

                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    name={[name, 'Condition']}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: 'Missing Condition',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        placeholder={<div className=' text-gray-400'>Select Condition</div>}
                                                                                        className="shadow Hello appearance-none border rounded-2xl w-full py-2 px-3  bg-colorPrimary  text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                                        options={conditionDataSell[key]}
                                                                                    />
                                                                                </Form.Item>

                                                                                {
                                                                                    includesSignalValue ?
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, 'Signal_Value']}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Missing Signal Value',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <input
                                                                                                id="Signal_Value"
                                                                                                className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                                                type="text"
                                                                                                name="Signal_Value"
                                                                                                placeholder='Enter Signal Value'
                                                                                            />
                                                                                        </Form.Item>
                                                                                        :
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, 'Long_Window']}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Missing Long Window',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <input
                                                                                                id="Long_Window"
                                                                                                className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                                                type="text"
                                                                                                name="Long_Window"
                                                                                                placeholder='Enter Long Window'
                                                                                            />
                                                                                        </Form.Item>
                                                                                }


                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    name={[name, 'Short_Window']}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: 'Missing Short Window',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <input
                                                                                        id="Short_Window"
                                                                                        className="shadow appearance-none border rounded-2xl w-full py-2 px-3 bg-colorPrimary text-white leading-tight focus:outline-none focus:shadow-outline"
                                                                                        type="text"
                                                                                        name="Short_Window"
                                                                                        placeholder='Enter Short Window'
                                                                                    />
                                                                                </Form.Item>
                                                                            </div>

                                                                            <div>
                                                                                <MinusCircleOutlined className=' text-2xl pb-5 text-red-500' onClick={() => {
                                                                                    remove(name)
                                                                                    // getParmtData.pop()
                                                                                }} />
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                })}

                                                                <Form.Item>
                                                                    <Button type="dashed" className=' text-black' onClick={() => add()} block icon={<PlusOutlined />}>
                                                                        Add field
                                                                    </Button>
                                                                </Form.Item>
                                                            </>
                                                        )
                                                    }}
                                                </Form.List>
                                        }
                                        <div>
                                            {
                                                loading ?
                                                    <div className=' flex justify-center '>
                                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 36, }} className=' text-neonPurple' spin />} />
                                                    </div>
                                                    :

                                                    <button type="primary" htmlType="submit"
                                                        disabled={!required}
                                                        className={`bg-neonPurple text-white font-semibold py-2 px-6 rounded-3xl block mx-auto ${!required && ' cursor-not-allowed'}`}
                                                    >
                                                        Submit
                                                    </button>
                                            }
                                        </div>
                                    </Form>
                                </div>
                            </div >,
                        },
                    ]}
                />
            </div >

            <Drawer
                title={<div className=" text-colorSecondary">BackTest</div>}
                headerStyle={{
                    background: "#161a1e",
                    borderBottom: "2px solid #868e9e",
                }}
                closeIcon={<CloseOutlined style={{ color: "#d9ad00", fontSize: 18 }} />}
                onClose={onClose}
                width={550}
                open={open}>
                <div className=' bg-colorTable h-full'>
                    {
                        report &&
                        <div className='pb-10  text-colorText  rounded-xl pt-10 bg-colorTable '>
                            {/* <p className=' text-center text-2xl font-semibold text-neonPurple underline'>{symb} : {Number(tradeData?.p)?.toFixed(4)}</p> */}
                            <div className=' px-14 py-4 text-base mx-4 rounded-xl'>
                                <p className=' flex justify-between'>Initial Balance :  <span>${(report?.Initial_balance)?.toFixed(4)}</span></p>
                                <p className=' flex justify-between'>Balance :  <span>${(report?.Current_Balance)?.toFixed(4)}</span></p>
                                <p className=' flex justify-between'>Holding :  <span>${(report?.Holdings)?.toFixed(4)}</span></p>
                                {/* <p className=' flex justify-between'>Final Balance :  <span>${(report?.Balance + (report?.Quantity * tradeData?.p))??.toFixed(4)}</span></p> */}
                                {/* <hr className=' border-t-2 border-neonPurple pb-2 mt-2' /> */}
                                <p className=' flex justify-between'>Total Trades :  <span>{report?.Total_trades}</span></p>
                                <p className=' flex justify-between'>Pos Count :  <span>{report?.Positive_trades}</span></p>
                                <p className=' flex justify-between'>Positive :  <span className=' text-green-500'>${(report?.Positive)?.toFixed(4)}</span></p>
                                <p className=' flex justify-between'>Neg Count :  <span>{report?.Negative_trades}</span></p>
                                <p className=' flex justify-between'>Negative :  <span className=' text-red-500'>${(report?.Negative)?.toFixed(4)}</span></p>
                                {/* <hr className=' border-t-2 border-neonPurple pb-2 mt-2' /> */}
                                <p className=' flex justify-between'>Last Buy Price :  <span>${((report?.Last_buy))?.toFixed(4)}</span></p>
                                <p className=' flex justify-between'>Quantity :  <span>{(report?.Last_quantity)?.toFixed(8)} {symb.replace("USDT", "")}</span></p>
                                <p className=' flex justify-between'>Fees :  <span>${(report?.Brokerage)?.toFixed(4)} </span></p>
                                <p className=' flex justify-between'>PnL :  <span>${(report?.PnL)?.toFixed(4)}</span></p>
                                {/* <p className=' flex justify-between'>PnL Percentage :  <span>{((report?.PnL * 100) / (report?.Initial_Balance))?.toFixed(4)} %</span></p> */}
                                {/* <p className=' flex justify-between'>Current PnL :  <span>${report?.Last_quantity}</span></p> */}
                            </div>

                            <div className="flex justify-center mt-4">
                                {" "}
                                <div className=" w-[190px] h-[190px] my-4">
                                    <Doughnut
                                        data={{
                                            labels: ["Positive", "Negative"],
                                            datasets: [
                                                {
                                                    data: [report?.Positive, report?.Negative],
                                                    backgroundColor: [
                                                        "rgba(14, 220, 83, 0.6)",
                                                        "rgba(255, 99, 132, 0.6)",
                                                    ],
                                                    borderColor: [
                                                        "rgba(14, 220, 83, 0.6)",
                                                        "rgba(255, 99, 132, 0.6)",
                                                    ],
                                                    borderWidth: 1,
                                                },
                                            ],
                                        }}
                                        options={donutOptions}
                                        style={{ width: "75px" }}
                                    />
                                </div>
                            </div>

                            <div className=' flex justify-center gap-10 mt-10'>
                                {/* <button onClick={() => setModalOpen(true)} className='font-bold bg-colorRight rounded-md text-neonPurple p-1  border-l-neonPurple w-20  text-center my-2 rounded-r-md'>Save</button> */}
                                <button onClick={() => { setOpen(false) }} className='font-bold bg-colorRight rounded-md text-neonPurple p-1  border-l-neonPurple w-20  text-center my-2 rounded-r-md'>Cancel</button>
                            </div>
                        </div>
                    }
                </div>
            </Drawer>
        </>
    )
}

export default MultiBot
