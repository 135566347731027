import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { toast } from "react-toastify";
import SupportTable from "../Components/SupportTable";
import { HandleSession } from "../Components/HandleSession";

const useStyles = makeStyles({
  root: {
    background: "#167cac",
    textAlign: "center",
    padding: "20px",
    color:"#fff"
  },
  table: {
    minWidth: 700,
  },
  style: {
    backgroundColor: "#ffffff",
  },
  input: {
    marginBottom: "15px",
    display: "block",
    marginLeft: "auto",
    width: " max-content",
  },
  InputLabelAnimated: {
    marginLeft: "25px",
  },
  MuiInputLabel: {
    padding: "0 25px",
  },
  align: {
    textAlign: "left",
  },
  button: {
    border: "2px solid #fff",
    margin: "2% 0",
    color:"#fff"
  },
  text: {
    background: "#fff",
    paddingLeft: "10px",
  },
});

function SupportFinal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [Subject, setSubject] = useState([]);
  const [Message, setMessage] = useState("");
  const [filteredData, setFilteredData] = useState([]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubject("");
    setMessage("");
    await internaltransfer();
    handleClose();
  };

  const internaltransfer = async () => {
    const jwt = localStorage.getItem("jwt");
    const formData = new FormData();
    formData.append("jwt", jwt); 
    formData.append("subject", Subject);
    formData.append("message", Message);
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "/support/create_support",
      data: formData,
    })
      .then(async function (response) {
        if (response.data) {
          if (response.data.error) {
            toast(response.data.Error);
          } else {
            toast(response.data.Success);
            gettransactions();
          }
        }
      })
      .catch((err) => {
       HandleSession(err)
      });
  };

  const gettransactions = () => {
   const jwt = localStorage.getItem("jwt"); 
   const postData = {
    jwt: jwt,

  };
    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "/support/view_support",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data.Success;
        // if (res != "No Queries Submitted Yet !") {
        //   setFilteredData(res);
        // }
        setFilteredData(res);
        toast(response.data.Success);
      })
      .catch((error) => {
        HandleSession(error)
      });
  };

  useEffect(() => {
    gettransactions();
  }, []);


  const MAX_MESSAGE_LENGTH = 100;

  const handleChange = (event) => {
    const filteredValue = event.target.value.replace(/[^a-zA-Z0-9_@$]/g, '');
    setMessage(filteredValue);
  };

  return (
    <>
      <div className="bg-neonPurple text-white flex h-40 flex-col justify-center items-center">
        <Typography variant="h5">Raise a Support Request</Typography>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={handleClickOpen}
        >
          Click Here
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Enter Your Information</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Subject"
                value={Subject}
                onChange={(event) => setSubject(event.target.value)}
                margin="normal"
                fullWidth
                className={classes.text}
              />
              <TextField
                label="Message"
                value={Message}
                type="text"
                onChange={handleChange}
                margin="normal"
                fullWidth
                multiline
                inputProps={{
                  maxLength: MAX_MESSAGE_LENGTH,
                  pattern:"[a-zA-Z0-9_@$]+",
                }}
                minRows={3}
                className={classes.text}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              style={{ background: "#892dd8", color: "#fff" }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="mx-4 my-5">
        <SupportTable   data={{
          rankqualData: filteredData,
         
        }}/>
      </div>
    </>
  );
}

export default SupportFinal;
