import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const FormField = ({ label, type, name, value, onChange, options = [], unit, required = false }) => {
  return (
    <div className='grid grid-cols-2 items-center gap-4'>
      <label className="block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      {type === 'select' ? (
        <select
          name={name}
          value={value}
          placeholder="Select options"
          onChange={(e) => onChange(e)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          required={required}
        >
          <option value="" disabled>Select Option</option>
          {options.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      ) : type === 'multi-select' ? (
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Select options"
          onChange={(values) => onChange(name, values)}
          value={value}
          className="mt-1"
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>{option.label}</Option>
          ))}
        </Select>
      ) : (
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type={type}
            name={name}
            value={value}
            onChange={(e) => onChange(e)}
            className={`flex-1 block w-full rounded-none rounded-l-md p-2 border border-gray-300 ${unit ? 'border-r-0' : ''}`}
            required={required}
          />
          {unit && (
            <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
              {unit}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormField;
