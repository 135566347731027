import { Card, Checkbox, Col, Input, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import Auth from "./Auth";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      old_password: oldPassword,
      new_password: newPassword,
      con_password: confirmPassword,
    };

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      toast.error(
        "Password must contain at least 8 characters, including at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character."
      );
    } else if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password must match.");
    } else {
      const response = axios({
        method: "POST",
        headers: "accept: application/json",
        url: process.env.REACT_APP_API_PATH_USER_ODECENT + "update_password",
        data: postdata,
      })
        .then(async (response) => {
          const res = await response?.data;
          if(res?.Success){
            toast(res?.Success)
            setError("");
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
           
          }
          
        })
        .catch((err) => {
          toast.error(err.response.data.Error);
        });
    }
  };

  useEffect(() => {
    const requirementsMet = [
      newPassword.length >= 8,
      /[a-z]/.test(newPassword),
      /[A-Z]/.test(newPassword),
      /\d/.test(newPassword),
      /[@$!%*?&]/.test(newPassword),
    ];

    setCheckboxes(requirementsMet);
  }, [newPassword]);

  const checkPasswordRequirements = () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const requirementsMet = [
      newPassword.length >= 8,
      /[a-z]/.test(newPassword),
      /[A-Z]/.test(newPassword),
      /\d/.test(newPassword),
      /[@$!%*?&]/.test(newPassword),
    ];

    return regex.test(newPassword) && requirementsMet.every((req) => req);
  };

  const [checkboxes, setCheckboxes] = useState([
    false, // 8 characters
    false, // 1 lowercase letter
    false, // 1 uppercase letter
    false, // 1 number
    false, // 1 special character
  ]);

  return (
    <>
      <div>
        <h2 className="text-2xl my-2 italic font-semibold  text-center ">
          Update Password
        </h2>
        <div className="grid lg:grid-cols-2 mt-4 px-2 gap-10 ">
          <div className="w-full  justify-center flex-row xl:w-full h-auto rounded-3xl   gap-6 p-6  text-black items-center ">
            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="relative mb-6">
                  <label
                    htmlFor="oldPassword"
                    className="block mb-1 text-start"
                  >
                    Old Password <span className="text-[red]">*</span>
                  </label>
                  <Input.Password
                    placeholder="old password"
                    className="shadow-neumorphicnew bg-colorPrimary"
                    id="oldPassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
                <div className="relative mb-6">
                  <label
                    htmlFor="newPassword"
                    className="block mb-1 text-start"
                  >
                    New Password <span className="text-[red]">*</span>
                  </label>
                  <Input.Password
                    placeholder="new password"
                    id="newPassword"
                    className="shadow-neumorphicnew bg-colorPrimary"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
                <div className="relative mb-6">
                  <label
                    htmlFor="confirmPassword"
                    className="block mb-1 text-start"
                  >
                    Confirm Password <span className="text-[red]">*</span>
                  </label>
                  <Input.Password
                    placeholder="confirm password"
                    id="confirmPassword"
                    className="shadow-neumorphicnew bg-colorPrimary"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
                {/* {error && <p className="mb-2 text-red-500">{error}</p>} */}
                <div className="flex justify-center items-center">
                  <button
                    // type="submit"
                    className="active text-white px-4 py-2 rounded-full hover:active"
                  >
                    Change Password
                  </button>
                </div>
                {message && <p className="mt-2 text-green-500">{message}</p>}
              </form>
            </div>
          </div>
          <div className="checkboxes flex-col flex justify-start text-start gap-2 p-6">
            {checkboxes.map((checked, index) => (
              <div key={index} className="py-2">
                <Row>
                  <Col xl={24}>
                    <Checkbox checked={checked} disabled />
                    <span style={{ marginRight: 8, marginLeft: 8 }}>
                      {index === 0 && "8 characters long"}
                      {index === 1 && "1 lowercase letter"}
                      {index === 3 && "1 number digits"}
                      {index === 2 && "1 uppercase letter"}
                      {index === 4 && "1 special character"}
                    </span>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
