import { Empty, Tabs } from "antd";
import React, { useEffect, useState, useRef } from "react";
import "../assets/css/style.css";
import axios from "axios";
import TradesTable from "../Components/TradesTable";
import { toast } from "react-toastify";
import FilterComponent from "./FilterComponent";
import { LuInbox } from "react-icons/lu";

function TradesCopy(props) {
  const [filterData, setfilterData] = useState([]);
  const [filterbotData, setfilterBotData] = useState([]);
  const [typedata, setTypeData] = useState([]);
  const [apiname, setApiname] = useState([]);
  const [exchange, setexchange] = useState([]);
  const [botname, setbotname] = useState([]);
  const [filterexchange, setFilterExchange] = useState("FUTURES");
  const [showModal, setShowModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [originalSelectedOptions, setOriginalSelectedOptions] = useState([]);
  const { handleGridClick, handleListClick, handleTradesClick } = props.data;

  useEffect(() => {
    setSelectedOptions(filterData);
  }, [filterData]);

  useEffect(() => {
    setFilterExchange(filterexchange);
  }, [filterexchange]);

  useEffect(() => {
    setSelectedOptions1(filterbotData);
  }, [filterbotData]);

  useEffect(() => {
    handleFilterClick("ALL");
  }, []);

  const handleFilter = (filterType) => {
    const jwt = localStorage.getItem("jwt");
    let postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      page_name: "Trades",
      filter_type: filterType,
    };

    if (filterType === "API_Name") {
      postData.filter_type = "API_Name";
    } else if (filterType === "Exchange") {
      postData.filter_type = "Exchange";
    } else if (filterType === "Symbol") {
      postData.filter_type = "Symbol";
    } else if (filterType === "VarBotID") {
      postData.filter_type = "VarBotID";
    } else if (filterType === "Exchange_Type") {
      postData.filter_type = "Exchange_Type";
    } else if (filterType === "Type") {
      postData.filter_type = "Type";
    } else if (filterType === "BotName") {
      postData.filter_type = "BotName";
    } else {
    }

    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_FIL + "filter",
      data: postData,
    })
      .then((res) => {
        if (filterType === "Symbol") {
          setfilterData(res?.data.Success || []);
          const updatedSelectedOptions = selectedOptions.filter((option) =>
            (res?.data.Success || []).includes(option)
          );
          setSelectedOptions(updatedSelectedOptions);
        } else if (filterType === "BotName") {
          setfilterBotData(res?.data.Success || []);
          const updatedSelectedOptions1 = selectedOptions1.filter((option) =>
            (res?.data.Success || []).includes(option)
          );
          setSelectedOptions1(updatedSelectedOptions1);
        } else if (filterType === "Exchange_Type") {
          setFilterExchange([res.data.Success[0]]);
        } else if (filterType === "Type") {
          setTypeData(res.data.Success);
        } else if (filterType === "API_Name") {
          setApiname(res.data.Success);
        } else if (filterType === "VarBotID") {
          setbotname(res.data.Success);
        } else if (filterType === "Exchange") {
          setexchange(res.data.Success);
        }
        toast(res?.data?.Success);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   handleFilter("Symbol");
  //   handleFilter("Exchange_Type");
  //   handleFilter("BotName");
  //   handleFilter("VarBotID");
  //   handleFilter("Exchange");
  //   handleFilter("API_Name");
  //   handleFilter("Type");
  // }, []);

  const handleFilterClick = (exchangeType) => {
    if (exchangeType === "ALL") {
    } else {
      setFilterExchange([exchangeType]);
    }
  };

  const [originalSelectedOptions1, setOriginalSelectedOptions1] = useState([]);

  const handleCloseModal = () => {
    setSelectedOptions1([...originalSelectedOptions1]);
    setSelectedOptions([...originalSelectedOptions]);
    setShowModal(false);
  };

  useEffect(() => {
    setOriginalSelectedOptions([...selectedOptions]);
    setOriginalSelectedOptions1([...selectedOptions1]);
  }, [showModal]);

  const Exchanges = [
    {
      name: "Future",
      click: "FUTURES",
    },
    {
      name: "Spot",
      click: "SPOT",
    },
  ];

  const item = [
    {
      name: "Future",
      click: "FUTURES",
    },
    {
      name: "Spot",
      click: "SPOT",
    },
  ];

  return (
    <>
   

      <div className="container mx-auto">
        <div className="md:px-5 px-0 lg:mt-0">
          <TradesTable
            data={[
              filterexchange,
              exchange,
              typedata,
              apiname,
              botname,
              filterbotData,
              filterData,
            ]}
            type={filterexchange}
            coin={selectedOptions}
            selectedBotID={selectedOptions1}
          />
        </div>
      </div>
    </>
  );
}

export default TradesCopy;
