import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form} from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { HandleSession } from "../Components/HandleSession";
import { FcApproval, FcCancel, FcOk } from "react-icons/fc";


function Signup() {
  
  const [check, setCheck] = useState({
    username: "",
    emailid: "",
    name: "",
    password: "",
    conf_password: "",
    sponsor: "",
    side: "",
  });
  const [sideCheck, setSideCheck] = useState("");
  const [isLabelAbove, setIsLabelAbove] = useState({
    username: false,
    emailid: false,
    password: false,
    conf_password: false,
    name: false,
    sponsor: false,
    side: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [checkpasswordvisibility, setCheckPasswordVisibility] =
    useState("hidden");
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [condintionalRender, setCondintionalRender] = useState(false);
  const [addSponsor, setAddSponsor] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [isConfirmPasswordError, setIsConfirmPasswordError] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false);


  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleFocusPasswordInput = () => {
    setIsPasswordInputFocused(true);
    handleFocus("password");
  };

  const handleBlurPasswordInput = () => {
    setIsPasswordInputFocused(false);
    handleBlur("password");
  };

  const handleFocus = (name) => {
    setIsLabelAbove((prevState) => ({ ...prevState, [name]: true }));
    setCheckPasswordVisibility("block");
  };
  const handleBlur = (name) => {
    setIsLabelAbove((prevState) => ({
      ...prevState,
      [name]: check[name] !== "",
    }));
    setCheckPasswordVisibility("hidden");
  };
  const [checkboxes, setCheckboxes] = useState([
    false, 
    false, 
    false,
    false, 
    false, 
  ]);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setCheck((prevState) => ({
      ...prevState,
      password: e.target.value,
    }));
  };

  const handleconf_passwordChange = (e) => {
    const newconf_password = e.target.value;
    setCheck((prevState) => ({
      ...prevState,
      conf_password: newconf_password,
    }));
    setIsConfirmPasswordFocused(true);
    // setPasswordMatch(prevState => prevState && check.password === newconf_password);
  };

  const [isEmailValid, setIsEmailValid] = useState(true);


  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setCheck((prevState) => ({
      ...prevState,
      emailid: newEmail,
    }));
    setIsEmailValid(validateEmail(newEmail));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const requirementsMet = [
      password.length >= 8,
      /[a-z]/.test(password),
      /[A-Z]/.test(password),
      /\d/.test(password),
      /[@$!%*?&]/.test(password),
    ];
    setCheckboxes(requirementsMet);
  }, [password]);

  useEffect(() => {
    setPasswordMatch(check.password === check.conf_password);
  }, [check.password, check.conf_password]);

  // const passwordMatch = password === check.conf_password;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheck((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {

    if (event) {
      event.preventDefault();
    }

    if (!isCheckboxChecked) {
      toast.error("Please accept the Terms & Conditions before submitting.");
      return;
    }

    if (!check.password) {
      toast.error("Please enter a password.");
      return;
    }

    if (check.password !== check.conf_password) {
      toast.error("Password and confirm password do not match.");
      return;
    }

    const requirementsMet = [
      check.password.length >= 8,
      /[a-z]/.test(check.password),
      /[A-Z]/.test(check.password),
      /\d/.test(check.password),
      /[@$!%*?&]/.test(check.password),
    ];

    if (requirementsMet.some((requirement) => !requirement)) {
      toast.error("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character (@$!%*?&).");
      return;
    }

    if (!validateEmail(check.emailid)) {
      toast.error("Please enter a valid email address");
      return;
    }

    const formData = new FormData();

    const { username, emailid, password, conf_password, name, sponsor,side } = check;
    formData.append("project", process.env.REACT_APP_PROJECT);
    formData.append("username", username);
    formData.append("email", emailid);
    formData.append("page", "Email Verification");
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "get_otp",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          toast(res?.Success);
          navigate(`/email_verify?email=${encodeURIComponent(res.Email)}&username=${username}`, {
            state: {
              username: username,
              email: emailid,
              name: name,
              password: password,
              con_password: conf_password,
              sponsor: sponsor,
              side: side.replace("left", "0").replace("right", "1"),
            },
          });
        } else {
          toast.error(res?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  const getUrlParameter = (name) => {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[?&]" + name + "=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const checkUser = async (username,side) => {
    const postData = {
      project: process.env.REACT_APP_PROJECT,
      username: username,
    };

    const response = await axios({
      method: "POST",
      headers: "accept: application/json",
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "username_avail",
      data: postData,
    })
      .then((response) => {
        console.log("response checkUser", response);
        if (response.data.Status === false) {
         
          if (side === "left" || side === "right") { 
            setCondintionalRender(true);
            setAddSponsor("");
          }else{
            setSideCheck("Check Your Position Link");
          }
        } else {
          setAddSponsor("Check Your Sponsor Name");
        }
      })
      .catch((err) => {
        setCondintionalRender(false);
        setAddSponsor("")
        HandleSession(err)
      });
  };

  useEffect(() => {
    const refByValue = getUrlParameter("ref_by");
    const refByValueside = getUrlParameter("side");
    checkUser(refByValue,refByValueside);

    if (refByValue) {
      console.log("value ==>>", refByValue);
    }

    setCheck((prevState) => ({
      ...prevState,
      sponsor: refByValue,
      side: refByValueside,
    }));
  }, []);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const username = e.target.value.trim().toLowerCase();
    setCheck({
      ...check,
      [name]: username,
    });

    if (username !== "") {
      getusername(username);
    }
  };

  const getusername = async (username) => {
    const postData = {
      project: process.env.REACT_APP_PROJECT,
      username: username,
    };

    const response = await axios({
      method: "POST",
      headers: "accept: application/json",
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "username_avail",
      data: postData,
    })
      .then((response) => {
        setApiResponse(response.data.Success);
      })
      .catch((err) => {
        setApiResponse(err?.response?.data?.Error);
      });
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  if (condintionalRender) {
    return (
      <>
        <div className="container mx-auto md:mt-20 px-2">
          <div className="flex justify-center items-center h-full">
            <div className="flex justify-center w-full bg-colorPrimaryDark  relative rounded-2xl  overflow-hidden">
              <div className="md:mx-auto mt-2 justify-center w-full">
                <div className="text-center">
                  <div className="md:text-3xl font-semibold mb-3 hidden md:block">
                    Create an account
                  </div>
                  <div className="hidden md:block">
                    Already have an account ?&nbsp;
                    <Link
                      to="/"
                      className="text-neonPurple underline font-bold"
                    >
                      Log in
                    </Link>
                  </div>
                  <div className="px-5 py-5 rounded-lg md:w-9/12 mx-auto">
                    <form>
                      <div className="relative my-4">
                        <input
                          id="username"
                          placeholder="Username"
                          name="username"
                          type="text"
                          className="shadow appearance-none border lowercase rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                          value={check.username}
                          onChange={(e) => handleInputChange(e)}
                          onFocus={() => handleFocus("username")}
                          onBlur={() => handleBlur("username")}
                          autoComplete="off"
                          maxLength={10}
                          pattern="[a-zA-Z0-9_]+"
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^a-zA-Z0-9_]/g, ''); // Remove any character that is not alphanumeric or underscore
                          }}
                          required
                        />

                        <p
                          className={`text-left ml-4 mt-2 ${apiResponse?.includes("available")
                              ? "text-green-500"
                              : "text-red-600"
                            }`}
                        >
                          {apiResponse}
                        </p>

                      </div>

                      <div className="relative my-4">
                        <input
                          placeholder="Email ID"
                          id="emailid"
                          name="emailid"
                          type="text"
                          className={`shadow appearance-none border lowercase rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline ${
                            !isEmailValid ? "border-red-500" : ""
                          }`}
                          value={check.emailid}
                          onChange={handleChange}
                          onFocus={() => handleFocus("emailid")}
                          onBlur={() => handleBlur("emailid")}
                          autoComplete="off"
                          required
                        />
                       
                      </div>

                      <div className="relative my-4">
                        <input
                          placeholder="Name"
                          id="name"
                          name="name"
                          type="text"
                          className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                          // className="py-2 px-4 border border-none rounded-full focus:outline-none bg-colorPrimaryDark shadow-neumorphic1  focus:border-none w-full"
                          value={check.name}
                          onChange={handleChange}
                          onFocus={() => handleFocus("name")}
                          onBlur={() => handleBlur("name")}
                          autoComplete="off"
                          required
                        />
                      </div>

                      {/* <div className="relative my-4">
                      <input
                        placeholder="Sponsor"
                        id="sponsor"
                        name="sponsor"
                        type="text"
                        className="py-2 px-4 border border-none rounded-full focus:outline-none bg-colorPrimaryDark shadow-neumorphic1  focus:border-none w-full"
                        value={check.sponsor}
                        onChange={handleChange}
                        onFocus={() => handleFocus("sponsor")}
                        onBlur={() => handleBlur("sponsor")}
                        autoComplete="off"
                      />
                    </div> */}

                      {/* {isSponsorProvided && ( */}

                      <div className="relative my-4">
                        <input
                          placeholder="Sponsor"
                          id="sponsor"
                          name="sponsor"
                          type="text"
                          className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                          // className="py-2 px-4 border border-none rounded-full focus:outline-none bg-colorPrimaryDark shadow-neumorphic1 focus:border-none w-full"
                          value={check.sponsor}
                          readOnly
                          style={{ cursor: "not-allowed" }}
                          autoComplete="off"
                          required
                        />
                      </div>
                      {/* )} */}
                      <div className="relative my-4">
                        <input
                          placeholder="Position"
                          id="side"
                          name="side"
                          type="text"
                          className="shadow text-[green] appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary leading-tight focus:outline-none focus:shadow-outline"
                          value={`side : ${check.side}`}
                          readOnly
                          // onFocus={() => handleFocus("side")}
                          // onBlur={() => handleBlur("side")}
                          autoComplete="off"
                          style={{ cursor: "not-allowed" }}
                          required
                        />
                      </div>

                      <div className="relative  my-4">
                        <input
                          placeholder="Password"
                          id="password"
                          className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                          // className="py-2 px-4 border border-none rounded-full focus:outline-none bg-colorPrimaryDark shadow-neumorphic1  focus:border-none w-full"
                          value={password}
                          onChange={handlePasswordChange}
                          type={showPassword ? "text" : "password"}
                          onFocus={handleFocusPasswordInput}
                          onBlur={handleBlurPasswordInput}
                          autoComplete="off"
                          required
                        />
                      
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center  text-sm leading-5">
                          {showPassword ? (
                            <FaEyeSlash
                              onClick={handleTogglePasswordVisibility}
                              className="h-6 text-gray-700"
                            />
                          ) : (
                            <FaEye
                              onClick={handleTogglePasswordVisibility}
                              className="h-6 text-gray-700"
                            />
                          )}
                        </div>
                      </div>



                      <div className="relative  my-4">
                        <input
                          placeholder="Confirm Password"
                          id="conf_password"
                          type={showConfirmPassword ? "text" : "password"}
                          className={`shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline ${
                            isConfirmPasswordError ? "border-red-500" : ""
                          }`}
                          value={check.conf_password}
                          onChange={handleconf_passwordChange}
                          onFocus={() => handleFocus("conf_password")}
                          onBlur={() => handleBlur("conf_password")}
                          autoComplete="off"
                          required
                        />
                        {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                        {passwordMatch ? (
                          <FcApproval className="h-6 text-green-500" />
                        ) : (
                          <FcCancel
                            className={`h-6 ${
                              isConfirmPasswordError ? "text-red-500" : "text-gray-700"
                            }`}
                          />
                        )}
                          {showConfirmPassword ? (
                            <FaEyeSlash
                              onClick={handleToggleConfirmPasswordVisibility}
                              className="h-6 text-gray-700"
                            />
                          ) : (
                            <FaEye
                              onClick={handleToggleConfirmPasswordVisibility}
                              className="h-6 text-gray-700"
                            />
                          )}
                         
                        </div> */}
                         {isConfirmPasswordFocused && ( // Render icons only when confirm password field is focused
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                          {passwordMatch ? (
                            <FcOk className="h-6 text-green-500" />
                          ) : (
                            <FcCancel
                              className={`h-6 ${
                                isConfirmPasswordError ? "text-red-500" : "text-gray-700"
                              }`}
                            />
                          )}
                          {showConfirmPassword ? (
                            <FaEyeSlash
                              onClick={handleToggleConfirmPasswordVisibility}
                              className="h-6 text-gray-700"
                            />
                          ) : (
                            <FaEye
                              onClick={handleToggleConfirmPasswordVisibility}
                              className="h-6 text-gray-700"
                            />
                          )}
                        </div>
                      )}
                    </div>
                       
                      {/* </div> */}
                        
                      <div
                        className={`mt-4 mb-4 grid grid-cols-2 ${isPasswordInputFocused ? "" : "hidden"
                          }`}
                      >
                        {checkboxes.map(
                          (checked, index) =>
                            !checked && (
                              <div key={index}>
                                <div className="text-center text-white md:text-black">
                                  <span className="mx-1 text-start">
                                    <Checkbox
                                      checked={checked}
                                      disabled
                                      className="mx-2"
                                    />
                                    {index === 0 && "8 characters long"}
                                    {index === 1 && "1 lowercase letter"}
                                    {index === 3 && "1 number digits"}
                                    {index === 2 && "1 uppercase letter"}
                                    {index === 4 && "1 special character"}
                                  </span>
                                </div>
                              </div>
                            )
                        )}
                      </div>


                      <Form.Item>
                        <Checkbox onChange={handleCheckboxChange}>
                          <span className="text-black">
                            Please Accept Terms & Conditions
                          </span>
                        </Checkbox>
                      </Form.Item>

                      <button
                        onClick={handleSubmit}
                        htmlType="submit"
                        disabled={!isCheckboxChecked || isLoading}
                        className={`rounded-full active shadow-neumorphic1 py-1 text-white hover:active/70 hover:text-white px-20 ${!isCheckboxChecked
                          ? " cursor-not-allowed"
                          : " cursor-pointer"
                          }`}
                      >
                        <span className="mb-5 p-0">
                          {isLoading ? "Signup..." : "Signup"}
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
                <div className="flex md:hidden text-white justify-center">
                  Already have an account?
                  <Link to="/" className="text-neonPurple underline">
                    &nbsp;Log in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }


  return (
    <>
      <div>
        <p className=" text-4xl font-semibold underline">{addSponsor}</p>
      </div>
      <div>
        <p className=" text-4xl font-semibold underline">{sideCheck}</p>
      </div>
    </>
  );
}

export default Signup;
