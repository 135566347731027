import React from "react";
import { Pagination as AntPagination, Select } from "antd";
import { useMediaQuery } from "react-responsive";

const CustomPagination = ({
  totalPages,
  currentPage,
  onPageChange,
  onPageSizeChange,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1050 });
  const pageSizeOptions = ["20", "30", "50", "100"];

  const handlePageSizeChange = (value) => {
    onPageSizeChange(Number(value));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: isMobile ? "flex-end" : "space-between",
        alignItems: "center",
      }}
    >
      {!isMobile && (
        <div>
          <Select defaultValue="20" onChange={handlePageSizeChange}>
            {pageSizeOptions.map((size) => (
              <Select.Option key={size} value={size}>
                {size} Pages
              </Select.Option>
            ))}
          </Select>
        </div>
      )}
      <div>
        <AntPagination
          current={currentPage}
          total={totalPages * 10}
          onChange={onPageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default CustomPagination;
