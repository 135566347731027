import axios from 'axios';
import { useEffect, useState } from 'react'

function Api({
  pageName,
  pageSize,
  page,
  setFilteredData,
  setDataLog,
  setPackagesData,
}) {
  const [storeData, setStoreData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);



  const handleFilterpage = () => {
    const jwt = localStorage.getItem("jwt");

     const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      page_name: pageName,
      page_size: pageSize,
      exchange: [],
      exchange_type: [],
      type: [],
      api_name: [],
      bot_id: [],
      bot_name: [],
      bot_name: [],
      symbol: [],
    };

    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_FIL + "total_pages",
      data: postdata,
    })
      .then((response) => {
        setTotalPages(response?.data?.Success);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const trades_transactions = () => {
    const jwt = localStorage.getItem("jwt");

    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      page_name: pageName,
      page_no: page,
      page_size: pageSize,
    };

    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "login_logs",
      data: postdata,
    })
      .then((res) => {
        setStoreData(res?.data?.Success);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleFilterpage();
    trades_transactions();
  }, [
    pageName,
    pageSize,
    page,
    setFilteredData,
    setPackagesData,
    setDataLog,
  ]);

  return [storeData,totalPages];
}

export default Api