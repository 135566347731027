import { Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const ViewReport = (props) => {
  const donutOptions = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const value = context.parsed || 0;
            return `${value}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    animation: {
      duration: 2000,
      easing: "easeInOutQuad",
    },
  };

  const colors = [];

  const options = {
    chart: {
      type: "bar",
      height: 440,
      stacked: true,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 2000,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          "<div class='custom-tooltip' style='font-size: 10px;'>" +
          ["A", "B", "C", "D", "E"][dataPointIndex] +
          "</div>"
        );
      },
      style: {
        fontSize: "11px",
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",
        colors: {
          ranges: colors,
        },
        // colors: ['#91ea92', '#33FF57', '#5733FF', '#33FFFF', '#FF33FF']
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },

    yaxis: {
      min: -100,
      max: 100,
      title: {
        // text: 'Age',
      },
      labels: {
        show: false, // Set this to false to hide y-axis labels
        formatter: function (val) {
          // You can customize the formatter if needed
          // return Math.abs(val) + "%";
        },
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val;
        },
      },
      y: {
        formatter: function (val) {
          return Math.abs(val).toFixed(2) + "%";
        },
      },
    },
    // title: {
    //   text: 'Mauritius population pyramid 2011'
    // },
    xaxis: {
      categories: ["", "", "", ""],
      // title: {
      //   text: 'Percent'
      // },
      labels: {
        show: false,
        formatter: function (val) {
          // return Math.abs(Math.round(val)) + "%"
        },
      },
    },
  };

  const {
    API_Name,
    BotName,
    Symbol,
    Exchange,
    Exchange_Type,
    Val_Total_Investment,
    Val_Balance,
    Val_Profit,
    Val_Position,
    Val_Brokerage,
    Rel_Val_Balance,
    Rel_Val_Profit,
    Rel_Val_Position,
    Rel_Val_Brokerage,
    Val_Days,
    Val_Total_Trades,
    Val_Winning_Trades,
    Val_Losing_Trades,
    Val_Hit_Rate,
    Rel_Winning_Trades,
    imageToDisplay,
    Rel_Losing_Trades,
  } = props.data;

  return (
    <>
      <div className="">
        <div className="bg-colorPrimary shadow-neumorphic rounded-lg shadow-2xl py-2 ">
          <div className="mx-4 ">
            <div className="pt-2">
              <div className="flex justify-around bg-colorSecondary  items-center text-white shadow-neumorphic   py-[10px]  rounded-[10px]">
                <div className="flex flex-col justify-around w-1/2 px-2 gap-1">
                  <h1 className=" text-sm font-semibold text-center capitalize">
                    API : {API_Name}
                  </h1>
                  <div className="flex px-3 gap-2  justify-between   w-full items-center">
                    <p className=" text-xs xl:text-sm">{BotName}</p>
                    <p className="text-xs xl:text-sm">{Symbol}</p>
                  </div>
                </div>
                <div className="pr-2">
                  <img
                    src={imageToDisplay}
                    alt="logo"
                    className="w-[150px] h-auto"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-around bg-colorPrimary py-1 shadow-neumorphicnew shadow-md text-[#212121] items-center rounded-[10px] pb-2 my-2">
              <div>
                <p className=" text-lg flex flex-col ">
                  
                  <span className="text-center  font-medium">PROFIT</span>
                  <div className="text-center">
                    <span
                      className={`text-center text-3xl font-semibold ${
                        Rel_Val_Profit < 0 ? "text-[#f00]" : "text-[#38913a]"
                      }`}
                    >
                      {Rel_Val_Profit.toFixed(2)}%
                    </span>
                  </div>
                </p>
              </div>

              <div className=" w-1/5">
                <p className="text-center font-bold text-base">Trades</p>
                <p className="text-xs leading-3 flex items-center justify-between ">
                  <div className="text-center">Total:&nbsp;</div>
                  <div className=" font-bold text-xs text-center">
                    {Val_Total_Trades}
                  </div>
                </p>

                <p className="text-xs leading-3 text-center flex justify-between items-center">
                  Winning:
                  <div className=" font-bold text-xs text-center">
                    {Val_Winning_Trades}
                  </div>
                </p>

                <p className="text-xs leading-3 text-center  flex justify-between items-center">
                  Losing:
                  <div className=" font-bold text-xs text-center">
                    {Val_Losing_Trades}
                  </div>
                </p>
                <p className="text-xs leading-3 text-center  flex justify-between items-center">
                  Hit rate:
                  <div className=" font-bold text-xs text-c">
                    {Val_Hit_Rate.toFixed(2)}
                  </div>
                </p>
              </div>

              <div className="w-[50] h-[50] flex justify-center">
                <Doughnut
                  data={{
                    labels: ["WinningTrades", "LoosingTrades"],
                    datasets: [
                      {
                        data: [Rel_Winning_Trades, Rel_Losing_Trades],
                        backgroundColor: [
                          "rgba(14, 220, 83, 0.6)",
                          "rgba(255, 99, 132, 0.6)",
                        ],
                        borderColor: [
                          "rgba(14, 220, 83, 0.6)",
                          "rgba(255, 99, 132, 0.6)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={donutOptions}
                  style={{ width: "75px" }}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 bg-colorPrimary py-1 shadow-neumorphicnew  rounded-md px-1 my-2">
              <div className=" w-full ">
                <div className="-mb-4 text-center text-sm">
                  Invested : <b>${Val_Total_Investment.toFixed(2)}</b>
                </div>
                <div className="flex justify-center gap-2 items-center mt-1">
                  <div className=" w-[40%]">
                    <div className="-ml-5 -mt-2">
                      <ReactApexChart
                        options={{
                          ...options,
                          colors: [
                            Rel_Val_Balance >= 0 ? "#892dd8" : "#FF0000",
                            "#0c2b44",
                          ],
                        }}
                        series={[
                          {
                            name: "",
                            data: [
                              Math.max(0, Rel_Val_Balance.toFixed(2)),
                              Math.max(0, Rel_Val_Position.toFixed(2)),
                              Math.max(0, Rel_Val_Profit.toFixed(2)),
                              Math.max(0, Rel_Val_Brokerage.toFixed(2)),
                            ],
                          },
                          {
                            name: "",
                            data: [
                              Math.min(0, Rel_Val_Balance.toFixed(2)),
                              Math.min(0, Rel_Val_Position.toFixed(2)),
                              Math.min(0, Rel_Val_Profit.toFixed(2)),
                              Math.min(0, Rel_Val_Brokerage.toFixed(2)),
                            ],
                          },
                        ]}
                        type="bar"
                        height={117}
                      />
                    </div>
                  </div>

                  <div className="text-[13px]  flex gap-1 justify-between items-center  flex-col w-2/5">
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Balance:</div>
                      <span className=" font-bold">
                        {Val_Balance.toFixed(2)}
                      </span>
                    </p>
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Position:</div>
                      <span className=" font-bold">
                        {Val_Position.toFixed(2)}
                      </span>
                    </p>
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Profit:</div>
                      <span className=" font-bold">
                        {Val_Profit.toFixed(2)}
                      </span>
                    </p>
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Brokerage:</div>
                      <span className=" font-bold">
                        {Val_Brokerage.toFixed(2)}
                      </span>
                    </p>
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Days:</div>
                      <span className=" font-bold">{Val_Days || "0"}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
