import { Empty, Table } from "antd";
import moment from "moment";
import React from "react";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";

function SubStatus(props) {
  const { subData, pageSize, page, totalPages, setPage, setpageSize } =
    props.data;

  const columns = [
    {
      title: "DATE",
      dataIndex: "Datetime",
      key: "Datetime",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },
    {
      title: "TRANS ID",
      dataIndex: "Trans_ID",
      key: "Trans_ID",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },
    {
      title: <div className="uppercase">SUBSCRIPTION NAME</div>,
      dataIndex: "Name",
      key: "Name",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },
    {
      title: <div className="uppercase">VALIDITY</div>,
      dataIndex: "Validity",
      key: "Validity",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },
    {
      title: <div className="uppercase">EXPIRY DATE</div>,
      dataIndex: "Valid_Upto",
      key: "Valid_Upto",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },

    {
      title: <div className="uppercase">PRICE</div>,
      dataIndex: "Price",
      key: "Price",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },
    {
      title: <div className="uppercase">WEEKS LEFT</div>,
      dataIndex: "Days_Left",
      key: "Days_Left",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },

    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <span style={{ color: text === "Active" ? "green" : "red" }}>
          {text ? text : "--"}
        </span>
      ),
    },
  ];

  const rows = [];

  if (subData) {
    for (let i = 0; i < subData.length; i++) {

      if (subData[i]?.Name) {
        rows.push({
          key: i,
          Datetime: subData[i].Datetime,
          Valid_Upto: subData[i]?.Valid_Upto,
          Trans_ID: subData[i]?.Trans_ID,
          Name: subData[i]?.Name,
          Validity: Math.floor(subData[i]?.Validity/7) + " week/s",
          Price: "$" + (subData[i]?.Price),
          Days_Left: Math.floor(subData[i]?.Days_Left/7) + " week/s",
          Status: subData[i]?.Status ? "Active" : "Expired",
        });
      }
    }
  }

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setpageSize(newPageSize);
  };


  const onChange = (pagination, filters, sorter, extra) => { };

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  if (isMobile) {
    return (
      <>
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />

        {rows.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">
            {rows.map((record) => (
              <div
                key={record.Trans_ID}
                className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
              >

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Date:</div>
                  <div className="card-data">{record.Datetime}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">TransID:</div>
                  <div className="card-data">{record.Trans_ID}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">SUBSCRIPTION NAME:</div>
                  <div className="card-data">{record.Name}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Validity:</div>
                  <div className="card-data">
                    <div className="card-data">{record.Validity}</div>
                  </div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Expiry Date:</div>
                  <div className="card-data">{record.Valid_Upto}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Price:</div>
                  <div className="card-data">{record.Price}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Weeks Left:</div>
                  <div className="card-data">{record.Days_Left}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Status:</div>
                  <div className="card-data">
                    <span
                      style={{
                        color: parseFloat(record.Status) > 100 ? "red" : "green",
                      }}
                    >
                      {record.Status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
            <Empty />
          </div>
        )}


        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </>
    );
  }


  return (
    <div>
      <div className="w-full bg-colorPrimary ">
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <Table
          className="table-newres my-2"
          columns={columns}
          dataSource={rows}
          onChange={onChange}
          pagination={false}
        />
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
}

export default SubStatus;
