import { Button, Empty, Modal, Switch, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SkeltorLoader from "./SkeltorLoader";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { MdDelete, MdDeleteForever } from "react-icons/md";
import WebsocketApi from './WebSocketApi';
import { render } from "@testing-library/react";
import KuCoinWebSocket from "./KuCoinWebSocket";

function KucoinBotTable(props) {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [record, setRecord] = useState();
  const { selectedBotID, type, coin } = props;
  const [visible, setVisible] = useState(false);
  const sortedTableData = KuCoinWebSocket();

  const showModal = (record) => {
    setVisible(true);
    setRecord(record);
  };

  const handleOk = () => {
    setVisible(false);
    handleDelete();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [
    filterexchange,
    filter,
    typedata,
    apiname,
    botname,
    filterbotData,
    filterData,
  ] = props.data;

  const simpleData = [];

 

  const generateColumns = (exchangeType) => {
    const commonColumns = [
      {
        title: "Date Time",
        dataIndex: "DateTime",
        //   ellipsis: true,
        // width: "auto",
        width: undefined,
        className: 'whitespace-nowrap',
        textAlign: "center",
      },
      {
        title: "Bot ID",
        dataIndex: "VarBotID",
        width: undefined,
        className: 'whitespace-nowrap',
      },
      {
        title: "Bot Name",
        dataIndex: "BotName",
        width: undefined,
        className: 'whitespace-nowrap',
      },
      {
        title: "Type",
        dataIndex: "Type",
        width: undefined,
        className: 'whitespace-nowrap',
      },
      {
        title: "API Name",
        dataIndex: "API_Name",
        width: undefined,
        className: 'whitespace-nowrap',
      },
      {
        title: "Exchange",
        dataIndex: "Exchange",
        width: undefined,
        className: 'whitespace-nowrap',
      },
      {
        title: "Exchange Type",
        dataIndex: "Exchange_Type",
        width: undefined,
        className: 'whitespace-nowrap',
      },
      {
        title: "Investment Type",
        dataIndex: "Investment_Risk",
        width: undefined,
        className: 'whitespace-nowrap',
      },
      {
        title: "Symbol",
        dataIndex: "Symbol",
        width: undefined,
        className: 'whitespace-nowrap',
      },
      {
        title: "Invest",
        dataIndex: "Total_Investment",
        width: undefined,
        className: 'whitespace-nowrap',
      },

      {
        title: "Drawdown/ Take Profit(%)",
        dataIndex: "DrawdownTakeProfit",
        width: undefined,
        className: 'whitespace-nowrap',
        render: (text, record) => (
          <span>
            {record.Drawdown} % / {record.Take_Profit} %
          </span>
        ),
      },


      {
        title: "Balance",
        dataIndex: "Balance",
        width: undefined,
        className: 'whitespace-nowrap',
        render: (text) => {
          const textColor = text < 0 ? 'text-[red]' : 'text-[green]';
          return (
            <span className={textColor}>
              {text.toFixed(2)}
            </span>
          );
        },
      },



      {
        title: "Current Asset",
        dataIndex: "openposition",
        width: undefined,
        className: 'whitespace-nowrap text-center',
        textAlign: "center",
        render: (text) => {
          const textColor = text < 0 ? 'text-[red]' : 'text-[green]';
          return (
            <span className={textColor}>
              {text.toFixed(2)}
            </span>
          );
        },
      },
      // ...(exchangeType === "FUTURES"
      //   ? [
      //     {
      //       title: "Open Position (L)",
      //       dataIndex: "openpositionl",
      // 
      //       render: (text) => <span>{parseFloat(text).toFixed(4)}</span>,
      //     },
      //     {
      //       title: "Open Position (S)",
      //       dataIndex: "openpositions",
      // 
      //       render: (text) => <span>{parseFloat(text).toFixed(4)}</span>,
      //     },
      //   ]
      //   : [
      //     {
      //       title: "Open Position",
      //       dataIndex: "openposition",
      // 
      //       render: (text) => <span>{parseFloat(text).toFixed(4)}</span>,
      //     },
      //   ]
      // ),
      {
        title: "Current Drawdown / Take Profit (%)",
        dataIndex: "CurrentDraw",
        width: undefined,
        className: 'whitespace-nowrap',
        render: (text) => {
          const textColor = text < 0 ? 'text-[red]' : 'text-[green]';
          return (
            <span className={textColor}>
              {text.toFixed(2)}
            </span>
          );
        },
      },
      // {
      //   title: "Panic Stop Bot",
      //   dataIndex: "Total_Investment",
      //   render (text){
      //     <button className=" px-2 py-1 rounded border border-gray-500"> Close All Position </button>
      //   }
      // }, 
      // {
      //   title: "Remark",
      //   dataIndex: "Total_Investment",
      //   render (text){
      //     <button className=" px-2 py-1 rounded">Running</button>
      //   }
      // },
      {
        title: "Mode",
        dataIndex: "Status",
        key: "Status",
        editable: true,
        className: "w-7",

        render: (text, record) => (
          <div className="justify-center items-center">
            <Switch
              className={record.Status === "ACTIVE" ? "off" : "on"}
              checked={record?.Status === "ACTIVE"}
              checkedChildren={<div className=" "> Start</div>}
              unCheckedChildren={<div className="text-white">Pause</div>}
              onChange={(checked) => handleSwitchChange(record, checked)}
            />
          </div>
        ),
      },

      {
        title: "Delete",
        dataIndex: "Delete",
        key: "Delete",
        render: (text, record) => (
          <Button
            type="danger"
            onClick={() => showModal(record)}
          >
            <MdDeleteForever size={25} className="text-lg text-[red]" />
          </Button>
        ),
      },
    ];

    return commonColumns;
  };

  const simpleColumns = generateColumns(filteredData[0]?.Exchange_Type);

  if (filteredData) {
    let filteredRows = filteredData;
    const priceMap = new Map(sortedTableData.map(data => [data.name, data.price]));

    for (let i = 0; i < filteredRows.length; i++) {
      const row = filteredRows[i];
      const symbolWithoutUSDT = row.Symbol?.replace("USDT", "");
      const currentPrice = priceMap.get(symbolWithoutUSDT) || 0;

      const openpositionl = row.TotalQuantityL * currentPrice;
      const openpositions = -row.TotalQuantityS * currentPrice;
      const openposition1 = row.TotalQuantity * currentPrice;

      let openposition = openposition1;

      if (row.Exchange_Type === "FUTURES") {
        openposition = openpositionl + openpositions;
      } else {
        openposition = openposition1;
      }

      let CurrentDraw = (
        ((row.Balance + (currentPrice) * row.TotalQuantity 
          ) * 100) / row.Total_Investment) - 100;


      simpleData.push({
        key: i,
        DateTime: row.Created_At,
        BotName: row.BotName,
        VarBotID: row.VarBotID,
        Exchange: row.Exchange,
        Exchange_Type: row.Exchange_Type,
        Type: row.Type,
        openpositionl,
        openposition,
        openpositions,
        Symbol: row.Symbol,
        Investment_Risk: row.Investment_Risk,
        Total_Investment: row.Invest,
        Drawdown: row.Drawdown,
        Take_Profit: row.Take_Profit,
        Balance: row.Balance,
        ChatID: row.ChatID,
        Status: row.Status,
        API_Name: row.API_Name,
        Telegram_Alert: row.Telegram_Alert,
        CurrentDraw: CurrentDraw
      });
    }
  }


  async function handleFilterpage() {
    const jwt = localStorage.getItem("jwt");

    let postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      page_name: "ActiveBot",
      page_size: size,
    };

    if (type !== null && type !== undefined) {
      postData.exchange_type = type;
    }

    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_FIL + "total_pages",
      data: postData,
    })
      .then((res) => {
        setTotalPages(res?.data?.Success);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const trades_transactions = async () => {
    const jwt = localStorage.getItem("jwt");

    const postdata = {
     
      jwt: jwt,
   
    };


    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_BOT + "/bot/view_bots",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data?.Success;

        setFilteredData(res);
        setLoading(false);

      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
        setLoading(false);
        if (err?.response?.data?.Error === "No bots yet!") {
          setFilteredData([]);
        }
      });
  };







  useEffect(() => {
    trades_transactions()
  }, [ page, size]);

  const handleSwitchChange = (record, checked) => {
    const newStatus = checked ? "ACTIVE" : "PAUSED";

    if (checked) {
      botStart(record);
    } else {
      botStop(record);
    }

    const updatedDataSource1 = filteredData.map((item) =>
      item.API_Name === record.API_Name && item.VarBotID === record.VarBotID
        ? { ...item, Status: newStatus }
        : item
    );
    setFilteredData(updatedDataSource1);
  };

  const botStart = (record) => {
    const token = localStorage.getItem("jwt");
    const postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: token,
      bot_id: record?.VarBotID,
      api_name: record?.API_Name,
    };
    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "start",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        trades_transactions();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  const botStop = (record) => {
    const token = localStorage.getItem("jwt");
    const postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: token,
      bot_id: record?.VarBotID,
      api_name: record?.API_Name,
    };
    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "pause",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        trades_transactions();
      })
      .catch((err) => toast.error(err?.res?.data?.Error));
  };

  const handleDelete = () => {
    const token = localStorage.getItem("jwt");
    const postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: token,
      bot_id: record?.VarBotID,
      api_name: record?.API_Name,
    };

    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "stop",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        trades_transactions();
        setVisible(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
        setVisible(false);
      });
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (size) => {
    setSize(size);
  };


  return (
    <>
      <div>
        <div className="mt-2  w-full container  table-newres ">
          {simpleData.length > 0 && (
            <CustomPagination
              totalPages={totalPages}
              currentPage={page}
              onPageChange={onPageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
          <Modal
            title="Delete Confirmation"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={<span className=" text-black">OK</span>}
            cancelText="Cancel"
            footer={false}
          >
            <p>Are you sure you want to delete this Bot?</p>
            <div className=" flex justify-end mt-4">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out"
                onClick={handleDelete}
              >
                OK
              </button>
            </div>
          </Modal>
          <div className=" my-2 table-newres ">
            {loading ? (
              <div className="w-full h-40 rounded-tr-3xl rounded-tl-3xl bg-colorPrimary shadow-neumorphic ">
                <div className="w-full h-14 bg-colorPrimary ">
                  <SkeltorLoader />
                </div>
              </div>
            ) : (
              <Table
                columns={simpleColumns}
                dataSource={simpleData}
                pagination={false}
                className="mt-2 md:mt-14 lg:mt-2"
              />
            )}
          </div>
          {simpleData.length > 0 && (
            <CustomPagination
              totalPages={totalPages}
              currentPage={page}
              onPageChange={onPageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
        </div>
      </div>

      <div className=" my-5 border border-gray-300 rounded-lg p-4 shadow-md">
        <div>
        <strong className="border-double border-b border-neonPurple ">Instructions and Notes:</strong>
        </div>
        <br />
        <p className="text-xs md:text-base ">
          <span className="text-black font-semibold border-b  border-neonPurple border-dashed">Current Drawdown/Bot Take Profit: </span>
          <ul className="list-disc ml-6">
            <div className="block lg:flex items-center gap-5">
              <li className="text-xs md:text-base"><span className="font-semibold">Negative Value:</span> Represents a drawdown.</li>
              <li className="text-xs md:text-base"><span className="font-semibold">Positive Value:</span> Represents a bot take profit.</li>
            </div>
          </ul>
        </p>
        <br />
        <p className="text-xs md:text-base ">
          <span className="text-black font-semibold  border-b  border-neonPurple border-dashed">Panic Stop Bot:</span>
          <ul className="list-disc ml-6">
            <div className="block lg:flex items-center gap-5">
              <li className="text-xs md:text-base">Close all open positions at the current market price.</li>
              <li className="text-xs md:text-base">Terminate the bot.
              </li>
              <li className="text-xs md:text-base">Delete all related data from the software database.
              </li>
            </div>
          </ul>
        </p>
        <br />
        <p className="text-xs md:text-base ">
          <span className="text-black font-semibold  border-b  border-neonPurple border-dashed">Drawdown/Bot Take Profit Updates:
          </span>
          <ul className="list-disc ml-6">
            <div className="block lg:flex items-center gap-5">
              <li className="text-xs md:text-base">These values will be checked and updated at intervals of 5, 10, 15, or 60 minutes.
              </li>

            </div>
          </ul>
        </p>

        <ul className="list-disc ml-6">
          <li className="text-xs md:text-base">Note that due to price fluctuations and time delays, the actual drawdown or bot take profit may differ from the set values during these periods.
          </li>
        </ul>
      </div>

     
    </>
  );
}

export default KucoinBotTable;