import React, { useEffect, useState } from "react";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { RxCrossCircled } from "react-icons/rx";
import { FcApproval } from "react-icons/fc";
import { TbBrandTelegram } from "react-icons/tb";

import Male from "../../assets/Profile/ProfileMale/1.webp";
import Female from "../../assets/Profile/Profilefemale/1.webp";
import Bot from "../../assets/Profile/Profilebull/1.webp";
import { Col, Image, Modal, Row, Skeleton, Tooltip,Button,Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../Auth";
import UpdateApi from "../UpdateApi";
import { EditOutlined } from '@ant-design/icons';

const P1 = ({ profile, View_Profile, loading }) => {
  const navigate = useNavigate();

  const [botImage1, setBotImage1] = useState("");
  const [maleImage1, setMaleImage1] = useState("");
  const [femaleImage1, setFemaleImage1] = useState("");
  const [opentour, setOpentour] = useState(false);

  const [emaileditmodal, setEmailEditModal] = useState(false);

  const [email, setEmail] = useState();
const [otp, setOtp] = useState('');
const [isOtpLayout, setIsOtpLayout] = useState(false);


  const images = [
    { name: "Male", url: maleImage1 },
    { name: "Female", url: femaleImage1 },
    { name: "Bot", url: botImage1 },
  ];

  const [selectedAvatarImage, setSelectedAvatarImage] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { avatar } = UpdateApi();

  const [side, setSide] = useState("0");

  const View_ProfileImage1 = async (imageName) => {
    setSelectedAvatarImage(imageName);


    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      avatar: imageName,
    };
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "set_avatar",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          toast(response?.data?.Success);
          setIsModalOpen(false);
        
          setOpentour(true);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  const jwt = localStorage.getItem("jwt");
  // const { View_Profile } = UpdateApi();

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploadButtonClicked, setIsUploadButtonClicked] = useState(false);
  const [ProfileImages, setProfileImages] = useState([]);

  const [botImage, setBotImage] = useState("");
  const [maleImage, setMaleImage] = useState("");
  const [femaleImage, setFemaleImage] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidOtp, setIsValidOtp] = useState(true);


  const Get_Profiles1 = async () => {
    try {
      const res = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_PUBLIC + "profile_image",
      });

      if (res.data.Success) {
        const profiles = res.data.Success[0];

        setBotImage1(profiles.Bot[0]);
        setMaleImage1(profiles.Male[0]);
        setFemaleImage1(profiles.Female[0]);
      } else {
      }
    } catch (err) {}
  };

  useEffect(() => {
    Get_Profiles1();
  }, []);

  const handleUploadButtonClick = () => {
    setIsUploadButtonClicked(true);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const View_ProfileImage = async (image) => {
    localStorage.setItem("selectedImage", image);
    setSelectedImage(image);
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      profile_image: image,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "update_profile_image",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          toast(response?.data?.Success);
          setModalVisible(false);
          View_Profile();
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  const Get_Profiles = async () => {
    try {
      const res = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_PUBLIC + "profile_image",
      });

      if (res.data.Success) {
        setProfileImages(res.data.Success[0]); // Assuming the response structure has only one object in the Success array
        const profiles = res.data.Success[0];
        setBotImage(profiles.Bot[0]);
        setMaleImage(profiles.Male[0]);
        setFemaleImage(profiles.Female[0]);
      } else {
      }
    } catch (err) {}
  };

  const imagesArray = ProfileImages[avatar] || [];

  let avatarImages = [];
  if (avatar === "Female") {
    avatarImages = [femaleImage];
  } else if (profile?.Avatar === "Bot") {
    avatarImages = [botImage];
  } else if (profile?.Avatar === "Male") {
    avatarImages = [maleImage];
  }

  const telegram = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT_TELE + "generate_telegram_link",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data.Success;
        window.open(res, "_blank");
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    setSelectedImage(profile?.Profile_Image || avatarImages[0]);
  }, [profile?.Profile_Image, avatarImages]);

  useEffect(() => {
    Get_Profiles();
  }, []);

  useEffect(() => {
    if (profile?.Avatar === "Female") {
      setSelectedImage(Female);
    } else if (profile?.Avatar === "Bot") {
      setSelectedImage(Bot);
    } else if (profile?.Avatar === "Male") {
      setSelectedImage(Male);
    }
  }, [profile?.Avatar]);

  if (!profile) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }

  
  const showModal = () => {

    setIsModalOpen(true)
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formatEmail = (email) => {
    const emailParts = email.split("@");
    const namePart = emailParts[0];
    const domainPart = emailParts[1];
    const formattedName = namePart.substring(0, 4) + "****";
    return `${formattedName}@${domainPart}`;
  };


  // edit email

  const handleEditClick = () => {
    setEmailEditModal(true);
  };
  
  const handleModalOk = () => {
    // Handle the modal submission here
    setEmailEditModal(false);
  };
  
  const handleModalCancel = () => {
    setEmailEditModal(false);
    setIsOtpLayout(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  
  const handleOtpChange = (e) => {
    const { value } = e.target;
    if (value.length <= 6 && /^[0-9]*$/.test(value)) {
      setOtp(value);
      setIsValidOtp(true); // Reset the validation state on OTP change
    }
  };

  const getotp = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      email:email,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_NEW + "/user/edit_email",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data.Success;
       toast(response?.data.Success)
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
      });
  };


  const editemail = async () => {
    const jwt = localStorage.getItem('jwt');
    const postdata = {
      email: email,
      jwt: jwt,
      otp: otp,
    };

    try {
      const response = await axios({
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        url: process.env.REACT_APP_API_PATH_NEW + '/user/verify_email',
        data: postdata,
      });

      if (response?.data?.Success) {
        toast.success(response.data.Success);
        setOtp("")
        setEmail("")
        return true; // Indicating success
      } else {
        toast.error('An error occurred while verifying the email.');
        return false; // Indicating failure
      }
    } catch (err) {
      console.error(err?.response?.data?.Error);
      toast.error(err?.response?.data?.Error || 'An unexpected error occurred.');
      return false; // Indicating failure
    }
  };









 

  const validateEmail = (email) => {
  
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };


  const handleSendOtp = () => {
    if (!email || !validateEmail(email)) {
      setIsValidEmail(false);
      toast.error('Please enter a valid email.');
      return;
    }
    getotp();
    setIsOtpLayout(true);
  };
  
  const handleFinalSubmit = async () => {
    if (!otp || otp.length !== 6) {
      setIsValidOtp(false);
      toast.error('Please enter a valid 6-digit OTP.');
      return;
    }

    const isSuccess = await editemail();
    if (isSuccess) {
      setEmailEditModal(false);
      View_Profile()
      // navigate('/dashboard/profile')
      setIsOtpLayout(false);
    }
  };


  const validateOtp = (otp) => {
    const re = /^\d{6}$/;
    return re.test(otp);
  };

  const handlePrevious = () => {
    setIsOtpLayout(false);
  };

  
  return (
    <>
      <Modal
        title={<div className="modal-title">Please Select Your Avatar</div>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={440}
        open={isModalOpen}
      >
        <div className="">
          <div className="flex ">
            {images?.map((image, index) => (
              <Col span={8} key={index}>
                <div className="image-option w-[120px]">
                  <img
                    className="images-avatar"
                    src={image.url}
                    alt={`Image ${index + 1}`}
                    onClick={() => View_ProfileImage1(image.name)}
                  />
                  {selectedAvatarImage === image.name && (
                    <div className="absolute top-0 right-0 text-green-500">
                      <FcApproval size={24} />
                    </div>
                  )}
                  <div className="text-center">{image.name}</div>
                </div>
              </Col>
            ))}
          </div>
        </div>
      </Modal>

      <div class="bg-white p-3 border-t-4 border-neonPurple">
        <div class="text-sm leading-6">
          <figure class="relative flex flex-col-reverse bg-slate-100 rounded-lg p-6 dark:bg-neonPurple dark:highlight-white/5">
            <figcaption class="flex items-center space-x-4">
              <img
                src={
                  profile?.Profile_Image === ""
                    ? avatarImages[0]
                    : profile?.Profile_Image
                }
                alt=""
                class="flex-none w-28 h-28 rounded-full object-cover"
                loading="lazy"
                decoding="async"
              />

              <div class="flex-auto">
                <div class=" text-slate-900  text-2xl font-semibold dark:text-slate-200">
                  {profile.Name}
                </div>
                <div class="mt-0.5 dark:text-slate-300 text-xl ">
                  <span className="text-xs">@</span>
                  {profile.Username}
                </div>
              </div>
            </figcaption>
          </figure>
          <div className="grid grid-cols-2 gap-6">
            <button
              class="mt-4 rounded-lg bg-neonPurple px-4 py-2 text-white"
              onClick={handleUploadButtonClick}
            >
              Change Profile
            </button>
            <button
              class="mt-4 rounded-lg bg-neonPurple px-4 py-2 text-white"
              onClick={showModal}
            >
              Change Avatar
            </button>
          </div>
        </div>

        <ul class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
          <li class="flex items-center py-3">
            <span>Sponsor</span>
            <span class="ml-auto">
              <span class="bg-green-500 py-1 px-2 rounded text-white text-sm">{profile?.Sponsor}
              </span>
            </span>
          </li>
          <li className="flex items-center py-3">
  <span>EmailID</span>
  <Tooltip title={profile?.Email}>
    <span className="ml-auto">{formatEmail(profile?.Email)}</span>
  </Tooltip>
  <EditOutlined className="ml-2 cursor-pointer" onClick={handleEditClick} />
</li>

<Modal
  title={isOtpLayout ? "Enter OTP" : "Edit Email"}
  visible={emaileditmodal}
  onCancel={handleModalCancel}
  footer={null} // Custom footer to handle buttons inside the modal
>
  {!isOtpLayout ? (
    <>
   
      <Input
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder="Enter new email"
        style={{
          borderColor: isValidEmail ? '' : 'red',
          backgroundColor: isValidEmail ? '' : 'linen'
        }}
      />
      <Button type="primary" onClick={handleSendOtp} className="mt-2">
        Send OTP
      </Button>
    </>
  ) : (
    <>
     <p className="text-red-500">Note:- Check your Previous Email for OTP</p>
      <Input
        type="text"
        value={otp}
        onChange={handleOtpChange}
        placeholder="Enter OTP"
        style={{
          borderColor: isValidOtp ? '' : 'red',
          backgroundColor: isValidOtp ? '' : 'linen'
        }}
      />
      <div className="flex justify-between mt-2">
        <Button onClick={handlePrevious}>Previous</Button>
        <Button type="primary" onClick={handleFinalSubmit}>
          Submit
        </Button>
      </div>
    </>
  )}
</Modal>


          {/* <li class="flex items-center py-3">
            <span>TelegramID</span>
            <span class="ml-auto">
              {" "}
              {!profile?.TelegramID ? (
                <Tooltip title="Coming Soon">
                  <button
                    // onClick={telegram}
                    className="text-sm cursor-not-allowed text-neonPurple border-2 border-neonPurple px-2 py-1 rounded-lg flex items-center gap-1 hover:bg-neonPurple hover:text-white transition-colors duration-200"
                  >
                    <TbBrandTelegram size={15} className="text-neonPurple" />
                    Telegram
                  </button>
                </Tooltip>
              ) : (
                <div className="text-sm text-colorSecondaryDark">
                  {profile.TelegramID}
                </div>
              )}
            </span>
          </li>{" "} */}
          {/* <li class="flex items-center py-3">
            <span>KYC Status</span>
            <span class="ml-auto">
              {" "}
              {profile.KYC_Status === "COMPLETE" ? (
                <RiCheckboxCircleLine size={20} className="text-green-500" />
              ) : profile.KYC_Status === "INCOMPLETE" ? (
                <Link to={"/dashboard/KycNew"}>
                  <button className="text-red-500 hover:underline">
                    INCOMPLETE
                  </button>
                </Link>
              ) : (
                <div className="text-red-500">PENDING</div>
              )}
            </span>
          </li>{" "}
          <li class="flex items-center py-3">
            <span>Status:</span>
            <span class="ml-auto">
              {" "}
              {profile.Status ? (
                <RiCheckboxCircleLine size={20} className="text-green-500" />
              ) : (
                <RxCrossCircled size={20} className="text-red-500" />
              )}
            </span>
          </li> */}
          <li class="flex items-center py-3">
            <span>2FA:</span>
            <span class="ml-auto">
              {" "}
              <Auth
                profile={profile.TOTP_Activation}
                View_Profile={View_Profile}
              />
            </span>
          </li>
        </ul>

        <Modal
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}
          centered
        >
          <Row gutter={[16, 16]}>
            {imagesArray.map((image, index) => (
              <Col span={8} key={index}>
                <div className="image-option">
                  <img
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="w-full h-full object-cover rounded-md shadow-sm hover:shadow-lg transition-shadow duration-200"
                    onClick={() => View_ProfileImage(image)}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Modal>
      </div>
    </>
  );
};

export default P1;
