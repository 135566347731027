import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin, Table, Input, Select } from "antd";
import UpdateApi from "./UpdateApi";

const { Option } = Select;
const CollectionWallet = ({ refresh }) => {
  const [datas, setDatas] = useState(null);
  const [address, setAddress] = useState(
    "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D"
  );
  const [chain, setChain] = useState("eth");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.NFT_Analyitics;

  const fetchData = () => {
    setLoading(true);
    const url = `https://deep-index.moralis.io/api/v2.2/${address}/nft/collections?chain=${chain}`;
    const headers = {
      Accepts: "application/json",
      "X-API-Key":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MjI2MTZmLWM4MzItNDJmZS04YzI1LTA4NGZiNTAyY2ZmOCIsIm9yZ0lkIjoiMzcwMjAxIiwidXNlcklkIjoiMzgwNDY5IiwidHlwZUlkIjoiYTM5MmYyMTItZGQ1OC00NjY2LWEyMGMtN2E2MDdlZGU3YmZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDM5MzEwMjMsImV4cCI6NDg1OTY5MTAyM30.1ik9oveoExEKuAyXdSIWTKWo3i22Yp59_37ZDIJl8ew",
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const result = response.data.result;
        setDatas(result);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (checkTradePackage == true) {
      fetchData();
    }
  }, [checkTradePackage, refresh]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleChainChange = (value) => {
    setChain(value);
  };

  const columns = [
    {
      title: "Collection Logo",
      dataIndex: "collection_logo",
      key: "collection_logo",
      render: (collection_logo) => (
        <img
          src={collection_logo}
          alt="Logo"
          style={{ width: 30, borderRadius: 50 }}
        />
      ),
    },
    {
      title: "Token Address",
      dataIndex: "token_address",
      key: "token_address",
    },

    {
      title: "Contract Type",
      dataIndex: "contract_type",
      key: "contract_type",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },


  ];

  return (
    <>
      <div className="mx-5">
      



<div className="flex justify-between items-center my-5"> 

<div className="flex gap-4"> 

        <Input
          value={address}
          onChange={handleAddressChange}
          style={{ width: 250 }}
        />
        <Select
          value={chain}
          onChange={handleChainChange}
          style={{ width: 120 }}
        >
          <Option value="eth">ETH</Option>
          <Option value="bsc">BSC</Option>
          {/* Add more options as needed */}
        </Select>
        </div>
        <div>
            <button
              onClick={fetchData}
              className="bg-neonPurple text-white px-4 py-1 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
            >
              Save
            </button>
            <button

              className="bg-gray-400 text-white px-4 py-1 rounded-md shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Clear Filters
            </button>
          </div>


        </div>

        {loading ? (
          <Spin
            size="large"
            style={{ display: "block", margin: "100px auto" }}
          />
        ) : (
          <Table
            dataSource={datas}
            columns={columns}
            pagination={false}
            rowKey="token_address"
          />
        )}
      </div>
    </>
  );
};

export default CollectionWallet;
