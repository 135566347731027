import { Select, Spin, Table, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

import { TfiReload } from "react-icons/tfi";

const LiveTransactioncopy = (props) => {

    const { username, option } = props?.data

    const [transData, setTransData] = useState([])
    const [loading, setLoading] = useState(false);
    const [option2 , setOption2] = useState(option)
    const [Api, setApi] = useState()

    useEffect(() => {
        setOption2(option)
        const fisrtLabel = option[0]?.value
        setApi(fisrtLabel)
       
    },[option])

    const transaction = () => {
        setTransData([])
        setLoading(true)
        const jwt = localStorage.getItem('jwt')
        const postData = {
            jwt: jwt,
            username: username,
            api_name: Api
        }
        const response = axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'wallet_transactions_data',
            data: postData
        }).then(res => {
            setLoading(false)
            setTransData(res?.data?.Success)
        }).catch(err =>{
            setLoading(false)
            // message.error(err?.response?.data?.Error)
        })
    }

    useEffect(() => {
        if(Api){
            transaction();
        }
    }, [Api]);

    const dataSource = [];

    if (transData) {
        for (let i = 0; i < transData.length; i++) {
            dataSource.push({
                asset: <p className=' text-center'>{transData[i]?.asset}</p>,
                income: <p className=' text-center'>{transData[i]?.income}</p>,
                incomeType: <p className=' text-center'>{transData[i]?.incomeType}</p>,
                info: <p className=' text-center'>{transData[i]?.info}</p>,
                symbol: <p className=' text-center'>{transData[i]?.symbol}</p>,
                tranId: <p className=' text-center'>{transData[i]?.tranId}</p>,
                tradeId: <p className=' text-center'>{transData[i]?.tradeId}</p>,
            })
        }
    }

    const columns = [
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Symbol</p>,
            dataIndex: 'symbol',
            key: 'symbol',
            width: 200
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>asset</p>,
            dataIndex: 'asset',
            key: 'asset',
            width: 200
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>income</p>,
            dataIndex: 'income',
            key: 'income',
            width: 200
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>incomeType</p>,
            dataIndex: 'incomeType',
            key: 'incomeType',
            width: 200
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>info</p>,
            dataIndex: 'info',
            key: 'info',
            width: 200
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>tranId</p>,
            dataIndex: 'tranId',
            key: 'tranId',
            width: 200
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>tradeId</p>,
            dataIndex: 'tradeId',
            key: 'tradeId',
            width: 200
        },
    ]

    const handleChange = (value) => {
        setApi(value)
    }

    return (
        <div className='container '>
            <div className=' flex gap-2 items-center mt-10 container'>
                <p className=' text-base font-semibold'>API Name : </p>
                <Select
                    value={Api}
                    className='focus:border-colorAccent hover:border-colorAccent'
                    style={{
                        width: 180,
                    }}
                    placeholder="Select API Name"
                    onChange={handleChange}
                    options={option2}
                />
            </div>
            
      <div className='my-3 flex flex-col md:flex-row md:items-center gap-2 px-2'>
        <p>Data may be displayed with a delay of 1-2 minutes, please click Refresh to update the data </p>
        <button className=' flex items-center gap-2 text-colorSecondaryDark font-medium' onClick={transaction}><TfiReload className='  ' /> Refresh</button>
      </div>
            <div className='container'>
                <Spin spinning={loading} >
                    <Table columns={columns} dataSource={dataSource}
                      scroll={{
                        x: 1500,
                      }} 
                    />
                </Spin>
            </div>
        </div>
    )
}

export default LiveTransactioncopy