import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import ActiveBotTable from "../Components/ActiveBotTable";
import { HandleSession } from "../Components/HandleSession";
import { act } from "react";
import KucoinBotTable from "../Components/KucoinBotTable";

const useOutsideClick = (ref, setShowOptions) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setShowOptions]);
};

const KucoinActiveSpot = (props) => {
  const [filterData, setfilterData] = useState([]);
  const [filterbotData, setfilterBotData] = useState([]);
  const [typedata, setTypeData] = useState([]);
  const [apiname, setApiname] = useState([]);
  const [exchange, setexchange] = useState([]);
  const [botname, setbotname] = useState([]);
  const [filter, setFilter] = useState("FUTURES");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
   


  const [selectedBotType, setSelectedBotType] = useState("FUTURES");

  useEffect(() => {
    setSelectedOptions1(apiname);
  }, [apiname]);

  useEffect(() => {
    setSelectedOptions(filterData);
  }, [filterData]);

  

 
  const [activeButton, setActiveButton] = useState("FUTURES");
  

  const handleFilterByBotType = (botType) => {
    setSelectedBotType(botType);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  const pageSize = isMobile ? 10 : 22;

  const pageSizeOptions = [10, 20, 30, 50, 100];

  const item = [
    {
      name: "Future",
      click: "FUTURES",
    },
    {
      name: "Spot",
      click: "SPOT",
    },
    
  ];

 

  return (
    <>
      <div className="container mx-auto">
        <div className=" px-6 md:my-4 gap-4 flex flex-col md:flex-row items-center justify-center md:justify-between">
          

        
        </div>

        <KucoinBotTable
          data={[
           
            
          ]}
          type={filter}
          coin={selectedOptions}
          selectedBotID={selectedOptions1}
          activeButton={activeButton}
        />
      </div>
    </>
  );
};

export default KucoinActiveSpot;
