
import { CloseOutlined } from "@material-ui/icons";
import { Modal } from "antd";

function DetailsArb({ setDrawer, drawer, selectedItem }) {

    const handleCancel = () => {
        setDrawer(false);
    };

    return (
        <div>
            <Modal
                title={
                    selectedItem && (
                        <>
                            <div className='flex gap-10 items-center bg-primaryColor text-black'>
                                <div className="flex gap-1 items-center justify-start">
                                    <img
                                        src={`/images/Icon/${selectedItem?.Symbol.split("/")[0]}.svg`}
                                        alt={selectedItem?.Symbol?.split("/")[0]}
                                        className='w-7 h-7'
                                    />
                                   <p className="text-xs"> {selectedItem?.Symbol}</p></div>
                                <div className="flex gap-1 items-center justify-start">
                                    <img
                                        src={`/images/Icon/${selectedItem?.Sell_Exchange}.svg`}
                                        alt={selectedItem?.Sell_Exchange} className='w-7 h-7'
                                    />
                                    <div className='text-xs font-semibold'>{selectedItem?.Sell_Exchange}</div>
                                </div>
                                <div className="flex gap-1 items-center justify-start">
                                    <img
                                        src={`/images/Icon/${selectedItem?.Buy_Exchange}.svg`}
                                        alt={selectedItem?.Buy_Exchange} className='w-7 h-7 '
                                    />
                                    <div className='text-xs font-semibold'>{selectedItem?.Buy_Exchange}</div>
                                </div>
                            </div>
                        </>
                    )
                }
                visible={drawer}
                onCancel={handleCancel}
                footer={null}
                closeIcon={<CloseOutlined className="text-red-300" />}
            // width={1000}
            >
                {selectedItem ? (
                    <>
                        <div>
                            <div className="flex justify-center items-center">
                                <h1 className="font-semibold text-center border-b w-1/2  border-neonPurple border-dotted"> Volume</h1>
                            </div>
                            <div className="grid grid-cols-3 gap-2">
                                <div className="flex items-start flex-col">
                                    <strong>Duration</strong>
                                    <strong>24H:</strong>
                                    <strong>6H:</strong>
                                    <strong>1H:</strong>
                                    <strong>5M:</strong>
                                </div>
                                <div>
                                    <strong className="text-[green]">Buy Exchange</strong>
                                    <p>$ {selectedItem.Buy_Volume.h24}</p>
                                    <p>$ {selectedItem.Buy_Volume.h6}</p>
                                    <p>$ {selectedItem.Buy_Volume.h1}</p>
                                    <p>$ {selectedItem.Buy_Volume.m5}</p>
                                </div>
                                <div>
                                    <strong className="text-[red]">Sell Exchange</strong>
                                    <p>$ {selectedItem.Sell_Volume.h24}</p>
                                    <p>$ {selectedItem.Sell_Volume.h6}</p>
                                    <p>$ {selectedItem.Sell_Volume.h1}</p>
                                    <p>$ {selectedItem.Sell_Volume.m5}</p>
                                </div>
                            </div>
                            <hr className="my-2" />

                            <div className="flex justify-center items-center">
                                <h1 className="font-semibold text-center border-b w-1/2  border-neonPurple border-dotted"> Price Change</h1>
                            </div>
                            <div className="grid grid-cols-3 gap-2">
                                <div className="flex items-start flex-col">
                                    <strong>Duration</strong>
                                    <strong>24H:</strong>
                                    <strong>6H:</strong>
                                    <strong>1H:</strong>
                                    <strong>5M:</strong>
                                </div>
                                <div>
                                    <strong className="text-[green]">Buy Exchange</strong>
                                    <p className={selectedItem.Buy_PriceChange.h24 < 0 ? 'text-red-500' : ''}> {selectedItem.Buy_PriceChange.h24} %</p>
                                    <p className={selectedItem.Buy_PriceChange.h6 < 0 ? 'text-red-500' : ''}> {selectedItem.Buy_PriceChange.h6} %</p>
                                    <p className={selectedItem.Buy_PriceChange.h1 < 0 ? 'text-red-500' : ''}> {selectedItem.Buy_PriceChange.h1} %</p>
                                    <p className={selectedItem.Buy_PriceChange.m5 < 0 ? 'text-red-500' : ''}> {selectedItem.Buy_PriceChange.m5} %</p>
                                </div>
                                <div>
                                    <strong className="text-[red]">Sell Exchange</strong>
                                    <p className={selectedItem.Sell_PriceChange.h24 < 0 ? 'text-red-500' : ''}> {selectedItem.Sell_PriceChange.h24} %</p>
                                    <p className={selectedItem.Sell_PriceChange.h6 < 0 ? 'text-red-500' : ''}> {selectedItem.Sell_PriceChange.h6} %</p>
                                    <p className={selectedItem.Sell_PriceChange.h1 < 0 ? 'text-red-500' : ''}> {selectedItem.Sell_PriceChange.h1} %</p>
                                    <p className={selectedItem.Sell_PriceChange.m5 < 0 ? 'text-red-500' : ''}> {selectedItem.Sell_PriceChange.m5} %</p>
                                </div>
                            </div>
                            <hr className="my-2" />

                            <div className="flex justify-center items-center">
                                <h1 className="font-semibold text-center border-b w-1/2  border-neonPurple border-dotted"> Liquidty</h1>
                            </div>

                            <div className="grid grid-cols-3 gap-2">
                                <div className="flex items-start flex-col">
                                    <strong>Name</strong>
                                    <strong>USD:</strong>
                                    <strong>{selectedItem.Symbol.split("/")[0]}:</strong>
                                    <strong>{selectedItem.Symbol.split("/")[1]}:</strong>
                                </div>
                                <div>
                                    <strong className="text-[green]">Buy Exchange</strong>
                                    <p>$ {selectedItem.Buy_Liquidty.usd}</p>
                                    <p>$ {selectedItem.Buy_Liquidty.base}</p>
                                    <p> {selectedItem.Buy_Liquidty.quote} {selectedItem.Symbol.split("/")[1]}</p>
                                </div>
                                <div>
                                    <strong className="text-[red]">Sell Exchange</strong>
                                    <p>$ {selectedItem.Sell_Liquidty.usd}</p>
                                    <p>$ {selectedItem.Sell_Liquidty.base}</p>
                                    <p> {selectedItem.Sell_Liquidty.quote} {selectedItem.Symbol.split("/")[1]}</p>
                                </div>
                            </div>
                            <hr className="my-2" />

                            <div className="flex justify-center items-center">
                                <h1 className="font-semibold text-center border-b w-1/2  border-neonPurple border-dotted">Transaction</h1>
                            </div>
                            <p className="text-center text-[green] font-semibold my-2">Buy Exchange</p>
                            <div className="flex justify-between items-center ">

                                <div>
                                    <strong>Action</strong>
                                    <p className="text-[green]">Buy:</p>
                                    <p className="text-[red]">Sell:</p>
                                </div>

                                <div>
                                    <strong>24H</strong>
                                    <p>{selectedItem.Buy_Transaction.h24.buys}</p>
                                    <p>{selectedItem.Buy_Transaction.h24.sells}</p>
                                </div>
                                <div>
                                    <strong>6H</strong>
                                    <p>{selectedItem.Buy_Transaction.h6.buys}</p>
                                    <p>{selectedItem.Buy_Transaction.h6.sells}</p>
                                </div>
                                <div>
                                    <strong>1H</strong>
                                    <p>{selectedItem.Buy_Transaction.h1.buys}</p>
                                    <p>{selectedItem.Buy_Transaction.h1.sells}</p>
                                </div>
                                <div>
                                    <strong>5M</strong>
                                    <p>{selectedItem.Buy_Transaction.m5.buys}</p>
                                    <p>{selectedItem.Buy_Transaction.m5.sells}</p>
                                </div>
                            </div>

                            <p className="text-center text-[red] font-semibold my-2">Sell Exchange</p>
                            <div className="flex justify-between items-center ">

                                <div>
                                    {/* <strong>Action</strong> */}
                                    <p className="text-[green]">Buy:</p>
                                    <p className="text-[red]">Sell:</p>
                                </div>
                                <div>
                                    {/* <strong>h24</strong> */}
                                    <p>{selectedItem.Sell_Transaction.h24.buys}</p>
                                    <p>{selectedItem.Sell_Transaction.h24.sells}</p>
                                </div>
                                <div>
                                    {/* <strong>h6</strong> */}
                                    <p>{selectedItem.Sell_Transaction.h6.buys}</p>
                                    <p>{selectedItem.Sell_Transaction.h6.sells}</p>
                                </div>
                                <div>
                                    {/* <strong>h1</strong> */}
                                    <p>{selectedItem.Sell_Transaction.h1.buys}</p>
                                    <p>{selectedItem.Sell_Transaction.h1.sells}</p>
                                </div>
                                <div>
                                    {/* <strong>m5</strong> */}
                                    <p>{selectedItem.Sell_Transaction.m5.buys}</p>
                                    <p>{selectedItem.Sell_Transaction.m5.sells}</p>
                                </div>



                            </div>
                        </div>

                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </Modal>
        </div>
    );
}

export default DetailsArb;


