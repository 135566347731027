import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin, Table, Tooltip, Select, Button, Input } from "antd";
import { AiOutlineWarning } from "react-icons/ai";
import Apisame from "./Apisame";
import UpdateApi from "./UpdateApi";

const { Option } = Select;

const WalletNFT = () => {
  const [datas, setDatas] = useState(null);
  const [chain, setChain] = useState("ETH");
  const [address, setAddress] = useState(
    "0xff3879b8a363aed92a6eaba8f61f1a96a9ec3c1e"
  );

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.NFT_Analyitics;

  const handleChainChange = (value) => {
    setChain(value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const fetchData = () => {
    setLoading(true);
    const url = `https://deep-index.moralis.io/api/v2.2/${address}/nft?chain=${chain}&format=decimal&media_items=false`;
    const headers = {
      Accepts: "application/json",
      "X-API-Key":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MjI2MTZmLWM4MzItNDJmZS04YzI1LTA4NGZiNTAyY2ZmOCIsIm9yZ0lkIjoiMzcwMjAxIiwidXNlcklkIjoiMzgwNDY5IiwidHlwZUlkIjoiYTM5MmYyMTItZGQ1OC00NjY2LWEyMGMtN2E2MDdlZGU3YmZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDM5MzEwMjMsImV4cCI6NDg1OTY5MTAyM30.1ik9oveoExEKuAyXdSIWTKWo3i22Yp59_37ZDIJl8ew",
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const result = response.data.result;

        const dataArray = Array.isArray(result) ? result : [result];
        setDatas(dataArray);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (checkTradePackage === true) {
      fetchData();
    }
  }, [checkTradePackage]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const chainOptions = [
    { value: "1", label: "ETH" },
    { value: "10", label: "BSC" },
  ];

  const columns = [
    {
      title: "Logo",
      dataIndex: "collection_logo",
      key: "image",
      render: (collection_logo) => {
        return collection_logo ? (
          <img
            src={collection_logo}
            alt="NFT"
            style={{ width: 50, height: 50 }}
          />
        ) : (
          "No Image"
        );
      },
    },
    {
      title: "Token ID",
      dataIndex: "token_id",
      key: "token_id",
    },
    {
      title: "Block Number",
      dataIndex: "block_number",
      key: "block_number",
    },
    {
      title: "Verified",
      dataIndex: "verified_collection",
      key: "verified_collection",
      render: (verified_collection) => {
        return verified_collection ? (
          <AiOutlineWarning style={{ color: "red" }} />
        ) : null;
      },
    },
    {
      title: "Contract Type",
      dataIndex: "contract_type",
      key: "contract_type",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Spam",
      dataIndex: "possible_spam",
      key: "possible_spam",
      render: (possible_spam) => {
        return possible_spam ? (
          <AiOutlineWarning style={{ color: "red" }} />
        ) : null;
      },
    },
  ];

  const clearFilter = () => {
    setAddress("")
    setChain("")
  }

  return (
    <>
      <div className="mx-5">
       

        <div className="flex justify-between items-center my-5">
          <div className="gap-4 flex"> 
        <Input
          value={address}
          onChange={handleAddressChange}
          style={{ width: 300 }}
        />
        <Select
          value={chain}
          onChange={handleChainChange}
          style={{ width: 120 }}
        >
          {chainOptions.map((option) => (
            <Option key={option.value} value={option.label}>
              {option.label}
            </Option>
          ))}
        </Select>
        </div>
        <div>
            <button
              onClick={fetchData}
              className="bg-neonPurple text-white px-4 py-1 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
            >
              Save
            </button>
            <button
              onClick={clearFilter}
              className="bg-gray-400 text-white px-4 py-1 rounded-md shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Clear Filters
            </button>
          </div>






        </div>

        {loading ? (
          <Spin
            size="large"
            style={{ display: "block", margin: "100px auto" }}
          />
        ) : (
          <Table
            dataSource={datas}
            columns={columns}
            pagination={false}
            rowKey="token_id"
          />
        )}
      </div>
    </>
  );
};

export default WalletNFT;
