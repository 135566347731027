import { Card, Switch } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import SkeltorLoader from "./SkeltorLoader";
import axios from "axios";
import FilterFormArb from "./FilterFormArb";
import Filter from "./Filter";
import { useNavigate } from "react-router-dom";
import Apisame from "./Apisame";
import { FaLock } from "react-icons/fa";
import UpdateApi from "./UpdateApi";
import { FaCopy } from "react-icons/fa";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";


const MultiExchange = () => {
  const [websocketData, setWebsocketData] = useState([]);
  const [exchangeOptions, setExchangeOptions] = useState([]);
  const [trackedExchanges, setTrackedExchanges] = useState([]);
  const [whitelistCurrencies, setWhitelistCurrencies] = useState([]);
  const [blacklistCurrencies, setBlacklistCurrencies] = useState([]);
  const [buyExchanges, setBuyExchanges] = useState([]);
  const [sellExchanges, setSellExchanges] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkBotPackage = checkBotFeature.Arbitrage_Multi_Exchange;
  const [checkBotPackageToggle, setCheckBotPackageToggle] = useState(true);
  const [intervalId, setIntervalId] = useState(null);

  const navigate = useNavigate();

  const View_Pack = async () => {
    setLoading(true);
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: "https://tools.skywealth.club/arbitrage/arbitrage_data",
    })
      .then(async (response) => {
        const res = await response?.data;
        setWebsocketData(res?.Success || []);
        applyFilters(res?.Success);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exchangeUrls = {
    binance: "https://www.binance.com/en/trade/{coin}USDT?type=spot",
    bybit: "https://www.bybit.com/trade/usdt/{coin}USDT",
    gateio: "https://www.gate.io/trade/{coin}_USDT",
    kucoin: "https://www.kucoin.com/trade/{coin}-USDT",
    hitbtc: "https://hitbtc.com/{coin}-to-usdt",
    bitfinex: "https://trading.bitfinex.com/t/{coin}:USD?type=exchange",
    kraken: "https://pro.kraken.com/app/trade/{coin}-usdt",
    okx: "https://www.okx.com/trade-spot/{coin}-usdt",
    mexc: "https://www.mexc.com/exchange/{coin}_USDT",
    htx: "https://www.htx.com/en-in/trade/{coin}_usdt",
  };

  const applyFilters = useCallback(
    (data) => {
      const filtered = data.filter((item) => {
        const coinName = item.coin.split("/")[0];
        return (
          (trackedExchanges.length === 0 ||
            trackedExchanges.includes(item.buy_exchange) ||
            trackedExchanges.includes(item.sell_exchange)) &&
          (whitelistCurrencies.length === 0 ||
            whitelistCurrencies.includes(coinName)) &&
          (blacklistCurrencies.length === 0 ||
            !blacklistCurrencies.includes(coinName)) &&
          (buyExchanges.length === 0 ||
            buyExchanges.includes(item.buy_exchange)) &&
          (sellExchanges.length === 0 ||
            sellExchanges.includes(item.sell_exchange))
        );
      });
      setFilteredData(filtered);
    },
    [
      trackedExchanges,
      whitelistCurrencies,
      blacklistCurrencies,
      buyExchanges,
      sellExchanges,
    ]
  );

  const handleSubmit = () => {
    applyFilters(websocketData);
    setModalIsOpen(false);
  };

  useEffect(() => {
    let interval;
    if (checkBotPackageToggle) {
      View_Pack();

      interval = setInterval(() => {
        View_Pack();
      }, 25000);
    } else {
    }

    // Cleanup interval on component unmount or if checkBotPackageToggle changes
    return () => clearInterval(interval);
  }, [checkBotPackageToggle]);

  const toggleSwitch = (checked) => {
    setCheckBotPackageToggle(checked);
  };

  useEffect(() => {
    const sortedData = [...websocketData].sort(
      (a, b) => (b?.percentage_diff || 0) - (a?.percentage_diff || 0)
    );
    setFilteredData(sortedData);
  }, [websocketData]);

  useEffect(() => {
    if (websocketData.length > 0) {
      applyFilters(websocketData);

      const buyExchangesSet = new Set(
        websocketData.map((item) => item.buy_exchange)
      );
      const sellExchangesSet = new Set(
        websocketData.map((item) => item.sell_exchange)
      );
      const allExchanges = Array.from(
        new Set([...buyExchangesSet, ...sellExchangesSet])
      );
      const uniqueCurrencies = Array.from(
        new Set(websocketData.map((item) => item.coin.split("/")[0]))
      );

      setExchangeOptions(
        allExchanges.map((exchange) => ({ value: exchange, label: exchange }))
      );
      setCurrencyOptions(
        uniqueCurrencies.map((currency) => ({
          value: currency,
          label: currency,
        }))
      );
    }
  }, [websocketData, applyFilters]);

  const handleClearFilters = () => {
    setTrackedExchanges([]);
    setWhitelistCurrencies([]);
    setBlacklistCurrencies([]);
    setBuyExchanges([]);
    setSellExchanges([]);
    setFilteredData(websocketData);
    setModalIsOpen(false);
  };

  const handleDetail = (item) => {
    const url = new URL("/dashboard/orderbook", window.location.origin);
    url.searchParams.append("coin", item.coin);
    url.searchParams.append("buyExchange", item.buy_exchange);
    url.searchParams.append("sellExchange", item.sell_exchange);
    url.searchParams.append("itembuyprice", item.buy_price);
    url.searchParams.append("itemsellprice", item.sell_price);
    url.searchParams.append("perdiff", item.percentage_diff);
    url.searchParams.append("pricediff", item.price_diff);
    window.open(url, "_blank");
  };


  const copyToClipboard = (index) => {
    const price = filteredData[index]?.buy_price
      ? filteredData[index].buy_price
      : "";
    copy(price.toString());
    toast("You Copied Buy");
  };

  const copyToClipboard2 = (index) => {
    const price = filteredData[index]?.sell_price
      ? filteredData[index].sell_price
      : "";
    copy(price.toString());
    toast("You Copied Sell");
  };

  return (
    <>
      <div className="container relative">
        <div className="mainDiv h-auto max-h-screen mb-4 ">
          {checkBotPackage === false ? (
            <div
              className="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
              style={{
                backgroundImage:
                  "url(https://cdn.odecents.com/Lock-bg/multiexchange.webp)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "95vh",
              }}
            >
              <FaLock className="text-gray-600 text-6xl mb-4" />
              <h2 className="text-2xl font-bold mb-2">Access Restricted</h2>
              <p className="mb-4">
                This feature is only available for BOT package users. Please
                upgrade to access this feature.
              </p>
            </div>
          ) : (
            <div>
              <div className="p-4 w-full mx-auto hidden lg:block">
                <p className="text-center  text-[#9b9999] text-xs">
                  Please double check the data on exchanges!
                </p>

                <div className="flex justify-between items-center gap-2">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                    <FilterFormArb
                      label="Tracked exchanges"
                      options={exchangeOptions}
                      selectedOptions={trackedExchanges}
                      handleChange={setTrackedExchanges}
                    />
                    <FilterFormArb
                      label="Whitelist of currencies"
                      options={currencyOptions}
                      selectedOptions={whitelistCurrencies}
                      handleChange={setWhitelistCurrencies}
                    />
                    <FilterFormArb
                      label="Blacklist of currencies"
                      options={currencyOptions}
                      selectedOptions={blacklistCurrencies}
                      handleChange={setBlacklistCurrencies}
                    />
                    <FilterFormArb
                      label="Exchanges for buying"
                      options={exchangeOptions.filter((option) =>
                        trackedExchanges.includes(option.label)
                      )}
                      selectedOptions={buyExchanges}
                      handleChange={setBuyExchanges}
                    />
                    <FilterFormArb
                      label="Exchanges for selling"
                      options={exchangeOptions.filter((option) =>
                        trackedExchanges.includes(option.label)
                      )}
                      selectedOptions={sellExchanges}
                      handleChange={setSellExchanges}
                    />
                  </div>

                  <div className="flex gap-2 justify-between items-center">
                    <div className="">
                      <label htmlFor="" className="">
                        Update:-{" "}
                      </label>
                      <Switch
                        checked={checkBotPackageToggle}
                        onChange={toggleSwitch}
                        className="bg-gray-500"
                      />
                    </div>
                    <div className="">
                      <button
                        onClick={handleSubmit}
                        className="bg-neonPurple  text-white px-4 py-1 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
                      >
                        Save
                      </button>
                      <button
                        onClick={handleClearFilters}
                        className="bg-gray-400  text-white px-4 py-1 rounded-md shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex lg:hidden z-30 justify-end">
                <button
                  className="bg-neonPurple rounded-lg text-white px-2 py-1 mb-3"
                  onClick={() => setModalIsOpen(true)}
                >
                  Open Filter
                </button>
                <Filter
                  isOpen={modalIsOpen}
                  setIsOpen={setModalIsOpen}
                  onRequestClose={() => setModalIsOpen(false)}
                  handleSubmit={handleSubmit}
                  handleClearFilters={handleClearFilters}
                  exchangeOptions={exchangeOptions}
                  currencyOptions={currencyOptions}
                  trackedExchanges={trackedExchanges}
                  setTrackedExchanges={setTrackedExchanges}
                  whitelistCurrencies={whitelistCurrencies}
                  setWhitelistCurrencies={setWhitelistCurrencies}
                  blacklistCurrencies={blacklistCurrencies}
                  setBlacklistCurrencies={setBlacklistCurrencies}
                  buyExchanges={buyExchanges}
                  setBuyExchanges={setBuyExchanges}
                  sellExchanges={sellExchanges}
                  setSellExchanges={setSellExchanges}
                />
              </div>

              <div
                className="grid grid-cols-1 md:grid-cols-1 mb-[3%] lg:grid-cols-2 xl:grid-cols-3 gap-2 2xl:gap-5"
                justify="center"
              >
                {loading ? (
                  Array.from({ length: 12 }).map((_, index) => (
                    <Card className="bg-white shadow-md text-black hover:shadow-lg transition duration-100 border drop gap-2">
                      <SkeltorLoader />
                    </Card>
                  ))
                ) : filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <div
                      key={index}
                      className="bg-white shadow-lg py-2 border-2 border-black rounded-lg hover:shadow-lg transition duration-100"
                    >
                      <div className="text-gray-800">
                        <div className="flex justify-between items-center px-2">
                          <div>
                            <div className="flex items-center gap-2">
                              <img
                                src={`/images/50x50/${
                                  item.coin.split("/")[0]
                                }.svg`}
                                alt={item.coin.split("/")[0]}
                                className="w-7 h-7"
                              />
                              <h2 className="font-bold">
                                {item.coin.split("/")[0]}
                              </h2>
                            </div>
                          </div>

                          <div className="flex flex-col justify-center items-center">
                            <a
                              target="_blank"
                              href={exchangeUrls[item.buy_exchange].replace(
                                "{coin}",
                                item.coin.split("/")[0].toUpperCase()
                              )}
                              className="text-center font-semibold text-[green]"
                            >
                              Buy
                            </a>
                            <div className="flex"> 
                            <h2 className="font-semibold text-xs md:text-base">
                              {item.buy_price} {item.coin.split("/")[1]}
                            </h2>
                            <button
                                    onClick={() => copyToClipboard(index)}
                                    className="border-none mx-3 hover:bg-active"
                                  >
                                    <FaCopy className="text-neonPurple" />
                                  </button>
                                  </div>
                          </div>
                          <div className="flex items-center flex-col">
                            <img
                              src={`/images/exchange/${item.buy_exchange}.svg`}
                              alt={item.buy_exchange}
                              className="w-7 h-7"
                            />
                            <div className="font-bold">{item.buy_exchange}</div>
                          </div>
                        </div>

                        <div className="border-t flex justify-center gap-2 items-center bg-neonPurple/80 text-white py-2 my-1 px-2">
                          <div>
                            <p className="text-xs lg:text-sm md:text-sm 2xl:text-lg font-semibold">
                              Arbitrage :
                            </p>
                          </div>
                          <div className="flex gap-1 2xl:gap-2">
                            <p className="text-xs lg:text-sm md:text-xs 2xl:text-lg font-semibold">
                              {item.percentage_diff.toFixed(4)} %
                            </p>
                            <p className="text-xs lg:text-sm md:text-xs 2xl:text-lg font-semibold">
                              ({"$" + item.price_diff.toFixed(4)})
                            </p>
                          </div>
                          <button
                            onClick={() => handleDetail(item)}
                            className="text-[12px] text-neonPurple  bg-white  px-5 rounded-md  text-end  cursor-pointer"
                          >
                            View More{" "}
                          </button>
                        </div>

                        <div className="flex justify-between items-center px-2">
                          <div>
                            <div className="flex items-center gap-2">
                              <img
                                src={`/images/50x50/${
                                  item.coin.split("/")[0]
                                }.svg`}
                                alt={item.coin.split("/")[0]}
                                className="w-7 h-7"
                              />
                              <h2 className="font-bold">
                                {item.coin.split("/")[0]}
                              </h2>
                            </div>
                          </div>

                          <div className="flex flex-col justify-center items-center">
                            <a
                              target="_blank"
                              href={exchangeUrls[item.sell_exchange].replace(
                                "{coin}",
                                item.coin.split("/")[0].toUpperCase()
                              )}
                              className="text-center font-semibold text-[red]"
                            >
                              Sell
                            </a>
                            <div className="flex">
                            <h2 className="font-semibold text-xs md:text-base">
                              {item.sell_price} {item.coin.split("/")[1]}
                            </h2>

                            <button
                                    onClick={() => copyToClipboard2(index)}
                                    className="border-none mx-3 hover:bg-active"
                                  >
                                    <FaCopy className="text-neonPurple" />
                                  </button>
                                  </div>
                          </div>
                          <div className="flex items-center flex-col">
                            <img
                              src={`/images/exchange/${item.sell_exchange}.svg`}
                              alt={item.sell_exchange}
                              className="w-7 h-7"
                            />
                            <div className="font-bold">
                              {item.sell_exchange}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-600 text-center py-4">
                    No data available
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MultiExchange;
