import React, { useEffect, useState } from 'react';
import { IoMdSearch } from 'react-icons/io';
import axios from 'axios';
import { FcCancel, FcOk } from 'react-icons/fc';
import { Select, Spin } from 'antd';
import DexTable from '../Layout/DexTable';
import UpdateApi from './UpdateApi';

const { Option } = Select;

const Dex = () => {
    const [searchValue, setSearchValue] = useState('0x556585379d717317b97503a8b2c8dd587cd245a3');
    const [searchResult, setSearchResult] = useState("");
    const [error, setError] = useState(null);
    const [chainId, setChainId] = useState("56");
    const [chainIdlabel, setChainIdlabel] = useState("bsc");
    const [loading, setLoading] = useState(false);
    const [searchResultApproval, setSearchResultApproval] = useState("");
    const [selectedExchange, setSelectedExchange] = useState("Ethereum");
    const { checkBotFeature, checkTradeFeature } = UpdateApi();
    const checkTradePackage = checkTradeFeature.Blockchain_Analytics;
    
    const handleSelectChange = (value, label) => {
        setChainId(value);
        setChainIdlabel(label.toLowerCase());
    };

    const handleSearch = () => {
        setLoading(true);
        axios.get(`https://api.gopluslabs.io/api/v1/address_security/${searchValue}`)
            .then(response => {
                setSearchResult(response.data.result);
            })
            .catch(err => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const renderStatus = (label, value) => {
        return (
            <div className='flex items-center justify-between gap-6'>
                {label}:
                <p>{value === "1" ? <FcOk /> : <FcCancel />}</p>
            </div>
        );
    };

    const handleChange = (value) => {
        setSelectedExchange(value);
    };

    const handleSearchSecurityApproval = () => {
        axios.get(`https://api.gopluslabs.io/api/v1/approval_security/${chainId}?contract_addresses=${searchValue}`, {
            headers: {
                'Accept': '*/*'
            }
        })
            .then(response => {
                setSearchResultApproval(response.data.result);
            })
            .catch(err => {
                setError(err.message);
            })

    };

    useEffect(() => {
        if (checkTradePackage === true) {
            handleSearch();
            handleSearchSecurityApproval();
        }
    }, [checkTradePackage, searchValue, chainId]);

    const chainOptions = [
        { value: "1", label: "Ethereum" },
        { value: "10", label: "Optimism" },
        { value: "25", label: "Cronos" },
        { value: "56", label: "BSC" },
        { value: "66", label: "OKC" },
        { value: "100", label: "Gnosis" },
        { value: "128", label: "HECO" },
        { value: "137", label: "Polygon" },
        { value: "250", label: "Fantom" },
        { value: "321", label: "KCC" },
        { value: "324", label: "zkSync Era" },
        { value: "10001", label: "ETHW" },
        { value: "201022", label: "FON" },
        { value: "42161", label: "Arbitrum" },
        { value: "43114", label: "Avalanche" },
        { value: "59144", label: "Linea Mainnet" },
        { value: "8453", label: "Base" },
        { value: "tron", label: "Tron" },
        { value: "534352", label: "Scroll" },
        { value: "204", label: "opBNB" }
    ];

    return (
        <>
            <div className=' flex flex-col md:flex-row items-center justify-start gap-6'>
                <div>
                    <Select
                        placeholder="Select Chain ID"
                        style={{ width: 200 }}
                        onChange={handleSelectChange}
                        value={chainId}
                        defaultValue="56"
                    >
                        {chainOptions.map(option => (
                            <Option key={option.value} value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                </div>
                <div className="relative w-full md:w-1/2">

                    <input
                        type="text"
                        placeholder="Contract Address..."
                        className="border border-gray-300 rounded-md py-2 px-4 w-full"
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                    />
                    <button
                        className="absolute right-0 top-0 mt-2 mr-4"
                        onClick={() => {
                            handleSearch();
                            handleSearchSecurityApproval();
                        }}
                    >
                        <IoMdSearch className="h-6 w-6 text-gray-500" />
                    </button>
                </div>
            </div>
            <Spin spinning={loading} className='flex items-center '>
                {searchResult && (
                    <>
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5'>
                            <div className='h-auto border border-[#ccc] bg-white rounded-lg px-4 py-2'>
                                {/* <h2>Portfolio Value</h2> */}
                                {/* <p>{searchResult.data_source}</p>  */}
                                {renderStatus('Gas abuse', searchResult.gas_abuse)}
                                <hr />
                                {renderStatus('Phishing Activities', searchResult.phishing_activities)}
                                <hr />
                                {renderStatus('Honeypot Related Address', searchResult.honeypot_related_address)}
                                <hr />
                                {renderStatus('Stealing Attack', searchResult.stealing_attack)}
                                <hr />
                                {renderStatus('Fake KYC', searchResult.fake_kyc)}
                                <hr />
                                {renderStatus('Malicious Mining Activities', searchResult.malicious_mining_activities)}
                                <hr />
                                {renderStatus('Darkweb Transactions', searchResult.darkweb_transactions)}
                                <hr />
                                {renderStatus('Cybercrime', searchResult.cybercrime)}
                                <hr />
                                {renderStatus('Money Laundering', searchResult.money_laundering)}
                            </div>
                            <div className='h-auto border border-[#ccc] bg-white rounded-lg px-4 py-2'>
                                {/* <h2>Portfolio Value</h2> */}
                                {/* <p>{searchResult.data_source}</p>  */}



                                {renderStatus('Financial Crime', searchResult.financial_crime)}
                                <hr />
                                {renderStatus('Blackmail Activities', searchResult.blackmail_activities)}
                                <hr />
                                {renderStatus('Mixer', searchResult.mixer)}
                                <hr />
                                <div className='flex items-center justify-between gap-6'> Number of Malicious Contracts Created:
                                    <p>{searchResult.number_of_malicious_contracts_created} </p>
                                </div>
                                <hr />
                                <div className='flex items-center justify-between gap-6'> Gas abuse:
                                    <p>{searchResult.gas_abuse} </p>
                                </div>
                                <hr />
                                <div className='flex items-center justify-between gap-6'> Fake Token:
                                    <p>{searchResult.fake_token} </p>
                                </div>
                                <hr />
                                <div className='flex items-center justify-between gap-6'> Fake Standard Interface:
                                    <p>{searchResult.fake_standard_interface} </p>
                                </div>
                                <hr />
                                <div className='flex items-center justify-between gap-6'> Reinit:
                                    <p>{searchResult.reinit} </p>
                                </div>
                            </div>

                        </div>

                        <DexTable searchResult={searchValue} chainId={chainIdlabel} />
                    </>
                )}


            </Spin>
        </>
    );
};

export default Dex;
