import React from "react";
import img1 from "../assets/black.png";

const Loader = () => {
  return (
    <main className=" min-h-screen bg-gradient-to-tr from-colorPrimary to-white  text-fg flex flex-col items-center justify-center">
      {/* <div className="preloader animate-largePopOut bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-full shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-1 1 2 shadow-inset--1 -1 2 w-48 h-48 relative">
        <div className="preloader__square animate-smallPopOut1 animate-popInOut bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-0.5 shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-0.25 0.25 0.5 shadow-inset--0.25 -0.25 0.5 absolute top-10 left-10 w-12 h-12"></div>
        <div className="preloader__square animate-smallPopOut2 animate-move2 bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-0.5 shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-0.25 0.25 0.5 shadow-inset--0.25 -0.25 0.5 absolute top-10 left-10 w-12 h-12"></div>
        <div className="preloader__square animate-smallPopOut3 animate-move3 bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-0.5 shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-0.25 0.25 0.5 shadow-inset--0.25 -0.25 0.5 absolute top-10 left-10 w-12 h-12"></div>
        <div className="preloader__square animate-smallPopOut4 animate-move4 bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-0.5 shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-0.25 0.25 0.5 shadow-inset--0.25 -0.25 0.5 absolute top-10 left-10 w-12 h-12"></div>
      </div> */}
      {/* <div> */}
      <div className=" w-56 px-3 py-5 text-center">
        <img
          src={img1}
          className=" bg-gradient-to-tr from-colorPrimary to-neonPurple w-36 mx-auto animate-pulse p-2 rounded-full"
        />
        {/* </div> */}
        <div className="status animate-fadeIn text-center text-colorSecondaryDark text-xl mt-3">
          Loading.....
          <span className="status__dot animate-appear1 inline-block"></span>
          <span className="status__dot animate-appear2 inline-block"></span>
          <span className="status__dot animate-appear3 inline-block"></span>
        </div>
      </div>
    </main>
  );
};

export default Loader;


// import React from 'react';

// const Loader = () => {
//   return (
//     <div className="flex bg-neonPurple/80 justify-center items-center min-h-screen bg-gradient-radial from-[#78d5ff] to-[#0081e8]">
//       <div className="relative w-[280px] h-[280px] flex justify-center items-center transform-style-3d perspective-[500px] rotate-x-[45deg]">
//         <span className="absolute block border-[15px] border-white box-border rounded-full shadow-[0_10px_0_#e0e0e0,inset_0_10px_0_#e0e0e0] animate-spinDelay" style={{ animationDelay: '0s' }}></span>
//         <span className="absolute block border-[15px] border-white box-border rounded-full shadow-[0_10px_0_#e0e0e0,inset_0_10px_0_#e0e0e0] animate-spinDelay" style={{ animationDelay: '-1.5s' }}></span>
//         {/* <img src={img1} alt='logo' className="w-56"/> */}
//         <span className="absolute block border-[15px] border-white box-border rounded-full shadow-[0_10px_0_#e0e0e0,inset_0_10px_0_#e0e0e0] animate-spinDelay" style={{ animationDelay: '-3s' }}></span>
//       </div>     
//     </div>
//   );
// };

// export default Loader;
