

import React, { useState, useEffect, useRef } from 'react';
import { Empty, Modal, Select, Spin, Table, Tooltip, message } from 'antd';
import axios from 'axios';
import WebsocketApi from './WebSocketApi';
import { useMediaQuery } from 'react-responsive';
import { TbFilterCog } from 'react-icons/tb';


const OpenPosition = () => {
    const [futureData, setFutureData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [apiNames, setApiNames] = useState([]);
    const [exchanges, setExchanges] = useState([]);
    const [filter, setFilter] = useState("PAPER");
    const [varBotIDs, setVarBotIDs] = useState([]);
    const [selectedApiName, setSelectedApiName] = useState(null);
    const [selectedExchange, setSelectedExchange] = useState(null);
    const [selectedVarBotID, setSelectedVarBotID] = useState(null);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const sortedTableData = WebsocketApi();

    const projectName = process.env.REACT_APP_PROJECT;

    const handleFilterClick = (type) => {
        setFilter(type);
    };


    const openPositionFut = async () => {
        setFutureData([]);
        setLoading(true);
        const token = localStorage.getItem('jwt');
        const postData = {
            project: projectName,
            jwt: token,
        };

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_PATH_BOT}open_position`,
                postData,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );

            const responseData = res.data.Success;

            const botDataWithImages = await Promise.all(
                responseData?.map(async (botItem) => {
                    const imagePath = `/images/AllCoin/${botItem?.Symbol}.webp`;
                    return {
                        ...botItem,
                        images: imagePath,
                    };
                })
            );

            setFutureData(botDataWithImages);
            setApiNames([...new Set(botDataWithImages.map(item => item.API_Name))]);
            setExchanges([...new Set(botDataWithImages.map(item => item.Exchange))]);
            setVarBotIDs([...new Set(botDataWithImages.map(item => item.BotName))]);
        } catch (err) {
            message.error(err?.response?.data?.Error || "Error fetching data");
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        openPositionFut();
    }, []);

    const groupAndSortData = (data) => {
        const groupedData = data.reduce((acc, item) => {
            if (!acc[item.Symbol]) acc[item.Symbol] = [];
            acc[item.Symbol].push(item);
            return acc;
        }, {});

        return Object.values(groupedData).flatMap(group =>
            group.sort((a, b) => (a.Side === 'LONG' ? -1 : 1))
        );
    };

    const filterDataSource = () => {
        const filteredData = futureData.filter(item => {
            return (
                (!selectedApiName || item.API_Name === selectedApiName) &&
                (!selectedExchange || item.Exchange === selectedExchange) &&
                (!selectedVarBotID || item.BotName === selectedVarBotID) &&
                (item.Type === filter)
            );
        });
        return groupAndSortData(filteredData);
    };

    const dataSource = filterDataSource().map((item, index) => {
        const symbolWithoutUSDT = item?.Symbol.replace("USDT", "");
        const matchingPriceData = sortedTableData.find(
            (data) => data.name === symbolWithoutUSDT
        );
        const TotalQuantity = item?.Side === "SHORT" ? -item?.TotalQuantity : item?.TotalQuantity;
        const AverageBuy = item?.AverageBuy || 0;
        const Side = item?.Side;
        const size = TotalQuantity * AverageBuy;
        const currentPrice = matchingPriceData ? matchingPriceData.price : 0;
        const PnL = (currentPrice - AverageBuy) * TotalQuantity;
        const ExchangeType = item?.Exchange_Type;
        let pnlp = 0;
        if (AverageBuy > 0) {
            if (Side === "LONG") {
                pnlp = ((currentPrice - AverageBuy) / AverageBuy) * 100;
            } else if (Side === "SHORT") {
                pnlp = ((AverageBuy - currentPrice) / AverageBuy) * 100;
            }
        }


        return {
            key: index,
            img: item.images,
            Symbol: (
                <p className={`p-1 rounded-lg border-l-4 flex ${Side === "SHORT" ? "border-red-500" : "border-[green]"}`}>
                    {item?.Symbol}
                    <span className="text-neonPurple ">{ExchangeType === "FUTURES" ? "20x" : ""}</span>
                </p>
            ),

            AvgAmt: (
                <p className={`p-1 rounded-lg"}`}>
                    $ {AverageBuy.toFixed(4)}
                </p>
            ),
            VarBotID: <p className="p-1 rounded-lg">{item?.VarBotID}</p>,
            matchingPriceData: (
                <p className="p-1 rounded-lg">
                    $ {matchingPriceData ? Number(matchingPriceData.price).toFixed(4) : 'Loading...'}
                </p>
            ),

            PnL: (
                <p className={`p-1 rounded-lg ${matchingPriceData ? (PnL > 0 ? "text-[green]" : "text-[red]") : ""}`}>
                    {matchingPriceData ? PnL.toFixed(4) : 'Loading...'}
                </p>
            ),
            // PnL: (
            //     <p className={`p-1 rounded-lg ${PnL > 0 ? "text-[green]" : "text-[red]"}`}>
            //         {PnL.toFixed(4)}
            //     </p>
            // ),
            pnlp: (
                <p className={`p-1 rounded-lg ${matchingPriceData ? (pnlp > 0 ? "text-[green]" : "text-[red]") : ""}`}>
                    {matchingPriceData ? pnlp.toFixed(4) : 'Loading...'} %
                </p>
            ),
            API_Name: <p className="p-1 rounded-lg">{item?.API_Name}</p>,
            BotName: <p className="p-1 rounded-lg">{item?.BotName}</p>,
            Exchange: <p className="p-1 rounded-lg">{item?.Exchange}</p>,
            // img: <p className="p-1 rounded-lg">{img}</p>,
            Investment_Risk: <p className="p-1 rounded-lg">{item?.Investment_Risk}</p>,
            Margin_Type: <p className="p-1 rounded-lg">{item?.Margin_Type}</p>,
            Position_Side: <p className="p-1 rounded-lg">{Side ? Side : "--"}</p>,
            size: (
                <p className={`p-1 rounded-lg ${size > 0 ? "text-[green]" : "text-[red]"}`}>
                    {size.toFixed(4)} USDT
                </p>
            ),
            Update_Time: <p className="p-1 rounded-lg">{item?.Update_Time}</p>,
            PositionAmount: (
                <p className={`p-1 rounded-lg ${TotalQuantity > 0 ? "text-[green]" : "text-[red]"}`}>
                    {TotalQuantity.toFixed(4)}
                </p>
            ),
        };
    });

    const columns = [
        { title: <p className='p-1 text-center rounded-lg table-cell'>Risk</p>, dataIndex: 'Investment_Risk', key: 'Investment_Risk' },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Symbol</p>,
            dataIndex: 'img',
            key: 'img',
            render: (img) => (
                <img
                    src={img}
                    alt={Symbol}
                    className="w-8 h-8 object-cover"
                />
            ),
        },
        { title: <p className='p-1 text-center rounded-lg table-cell'>Pair</p>, dataIndex: 'Symbol', key: 'Symbol' },
        { title: <Tooltip title="Position Side" placement="top"><p className='p-1 text-center rounded-lg table-cell'>Side</p></Tooltip>, dataIndex: 'Position_Side', key: 'Position_Side' },
        { title: <p className='p-1 text-center rounded-lg table-cell'>Avg Price ($)</p>, dataIndex: 'AvgAmt', key: 'AvgAmt' },
        { title: <p className='p-1 text-center rounded-lg table-cell'>Size</p>, dataIndex: 'size', key: 'size' },
        { title: <Tooltip title="Position Amount" placement="top"><p className='p-1 text-center rounded-lg table-cell'>Quantity</p></Tooltip>, dataIndex: 'PositionAmount', key: 'PositionAmount' },
        { title: <p className='p-1 text-center rounded-lg table-cell'>Mark Price ($)</p>, dataIndex: 'matchingPriceData', key: 'matchingPriceData' },
        { title: <p className='p-1 text-center rounded-lg table-cell'>Unrealized Pnl (%)</p>, dataIndex: 'pnlp', key: 'pnlp' },
        { title: <p className='p-1 text-center rounded-lg table-cell'>Unrealized Pnl ($)</p>, dataIndex: 'PnL', key: 'PnL' },
    ];

    const [isModalVisible1, setIsModalVisible1] = useState(false);

    const showModal1 = () => {
        setIsModalVisible1(true);
    };

    const handleCancel1 = () => {
        setIsModalVisible1(false);
    };

    return (
        <div className=' '>

            <header className=" my-2 z-30    ">
                <div className="lg:hidden flex items-center justify-end">
                    <div onClick={showModal1} className="flex justify-end items-center">
                        <Tooltip title="Filter">
                            <TbFilterCog size={25} className="text-colorSecondaryDark" />
                        </Tooltip>
                    </div>
                    <Modal
                        title="Open Trades Filter"
                        visible={isModalVisible1}
                        // onOk={false}
                        footer={false}
                        onCancel={handleCancel1}
                    >
                        <div className="grid grid-cols-1 align-middle  md:grid-cols-2 xl:grid-cols-4 gap-4 justify-items-center items-center mb-4">

                            <div className='flex  items-center'>
                                <button
                                    className={`mr-2 2xl:px-4 py-1 rounded-full w-20  shadow-md ${filter === "PAPER"
                                        ? "bg-neonPurple text-white border-2 border-white drop-shadow-xl"
                                        : "bg-white text-black/60 shadow-neumorphic"
                                        }`}
                                    onClick={() => handleFilterClick("PAPER")}
                                >
                                    Paper
                                </button>
                                <button
                                    className={`mr-2 2xl:px-4 py-1 rounded-full w-20  shadow-md drop-shadow-xl ${filter === "LIVE"
                                        ? "bg-neonPurple text-white border-2 border-white "
                                        : "bg-white text-black/60 shadow-neumorphic"
                                        }`}
                                    onClick={() => handleFilterClick("LIVE")}
                                >
                                    Live
                                </button>
                            </div>
                            <Select
                                placeholder="Select API Name"
                                onChange={value => setSelectedApiName(value)}
                                style={{ width: 200 }}
                                options={apiNames.map(name => ({ value: name, label: name }))}
                                allowClear
                            />
                            <Select
                                placeholder="Select Exchange"
                                onChange={value => setSelectedExchange(value)}
                                style={{ width: 200 }}
                                options={exchanges.map(exchange => ({ value: exchange, label: exchange }))}
                                allowClear
                            />
                            <Select
                                placeholder="Select Bot Name"
                                onChange={value => setSelectedVarBotID(value)}
                                style={{ width: 200 }}
                                options={varBotIDs.map(id => ({ value: id, label: id }))}
                                allowClear
                            />
                        </div>
                    </Modal>
                </div>
            </header>

            <div className="hidden lg:grid grid-cols-1 align-middle  md:grid-cols-2 xl:grid-cols-4 gap-4 justify-items-center items-center mb-4">

                <div className='flex  items-center'>
                    <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20  shadow-md ${filter === "PAPER"
                            ? "bg-neonPurple text-white border-2 border-white drop-shadow-xl"
                            : "bg-white text-black/60 shadow-neumorphic"
                            }`}
                        onClick={() => handleFilterClick("PAPER")}
                    >
                        Paper
                    </button>
                    <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20  shadow-md drop-shadow-xl ${filter === "LIVE"
                            ? "bg-neonPurple text-white border-2 border-white "
                            : "bg-white text-black/60 shadow-neumorphic"
                            }`}
                        onClick={() => handleFilterClick("LIVE")}
                    >
                        Live
                    </button>
                </div>
                <Select
                    placeholder="Select API Name"
                    onChange={value => setSelectedApiName(value)}
                    style={{ width: 200 }}
                    options={apiNames.map(name => ({ value: name, label: name }))}
                    allowClear
                />
                <Select
                    placeholder="Select Exchange"
                    onChange={value => setSelectedExchange(value)}
                    style={{ width: 200 }}
                    options={exchanges.map(exchange => ({ value: exchange, label: exchange }))}
                    allowClear
                />
                <Select
                    placeholder="Select Bot Name"
                    onChange={value => setSelectedVarBotID(value)}
                    style={{ width: 200 }}
                    options={varBotIDs.map(id => ({ value: id, label: id }))}
                    allowClear
                />
            </div>

            <Spin spinning={loading}>
                {isMobile ? (
                    <div className="grid gap-4">
                        {dataSource.length > 0 ? (
                            dataSource.map((item, index) => (
                                <div key={index} className="border rounded-lg p-4 shadow-md">
                                    <p className='flex justify-between items-center'><strong>Risk:</strong> {item.Investment_Risk}</p>
                                    <p className='flex justify-between items-center'><strong>Symbol:</strong> <img src={item.img} alt={item.Symbol} className='w-8 h-8 object-cover'/></p>
                                    <p className='flex justify-between items-center'><strong>Pair:</strong> {item.Symbol}</p>
                                    <p className='flex justify-between items-center'><strong>Side:</strong> {item.Position_Side}</p>
                                    <p className='flex justify-between items-center'><strong>Avg Price ($):</strong> {item.AvgAmt}</p>
                                    <p className='flex justify-between items-center'><strong>Size:</strong> {item.size}</p>
                                    <p className='flex justify-between items-center'><strong>Quantity:</strong> {item.PositionAmount}</p>
                                    <p className='flex justify-between items-center'><strong>Mark Price ($):</strong> {item.matchingPriceData}</p>
                                    <p className='flex justify-between items-center'><strong>Unrealized PnL (%):</strong> {item.matchingPriceData ? item.pnlp: "Loading..."}</p>
                                    <p className='flex justify-between items-center'><strong>Unrealized PnL ($):</strong> {item.matchingPriceData ? item.PnL: "Loading..."}</p>
                                </div>
                            ))
                        ) : (
                            <div className="text-center p-4"><Empty /></div>
                        )}
                    </div>
                ) : (
                    <div className='container mx-auto'>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            pagination={
                                {
                                    pageSize: 50,
                                    showSizeChanger: false,
                                }
                            }
                        />
                    </div>
                )}
            </Spin>
        </div>
    );
};

export default OpenPosition;
