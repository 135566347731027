import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  message,
} from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import P1 from "../Components/Profile/P1";
import P4 from "../Components/Profile/P4";
import P2 from "../Components/Profile/P2";
import P3 from "../Components/Profile/P3";

const ProfilePage = (props) => {
  const location = useLocation();
  const [profile, setProfile] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const View_Profile = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setProfile(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    View_Profile();
  }, []);

  const onFinish = (values) => {
    const jwt = localStorage.getItem("jwt");

    const postData = {
      ...values,
      jwt: jwt,
      network: "BEP.20",
    };

    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_USER + "update_wallet_address",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        setIsModalOpen(false);
        View_Profile();
      })
      .catch((err) => {
        console.log(err);
        message.error(err?.response?.data?.Error);
      });
  };

  return (
    <>
      {/* // new UI of profile */}
      <div class="bg-gray-50 shadow-lg h-auto container  ">
        <div class="container mx-auto my-5 p-5">
          <div class=" no-wrap md:-mx-2 grid grid-cols-1 xl:flex ">
            <div class="w-full xl:w-3/12 md:mx-2 h-auto">
              <P1 View_Profile={View_Profile} profile={profile} />

              {/* <P3 /> */}
            </div>

            <div class="w-full xl:w-9/12 mx-2 h-auto">
              <P2 />
              {/* <P4 /> */}
            </div>
          </div>
        </div>
      </div>

      {/* // new UI of profile ends */}
      <Modal
        title="Update user Wallet Adderss"
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        <div>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              className=" mt-6"
              label="Wallet address"
              name="address"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Please input your Wallet address!",
                },
              ]}
            >
              <Input placeholder="Enter Wallet address" />
            </Form.Item>

            <div className=" flex justify-center">
              <button className=" active text-sm font-medium py-1 px-4 rounded-xl">
                Submit
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default ProfilePage;
