import React, { useEffect, useState } from "react";
import img2 from "../assets/Fingenius/ADA.png";
import img3 from "../assets/Fingenius/BNB.png";
import img4 from "../assets/Fingenius/BTC.png";
import img5 from "../assets/Fingenius/DOGE.png";
import img6 from "../assets/Fingenius/ETH.png";
import img7 from "../assets/Fingenius/GALA.png";
import img8 from "../assets/Fingenius/MANA.png";
import img9 from "../assets/Fingenius/OCEAN.png";
import img10 from "../assets/Fingenius/SOL.png";
import img11 from "../assets/Fingenius/XRP.png";

const formatPrice = (price) => {
  const parsedPrice = parseFloat(price);
  const decimalPlaces = parsedPrice % 1 === 0 ? 0 : 4;
  return parsedPrice.toFixed(decimalPlaces);
};

function KuCoinWebSocket() {
  const [tableData, setTableData] = useState([]);
  const [retryCount, setRetryCount] = useState(0);


  const [token, setToken] = useState();
  const [endPoint, setEndPoint] = useState();

  async function getWsConnectionDetails() {
    try {
      const response = await fetch('https://api.kucoin.com/api/v1/bullet-public', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      console.log(response);

      const result = await response.json();
      const { token, instanceServers } = result.data;
      const endpoint = instanceServers[0].endpoint;
      setToken(token);
      setEndPoint(endpoint);

      return { token, endpoint };
    } catch (error) {
      console.log('Error fetching KuCoin WebSocket connection details:', error);
    }
  }

  useEffect(() => {
    getWsConnectionDetails();
  }, []);










  const exchangeImageMapping1 = {
    ADA: { image: img2, fullname: "(Cardano)" },
    BNB: { image: img3, fullname: "(Binance Coin)" },
    BTC: { image: img4, fullname: "(Bitcoin)" },
    DOGE: { image: img5, fullname: "(Dogecoin)" },
    ETH: { image: img6, fullname: "(Ethereum)" },
    GALA: { image: img7, fullname: "(Gala)" },
    MANA: { image: img8, fullname: "(Decentraland)" },
    OCEAN: { image: img9, fullname: "(Ocean Protocol)" },
    SOL: { image: img10, fullname: "(Solana)" },
    XRP: { image: img11, fullname: "(XRP)" },
  };

  useEffect(() => {
    const connectWebSockets = () => {
      const symbols = [
        "btcusdt",
        "bnbusdt",
        "manausdt",
        "oceanusdt",
        "solusdt",
        "dogeusdt",
        "ethusdt",
        "adausdt",
        "xrpusdt",
        "galausdt",
        "usdtusdt"
      ];
      const symbolStreamsTrades = symbols
        .map((symbol) => `${symbol}@trade`)
        .join("/");
      const symbolStreamsTickers = symbols
        .map((symbol) => `${symbol}@ticker`)
        .join("/");

      const socketEndpointTrades = `wss://stream.binance.com:9443/stream?streams=${symbolStreamsTrades}`;
      const socketEndpointTickers = `wss://stream.binance.com:9443/stream?streams=${symbolStreamsTickers}`;

      

      const socketTrades = new WebSocket(socketEndpointTrades);
      const socketTickers = new WebSocket(socketEndpointTickers);

      socketTrades.onopen = () => {
        console.log("WebSocket connection for trades established.");
      };

      socketTickers.onopen = () => {
        console.log("WebSocket connection for tickers established.");
      };

      socketTrades.onerror = (error) => {
        console.error("WebSocket error for trades:", error);
        reconnectWebSockets();
      };

      socketTickers.onerror = (error) => {
        console.error("WebSocket error for tickers:", error);
        reconnectWebSockets();
      };

      socketTrades.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          const tickers = data.data;
          setTableData((prevData) => {
            const coinName = tickers.s.replace("USDT", "");
            const existingCoin = prevData.find((coin) => coin.name === coinName);
            if (existingCoin) {
              return prevData.map((coin) =>
                coin.name === coinName
                  ? {
                      ...coin,
                      price: tickers.p,
                    }
                  : coin
              );
            } else {
              return [
                ...prevData,
                {
                  name: coinName,
                  price: tickers.p,
                },
              ];
            }
          });
        } catch (error) {
          console.error("Error parsing trades message:", error);
        }
      };

      socketTickers.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          const tickers = data.data;
          setTableData((prevData) => {
            const coinName = tickers.s.replace("USDT", "");
            const existingCoin = prevData.find((coin) => coin.name === coinName);
            if (existingCoin) {
              return prevData.map((coin) =>
                coin.name === coinName
                  ? {
                      ...coin,
                      newprice: tickers.P,
                    }
                  : coin
              );
            } else {
              const coinFullName =
                exchangeImageMapping1[coinName]?.fullname || coinName;
              return [
                ...prevData,
                {
                  fullname: coinFullName,
                  newprice: tickers.P,
                },
              ];
            }
          });
        } catch (error) {
          console.error("Error parsing tickers message:", error);
        }
      };

      socketTrades.onclose = () => {
        console.warn("WebSocket connection for trades closed. Reconnecting...");
        reconnectWebSockets();
      };

      socketTickers.onclose = () => {
        console.warn("WebSocket connection for tickers closed. Reconnecting...");
        reconnectWebSockets();
      };

      const reconnectWebSockets = () => {
        if (retryCount < 5) {
          setRetryCount((prevCount) => prevCount + 1);
          setTimeout(connectWebSockets, 2000); // Retry after 2 seconds
        } else {
          console.error("Max retries reached. Unable to connect to WebSocket.");
        }
      };
    };

    connectWebSockets();

    return () => {
      setRetryCount(0); // Reset retry count on component unmount
    };
  }, [retryCount]);

  const filteredTableData = tableData.filter(
    (coin) => exchangeImageMapping1[coin.name]
  );

  const sortedTableData = filteredTableData?.sort((a, b) => b.price - a.price);


//   const sortedTableData = filteredTableData?.sort((a, b) => b.price - a.price);

  return sortedTableData;
}

export default KuCoinWebSocket;


