import React, { useEffect } from 'react';

const OverviewMarket = ({ defaultColumn }) => {
  useEffect(() => {
    const containerId = `tradingview-widget-container-${defaultColumn}`;
    const scriptId = `tradingview-widget-script-${defaultColumn}`;

    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      "width": "100%",
      "height": "850",
      "defaultColumn": defaultColumn,
      "screener_type": "crypto_mkt",
      "displayCurrency": "USD",
      "colorTheme": "light",
      "locale": "en",
      "isTransparent": true
    });

    const container = document.getElementById(containerId);
    container.innerHTML = ''; 
    container.appendChild(script);
  }, [defaultColumn]);

  return (
    <div
      className="tradingview-widget-container my-4 p-4 bg-white shadow-md rounded-lg"
      id={`tradingview-widget-container-${defaultColumn}`}
    >
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default OverviewMarket;
