import React, { useState, useEffect, useRef } from 'react'
import { Table, Select, Spin, message } from 'antd';
import axios from 'axios';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { TfiReload } from "react-icons/tfi";
import { text } from 'd3';
import { HandleSession } from './HandleSession';

const OrderHistorySpot = (props) => {
  const { username, option, newOption } = props?.data

  const [getApi, setGetApi] = useState();
  const [options, setOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState()
  const [historyData, setHistoryData] = useState([])
  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [option2, setOption2] = useState(option)
  const [Api, setApi] = useState()

  useEffect(() => {
    setOption2(option)
    const fisrtLabel = option[0]?.label
    setApi(fisrtLabel)
  }, [option])

  const symbol = ["ADA", "BNB", "BTC", "DOGE", "ETH", "GALA", "MANA", "OCEAN", "SOL", "XRP"]
  const [selectedSymbols, setSelectedSymbols] = useState(symbol);


  const dataSource = [];

  if (historyData) {
    for (let i = 0; i < historyData.length; i++) {
      dataSource.push({
        symbol: <p className=' text-center'>{historyData[i]?.symbol}</p>,
        price: <p className=' text-center'>{historyData[i]?.price}</p>,
        origQty: <p className=' text-center'>{historyData[i]?.origQty}</p>,
        executedQty: <p className=' text-center'>{historyData[i]?.executedQty}</p>,
        status: <p className=' text-center'>{historyData[i]?.status}</p>,
        type: <p className=' text-center'>{historyData[i]?.type}</p>,
        side: <p className=' text-center'>{historyData[i]?.side}</p>,
        stopPrice: <p className=' text-center'>{historyData[i]?.stopPrice}</p>,
        updateTime: <p className=' text-center'>{historyData[i]?.updateTime}</p>,
      })
    }
  }



  const columns = [
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>UpdateTime</p>,
      dataIndex: 'updateTime',
      key: 'updateTime',
      textAlign: 'center',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Symbol</p>,
      dataIndex: 'symbol',
      key: 'symbol',
      width: 200
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Price</p>,
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>OrigQty</p>,
      dataIndex: 'origQty',
      key: 'origQty',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>ExecutedQty</p>,
      dataIndex: 'executedQty',
      key: 'executedQty',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Status</p>,
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Type</p>,
      dataIndex: 'type',
      key: 'type',
    },
  ]


  const handleChange = (value) => {
    setApi(value)
  }

  const orderHistory = () => {
    setHistoryData()
    setLoading(true)
    const token = localStorage.getItem('jwt')
    const postData = {
      jwt: token,
      username: username,
      api_name: newOption,
      symbol: ["ADA", "BNB", "BTC", "DOGE", "ETH", "GALA", "MANA", "OCEAN", "SOL", "XRP"]
    }


    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "order_history_spot",
      data: postData
    }).then(res => {
      const resposneData = res.data.Success

      const formattedData = resposneData.map(item => ({
        ...item,
        updateTime: new Date(item?.updateTime).toLocaleDateString('en-GB') + ' ' + new Date(1701540808180).toLocaleTimeString()
      }))
      setLoading(false)
      setHistoryData(formattedData)
    }).catch(err => {
      setLoading(false)
      HandleSession(err)
      // message.error(err?.response?.data?.Error)
    })
  }

  useEffect(() => {
    if (newOption) {
      orderHistory()
    }
  }, [newOption, selectedSymbols])

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSymbolFilter = (symbol) => {
    const updatedSelectedSymbols = [...selectedSymbols];

    if (updatedSelectedSymbols.includes(symbol)) {
      updatedSelectedSymbols.splice(updatedSelectedSymbols.indexOf(symbol), 1);
    } else {
      updatedSelectedSymbols.push(symbol);
    }
    setSelectedSymbols(updatedSelectedSymbols);
  };

  return (
    <div className=' min-h-[400px]'>
      <div className=' flex gap-4 mb-4'>

        {/* <div>
          <span className=' text-base font-semibold'>API Name : </span>
          <Select
            value={Api}
            className='focus:border-colorAccent hover:border-colorAccent'
            style={{
              width: 180,
            }}
            placeholder="Select API Name"
            onChange={handleChange}
            options={option2}
          />
        </div> */}

        {/* <Select placeholder="Select Symbol" className=' w-[200px]'>
          {symbolOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              <Checkbox onChange={() => checkSymbol(option.label)}>{option.label}</Checkbox>
            </Option>
          ))}
        </Select> */}

        {/* <div ref={dropdownRef} className="flex flex-col items-center  lg:space-x-4 ">
          <div className="">
            <div className=' flex gap-2 items-center'>
              <span className='text-base font-semibold'>Symbols :</span>
              <div className="relative">
                <button
                  id="selectedOption"
                  onClick={() => setShowOptions(!showOptions)}
                  className="text-left flex justify-between items-center px-3 bg-white text-[#d0d0d0] border-[#d9d9d9] border w-[200px] text-sm   py-[5px] rounded-md"
                >
                  Select Symbols <span className=' text-xl'>{showOptions ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</span>
                </button>

                {showOptions && (
                  <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                    {symbol?.map((option, index) => (
                      <label key={index} className="block px-2 py-1 text-sm">
                        <input
                          type="checkbox"
                          onChange={() => handleSymbolFilter(option)}
                          checked={selectedSymbols.includes(option)}
                          className="mr-2"
                        />
                        {option}
                      </label>
                    ))}
                    <button onClick={orderHistory} className=' block mx-auto menu-item font-semibold rounded-2xl mb-4 bg-colorAccent py-2 top-left-shadow w-[50%]'>submit</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className='my-3 flex flex-col md:flex-row md:items-center gap-2 px-2'>
        <p>Data may be displayed with a delay of 1-2 minutes, please click Refresh to update the data </p>
        <button className=' flex items-center gap-2 text-colorSecondaryDark font-medium' onClick={orderHistory}><TfiReload className='  ' /> Refresh</button>
      </div>

      <div>
        <Spin spinning={loading} >
          <Table columns={columns} dataSource={dataSource}
          className=' overflow-x-scroll'
          size='small'
          bordered
          />
        </Spin>
      </div>
    </div>
  )
}

export default OrderHistorySpot
