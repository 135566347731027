import { Select, Spin, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import binanceLogo from "../assets/coins/BINANCESPOT.png";
import bybitLogo from "../assets/coins/BYBITSPOT.png";
import { FaLock } from 'react-icons/fa';
import UpdateApi from './UpdateApi';

const LiquidationData = () => {

    const [websocketData, setWebsocketData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataReceived, setDataReceived] = useState(false);
    const [selectedExchange, setSelectedExchange] = useState('Binance');
    const { checkBotFeature, checkTradeFeature } = UpdateApi();
    const checkTradePackage = checkTradeFeature.Liquidation_Heatmap;

    // useEffect(() => {
    //     const newWs = new WebSocket("wss://fstream.binance.com:443/ws/!forceOrder@arr");

    //     newWs.onopen = () => {
    //         console.log('WebSocket connection established.');
    //     };

    //     newWs.onmessage = (event) => {
    //         const correctedDataString = event.data.replace(/'/g, '"');
    //         const data = JSON.parse(correctedDataString);
    //         // Concatenate new data with existing websocketData array
    //         setWebsocketData(prevData => [...prevData, data.o]);
    //     };

    //     newWs.onerror = (error) => {
    //         console.error('WebSocket error:', error);
    //     };

    //     newWs.onclose = () => {
    //         console.log('WebSocket connection closed.');
    //     };

    //     // Clean up the WebSocket connection when component unmounts
    //     return () => {
    //         if (newWs) {
    //             newWs.close();
    //         }
    //     };
    // }, []);

    useEffect(() => {
        if (checkTradePackage === true) {
            const newWs = new WebSocket("wss://fstream.binance.com:443/ws/!forceOrder@arr");
            setLoading(true);
            newWs.onopen = () => {
                console.log('WebSocket connection established.');
                setLoading(false);
            };

            newWs.onmessage = (event) => {
                const correctedDataString = event.data.replace(/'/g, '"');
                const data = JSON.parse(correctedDataString);
                setWebsocketData(prevData => [...prevData, data.o]);
                setDataReceived(true);
            };

            newWs.onerror = (error) => {
                console.error('WebSocket error:', error);
                setLoading(false);
            };

            newWs.onclose = () => {
                console.log('WebSocket connection closed.');
                setLoading(false);
            };

            return () => {
                if (newWs) {
                    newWs.close();
                }
            };
        }
    }, [checkTradePackage]);

    const handleChange = (value) => {
        setSelectedExchange(value);
    };

    const dataSource = [];
    if (websocketData) {
        const startIndex = Math.max(0, websocketData.length - 20);
        for (let i = startIndex; i < websocketData.length; i++) {
            const data = websocketData[i];
            const date = new Date(data.T);

            // Formatting the date and time
            const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear().toString().slice(-2)}`;
            const formattedTime = date.toLocaleTimeString(); // This will give the local time in a readable format

            dataSource.push({
                key: i + 1,
                Binance: "Binance",
                s: data.s,
                S: <div className={data.S === 'BUY' ? 'text-[green]' : 'text-[red]'}>{data.S}</div>,
                o: data.o,
                z: data.z,
                l: data.l,
                q: data.q,
                T: `${formattedDate} ${formattedTime}`, // Combine formatted date and time
                p: <div className={data.S === 'BUY' ? 'text-[green]' : 'text-[red]'}>{'$' + data.p}</div>,
                ap: data.ap,
                X: data.X,
            });
        }
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'T',
            key: 'T',
        },
        {
            title: 'Exchange',
            dataIndex: 'Binance',
            key: 'Binance',
        },
        {
            title: 'Symbol',
            dataIndex: 's',
            key: 's',
        },
        {
            title: 'Side',
            dataIndex: 'S',
            key: 'S',
        },

        {
            title: 'Limit',
            dataIndex: 'o',
            key: 'o',
        },
        {
            title: 'Quantity',
            dataIndex: 'q',
            key: 'q',
        },
        {
            title: 'Price',
            dataIndex: 'p',
            key: 'p',
        },
        {
            title: 'Side',
            dataIndex: 'S',
            key: 'S',
        },
        {
            title: <Tooltip title="Order Last Filled Quantity">OLFQ</Tooltip>,
            dataIndex: 'l',
            key: 'l',
        },
        {
            title: <Tooltip title="Order Filled Accumulated Quantity : The total quantity of a security that has been executed and filled over a specific period, typically during a trading in defined time frame."> OFAQ</Tooltip>,
            dataIndex: 'z',
            key: 'z',
        },
        {
            title: 'Average Price',
            dataIndex: 'ap',
            key: 'ap',
        },
        {
            title: 'Status',
            dataIndex: 'X',
            key: 'X',
        },
    ];

    return (
        <>
         <div className="bg-colorPrimary mx-2 rounded-3xl">
            <div className='relative'>
                {checkTradePackage === false ?
                    <div className="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
                        style={{
                            backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/liquidation.webp)',
                            backgroundSize: 'cover',
                            height: "100vh"
                        }}
                    >
                        <FaLock className="text-gray-600 text-6xl mb-4" />
                        <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
                        <p className="mb-4">To access this feature, please upgrade your Package.
                        </p>
                    </div>
                    :
                    <div>
                        <div className='my-4 mx-2 md:mx-10 flex justify-center items-center md:justify-start gap-2'>
                            <p className='text-sm md:text-base font-semibold'>Select Exchange : </p>
                            <Select
                                defaultValue="Binance"
                                style={{ width: 150 }}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'Binance',
                                        label: <div className='flex items-center gap-2'><img src={binanceLogo} alt='' className='w-4 h-4 ' /> Binance</div>,
                                    },
                                    {
                                        value: 'Bybit',
                                        label: <Tooltip title="Coming Soon.."><div className='flex items-center gap-2 cursor-not-allowed'><img src={bybitLogo} alt='' className='w-4 h-4 ' /> BYBIT</div></Tooltip>,
                                    },
                                ]}
                            />
                        </div>
                        <div className="text-center">
                            {dataReceived === false ?
                                <p className='blink_me text-lime-700'>Fetching Live Data Please Wait!</p>
                                :
                                ""
                            }
                        </div>
                        <div className='pt-0 md: py-10 container'>
                            <Spin spinning={loading} tip="Loading...">
                                <Table
                                    dataSource={dataSource.reverse()}
                                    columns={columns}
                                    pagination={{ pageSize: 20, hideOnSinglePage: true }}
                                />
                            </Spin>
                        </div>
                        {/* <ChartComponent /> */}
                    </div>
                }


            </div>
            </div>

        </>
    )
}

export default LiquidationData