// src/components/DexPrice.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Spin, Table } from 'antd';

const DexPrice = ({refresh}) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        const url = 'https://deep-index.moralis.io/api/v2.2/market-data/erc20s/top-tokens';
        const headers = {
            'Accepts': 'application/json',
            'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MjI2MTZmLWM4MzItNDJmZS04YzI1LTA4NGZiNTAyY2ZmOCIsIm9yZ0lkIjoiMzcwMjAxIiwidXNlcklkIjoiMzgwNDY5IiwidHlwZUlkIjoiYTM5MmYyMTItZGQ1OC00NjY2LWEyMGMtN2E2MDdlZGU3YmZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDM5MzEwMjMsImV4cCI6NDg1OTY5MTAyM30.1ik9oveoExEKuAyXdSIWTKWo3i22Yp59_37ZDIJl8ew',
        };

        axios.get(url, { headers })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
      };
    
      useEffect(() => {
        fetchData();
      }, [refresh]); 

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // if (!data) {
    //     return <div>Loading...</div>;
    // }

    return (
        <>
            <div className='mx-5'>
                {loading ? (
                    <Spin size="large" style={{ display: 'block', margin: '100px auto' }} />
                ) : (
                    <Table
                        dataSource={data}
                        pagination={false}
                        rowKey="token_symbol"
                    >
                       <Table.Column
                        title="Token"
                        key="token"
                        render={(_, record) => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={record.token_logo} alt="Token Logo" style={{ width: '30px', borderRadius: '50%', marginRight: '10px' }} />
                                <span>{record.token_symbol}</span>
                            </div>
                        )}
                    />
                        <Table.Column title="Token Name" dataIndex="token_name" key="token_name" />

                        <Table.Column
                            title="Price USD"
                            dataIndex="price_usd"
                            key="price_usd"
                            render={change => {
                                const fixedChange = parseFloat(change).toFixed(4);
                                return <span style={{ color: parseFloat(change) >= 0 ? 'green' : 'red' }}>$ {fixedChange} </span>;
                            }}
                        />
                        <Table.Column
                            title="24h Change (%)"
                            dataIndex="price_24h_percent_change"
                            key="price_24h_percent_change"
                            render={change => {
                                const fixedChange = parseFloat(change).toFixed(4);
                                return <span style={{ color: parseFloat(change) >= 0 ? 'green' : 'red' }}>{fixedChange} %</span>;
                            }}
                        />
                        <Table.Column
                            title="7d Change (%)"
                            dataIndex="price_7d_percent_change"
                            key="price_7d_percent_change"
                            render={change => {
                                const fixedChange = parseFloat(change).toFixed(4);
                                return <span style={{ color: parseFloat(change) >= 0 ? 'green' : 'red' }}>{fixedChange} %</span>;
                            }}
                        />

                        <Table.Column
                            title="Market Cap USD"
                            dataIndex="market_cap_usd"
                            key="market_cap_usd"
                            render={marketCap => {
                                const formattedMarketCap = new Intl.NumberFormat().format(marketCap);
                                return <span>$ {formattedMarketCap}</span>;
                            }}
                        />
                    </Table>
                )}
            </div>
        </>
    );
};

export default DexPrice;
