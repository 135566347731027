import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { HandleSession } from "./HandleSession";
import { useLocation } from "react-router-dom";

function Apisame({ pageName, pageSize, page,wallet_address }) {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const jwt = localStorage.getItem("jwt");
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postdata = {
          jwt: jwt,
          page_name: pageName,
          page_size: pageSize,
          page_no: page,
          // wallet_address: location.pathname === '/dashboard/dex' ? wallet_address : wallet_address
        };

        // if (location.pathname === '/dashboard/dex') {
        //   postdata.wallet_address = wallet_address;
        // }

        const response = await axios.post(
          process.env.REACT_APP_API_PATH_USER_ODECENT + "total_pages",
          // "http://192.168.0.223:8000/wallet_transaction/total_pages",
          postdata
        );
        setTotalPages(response?.data?.Success);
        let responseData;
        if (pageName === "Wallet_Transfer") {
          const cashFlowResponse = await axios.post(
            process.env.REACT_APP_API_Wallet + "wallet_transfer",
            postdata
          );
          responseData = cashFlowResponse?.data?.Success;
        } else if (pageName === "Network_Transfer") {
          const networkResponse = await axios.post(
            process.env.REACT_APP_API_Wallet + "network_transfer",
            postdata
          );
          responseData = networkResponse?.data?.Success;
        } else if (pageName === "Subscription_Transaction") {
          const with11Response = await axios.post(
            process.env.REACT_APP_API_SUB + "subscription_transaction",
            postdata
          );
          responseData = with11Response?.data?.Success;
        } else if (pageName === "Subscription_Qualification") {
          const statusResponse = await axios.post(
            process.env.REACT_APP_API_SUB + "subscription_status",
            postdata
          );
          responseData = statusResponse?.data?.Success;
        } else if (pageName === "Withdrawal_Request") {
          const withdrawalResponse = await axios.post(
            process.env.REACT_APP_API_Wallet + "withdrawal_transactions",
            postdata
          );
          responseData = withdrawalResponse?.data?.Success;
        } else if (pageName === "Deposit_Transaction") {
          const depositResponse = await axios.post(
            process.env.REACT_APP_API_deposit + "deposit_transactions",
            postdata
          );
          responseData = depositResponse?.data?.Success;
        } else if (pageName === "Rank_Reward_Transaction") {
          const rankResponse = await axios.post(
            process.env.REACT_APP_API_PATH_NEW + "/rank/rank_earning_transactions",
            postdata
          );
          responseData = rankResponse?.data?.Success;
        } else if (pageName === "Rank_Qualification") {
          const rankqualResponse = await axios.post(
            process.env.REACT_APP_API_PATH_NEW + "/rank/rank_qualification",
            postdata
          );
          responseData = rankqualResponse?.data?.Success;
        } else if (pageName === "Business_Volume_Transaction") {
          const rankqualResponse = await axios.post(
            process.env.REACT_APP_API_PATH_NEW + "/binary/business_volume_transaction",
            postdata
          );
          responseData = rankqualResponse?.data?.Success;
        } else if (pageName === "Commission_Transaction") {
          const levelResponse = await axios.post(
            process.env.REACT_APP_API_PATH_NEW + "/binary/commission_transactions",
            postdata
          );
          responseData = levelResponse?.data?.Success;
        } else if (pageName === "Binary_Transaction") {
          const levelqualResponse = await axios.post(
            process.env.REACT_APP_API_PATH_NEW + "/binary/binary_transactions",
            postdata
          );
          responseData = levelqualResponse?.data?.Success;
        } else if (pageName === "Matrix_Earning_Transaction") {
          const matrixResponse = await axios.post(
            process.env.REACT_APP_API_Matrix + "matrix_earning_transactions",
            postdata
          );
          responseData = matrixResponse?.data?.Success;
        } else if (pageName === "Matrix_Qualification") {
          const martixQualData = await axios.post(
            process.env.REACT_APP_API_Matrix + "matrix_qualification",
            postdata
          );
          responseData = martixQualData?.data?.Success;
        } else if (pageName === "Matching_Income_Transaction") {
          const matchingResponse = await axios.post(
            process.env.REACT_APP_API_Matching + "matching_income_transactions",
            postdata
          );
          responseData = matchingResponse?.data?.Success;
        } else if (pageName === "Matching_Income_Qualification") {
          const matchingqualResponse = await axios.post(
            process.env.REACT_APP_API_Matching +
              "matching_income_qualification",
            postdata
          );
          responseData = matchingqualResponse?.data?.Success;
        }
        // else if (pageName === "Wallet_address_transactions") {
        //   const WalletAddressTrans = await axios.post(
        //     process.env.REACT_APP_API_Matching +
        //       "Wallet_address_transactions",
        //     // "http://192.168.0.223:8000/wallet_transaction/get_transaction_details",
        //     postdata
        //   );
        //   responseData = WalletAddressTrans?.data?.Data;
        // }
        // else if (pageName === "Wallet_address_tokens") {
        //   const WalletAddressTokens = await axios.post(
        //     process.env.REACT_APP_API_Matching +
        //       "Wallet_address_tokens",
        //     // "http://192.168.0.223:8000/wallet_transaction/get_token_details",
        //     postdata
        //   );
        //   responseData = WalletAddressTokens?.data?.Data;
        // }
        // if (responseData === "No transactions yet!") {
        //   toast.info("No transactions yet!");
        // } else {
          setData(responseData);
        // }
      } catch (error) {
        toast.error(error.response.data.Error);
        HandleSession(error);
      }
    };

    fetchData();
  }, [pageName, pageSize, page,wallet_address, jwt]);

  return { data, totalPages,setData };
}

export default Apisame;

