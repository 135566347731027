import React, { useEffect, useState } from "react";
import { Empty, Table, Tooltip, message } from "antd";
import { FaRegCopy, FaRegEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { RiShareForward2Fill } from "react-icons/ri";
import CustomPagination from "./Pagination";
import { toast } from "react-toastify";



const PaymentTran = (props) => {
  const { subData, pageSize, page, totalPages, setPage, setpageSize } =
    props?.data;



  const navigate = useNavigate();

  const handlesubmit = (record) => {
    window.open(subData.URL, '_blank');
    console.log(subData.URL, "jjhj");
    if (subData && subData.URL) {
      window.open(subData.URL, '_blank');
      console.log(subData.URL, "jjhj");
    } else {
      console.log("URL is undefined or null");
    }
  };


  const columns = [
    {
      title: "DATETIME",
      dataIndex: "Datetime",
      key: "Datetime",
      render: (text) => (
        <span>
          {/* {text ? new Date(text).toLocaleString() : "-"} */}
          {text ? text : "--"}
        </span>
      )
    },
    {
      title: "TRANSID",
      dataIndex: "TransID",
      key: "TransID",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },
    {
      title: "GATEWAY",
      dataIndex: "Gateway",
      key: "Gateway",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },
    {
      title: "WALLET ADDRESS",
      dataIndex: "Receiver_Wallet_Address",
      key: "Receiver_Wallet_Address",

      // render: (text) => (
      //   <span>
      //     {text && `${text.slice(0, 4)}...${text.slice(-4)}`}
      //     <button onClick={() => copyToClipboard(text)}>
      //       <FaRegCopy />
      //     </button>
      //   </span>
      // ),
      render: (text) => (
        <Tooltip title={text}>
          <span>
            {text ? `${text.slice(0, 4)}...${text.slice(-4)}` : "--"}
            {text && (
              <button onClick={() => copyToClipboard(text)}>
                <FaRegCopy />
              </button>
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "ASSET",
      dataIndex: "Coin",
      key: "Coin",
      render: (text) => (
        <span>
          {text ? text : "--"}
        </span>
      )
    },
    {
      title: "AMOUNT",
      dataIndex: "USD",
      key: "USD",
      render: (text) => {
        const amount = parseFloat(text);
        return isNaN(amount) ? '--' : `$ ${amount.toFixed(0)}`;
      },
    },

    {
      title: "PRICE",
      dataIndex: "Price",
      key: "Price",
      render: (text) => {
        const cleanedPrice = parseFloat(text.replace(/[^0-9.]/g, ''));
        return (
          <span>
            $ {isNaN(cleanedPrice) ? '--' : cleanedPrice.toFixed(2)}
          </span>
        );
      },
    },
    {
      title: "QTY ASSETS",
      dataIndex: "Amount",
      key: "Amount",
      render: (text) => (
        <span>
          {text ? Number(text).toFixed(2) : '--'}
        </span>
      ),
    },
    {
      title: "PAID ASSETS",
      dataIndex: "Amount_Recieved",
      key: "Amount_Recieved",
      render: (text) => (
        <span>
          {text ? Number(text).toFixed(2) : '--'}
        </span>
      ),
    },
    {
      title: "PAID ASSETS($)",
      dataIndex: "USD_Recieved",
      key: "USD_Recieved",
      render: (text) => (
        <span>
          {text ? Number(text).toFixed(2) : '--'}
        </span>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "Status",
      render: (text, record) => (
        <div className="flex justify-between items-center">
          <span className={text === "SUCCESS" ? "text-[green]" : "text-[red]"}>




            <span>
              {text}


              {record && record.Receiver_Wallet_Address && (
                <Tooltip title="View Status">

                  <Link to={record.Status_URL} target="_blank">
                    <button  >
                      <RiShareForward2Fill className="ml-1 text-black " />
                    </button>
                  </Link>
                </Tooltip>
              )}
            </span>

          </span>
        </div>
      ),
    },
  ];

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDateTime = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    }).format(date);
  };

  const adjustDatetime = (itemDatetime, currentDateTime) => {
    const itemDate = new Date(itemDatetime);
    const currentDate = new Date(currentDateTime);

    const timeDifference = currentDate.getTime() - itemDate.getTime(); // Difference in milliseconds

    // Adjust itemDate by adding timeDifference
    const adjustedDate = new Date(itemDate.getTime() + timeDifference);

    return adjustedDate;
  };


  const rows = Array.isArray(subData)
    ? subData.map((item, index) => ({
      key: index,
      Datetime: item?.Datetime,
      Status: item?.Status,
      TransID: item?.TransID,
      Gateway: item.Gateway ? item.Gateway : "By Admin",
      URL: item?.URL,
      IP: item?.IP,
      Status_URL: item?.Status_URL,
      Receiver_Wallet_Address: item?.Receiver_Wallet_Address,
      Coin: item?.Coin?.replace(/_/g, " "),
      // Amount: "$" + parseFloat(item?.Amount?.USDT) || 0,
      Price: "$ " + item?.Price,
      Amount_Recieved: item?.Amount_Recieved,
      USD_Recieved: item?.Gateway === "COINPAYMENTS" ? item?.Price * item?.Amount_Recieved : item?.USD_Recieved,
      USD: (item?.USD),
      Amount: item?.Amount,
    }))
    : [];








  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.setAttribute("readonly", "");
    textArea.style.position = "absolute";
    textArea.style.left = "-9999px";

    document.body.appendChild(textArea);
    textArea.select();

    try {
      const success = document.execCommand("copy");
      if (success) {
        toast("Copied");
      } else {
        message.error("Copy failed. Please try again.");
      }
    } catch (err) {
      message.error("Copy failed. Please try again.");
    }

    document.body.removeChild(textArea);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };


  const handlePageSizeChange = (newPageSize) => {
    setpageSize(newPageSize);
  };


  const isMobile = useMediaQuery({ maxWidth: 1050 });

  if (isMobile) {
    return (
      <>
        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />

        {rows.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full ">
            {rows.map((record) => (
              <div
                key={record.TransID}
                className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
              >
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Date:</div>
                  <div className="card-data">{record.Datetime}</div>


                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">TransID:</div>
                  <div className="card-data">
                    {record.TransID}
                  </div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">GATEWAY:</div>
                  <div className="card-data">
                    {record.Gateway}
                  </div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm capitalize">
                  <div className="card-title font-semibold capitalize">Wallet Address:</div>
                  <div className="card-data">
                    {`${record.Receiver_Wallet_Address?.slice(
                      0,
                      4
                    )}...${record.Receiver_Wallet_Address?.slice(-4)}`}{" "}
                    <button
                      onClick={() =>
                        copyToClipboard(record.Receiver_Wallet_Address)
                      }
                    >
                      <FaRegCopy className="ml-4" />
                    </button>
                  </div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Asset:</div>
                  <div className="card-data">{record.Coin}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Amount:</div>
                  <div className="card-data">$ {record.USD}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Price:</div>
                  <div className="card-data"> {record.Price}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">QTY ASSETS:</div>
                  <div className="card-data">$ {Number(record.Amount) ? Number(record.Amount.toFixed(4)) : "--"}</div>
                </div>



                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">PAID ASSETS:</div>
                  <div className="card-data"> {record.Amount_Recieved ? record.Amount_Recieved.toFixed(4) : "--"}</div>
                </div>


                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">PAID ASSETS($):</div>
                  <div className="card-data">
                    {
                      Number(record.USD_Recieved).toFixed(4)
                    }
                  </div>
                </div>




                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold ">Status:</div>
                  <div className="card-data  ">
                    {record.Status === "SUCCESS" ? (
                      <span className="text-[green]">{record.Status}</span>
                    ) : (
                      <span className="text-[red] flex justify-center items-center">
                        {record.Status}
                        {record.Status !== "SUCCESS" && (
                          <>
                            {record.Receiver_Wallet_Address && (
                              <Tooltip title="View Status">
                                <Link to={record.URL} target="_blank">
                                  <button >
                                    <RiShareForward2Fill
                                      size={25}
                                      className="ml-1 text-black rounded-full bg-colorPrimary  p-1"
                                    />
                                  </button>
                                </Link>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
            <Empty />
          </div>
        )}


        <CustomPagination
          totalPages={totalPages}
          currentPage={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </>
    );
  }



  // ... (remaining code)

  return (
    <div className=" mx-auto w-full">

      <CustomPagination
        totalPages={totalPages}
        currentPage={page}
        pageSize
        onPageChange={onPageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      {/* // */}


      <Table
        columns={columns}
        className=" my-2"
        pagination={false}
        dataSource={rows}
      />
      <CustomPagination
        totalPages={totalPages}
        currentPage={page}
        pageSize
        onPageChange={onPageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};
export default PaymentTran;
