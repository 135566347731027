import { Select, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import Apitable from './Apitable';
import OpenPosition from './OpenPosition';
import OrderHistoryFuture from './OrderHistoryFuture';

import Apioverview from './Apivoverview';



const MainFuture = (props) => {
  const { username, option } = props?.data
  const [option2, setOption2] = useState(option)
  const [Api, setApi] = useState()

  useEffect(() => {
    setOption2(option)
    const fisrtLabel = option[0]?.value
    setApi(fisrtLabel)
  }, [option])

  const handleChange = (value) => {
    setApi(value)
  }

  const futureItems = [
    {
      key: '1',
      label: <div className='px-4 md:px-10 text-xs md:text-sm'>BALANCE</div>,
      children: <Apitable data={{ username: username, option: option, newOption: Api }} />,
    },
    // {
    //     key: '2',
    //     label: 'OPEN ORDER',
    //     children: <OpenorderFuture data={{ username: username, option: option , newOption : Api }} />,
    // },
    {
      key: '2',
      label: <div className='px-4 md:px-10 text-xs md:text-sm'>OPEN POSITIONS</div>,
      children: <OpenPosition data={{ username: username, option: option, newOption: Api }} />,
    },
    // {
    //     key: '3',
    //     label: 'CLOSE POSITION',
    //     children: <ClosePosition data={{ username: username, option: option , newOption : Api }} />,
    // },
    {
      key: '3',
      label: <div className='px-4 md:px-10 text-xs md:text-sm'>API OVERVIEW</div>,
      children: <Apioverview data={{ username: username, option: option, newOption: Api }} />,
    },
    {
      key: '4',
      label: <div className='px-4 md:px-10 text-xs md:text-sm'>ORDER HISTORY</div>,
      children: <OrderHistoryFuture data={{ username: username, option: option, newOption: Api }} />
      ,
    },
  ];

  return (
    <div>
      <div className=' px-5 pb-4'>
        <span className=' text-base font-semibold'>API Name : </span>
        <Select
          value={Api}
          className='focus:border-colorAccent hover:border-colorAccent'
          style={{
            width: 180,
          }}
          placeholder="Select API Name"
          onChange={handleChange}
          options={option2}
        />
      </div>
      <div className=' xl:hidden overflow-x-hidden'>
        <Tabs className='min-h-[400px] tabakhil px-1' defaultActiveKey="1" items={futureItems}  />
      </div>
      <div className=' hidden xl:block '>
        <Tabs className='min-h-[400px] ' defaultActiveKey="1" items={futureItems} tabPosition={'left'} />
      </div>
    </div>
  )
}

export default MainFuture