import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import ReportView from "../Components/ReportView";

function Reportcopy() {
  const [filter, setFilter] = useState("FUTURES");
  const [filter1, setFilter1] = useState("hightolow");
  const [filter2, setFilter2] = useState("All");
  const [filterData, setfilterData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [typedata, setTypeData] = useState([]);
  const [exchange, setexchange] = useState([]);
  const [botname, setbotname] = useState([]);
  const [filterbotData, setfilterBotData] = useState([]);
  const [symbol, setsymbol] = useState([]);
  // const { handleGridClick, handleListClick,handleTradesClick } = props.data;

  const sortedFilterBotHighToLow = () => {
    setFilter1("hightolow");
  };

  const sortedFilterBotLowToHigh = () => {
    setFilter1("lowtohigh");
  };

  const [activeButton, setActiveButton] = useState("FUTURES");
  const handleFilterClick = (exchangeType) => {
    setFilter([exchangeType]);
    setActiveButton(exchangeType);
  };

  useEffect(() => {
    // handleFilterClick("FUTURES");
    sortedFilterBotHighToLow("hightolow");
  }, []);

  useEffect(() => {
    setFilter1("hightolow");
  }, []);

  const handleFilter = (filterType) => {
    const jwt = localStorage.getItem("jwt");

    let postData = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
      page_name: "Report",
      filter_type: filterType,
    };
    if (filterType === "API_Name") {
      postData.filter_type = "API_Name";
    } else if (filterType === "Exchange") {
      postData.filter_type = "Exchange";
    } else if (filterType === "Symbol") {
      postData.filter_type = "Symbol";
    } else if (filterType === "VarBotID") {
      postData.filter_type = "VarBotID";
    } else if (filterType === "Exchange_Type") {
      postData.filter_type = "Exchange_Type";
    } else if (filterType === "Type") {
      postData.filter_type = "Type";
    } else if (filterType === "BotName") {
      postData.filter_type = "BotName";
    } else {
    }

    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_FIL + "filter",
      data: postData,
    })
      .then((res) => {

        if (filterType === "API_Name") {
          setfilterData(res?.data.Success);
          // const updatedSelectedOptions = selectedOptions.filter((option) =>
          //   (res?.data.Success || []).includes(option)
          // );
          setSelectedOptions(res.data.Success);
        } else if (filterType === "Exchange_Type") {
          setFilter([res.data.Success[0]]);
        } else if (filterType === "BotName") {
          setfilterBotData(res.data.Success);
        } else if (filterType === "Type") {
          setTypeData(res.data.Success);
        } else if (filterType === "VarBotID") {
          setbotname(res.data.Success);
        } else if (filterType === "Exchange") {
          setexchange(res.data.Success);
        } else if (filterType === "Symbol") {
          setsymbol(res.data.Success);
        }
        toast(res?.data?.Success);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.Error);
      });
  };


  

  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    setSelectedOptions(filterData);
  }, [filterData]);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   handleFilter("Symbol");
  //   handleFilter("Exchange_Type");
  //   handleFilter("BotName");
  //   handleFilter("VarBotID");
  //   handleFilter("Exchange");
  //   handleFilter("API_Name");
  //   handleFilter("Type");
  // }, []);

  const item = [
    {
      name: "Future",
      click: "FUTURES",
    },
    {
      name: "Spot",
      click: "SPOT",
    },
  ];

  useEffect(() => {
    handleFilterClick("FUTURES");
  },[]); 

  return (
    <>
      <div className="container mx-auto">
        <div className="  lg:mt-0 ">
          <div className="container px-6 my-8 flex justify-between">
           

            <div className="flex gap-8">
              {item.map((item) => {
                return (
                  <button
                    onClick={() => handleFilterClick(item?.click)}
                    className={` font-semibold shadow-xl py-1 px-8  ${activeButton === item?.click
                        ? "bg-neonPurple text-white  rounded-full"
                        : "rounded-full"
                      }`}
                  >
                    {item?.name}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="container">
            <ReportView
              data={[
                filter,
                exchange,
                typedata,
                botname,
                filterbotData,
                filterData,
                symbol,
              ]}
              coin={selectedOptions}
              symbol={symbol}
              type={filter}
              botname={botname}
              filterbotData={filterbotData}
              Rel_Val_Profit={filter1}
              sortedFilterBotHighToLow={sortedFilterBotHighToLow}
              sortedFilterBotLowToHigh={sortedFilterBotLowToHigh}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Reportcopy;
