import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import ModelEnter from "../Components/ModelEnter";
import ArbTrans from "../Components/ArbTrans";
import ArbitrageTrade from "../Components/ArbitrageTrade";
import { Button, Card, Col, Collapse, Form, Row, Select, Tooltip } from 'antd'
import FormField from '../Components/FormField';
import axios from 'axios';
import { toast } from 'react-toastify';
import BalanceSpotcopy from "../Components/BalanceSpotcopy";

const ArbitrageTransaction = (props) => {
  const [index, setIndex] = useState(0);

  const tabNames = [
    "Arbitrage Transaction",
    "Arbitrage Trade",
  ];


  const tabContent = [
    <ArbTrans key={0} />,
    <ArbitrageTrade key={1} />,
  ];

  const [selectedExchange1, setSelectedExchange1] = useState([]);
  const [formConditions, setFormConditions] = useState([]);
  const [api_name, setApi_Name] = useState([]);
  const [selectedblacklist, setSelectedblacklist] = useState([]);
  const [selectCoin, setSelectCoin] = useState([]);
  const jwt = localStorage.getItem("jwt");
  const [formData, setFormData] = useState({
    bot_type: "",
    api_name: "",
    take_profit: '2',
    trade_value: '25',
    trade_type: 'MARKET',
    blacklist_symbol: [],
    buy_supporting: [],
    sell_supporting: []
  });


  const formattedOptions = selectedblacklist.map(item => ({
    value: item,
    label: item
  }));

  const formattedOptions1 = selectCoin.map(item => ({
    value: item,
    label: item
  }));

  const blacklist = () => {
    axios.post(process.env.REACT_APP_API_TOOL + "/arbitrage_trade/get_coins_list")
      .then(response => {
        setSelectedblacklist(response.data.Success);
      })
      .catch(error => {
        console.error('Error fetching blacklist options:', error);
      });
  }

  const handlebinance = () => {
    axios.post(process.env.REACT_APP_API_TOOL + "/arbitrage_trade/get_pairs_list")
      .then(response => {
        setSelectedExchange1(response.data.Success);
      })
      .catch(error => {
        console.error('Error fetching blacklist options:', error);
      });
  }

  const handlesymbol = () => {
    axios.post(process.env.REACT_APP_API_TOOL + "/arbitrage_trade/get_buy_sell_symbols")
      .then(response => {
        setSelectCoin(response.data.Success);
      })
      .catch(error => {
        console.error('Error fetching blacklist options:', error);
      });
  }

  const onFinish = () => {

    // const postData = {
    //     ...formData,
    //     jwt: jwt,
    // };

    const defaultOptions = formattedOptions1.map(option => option.value); // Extract all available options

    const postData = {
      ...formData,
      buy_supporting: formData.buy_supporting.length === 0 ? defaultOptions : formData.buy_supporting,
      sell_supporting: formData.sell_supporting.length === 0 ? defaultOptions : formData.sell_supporting,
      jwt: jwt,
    };

    axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_TOOL + "/arbitrage_trade/get_trade_data",
      data: postData,
    })
      .then((res) => {
        console.log(res);
        toast(res?.data?.Success);
        setFormData({
          bot_type: '',
          api_name: '',
          take_profit: '',
          trade_value: '',
          trade_type: '',
          blacklist_symbol: [],
          buy_supporting: [],
          sell_supporting: [],
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.Error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (name, selectedValues) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedValues,
    }));
  };

  const exchage_bot_list = async () => {
    try {
      const postdata = {
        project: process.env.REACT_APP_PROJECT,
        jwt: jwt,
        exchange_type: "SPOT",
        exchange: "BINANCE",
      };
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_API + "apiname_options",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setApi_Name(response?.data?.Success || []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    exchage_bot_list();
    handlebinance()
    blacklist()
    handlesymbol()
  }, []);

  useEffect(() => {
    const formConditions = [
      { label: 'Bot Type :', type: 'select', name: 'bot_type', value: formData.bot_type, options: ['Live', 'Paper'], required: true },
      { label: 'Api Name :', type: 'select', name: 'api_name', value: formData.api_name, options: api_name, required: true },
      { label: 'Take Profit :', type: 'number', name: 'take_profit', value: formData.take_profit, unit: '%', required: true },
      { label: 'Trade Amount :', type: 'number', name: 'trade_value', value: formData.trade_value, unit: '$', required: true },
      { label: 'Trade Type :', type: 'select', name: 'trade_type', value: formData.trade_type, options: ['MARKET', 'LIMIT'], required: true },
      { label: 'Blacklist Ticker : ', type: 'multi-select', name: 'blacklist_symbol', value: formData.blacklist_symbol, options: formattedOptions },
      { label: 'Buy Whitelist : ', type: 'multi-select', name: 'buy_supporting', value: formData.buy_supporting, options: formattedOptions1 },
      { label: 'Sell Whitelist :', type: 'multi-select', name: 'sell_supporting', value: formData.sell_supporting, options: formattedOptions1 },
    ];

    setFormConditions(formConditions);
  }, [formData, selectedblacklist, selectCoin]);

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange1 = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };

  const items = [
    {
      key: '1',
      label: <div>Advance Options <span className='text-sm bg-neonPurple text-white rounded-lg px-2  animate-pulse'>Coming soon..</span></div>,
      children: <div>
        <form>
          <div className='flex items-center justify-between gap-2'>
            <p className='text-sm font-semibold'>Convert : </p>

            <Select
              defaultValue=""
              placeholder="Select "
              style={{ width: 120 }}
              onChange={handleChange1}
              options={selectedExchange1.map(item => ({
                value: item,
                label: item,
              }))}
              value={selectedValue}

            />

            <input type="number" className='w-28 border border-[#ccc] rounded-md py-1 px-3' placeholder='0.00000000' />
            <Tooltip className='cursor-pointer' title="Coming Soon.."> <button type="submit" className="bg-neonPurple text-white px-4 py-1 rounded-md">Convert</button></Tooltip>
          </div>

        </form>
      </div>,
    },

  ];

  const { username = '', option = [] } = props?.data || {};
  const [option2, setOption2] = useState(option);
  const [Api, setApi] = useState(option[0]?.value || '');

  useEffect(() => {
    setOption2(option);
    if (option.length > 0) {
      setApi(option[0].value);
    }
  }, [option]);

  const handleChange = (value) => {
    setApi(value)
  }

  return (
    <>
      <div className="relative">
        <div className='text-center font-semibold text-xl'>Arbitrage Bot 
          {/* <span className='text-sm bg-neonPurple text-white rounded-lg px-2  animate-pulse'>Coming soon..</span> */}
          </div>
        <div >
          <ModelEnter />
          <Row gutter={[24, 24]} className="px-2">
            <Col xs={24} md={8}>
              <Card>
                <Form onFinish={onFinish}>
                  {formConditions.map((field, index) => (
                    <Form.Item
                      key={index}
                      // label={field.label}
                      // name={field.name}
                      rules={[{ required: field.required, message: `Please input your ${field.label.toLowerCase()}` }]}
                    >
                      <FormField
                        label={field.label}
                        type={field.type}
                        name={field.name}
                        value={field.value}
                        onChange={field.type === 'multi-select' ? handleSelectChange : handleInputChange}
                        options={field.options}
                        unit={field.unit}
                        required={field.required}
                      />
                    </Form.Item>
                  ))}
                  <Form.Item className='flex justify-center items-center gap-2'>
                    <Button type="primary" htmlType="submit">Submit</Button>
                  </Form.Item>
                </Form>
                {/* <div>
              <Collapse items={items} onChange={onChange} />
            </div> */}
              </Card>
            </Col>
            <Col xs={24} md={16}>
              <Card>
                <BalanceSpotcopy />
              </Card>
            </Col>
          </Row>

          {/* <div className="bg-colorPrimary mx-2 rounded-3xl">
            <div className="mt-5">
              <div className="flex border-b-2 border-[#ccc6] mobilescroll mx-5">
                {tabNames?.map((tabName, tabIndex) => (
                  <div
                    key={tabIndex}
                    className={`tab py-1 w-auto min-w-fit uppercase px-6 mx-3 text-center cursor-pointer text-sm ${index === tabIndex
                      ? "active-tab border-gradientbottom text-black font-semibold"
                      : "text-[#666666] "
                      }`}
                    onClick={() => handleTabClick(tabIndex)}
                  >
                    {tabName}
                  </div>
                ))}
              </div>
            </div>
            <SwipeableViews
              index={index}
              onChangeIndex={setIndex}
              className="my-3 mx-2 md:mx-4"
            >
              {tabContent}
            </SwipeableViews>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ArbitrageTransaction;