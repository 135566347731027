import { Collapse } from 'antd';
import React from 'react';
import riskspot from "../assets/img/risk/Spotrisk.png";
import riskfuture from "../assets/img/risk/futurerisk.png";

function FAQ() {

     const projectName = process.env.REACT_APP_DEMO || "Default Project";

    const items = [
      {
        key: "1",
        label: `1. What is ${projectName} (beta)?`,
        children:
          `${projectName} known as a software tool that automates strategy and helps you take advantage of the cryptocurrency market. By employing a bot, you can protect and increase your capital while saving time as you don't need to monitor trades all day. Even while you're sleeping, algorithms are actively trading bitcoin or altcoin for you.`,
      },
      {
        key: "2",
        label: "2. How do I get started?",
        children: `To get started, sign up on ${projectName} and connect your live exchange API. Once connected, browse through the list of available bots and select the one that suits your preferences.`,
      },
      // {
      //   key: "3",
      //   label: "3. What is a paper API?",
      //   children:
      //     "A paper API is a simulated trading interface that allows you to test bots without using real funds. It's a risk-free way to evaluate a bot's performance before deciding to invest real money.",
      // },
      {
        key: "3",
        label: "3. How do I copy a bot?",
        children:
            `After selecting a bot, choose the APIs you want to use for copying live. Follow the simple instructions provided on the ${projectName} platform to initiate the copying process.`,
      },
      {
        key: "4",
        label: "4. What is the risk parameter for spot?",
        children: (
          <div>
            <ul>
              <li>
                <div>
                  <img src={riskspot} alt="" className="my-2" />
                </div>
                <b>Low: </b>To get started, just copy the bot and invest a
                minimum of 1270 USDT for low risk. Our algorithm strategies are
                set to protect liquidation till the market collapses up to 127%.
                <br></br> <b>Medium:</b>
                To get started, just copy the bot and invest a minimum of 1135
                USDT for medium risk. Our algorithm strategies are set to
                protect liquidation till the market collapses up to 113.5%.{" "}
                <br></br>
                <b>High:</b> To get started, just copy the bot and invest a
                minimum of 250 USDT for high risk. Our algorithm strategies are
                set to protect liquidation till the market collapses up to 25%.
              </li>
            </ul>
          </div>
        ),
      },
      {
        key: "5",
        label: "5. What is the risk parameter for future?",
        children: (
          <div>
            <ul>
              <li>
                <div>
                  <img src={riskfuture} alt="" className="my-2" />
                </div>
                <b>Low:</b> To get started, just copy the bot and invest a
                minimum of 127 USDT for low risk. Our algorithm strategies are
                set to protect liquidation till the market collapses up to 127%.
                <br></br>
                <b>Medium:</b>
                To get started, just copy the bot and invest a minimum of 113.5
                USDT for medium risk. Our algorithm strategies are set to
                protect liquidation till the market collapses up to 113.5%.
                <br></br>
                <b>High:</b> To get started, just copy the bot and invest a
                minimum of 25 USDT for high risk. Our algorithm strategies are
                set to protect liquidation till the market collapses up to 25%.
              </li>
            </ul>
          </div>
        ),
      },
      {
        key: "6",
        label: "6. How does the risk parameter affect my investment?",
        children:
          "The risk parameter determines the aggressiveness of the trading strategy employed by the bot. Low risk may have a more conservative approach, while high risk may involve more dynamic and potentially lucrative strategies. Adjusting the risk parameter also affects the minimum investment required to start copying a bot.",
      },
      {
        key: "7",
        label: "7. Can I change the risk parameter after starting a bot?",
        children:
          "No, once a bot is activated, the risk parameter cannot be changed. It's essential to carefully choose the risk level based on your risk tolerance and investment goals before initiating the copying process.",
      },
      {
        key: "8",
        label: `8. Is my investment safe on ${projectName}?`,
        children:
          "While we implement stringent security measures, all investments carry inherent risks. It's crucial to conduct your own research and choose bots and risk parameters that align with your risk tolerance and financial goals.",
      },
      {
        key: "9",
        label: "9. How often are bots updated?",
        children:
          "Bot performance and strategies may be updated periodically. Users will be notified of any changes to ensure transparency and allow them to make informed decisions.",
      },
      {
        key: "10",
        label:
          "10. Why do I experience data mismatches in my bot when fetching data from Binance?",
        children:
          "Data mismatches can arise due to external factors beyond your bot's control. Issues may stem from delays in data updates, network fluctuations, or temporary disruptions on Binance's end. It's crucial to recognize that these discrepancies are not directly caused by your bot.",
      },
      {
        key: "11",
        label: "11. How can I track the performance of my copied bots?",
        children:
          `You can monitor the performance of your copied bots in real-time through the ${projectName} trade Page & Report page. Detailed performance metrics, including profit and loss, are provided for each bot.`,
      },
      {
        key: "12",
        label:
          "12. Why is my bot not consistently meeting the company performance guidelines?",
        children:
          "Bot performance is contingent upon various factors, primarily the dynamic nature of the market and its behavior. The market is inherently volatile, and the bot's performance can fluctuate based on real-time changes in market conditions.",
      },
      {
        key: "13",
        label: "13. How does market volatility impact my bot's performance?",
        children:
          "Market volatility directly influences the performance of your bot. Sudden market shifts, fluctuations in prices, and unexpected events can significantly affect trading outcomes. The bot's algorithms are designed to adapt, but the inherent unpredictability of the market poses challenges in consistently meeting performance benchmarks.",
      },
      {
        key: "14",
        label: "14. How does market volatility impact my bot's performance?",
        children:
          "Market volatility directly influences the performance of your bot. Sudden market shifts, fluctuations in prices, and unexpected events can significantly affect trading outcomes. The bot's algorithms are designed to adapt, but the inherent unpredictability of the market poses challenges in consistently meeting performance benchmarks.",
      },
      {
        key: "15",
        label:
          "15. Why does the bot's performance vary in different market situations?",
        children:
          "The bot's performance is subject to the unique characteristics of each market situation. Market volume, liquidity, and participant behavior play crucial roles. The bot's algorithms analyze these factors to make informed decisions, but outcomes may differ based on the specific dynamics of each scenario.",
      },
      {
        key: "16",
        label:
          "16. Can the bot's performance be affected by changes in market behavior?",
        children:
          "Yes, market behavior evolves over time, and the bot's performance is sensitive to these changes. Shifts in trends, sentiment, or trading patterns can impact how the bot operates. Regular updates to the bot's algorithms are made to adapt to evolving market conditions.",
      },
      {
        key: "17",
        label: "17. How does data input affect the bot's performance?",
        children:
          " The quality and timeliness of data input are critical factors. If the data available to the bot is delayed, incomplete, or inaccurate, it can impact decision-making. Ensuring a reliable data feed is essential to optimize the bot's performance.",
      },
      {
        key: "18",
        label:
          "18. Is there a guarantee that the bot will always meet performance guidelines?",
        children:
          " Due to the inherent uncertainties in financial markets, no bot can provide an absolute guarantee of performance. While efforts are made to optimize algorithms and adapt to market changes, the dynamic nature of the financial landscape means that performance can vary.",
      },
      {
        key: "19",
        label:
          "19. How can I mitigate risks associated with fluctuations in bot performance?",
        children:
          " Diversification, risk management strategies, and staying informed about market developments are crucial. Establish clear risk tolerance levels, regularly assess your bot's parameters, and be prepared to make adjustments based on changing market conditions.",
      },
      {
        key: "20",
        label:
          "20. Is customer support available for assistance with bot performance issues?",
        children:
          " Yes, our customer support team is available to assist with any issues or concerns related to bot performance. Feel free to reach out with specific questions or for guidance on optimizing your bot for prevailing market conditions",
      },
      {
        key: "21",
        label: "21. What is the minimum balance requirement on the platform?",
        children:
          " Users must maintain a minimum balance as specified by the platform. Falling below this minimum may result in the suspension of bot services.",
      },
      {
        key: "22",
        label:
          "22. What happens if my account balance is not in line with the allocated funds set by the bot?",
        children:
          "If the balance is not maintained according to the allocated funds, the company will not be responsible for any resulting losses and liquidation.",
      },
      {
        key: "23",
        label: "23. Can I manually trade while using the copy bot service?",
        children:
          " No, users are strictly prohibited from engaging in manual trading. All trading activities should be exclusively conducted through the automated bot.",
      },
      {
        key: "24",
        label: "24. Why is it important to keep Telegram alerts enabled?",
        children:
          " Users must keep Telegram alerts enabled for both the bot's notifications and general trading alerts. Timely responses are essential to maximize the effectiveness of the copy bot.",
      },
      {
        key: "25",
        label:
          "25. What happens if there is a malfunction or suspicious activity in my account?",
        children:
          "Any detected malfunction or suspicious activity may lead to the temporary or permanent suspension of the account. It is crucial to report technical issues promptly to avoid such consequences.",
      },
      {
        key: "26",
        label: "26. How often should I monitor my account?",
        children:
          "Users are encouraged to regularly monitor their accounts for discrepancies or unauthorized activities. Immediate reporting of any issues to customer support is recommended.",
      },
      {
        key: "27",
        label: "27. Is there a guarantee of profits on the platform?",
        children:
          "No, users acknowledge the inherent risks associated with cryptocurrency trading. Both profits and losses are possible, and the platform does not guarantee profits. Users participate at their own risk.",
      },
      {
        key: "28",
        label: "28. What security measures should I implement for my account?",
        children:
          "Users are responsible for implementing and maintaining robust security measures for their accounts, including secure password practices and two-factor authentication.",
      },
      {
        key: "29",
        label:
          "29. What happens if trades cannot be executed or there are data mismatches due to Binance's live API block or restrictions?",
        children:
          "If trades cannot be executed or data mismatches occur due to Binance's live API block or restrictions, the responsibility lies with Binance. The platform is not accountable for bot performance issues or fund losses arising from Binance API restrictions. Users are advised to monitor Binance's service status and contact Binance support for resolution in such cases.",
      },
      {
        key: "30",
        label:
          "30. What happens during highly volatile market conditions when Binance restricts trading or blocks the API?",
        children:
          "During periods of high market volatility leading to Binance trading restrictions or API blocks, the copy bot on our platform will not be responsible for any losses or effects on user funds. Users are advised to stay informed about market conditions, and the platform recommends exercising caution during such volatile periods. The platform will resume normal operations when Binance lifts the restrictions.",
      },
    ];


  return (
    <div>
      <div className=" bg-colorPrimary mx-2 md:mx-4  w-100">
        <div className="p-2 text-sm">
          <div className="text-xl text-black font-bold text-center mb-4">
            FAQ'S
          </div>
          <Collapse
            className="text-sm "
            accordion
            items={items}
            render={(item, index) => (
              <div className="flex items-center">
                <div className="mr-2">{`${index + 1}.`}</div>
                <div className="collapse-item text-sm ">{item.label}</div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default FAQ