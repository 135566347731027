import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import NeoDesign from "./NeoDesign";
// import logo from "../assets/logoPurple.png";

function AuthWrapper() {

  const isMdScreen = window.matchMedia("(min-width: 768px)").matches;
  
  return (
    <div className="">
      <div className="mx-w-[1540px] relative  md:bg-colorPrimaryDark ">
      {isMdScreen ? null : <div className=" md:hidden">
        <div className="flex justify-center items-center h-28 ">
          <div className="text-white text-3xl flex justify-center">
            <img src="https://imagedelivery.net/_61vLPSGQhg-TYoL9kq0Vg/2b9c0ea3-f2c8-42e9-40c0-e42e826c7c00/public" alt="Logo" className="" />
          </div>
        </div>
      </div>}

        <div className="  flex items-center justify-center md:ml-5 overflow-hidden">
          <div className=" w-8/12 justify-start md:flex md:flex-col text-justify md:items-start hidden">
            <NeoDesign />
          </div>

          <div className=" xl:w-4/12 z-10  w-full rounded-t-full bg-colorPrimaryDark flex flex-col items-center justify-center">

            <div className=" w-full px-4 flex justify-center items-center">
              <div className="flex md:justify-between justify-center items-center w-full"></div>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthWrapper;
