import React, { useEffect, useState } from 'react'
import './App.css';
import Routerpage from './Routes/Routerpage';
import Loader from './Layout/Loader';

const App = () => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100); 
  }, []);

  return (
    <>
       <div className="App">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Routerpage />
          </>
        )}
      </div>
    </>
  );
}

export default App
