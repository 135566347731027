import { toast } from 'react-toastify';

export const HandleSession = (err) => {
  const errorMessage = err?.response?.data?.Error;

  if (errorMessage) {
    toast.error(errorMessage);

    if (errorMessage === "Session Expired" || errorMessage === "Invalid Authentication") {
      setTimeout(() => {
        localStorage.clear();
        window.location.assign("/");
      }, 1000); 
    }
  }
};
