import React, { useEffect } from 'react'
import NeoDesign from './NeoDesign';

function LoginAsLogin() {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const jwt = urlParams.get('jwt');

        if (jwt) {
            localStorage.setItem('jwt', jwt);
            window.location.href = '/dashboard/home';
        }
    }, []);
    return (
        <div>
             <div className=" w-full justify-start md:flex md:flex-col text-justify md:items-start hidden">
            <NeoDesign />
          </div>  
        </div>
    )
}

export default LoginAsLogin