import { Modal } from "antd";
import React, { useState, useCallback } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { AiOutlineBlock } from "react-icons/ai";
import ButtonApi from "../Components/ButtonApi";
import { useEffect } from "react";
import ConnectContext from "./ConnectContext";
import { FaLock } from 'react-icons/fa';
import UpdateApi from "../Components/UpdateApi";

const Step = ({ title, completed, index, isChecked, onClick }) => {
  const stepClassName = completed ? "current bg-[#000]" : "bg-blue-300";

  return (
    <div className={`step ${stepClassName}`}>
      <span className={`step-number ${completed ? "checkmark" : ""}`}>
        {completed ? "✔" : index}
      </span>
      <span>{title}</span>
      {title === "Choose Exchange" && isChecked && (
        <span className="checkbox">✔</span>
      )}
    </div>
  );
};

const Api_Setting = () => {
  const location = useLocation();
  // const locationState = location.state || false;
  const navigate = useNavigate();
  const { param } = useParams();
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkBotPackage = checkBotFeature.Bot_Package_Active;

  const [stepsData, setStepsData] = useState([
    { title: "Choose Exchange", completed: false },
    { title: "Setup Keys", completed: false },
    { title: "Connecting..", completed: false },
  ]);
  const [locationState, setLocationState] = useState(location.state || false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/api_settings/Paperfutureconnectiondetails" || location.pathname === "/dashboard/api_settings/Spotconnectiondetails") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: false },

        { ...prevStepsData[2], completed: false },
      ]);
    }
    else if (location.pathname === "/dashboard/api_settings/Paperfutureconnectiondetails" || parentState === "Clicked") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: true },
      ]);
    }
    else if (location.pathname === "/dashboard/api_settings/ConnectionLoder") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: false },

      ]);
    }
    else if (location.pathname === "/dashboard/api_settings/ConnectionLoder" && parentState === "Clicked") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: true },

      ]);
    }
    else if (location.pathname === "/dashboard/api_settings") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: false },
        { ...prevStepsData[1], completed: false },
        { ...prevStepsData[2], completed: false },
      ]);
    }
  }, [location, locationState, location.pathname]);
  const [parentState, setParentState] = useState('Initial Value');

  const updateParentState = useCallback((newValue) => {
    setParentState(newValue);
    if (location.pathname === "/dashboard/api_settings/Paperfutureconnectiondetails" && newValue === "Clicked") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: true },
      ]);
    }
    if (location.pathname === "/dashboard/api_settings/ConnectionLoder" && newValue === "Clicked") {
      setStepsData((prevStepsData) => [
        { ...prevStepsData[0], completed: true },
        { ...prevStepsData[1], completed: true },
        { ...prevStepsData[2], completed: true },

      ]);
    }

  }, []);
  return (
    <>
      <div className="relative">
        {checkBotPackage === false ?
          <div className="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
            style={{
              backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/api_settings.webp)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: "100vh"
            }}>
            <FaLock className="text-gray-600 text-6xl mb-4" />
            <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
            <p className="mb-4">To access this feature, please upgrade your Package.
            </p>
          </div>
          :
          <div>
            <div className="container mx-auto ">
              <div className="wrapper ">
                <div className="arrow-steps flex mt-5 md:mt-0   flex-wrap justify-center">
                  {stepsData.map((step, index) => (
                    <Step
                      key={index}
                      title={step.title}
                      completed={step.completed}
                      index={index + 1}
                      className="shadow-md shadow-black "
                    // isChecked={locationState}
                    />
                  ))}
                </div>
                {/* <div className="fixed bottom-7 right-2 xl:hidden block z-40">
                  <button
                    type="primary"
                    onClick={showModal}
                    className=" rounded-full py-1 shadow-neumorphic active"
                  >
                    <AiOutlineBlock size={25} className="" />
                  </button>
                  <Modal
                    title="Status"
                    visible={isModalVisible}
                    onOk={handleOk}
                    footer={false}
                    onCancel={handleCancel}
                  >
                    <ButtonApi />
                  </Modal>
                </div> */}
              </div>
              <ConnectContext.Provider value={{ parentState, updateParentState }}>
                <Outlet />
              </ConnectContext.Provider>
            </div>
          </div>
        }

      </div>
    </>
  );
};

export default Api_Setting;
