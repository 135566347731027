import React, { useEffect, useState } from "react";
import ActiveBot from "./ActiveBot";
import Reportcopy from "./Reportcopy";
import TradesCopy from "./TradesCopy";
import { FaLock } from 'react-icons/fa';
import UpdateApi from "../Components/UpdateApi";

function ActiveSwitch() {
  const [activeView, setActiveView] = useState("grid");
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkBotPackage = checkBotFeature.Bot_Package_Active;

  const handleGridClick = () => {
    setActiveView("grid");
  };

  const handleListClick = () => {
    setActiveView("list");
  };

  const handleTradesClick = () => {
    setActiveView("trades");
  };

  return (
   


    <div>
      <ActiveBot data={{ handleGridClick, handleListClick, handleTradesClick }} />
    </div>
  );
}

export default ActiveSwitch;
