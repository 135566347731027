import { Button } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Forgetusername() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate()
  const [isLabelAbove, setIsLabelAbove] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFocus = () => {
    setIsLabelAbove(true);
  };

 const handleSubmit = async (e) => {
  e.preventDefault();
   const formData = new FormData();
   formData.append("project", process.env.REACT_APP_PROJECT);
   formData.append("email", email);
   const response = await axios({
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     url: process.env.REACT_APP_API_PATH_USER_ODECENT + "forgot_username",
     data: formData,
   })
     .then(async function (response) {
       const res = await response?.data;
       if (res?.Success) {
         toast(res.Success);
         navigate("/submit", {
           state: {
             email: email,
           },
         });
       } else {
         toast.error(res.Error);
       }
     })
     .catch((err) => {
       toast.error(err.response.data.Error);
     });
 };

  

  
  return (
    <>
      <div className="container mx-auto mt-10 px-2 flex justify-center items-center">
        <div className="w-full bg-colorPrimaryDark  relative rounded-2xl py-3  overflow-hidden">
          <div className="text-center">
            <div className="text-lg md:text-3xl font-semibold mb-3 text-neonPurple">
              Forgot your username?
            </div>
            <div>
              <a className="text-gray-100 md:text-gray-500 font-bold ">
                Don’t worry. We’ll send it to your email address.{" "}
              </a>
            </div>
            <div className="px-5 py-5   rounded-lg mt-5  md:w-9/12 mx-auto">
              <form>
                {/* <FloatingLabelInput label="Email" /> */}
                <input
                  placeholder="Email ID"
                  id="email"
                  name="email"
                  type="email"
                  className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                  // className="py-2 px-4 border border-none rounded-full focus:outline-none bg-colorPrimaryDark shadow-neumorphic1  focus:border-none w-full"
                  value={email}
                  onChange={handleChange}
                  onFocus={() => handleFocus("email")}
                  // onBlur={() => handleBlur("email")}
                  autoComplete="off"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                />
                {/* <input
                type="email"
                id="Email"
                value={email}
                onChange={handleChange}
                className="peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 py-2 px-4 border border-slate-900 rounded-full  focus:border-blue-500 w-full bg-white "
                placeholder="Email"
              /> */}

                {/* <span className="pointer-events-none absolute  left-2 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
                Email
              </span> */}

                <button
                  htmlType="submit"
                  onClick={handleSubmit}
                  className="rounded-full active  shadow-neumorphic1 py-1 text-white mt-6 cursor-pointer hover:active/70 hover:text-white px-4"
                >
                  Request username
                </button>
              </form>
              <div className="flex justify-between items-center gap-4 my-5 text-neonPurple underline">
                <button >
                  <Link to="/forgetpassword"> Forgot Password?</Link>
                </button>
                <button >
                  <Link to="/"> Sign In Here</Link>
                </button>
              </div>
              <div className="mt-1"> </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgetusername;
