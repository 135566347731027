import React, { useState } from 'react';
import FormField from '../Components/FormField';
import DatePickerField from '../Components/DatePicker';
import { Tooltip } from 'antd';

const Form = () => {
    const [formData, setFormData] = useState({
        marketType: 'Crypto',
        exchange: 'Binance',
        instrument: 'DIR',
        candlesticksType: 'NORMAL',
        timeframe: '15m',
        tradeDirection: 'LONG',
        takeProfitType: 'AVERAGE',
        tradeType: 'SPOT',
        priceType: 'CLOSE',
        endDate: new Date(),
        periodOfBacktest: 30,
        investmentAmount: 10000,
        baseAmount: 100,
        subsequentOrderAmount: 100,
        subsequentOrderCount: 10,
        subsequentOrderAmountMultiple: 1.5,
        buyConditionIndicator: 'MA',
        indicatorParam1: 7,
        indicatorParam2: 7,
        takeProfitPercent: 3,
        stopLossPercent: 3,
        pair: 'BNB/USDT',
        strategyName: 'MY FIRST STRATEGY',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };

    const formFields = [
        { label: 'Market Type', type: 'select', name: 'marketType', options: ['Crypto', 'Indian', 'US'] },
        { label: 'Exchange', type: 'select', name: 'exchange', options: ['Binance', 'Kucoin', 'Bybit', 'Zerodha'] },
        { label: 'Instrument', type: 'text', name: 'instrument' },
        { label: 'Candlesticks Type', type: 'select', name: 'candlesticksType', options: ['NORMAL', 'HEIKIN ASHI', 'LINE', 'CANDLESTICK', 'BARS', 'STEP', 'WAVE', 'RENKO', 'BASELINE', 'VOLUME FOOT'] },
        { label: 'Timeframe', type: 'select', name: 'timeframe', options: ['1m', '2m', '3m', '5m', '15m', '30m', '1H', '1D'] },
        { label: 'Trade Direction', type: 'select', name: 'tradeDirection', options: ['LONG', 'SHORT', 'HEDGED'] },
        { label: 'Take Profit Type', type: 'select', name: 'takeProfitType', options: ['AVERAGE', 'ENTRY'] },
        { label: 'Trade Type', type: 'select', name: 'tradeType', options: ['SPOT', 'FUTURES', 'OPTION', 'INDEX'] },
        { label: 'Price Type', type: 'select', name: 'priceType', options: ['OPEN', 'CLOSE', 'HIGH', 'LOW'] },
        { label: 'Period of Backtest', type: 'number', name: 'periodOfBacktest' },
        { label: 'Investment Amount', type: 'number', name: 'investmentAmount', unit: '$' },
        { label: 'Base Amount', type: 'number', name: 'baseAmount', unit: '$' },
        { label: 'Subsequent Order Amount', type: 'number', name: 'subsequentOrderAmount', unit: '$' },
        { label: 'Subsequent Order Count', type: 'number', name: 'subsequentOrderCount', unit: '$' },
        { label: 'Subsequent Order Amount Multiple', type: 'number', name: 'subsequentOrderAmountMultiple' },

    ];

    const formConditions = [
        { label: 'Buy Condition Indicator', type: 'select', name: 'buyConditionIndicator', options: ['MA', 'RSI', 'BOLL', 'MACD', 'STOCHASTIC'] },
        { label: 'Indicator Parameter 1', type: 'number', name: 'indicatorParam1' },
        { label: 'Indicator Parameter 2', type: 'number', name: 'indicatorParam2' },
    ];

    const formFieldsell = [
        { label: 'Take Profit Percent', type: 'number', name: 'takeProfitPercent', unit: '%' },
        { label: 'Stop Loss Percent', type: 'number', name: 'stopLossPercent', unit: '%' },
        { label: 'Pair', type: 'text', name: 'pair' },
        { label: 'Strategy Name', type: 'text', name: 'strategyName', },
    ];

    return (
        <>
            <div className='relative'>
                <div>
                    <div className='container mx-auto p-4'>
                        <div className='text-center font-semibold text-xl'>Create Bot
                             {/* <span className='text-sm bg-neonPurple text-white rounded-lg px-2  animate-pulse'>Coming soon..</span>\ */}
                             
                             </div>
                        <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    {formFields.map((field) => (
                                        <FormField
                                            key={field.name}
                                            label={field.label}
                                            type={field.type}
                                            name={field.name}
                                            value={formData[field.name]}
                                            onChange={handleChange}
                                            options={field.options || []}
                                            unit={field.unit}
                                        />
                                    ))}
                                    <DatePickerField
                                        label="End Date"
                                        name="endDate"
                                        selected={formData.endDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <h1 className='border-b border-[#ccc] my-4 font-semibold'>Buy Condition</h1>

                                    {formConditions.map((field) => (
                                        <FormField
                                            key={field.name}
                                            label={field.label}
                                            type={field.type}
                                            name={field.name}
                                            value={formData[field.name]}
                                            onChange={handleChange}
                                            options={field.options || []}
                                            unit={field.unit}
                                        />
                                    ))}
                                    <h1 className='border-b border-[#ccc] my-4 font-semibold'>Sell/Close Conditions</h1>
                                    {formFieldsell.map((field) => (
                                        <FormField
                                            key={field.name}
                                            label={field.label}
                                            type={field.type}
                                            name={field.name}
                                            value={formData[field.name]}
                                            onChange={handleChange}
                                            options={field.options || []}
                                            unit={field.unit}
                                        />
                                    ))}
                                    <div className='my-5 flex justify-center items-center'>
                                        <Tooltip className='cursor-pointer' title="Coming Soon.."><button type="submit" className="bg-blue-500 text-white p-2 rounded">Backtest Now</button></Tooltip>
                                    </div>
                                    <div className="mt-4 flex justify-center items-center space-x-4">

                                        <Tooltip className='cursor-pointer' title="Coming Soon.."> <button type="button" className="bg-green-500 text-white p-2 rounded">Save Existing Strategy</button></Tooltip>
                                        <Tooltip className='cursor-pointer' title="Coming Soon.."> <button type="button" className="bg-yellow-500 text-white p-2 rounded">Save As New Strategy</button></Tooltip>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Form;
