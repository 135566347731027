import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import ModelEnter from "../Components/ModelEnter";
import Address from "../Components/Address";
import ContractAddress from "../Components/ContractAddress";
import ExchangeDex from "../Components/ExchangeDex";
import { FaLock } from 'react-icons/fa';
import UpdateApi from "../Components/UpdateApi";

const Dex = () => {
  const [index, setIndex] = useState(0);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.Blockchain_Analytics;

  const tabNames = [
    "CONTRACT ADDRESS",
    "ADDRESS",
    "Dapp DETAILS",
  ];

  const handleTabClick = (newIndex) => {
    // console.log("Tab clicked. New index:", newIndex);
    setIndex(newIndex);
  };

  const tabContent = [
    <ContractAddress key={0} />,
    <Address key={1} />,
    <ExchangeDex key={2} />
  ];

  return (
    <>
      <ModelEnter />
      <div className="bg-colorPrimary mx-2 rounded-3xl">
        <div className="relative">
          {checkTradePackage === false ?
            <div className="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
              style={{
                backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/DEX.webp)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: "100vh"
              }}
            >
              <FaLock className="text-gray-600 text-6xl mb-4" />
              <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
              <p className="mb-4">To access this feature, please upgrade your Package.
              </p>
            </div>
            :
            <div>
              <div className="mt-5">
                <div className="flex border-b-2 border-[#ccc6] mobilescroll mx-5">
                  {tabNames?.map((tabName, tabIndex) => (
                    <div
                      key={tabIndex}
                      className={`tab py-1 w-auto min-w-fit  px-6 mx-3 text-center cursor-pointer text-sm ${index === tabIndex
                        ? "active-tab border-gradientbottom text-black font-semibold"
                        : "text-[#666666] "
                        }`}
                      onClick={() => handleTabClick(tabIndex)}
                    >
                      {tabName}
                    </div>
                  ))}
                </div>
              </div>
              <SwipeableViews
                index={index}
                onChangeIndex={setIndex}
                className="mb-3 mx-2 md:mx-4"
              >
                <div className="tab-content">
                  <ContractAddress />
                </div>
                <div className="tab-content">
                  <Address />
                </div>
                <div className="tab-content">
                  <ExchangeDex />
                </div>
              </SwipeableViews>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Dex;