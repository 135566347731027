import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import OpenTrades from "../Components/OpenTrades";
import TradesHistroy from "../Components/TradesHistroy";
import TradesCopy from "./TradesCopy";


const TradesNewV = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(20);

  const abbreviatedNames = [
    "Deposit_Transaction",
    "Subscription_Qualification",
  ];

  const tabNames = [
    "Open Trades",
    "Trades History",
  ];

  const [pageName, setPageName] = useState(abbreviatedNames[0]); 
 
  const { data, totalPages,setData } = useState({ pageName, pageSize, page });

  const handleTabClick = (newIndex, pageName) => {
    setIndex(newIndex);
    // setPage(1);
    // setPageName(pageName);
    // setData([]);
    
  };

  const tabContent = [
    <OpenTrades key={0} data={{ subData: data, page,pageSize, totalPages, setData,setPage, setpageSize }} />,
    <TradesCopy key={1} data={{ subData: data, page, pageSize, totalPages,setData, setPage, setpageSize }} />,
  ];

  return (
    <>
     <div className="bg-colorPrimary mx-2 rounded-3xl">
        <div className="mt-5">
          <div className="flex border-b-2 border-[#ccc6] mobilescroll mx-5">
            {tabNames?.map((tabName, tabIndex) => (
              <div
                key={tabIndex}
                className={`tab py-1 w-auto min-w-fit uppercase px-6 mx-3 text-center cursor-pointer text-sm ${index === tabIndex
                    ? "active-tab border-gradientbottom text-black font-semibold"
                    : "text-[#666666] "
                  }`}
                onClick={() => handleTabClick(tabIndex, abbreviatedNames[tabIndex])}
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          className="mt-3 mx-4"
        >
          {tabContent}
        </SwipeableViews>
      </div>
    </>
  );
};

export default TradesNewV;