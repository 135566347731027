import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import Apisame from "../Components/Apisame";
import PackageView from "./PackageView";

const Packagesnew = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(20);

  const abbreviatedNames = ["tradepackage", "botpackage"];

  const tabNames = ["Trade Package", "Bot Package"];

  const { data, totalPages, setData } = Apisame({ pageSize, page });

  const handleTabClick = (newIndex, pageName) => {
    setIndex(newIndex);
    setPage(1);

    setData([]);
  };

  // const tabContent = [<Packages />,<BotPackage/>];
  const tabContent = [<PackageView packageType="tradepackage"/>,<PackageView packageType="botpackage"/>]

  return (
    <>
      <div className="bg-colorPrimary mx-2 rounded-3xl">
        <div className="mt-5">
          <div className="flex  mobilescroll mx-5">
            {tabNames?.map((tabName, tabIndex) => (
              <div
                key={tabIndex}
                className={`tab py-1 w-auto min-w-fit uppercase px-6 mx-3 text-center cursor-pointer text-sm ${
                  index === tabIndex
                    ? "active-tab border-b-2 border-gradientbottom text-black font-semibold"
                    : "text-[#666666] "
                }`}
                onClick={() =>
                  handleTabClick(tabIndex, abbreviatedNames[tabIndex])
                }
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          className="mt-3 mx-4"
        >
          {tabContent}
        </SwipeableViews>
      </div>
    </>
  );
};

export default Packagesnew;
