// actions.js
export const FETCH_WALLET_REQUEST = 'FETCH_WALLET_REQUEST';
export const FETCH_WALLET_SUCCESS = 'FETCH_WALLET_SUCCESS';
export const FETCH_WALLET_FAILURE = 'FETCH_WALLET_FAILURE';

export const fetchWalletRequest = () => ({
  type: FETCH_WALLET_REQUEST,
});

export const fetchWalletSuccess = (wallet) => ({
  type: FETCH_WALLET_SUCCESS,
  payload: wallet,
});

export const fetchWalletFailure = (error) => ({
  type: FETCH_WALLET_FAILURE,
  payload: error,
});
