// reducers.js
import { FETCH_WALLET_REQUEST, FETCH_WALLET_SUCCESS, FETCH_WALLET_FAILURE } from './Action';

const initialState = {
  wallet: {},
  loading: false,
  error: null,
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        wallet: action.payload,
      };
    case FETCH_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default walletReducer;
