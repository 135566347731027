import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal, Spin, Popconfirm, Button, Radio, Tooltip } from "antd";
// import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import UpdateApi from "../Components/UpdateApi";
import { FcApproval, FcCancel, FcOk } from "react-icons/fc";

const CustomLoader = () => {
  return (
    <div className="custom-loader">
      <div className="loader"></div>
    </div>
  );
};


function PackageView({ packageType }) {
  const [pack, setPack] = useState([]);

  const [Wallet_type, setWalletType] = useState("Wallet_Balance");

  const [selectedPackage, setSelectedPackage] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const { View_Wallet } = UpdateApi();
  const jwt = localStorage.getItem("jwt");

  const navigate = useNavigate();
  // const [selectedVideoSrc, setSelectedVideoSrc] = useState("");
  const [loading, setLoading] = useState(false);

  const { wallet } = UpdateApi();
  const [walletBalance, setwalletBalance] = useState(wallet?.Wallet_Balance);
  const [subbalance, setsubbalance] = useState(wallet?.Subscription_Balance);

  const projectname = process.env.REACT_APP_PROJECT;

  //
  const View_Wallet2 = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
    };

    try {
      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_Wallet + "wallet_details",
        data: postdata,
      });
      const res = response.data; // Directly use response.data
      return res.Success; // Ensure Success is returned
    } catch (err) {
      console.log("err", err);
      return null; // Return null in case of an error
    }
  };

  const openModal = async (packageItem) => {

    try {
      const latestWalletData = await View_Wallet2();

      setwalletBalance(latestWalletData?.Wallet_Balance);
      setsubbalance(latestWalletData?.Subscription_Balance);

      setSelectedPackage(packageItem);
      setModalVisible(true);
    } catch (error) {
      console.error("Failed to fetch the latest wallet data:", error);
    }
  };
  //

  // const openModal = async (packageItem) => {
  //   // if (packageType === "tradepackage") {

  //   setSelectedPackage(packageItem);
  //   // setTimeout(() => {
  //   //   navigate("/dashboard/subscription");
  //   // }, 2000);
  //   // setTimeout(() => {
  //   //   navigate("/dashboard/subscriptions");
  //   // }, 4000);
  //   // setTimeout(() => {
  //   //   navigate("/dashboard/subscription");
  //   // }, 5000);
  //   setModalVisible(true);
  //   // }
  // };
  const closeModal = () => {
    setSelectedPackage("");

    setModalVisible(false);
  };
  const projectNameView = process.env.REACT_APP_PROJECT || "Odecent";
  const View_Pack = async () => {
    setLoading(true);
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
    };

    try {
      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_PUBLIC + "subscription",
        data: postdata,
      });
      const res = await response.data;
      const filteredPacks = res.Success.filter((packItem) =>
        packItem.Name.startsWith(packageType === "tradepackage" ? "TP" : "BOT")
      );
      setPack(filteredPacks);
      setLoading(false);
      navigate("/dashboard/subscription");
    } catch (err) { }
  };

  useEffect(() => {
    View_Pack();
  }, []);

  const [subsloading, setsubsLoading] = useState(false);

  const View_Packbuy = async (subscriptionName) => {
    setsubsLoading(true);
    const postdata = {
      jwt: jwt,
      subscription_name: subscriptionName,
      wallet_type:
        packageType === "tradepackage" ? "Wallet_Balance" : Wallet_type,
    };

    try {
      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_SUB + "buy_subscription",
        data: postdata,
      });
      const res = await response.data;
      toast(res.Success);
      View_Wallet();
      closeModal();
      await navigate("/dashboard/subscriptions");

      setTimeout(async () => {
        await navigate("/dashboard/subscription");
      }, 1000); 
    } catch (err) {
      toast.error(err.response.data.Error);
    } finally {
      setsubsLoading(false); 
    }
  };

  const handleImageLoad = () => {
    // handle image load logic if needed
  };

  const confirm = () => {
    View_Packbuy(selectedPackage?.Name);
  };

  const onChange = (e) => {
    setWalletType(e.target.value);
  };

  // if(!wallet){
  //   return <div>Loading....</div>
  //     }
  //

  return (
    <div className="container">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 md:gap-x-2 gap-y-4 lg:gap-5">
        {loading
          ? Array.from({ length: 12 }).map((_, index) => (
            <div
              key={index}
              className="card w-full rounded-[20px] bg-[white] p-[5px] overflow-hidden shadow-[rgba(100,100,111,0.2)_0px_7px_20px_0px] transition-transform duration-500 ease-[cubic-bezier(0.175,0.885,0.32,1.275)] hover:scale-105"
            >
              <div className="top-section h-[150px] rounded-[15px] flex flex-col relative">
                <div className="placeholder h-full w-full bg-gray-300 animate-pulse rounded-[15px]" />
                <div className="bottom-section text-white p-4 space-y-2">
                  <p className="text-sm">Loading...</p>
                  <div className="flex justify-center items-center">
                    <Spin spinning={true} size="large" />
                  </div>
                </div>
              </div>
            </div>
          ))
          : pack.map((packItem) => (
            <div
              key={packItem.ID}
              className="card w-full  rounded-[20px] bg-[white] bg-gradient-to-br from-purple-50 to-blue-100 p-[5px] overflow-hidden shadow-[rgba(100,100,111,0.2)_0px_7px_20px_0px] transition-transform duration-500 ease-[cubic-bezier(0.175,0.885,0.32,1.275)] hover:scale-105"
            >
              <div className="top-section   flex flex-col relative">
                <img
                  src={packItem.Achivement}
                  alt={`package-${packItem.ID}`}
                  className="h-full w-full object-cover rounded-[15px]"
                  onLoad={handleImageLoad}
                />
              </div>
              <div className="bottom-section text-white p-4   grid grid-cols-1">
                <button
                  onClick={() => openModal(packItem)}
                  className="py-1 px-2 bg-neonPurple rounded text-sm"
                >
                  View More
                  {/* {packageType === "tradepackage" ? "View More" : "Coming Soon"} */}
                </button>
              </div>
            </div>
          ))}
      </div>

      <Modal
        title="Package Details"
        visible={modalVisible}
        onCancel={closeModal}
        footer={false}
        width={800}
      >
        {selectedPackage && (
          <>
            <div className="grid grid-cols-1 gap-5 my-6 items-center">
              <div className="flex justify-center">
                <img
                  src={selectedPackage.Achivement}
                  alt={`package-${selectedPackage.ID}`}
                  className="h-[200px] w-[400px]  rounded-[15px]"
                  onLoad={handleImageLoad}
                />
              </div>
              <div className="capitalize">
                {packageType === "tradepackage"
                  ? pack
                    .filter((pkg) => pkg.Name === selectedPackage.Name)
                    .map((pkg, index) => (
                      <div
                        key={index}
                        className="flex flex-col mb-4 p-4 border rounded"
                      >
                        <div className="flex justify-center items-center bg-purple-500 w-full py-4 px-6 rounded-lg shadow-lg font-medium md:text-sm lg:text-sm capitalize">
                          <div
                            value="Wallet_Balance"
                            className="bg-white py-2 px-1 rounded-full shadow-md hover:bg-gray-100 transition-colors duration-300"
                          >
                            <span className="font-medium ">
                              WALLET BALANCE:
                            </span>

                            <span className="font-semibold text-neonPurple">
                              {/* {(wallet.Wallet_Balance ?? 0).toFixed(2)}{" "} */}
                              {!wallet ? "0" : walletBalance?.toFixed(2)}
                            </span>

                            <span className="font-medium text-green-500">
                              {" "}
                              Points
                            </span>
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <span className="text-xs md:text:sm lg:text-sm  font-medium flex items-center gap-4">
                            PACKAGE NAME:
                          </span>
                          {pkg.Name}
                        </div>
                        <div className="flex justify-between">
                          <span className="text-xs md:text:sm lg:text-sm  font-medium  flex items-center gap-4">
                            PACKAGE VALUE:
                          </span>
                          {pkg.Price}
                        </div>
                        <div className="flex justify-between">
                          <span className="text-xs md:text:sm lg:text-sm  font-medium  flex items-center gap-4">
                            PACKAGE VALIDITY:
                          </span>
                          {pkg.Validity / 7} {"Weeks"}
                        </div>

                        {/* MarketDataAnalysis: { available: true, icon: <FcApproval size={20}/> },
        BlockchainAnalytics: { available: true, icon: <FcApproval size={20}/> },
        NFTAnalytics: { available: false, icon: <FcCancel size={20}/> }, */}

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2  md:grid-cols-2 gap-x-3">
                          {/* <div className="grid grid-cols-2 gap-2"> */}
                          <div className="flex justify-between">
                            <span className="text-xs uppercase font-medium  md:text:sm lg:text-sm  flex items-center gap-4">
                              Market Data Analysis:
                            </span>
                            {pkg.Features.Market_Data_Analysis
                              ? <FcApproval size={20} />
                              : <FcCancel size={20} />}
                          </div>
                          {/* <div className="flex justify-between">
                                <span className="text-xs uppercase font-medium  md:text:sm lg:text-sm  flex items-center gap-4">
                                  Trade Analysis :
                                </span>
                                {pkg.Features.Market_Analysis_Tools
                                  ? <FcApproval size={20}/>
                                  : <FcCancel size={20}/>}
                              </div> */}
                          <div className="flex justify-between">
                            <span className="text-xs uppercase font-medium  md:text:sm lg:text-sm flex items-center gap-4">
                              Blockchain Analytics :
                            </span>
                            {pkg.Features.Blockchain_Analytics
                              ? <FcApproval size={20} />
                              : <FcCancel size={20} />}
                          </div>
                          <div className="flex justify-between">
                            <span className="text-xs uppercase font-medium  md:text:sm lg:text-sm flex items-center gap-4">
                              NFT Analyitics:
                            </span>
                            {pkg.Features.NFT_Analyitics ? <FcApproval size={20} /> : <FcCancel size={20} />}
                          </div>
                          <div className="flex justify-between">
                            <span className="text-xs uppercase font-medium  md:text:sm lg:text-sm flex items-center gap-4">
                              Liquidation Heatmap:
                            </span>
                            {pkg.Features.Liquidation_Heatmap ? <FcApproval size={20} /> : <FcCancel size={20} />}
                          </div>

                        </div>
                      </div>
                      // </div>
                    ))
                  : pack
                    .filter((pkg) => pkg.Name === selectedPackage.Name)
                    .map((pkg, index) => (
                      <div
                        key={index}
                        className="flex flex-col mb-6 p-6 border border-gray-200 rounded-lg shadow-lg bg-white"
                      >
                        <div className="flex justify-center items-center bg-purple-500 w-full py-4 px-6 rounded-lg shadow-lg">
                          <Radio.Group
                            onChange={onChange}
                            value={Wallet_type}
                            className="space-x-4"
                          >
                            <div className="flex flex-col gap-4 lg:block md:block sm:flex xl:block 2xl:block">
                              <Radio
                                value="Wallet_Balance"
                                className="bg-white py-2 px-1  rounded-full shadow-md hover:bg-gray-100 transition-colors duration-300"
                              >
                                <span className="font-medium ">
                                  WALLET BALANCE:
                                </span>
                                <span className="font-semibold text-neonPurple">
                                  {!wallet ? "0" : walletBalance?.toFixed(2)}
                                </span>

                                <span className="font-medium text-green-500">
                                  {" "}
                                  Points
                                </span>
                              </Radio>
                            </div>
                          </Radio.Group>
                        </div>

                        <div className="flex justify-between ">
                          <span className="text-xs md:text:sm lg:text-sm  font-medium flex items-center gap-4">
                            PACKAGE NAME:
                          </span>
                          {pkg.Name}
                        </div>
                        <div className="flex justify-between ">
                          <span className="text-xs  md:text:sm lg:text-sm font-medium  flex items-center gap-4">
                            PACKAGE VALUE:
                          </span>
                          {pkg.Price}
                        </div>

                        <div className="flex justify-between">
                          <span className="text-xs  md:text:sm lg:text-sm font-medium  flex items-center gap-4">
                            PAPER APIs:
                          </span>
                          {pkg.Paper_API}
                        </div>
                        <div className="flex justify-between">
                          <span className="text-xs  md:text:sm lg:text-sm font-medium  flex items-center gap-4">
                            LIVE APIs:
                          </span>
                          {pkg.Live_API}
                        </div>






                        <div className="flex justify-between">
                          <span className="text-xs  md:text:sm lg:text-sm font-medium  flex items-center gap-4">
                            NO OF BOTS ACTIVE:
                          </span>
                          {pkg.Live_Bots}
                        </div>

                        <div className="flex justify-between">
                          <span className="text-xs   md:text:sm lg:text-sm font-medium  flex items-center gap-4">
                            MAX BOT ALLOCATED FUND:
                          </span>
                          {pkg.Max_Fund_Allotted}
                        </div>
                        <div className="flex justify-between">
                          <span className="text-xs  md:text:sm  lg:text-sm font-medium  flex items-center gap-4">
                            PACKAGE VALIDITY:
                          </span>
                          {pkg.Validity / 7} {"Weeks"}
                        </div>

                        {/* // feature section below  */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2  md:grid-cols-2 gap-6">
                          <div>
                            <div className="flex justify-between">
                              <span className="text-xs font-medium  md:text:sm lg:text-sm  flex items-center gap-4">
                                ALGO FUTURE TRADING:
                              </span>
                              {pkg.Features.Algo_Future_Trading ? <FcApproval size={20} /> : <FcCancel size={20} />}
                            </div>
                            <div className="flex justify-between">
                              <span className="text-xs font-medium  md:text:sm lg:text-sm flex items-center gap-4">
                                ALGO SPOT TRADING:
                              </span>
                              {pkg.Features.Algo_Future_Trading ? <FcApproval size={20} /> : <FcCancel size={20} />}
                            </div>
                            {/* <div className="flex justify-between">
                            <span className="text-xs  md:text:sm  uppercase lg:text-sm font-medium  flex items-center gap-4">
                            Market Analysis Tools
                            </span>
                            {pkg.Features.Market_Analysis_Tools ? <FcApproval size={20}/> : <FcCancel size={20}/>}
                          </div> */}


                          </div>

                          <div>
                            <div className="flex justify-between">
                              <span className="text-xs font-medium   md:text:sm lg:text-sm flex items-center gap-4">
                                ARBITRAGE SCREENER SAME EXCHANGE:
                              </span>
                              {pkg.Features.Arbitrage_Same_Exchange
                                ? <FcApproval size={20} />
                                : <FcCancel size={20} />}
                            </div>

                            <div className="flex justify-between">
                              <span className="text-xs font-medium  md:text:sm lg:text-sm flex items-center gap-4">
                                ARBITRAGE SCREENER MULTI EXCHANGE:
                              </span>
                              {pkg.Features.Arbitrage_Multi_Exchange
                                ? <FcApproval size={20} />
                                : <FcCancel size={20} />}
                            </div>
                            <div className="flex justify-between">
                              <span className="text-xs font-medium  md:text:sm lg:text-sm flex items-center gap-4">
                                ARBITRAGE SCREENER DEX EXCHANGE:
                              </span>
                              {pkg.Features.Arbitrage_Dex_Exchange
                                ? <FcApproval size={20} />
                                : <FcCancel size={20} />}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>

              <div>
                <Popconfirm
                  title="Are you sure?"
                  description="Do you want to subscribe to this package?"
                  onConfirm={confirm}
                  onOpenChange={() => console.log("open change")}
                  okText={
                    <span className={` ${subsloading ? "bg-neonPurple text-white " : ""} `}>
                      {subsloading ? <CustomLoader /> : "Yes"}
                    </span>
                  }
                  cancelText="No"
                  okButtonProps={{ disabled: subsloading }} // Disable the button when loading is true
                >
                  <button
                    key="subscribe"
                    className="border  border-[#ccc] active font-bold text-white rounded-lg px-4 py-2 w-full md:w-1/2 block mx-auto"
                  >
                    Subscribe Now
                  </button>

                  {/* <div>
                    <button>No</button>
                    <span className="mb-5 p-0">
                        {isLoading ? <CustomLoader /> : "Login"}
                      </span>
                  </div> */}
                </Popconfirm>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default PackageView;