import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import img1 from "../assets/Api/BINANCEFUTURES.png";
import img2 from "../assets/Api/BINANCESPOT.png";
import img5 from "../assets/Api/BYBITFUTURES.png";
import img6 from "../assets/Api/BYBITSPOT.png";
import img3 from "../assets/Api/KUCOINFUTURES.png";
import img4 from "../assets/Api/KUCOINSPOT.png";
import img11 from "../assets/coins/BINANCEFUTURES.png";
import img21 from "../assets/coins/BINANCESPOT.png";
import img51 from "../assets/coins/BYBITFUTURES.png";
import img61 from "../assets/coins/BYBITSPOT.png";
import img31 from "../assets/coins/KUCOINFUTURES.png";
import img41 from "../assets/coins/KUCOINSPOT.png";

import StaticImg1 from "../assets/Static/bitfinex.png";
import StaticImg2 from "../assets/Static/bybit.png";
import StaticImg3 from "../assets/Static/crypto.png";
import StaticImg4 from "../assets/Static/gatejo.png";
import StaticImg5 from "../assets/Static/htx.png";
import StaticImg6 from "../assets/Static/kraken.png";
import StaticImg7 from "../assets/Static/kucoin.png";
import StaticImg8 from "../assets/Static/mexc.png";
import StaticImg9 from "../assets/Static/okx.png";
import StaticImg10 from "../assets/Static/binance.png";
import axios from "axios";
import { toast } from "react-toastify";
import TelegramModel from "./TelegramModel";
import SkeltorLoader from "./SkeltorLoader";
import { Activebotnew } from "./Activebotnew";
import { Tooltip } from "antd";
import ExchangeAPIModal from "./ExchangeApi Model";

import SwipeableViews from "react-swipeable-views";
import SwipeableAPI from "./SwipeableAPI";

function ExchangeItem({
  exchangeName,
  imgSrc,
  imgSrc2,
  onLiveClick,
  onPaperClick,
  currentStepIndex,
  profile,
}) {
  const Navigate = useNavigate();

  const [stepsData, setStepsData] = useState([
    { title: "Choose Exchange", completed: false },
    { title: "Setup Keys", completed: false },
    { title: "Connect", completed: false },
  ]);

  const handleStepClick = (stepIndex) => {
    const updatedStepsData = stepsData.map((step, index) => ({
      ...step,
      completed: index === 0,
    }));
    setStepsData(updatedStepsData);
  };

  const isSetupKeysStep = currentStepIndex === 1;

  return (
    <div className="grid grid-cols-6 w-full border-colorText border bg-colorPrimaryDark rounded-2xl shadow-[10px_10px_15px_-10px_rgba(0,0,0)]  ">
      <div className="col-span-4 mx-auto flex lg:py-6  py-8 px-2 rounded-tl-2xl rounded-bl-2xl ">
        <img src={imgSrc} alt="logo" className="rounded-2xl" />
      </div>
      <div className="col-span-2 bg-colorSecondaryDark flex justify-center items-center rounded-tr-2xl rounded-br-2xl">
        <button
          className={` text-base py-1 px-12  shadow-sm 2xl:px-8 md:px-7 lg:px-8 text-white rounded-2xl bg-colorSecondary hover:text-textColor hover:border-black shadow-white hover:bg-white hover:border-none ${
            isSetupKeysStep ? "checked" : ""
          }`}
          onClick={onLiveClick}
        >
          LIVE
        </button>
      </div>
    </div>
  );
}

function Exchange_pre() {

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(null);
  const [itemToNavigate, setItemToNavigate] = useState(null);
  const [exchangeType, setExchangeType] = useState(null)

  const handleNavigation = (item) => {
  
    if (selectedTab === "LIVE") {
      if (exchangeType === "FUTURES") {
        navigate("/dashboard/api_settings/Spotconnectiondetails", {
          state: {
            exchange: item?.exchange,
            exchangeType: item?.exchangeType1,
            Type: item?.TypeL,
          },
        });
      } else if (exchangeType === "SPOT") {
        navigate("/dashboard/api_settings/Spotconnectiondetails", {
          state: {
            exchange: item?.exchange,
            exchangeType: item?.exchangeType2,
            Type: item?.TypeL,
          },
        });
      }
    } else if (selectedTab === "PAPER") {
      if (exchangeType === "FUTURES") {
        navigate("/dashboard/api_settings/Paperfutureconnectiondetails", {
          state: {
            exchange: item?.exchange,
            exchangeType: item?.exchangeType1,
            Type: item?.TypeS,
          },
        });
      } else if (exchangeType === "SPOT") {
        navigate("/dashboard/api_settings/Paperfutureconnectiondetails", {
          state: {
            exchange: item?.exchange,
            exchangeType: item?.exchangeType2,
            Type: item?.TypeS,
          },
        });
      }
    } else {
      console.error("No tab selected");
    }
  };
  

// Effect to handle navigation after state update
useEffect(() => {
  if (selectedTab && itemToNavigate) {
    handleNavigation(itemToNavigate);
  }
}, [selectedTab, itemToNavigate]);

// Handle tab click
const handleTabClick = (tab, item, exchangeType) => {
  setSelectedTab(tab); // Set the selected tab
  setItemToNavigate(item); // Set the item to navigate
  setExchangeType(exchangeType); // Set the exchange type
};

console.log(selectedTab);
console.log(itemToNavigate);
console.log(exchangeType);




  const [stepsData, setStepsData] = useState([
    { title: "Choose Exchange", completed: false },
    { title: "Setup Keys", completed: false },
    { title: "Connect", completed: false },
  ]);
  const [exchangeData, setExchangeData] = useState([]);
  
  const [Packageview1, setPackageview1] = useState([]);
  const [profile, setProfile] = useState("");

  const showSpotConnectionDetails = () => {
    navigate("/dashboard/api_settings/Spotconnectiondetails");
  };

  const [loading, setLoading] = useState(true);

  const View_Profile = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_USER + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setProfile(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
        if (err?.response?.data?.Error === "JWT Expired") {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/";
          }, 5000);
        }
      });
  };

  useEffect(() => {
    View_Profile();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRadio, setIsModalOpenRadio] = useState(false);

  const showModal1 = () => {
    setIsModalOpen(true);
  };
  // const handleOk1 = () => {
  //   setIsModalOpen(false);
  // };
  const handleCancel1 = () => {
    setIsModalOpen(false);
  };

  const handleCancelRadio = () => {
    setIsModalOpenRadio(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const jwt = localStorage.getItem("jwt");
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_PATH_USER_NEW + "exchange",
          { jwt: jwt },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = response?.data?.Success || {};

        const images = [
          { name: "BINANCEFUTURES", img: img1, imgnew: img11 },
          { name: "BINANCESPOT", img: img2, imgnew: img21 },
          { name: "KUCOINSPOT", img: img4, imgnew: img31 },
          { name: "KUCOINFUTURES", img: img3, imgnew: img41 },
          { name: "BYBITFUTURES", img: img5, imgnew: img51 },
          { name: "BYBITSPOT", img: img6, imgnew: img61 },
        ];

        const filteredImages = images?.filter((image) => {
          const exchangeName = image.name.split(/(SPOT|FUTURES)/)[0];
          const exchangeTypes = responseData[exchangeName] || [];
          return (
            exchangeTypes.includes("SPOT") && exchangeTypes.includes("FUTURES")
          );
        });


        setExchangeData(filteredImages);
        setLoading(false);
      } catch (error) {
        toast.error(error?.response?.data?.Error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleStepClick = (stepIndex, imgSrc) => {
    const updatedStepsData = stepsData.map((step, index) => ({
      ...step,
      completed: index === stepIndex,
    }));
    setStepsData(updatedStepsData);

    navigate("/dashboard/api_settings/Spotconnectiondetails", {
      state: { status: true, imgSrc },
    });
  };

  const images = [
    { name: "BINANCEFUTURES", img: img1, imgnew: img11 },
    { name: "BINANCESPOT", img: img2, imgnew: img21 },
    { name: "KUCOINSPOT", img: img4, imgnew: img31 },
    { name: "KUCOINFUTURES", img: img3, imgnew: img41 },
    { name: "BYBITFUTURES", img: img5, imgnew: img51 },
    { name: "BYBITSPOT", img: img6, imgnew: img61 },
  ];

  const targetNames =
    "BINANCEFUTURES,BINANCESPOT,KUCOINSPOT,KUCOINFUTURES,BYBITFUTURES,BYBITSPOT";
  const targetNamesArray = targetNames.split(",");

  const filteredImages1 = images
    .filter((item) => targetNamesArray.includes(item.name))
    .map((item) => item.imgnew);

  const handleExchangeItemClick = (
    exchangeName,
    filteredImages,
    selectedImage,
    filteredImages1,
    destinationImageName
  ) => {
    const { name, img, imgnew } = selectedImage;
    const [exchange, type] = exchangeName.split(/(SPOT|FUTURES)/);
    const types = type || "";
    if (!profile || !profile.Telegram) {
      setIsModalOpen(true);
      return;
    }

    if (Packageview1.Live_API <= 0) {
      toast.error("LIVE API limit reached. You cannot add more.");
      return;
    }
    navigate(`/dashboard/api_settings/Spotconnectiondetails`, {
      state: {
        status: true,
        imgSrc: img,
        filteredImages: filteredImages,
        exchangeName: exchange,
        exchangeTypes: types,
        filteredImages1: filteredImages1,
        [destinationImageName]: imgnew,
        exchangeData: exchangeData,
      },
    });
  };

  const handleExchangeItemClick1 = (
    exchangeName,
    filteredImages,
    img,
    images,
    imgSrc2
  ) => {
    const [exchange, type] = exchangeName.split(/(SPOT|FUTURES)/);
    const types = type || "";

    if (!profile || !profile.TelegramID) {
      setIsModalOpen(true);
      return;
    }

    navigate(`/dashboard/api_settings/Paperfutureconnectiondetails`, {
      state: {
        status: true,
        imgSrc: img,
        images,
        filteredImages,
        exchangeName: exchange,
        exchangeTypes: types,
        imgSrc2: images,
        exchangeData: exchangeData,
      },
    });
  };

  const futureData = [
    // { Img: StaticImg1 },
    // { Img: StaticImg2 },
    // { Img: StaticImg3 },
    // { Img: StaticImg4 },
    // { Img: StaticImg5 },
    // { Img: StaticImg6 },
    // { Img: StaticImg7 },
    // { Img: StaticImg8 },
    // { Img: StaticImg9 },
    {
      Img: StaticImg10,
      exchange: "BINANCE",
      exchangeType1: "FUTURES",
      exchangeType2: "SPOT",
      TypeL: "LIVE",
      TypeS: "PAPER",
    },
    // {
    //   Img: StaticImg7,
    //   exchange: "KUCOIN",
    //   exchangeType1: "FUTURES",
    //   exchangeType2: "SPOT",
    //   TypeL: "LIVE",
    //   TypeS: "PAPER",
    // },
   
  ];


  const spotData = [
    {
         Img: StaticImg7,
         exchange: "KUCOIN",
        exchangeType1: "FUTURES",
         exchangeType2: "SPOT",
         TypeL: "LIVE",
         TypeS: "PAPER",
      },
  ]



 
  const handleOk1 = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=" min-h-[600px] h-auto flex gap-20 flex-col">
      {/* asd */}
      <div className=" md:flex gap-2 w-full">
      <div className="w-full md:w-6/12 border border-solid border-r-black border-l-0 border-t-0 border-b-0 pr-2">
  <div className="active uppercase text-white text-xl py-1 mb-4 text-center rounded-lg">
    Future
  </div>
  <div className=" w-full border-2 border-black gap-4">
    {futureData.reverse().map((item, index) => (
      <div
        key={index}
        className="transition-transform duration-500 w-full p-2 flex justify-center items-center rounded-lg"
      >
        <div className="w-full flex justify-between border-2 p-2">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleTabClick("LIVE", item, item?.exchangeType1)}
          >
            <img
              src={item?.Img}
              className="w-20"
              alt="Future"
            />
            <h1>LIVE</h1>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleTabClick("PAPER", item, item?.exchangeType1)}
          >
            <img
              src={item?.Img}
              className="w-20"
              alt="Future"
            />
            <h1>PAPER</h1>
          </div>
        </div>
        {index !== 0 && (
          <Tooltip title="Coming soon...">
            <a>
              <img
                src={item?.Img}
                className="w-20"
                alt="Future"
              />
            </a>
          </Tooltip>
        )}
      </div>
    ))}
  </div>
</div>

<div className="w-full md:w-6/12 border border-solid border-r-black border-l-0 border-t-0 border-b-0 pr-2">
  <div className="active uppercase text-white text-xl py-1 mb-4 text-center rounded-lg">
    Spot
  </div>
  <div className=" w-full border-2 border-black gap-4">
    {futureData.reverse().map((item, index) => (
      <div
        key={index}
        className="transition-transform duration-500 w-full p-2 flex justify-center items-center rounded-lg"
      >
        <div className="w-full flex justify-between border-2 p-2">



          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleTabClick("LIVE", item, item?.exchangeType2)}
          >
            <img
              src={item?.Img}
              className="w-20"
              alt="Future"
            />
            <h1>LIVE</h1>
          </div>
          


          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleTabClick("PAPER", item, item?.exchangeType2)}
          >
            <img
              src={item?.Img}
              className="w-20"
              alt="Future"
            />
            <h1>PAPER</h1>
          </div>
        </div>








        
        {index !== 0 && (
          <Tooltip title="Coming soon...">
            <a>
              <img
                src={item?.Img}
                className="w-20"
                alt="Future"
              />
            </a>
          </Tooltip>
        )}
      </div>
    ))}
  </div>

{/* // new code  */}
  <div className=" w-full border-2 mt-[10px] border-black gap-4">
    {spotData.reverse().map((item, index) => (
      <div
        key={index}
        className="transition-transform duration-500 w-full p-2 flex justify-center items-center rounded-lg"
      >
        <div className="w-full flex justify-between border-2 p-2">



          <div
            className="flex flex-col items-center cursor-pointer"
            // onClick={() => handleTabClick("LIVE", item, item?.exchangeType2)}
          >

<div className="flex gap-2">
            <img
              src={item?.Img}
              className="w-20"
              alt="Future"
            />
            <h6 className="text-[10px]" >(Coming Soon)</h6>

</div>

            <h1 className="">LIVE</h1>
          </div>
          


          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleTabClick("PAPER", item, item?.exchangeType2)}
          >
            <img
              src={item?.Img}
              className="w-20"
              alt="Future"
            />
            <h1>PAPER</h1>
          </div>
        </div>








        
        {index !== 0 && (
          <Tooltip title="Coming soon...">
            <a>
              <img
                src={item?.Img}
                className="w-20"
                alt="Future"
              />
            </a>
          </Tooltip>
        )}
      </div>
    ))}
  </div>
{/* //  */}

</div>

      </div>

      {/* <Modal
      title="Choose Connection Type"
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <Radio.Group onChange={onRadioChange} value={selectedOption}>
        <Radio value="LIVE">LIVE</Radio>
        <Radio value="PAPER">PAPER</Radio>
      </Radio.Group>
      <div style={{ marginTop: 20, textAlign: "right" }}>
        <Button type="primary" onClick={onNextClick}>
          Next
        </Button>
      </div>
    </Modal> */}

      {isModalOpen && (
        <TelegramModel
          isModalOpen={isModalOpen}
          showModal1={showModal1}
          handleOk1={handleOk1}
          handleCancel1={handleCancel1}
        />
      )}




      {/* <div className="">
        <Activebotnew />
      </div> */}
      

      <div>
        <SwipeableAPI/>
      </div>


    </div>
  );
}

export default Exchange_pre;
