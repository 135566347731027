import React, { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import axios from "axios";
import { FcCancel, FcOk } from "react-icons/fc";
import { FaGlobe, FaReddit, FaTelegram } from "react-icons/fa";
import { FaMedium, FaSquareXTwitter, FaSquareGithub } from "react-icons/fa6";
import { Empty, Select, Spin, Table, Tooltip } from "antd";
import DrawerTooltip from "./DrawerTooltip";
import { PiInfo } from "react-icons/pi";
import DrawerComponent from "./DrawerComponent";
import {
  tooltips,
  tooltips1,
  tooltips2,
  tooltips3,
  tooltips4,
} from "./tooltipDetails";
import { MdOutlineFacebook } from "react-icons/md";
import Apisame from "./Apisame";
import UpdateApi from "./UpdateApi";

const { Option } = Select;

const ContractAddress = () => {
  const [searchValue, setSearchValue] = useState(
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
  );
  const [searchResult, setSearchResult] = useState("");
  const [searchData, setsearchData] = useState([]);
  const [chainValue, setchainValue] = useState("bsc");
  const [error, setError] = useState(null);
  const [searchResultApproval, setSearchResultApproval] = useState("");
  const [chainId, setChainId] = useState("56");
  const [loading, setLoading] = useState(false);
  const [drawerState, setDrawerState] = useState({
    visible: false,
    tooltipTexts: [],
    activeIndex: -1,
    activetabtitle: "",
  });
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.Blockchain_Analytics;

  const handleSelectChange = (value, label) => {
    setChainId(value);
    setchainValue(label.tolowerCase());
  };

  const handleSearch = () => {
    setLoading(true);
    axios
      .get(
        `https://api.gopluslabs.io/api/v1/token_security/${chainId}?contract_addresses=${searchValue}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      )
      .then((response) => {
        setSearchResult(response.data.result);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSearchSecurityApproval = () => {
    axios
      .get(
        `https://api.gopluslabs.io/api/v1/approval_security/${chainId}?contract_addresses=${searchValue}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      )
      .then((response) => {
        setSearchResultApproval(response.data.result);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleSearchData = () => {
    setLoading(true);
    axios
      .get(`https://deep-index.moralis.io/api/v2.2/erc20/metadata`, {
        headers: {
          Accept: "*/*",
          "X-API-Key":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MjI2MTZmLWM4MzItNDJmZS04YzI1LTA4NGZiNTAyY2ZmOCIsIm9yZ0lkIjoiMzcwMjAxIiwidXNlcklkIjoiMzgwNDY5IiwidHlwZUlkIjoiYTM5MmYyMTItZGQ1OC00NjY2LWEyMGMtN2E2MDdlZGU3YmZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDM5MzEwMjMsImV4cCI6NDg1OTY5MTAyM30.1ik9oveoExEKuAyXdSIWTKWo3i22Yp59_37ZDIJl8ew",
        },
        params: {
          chain: chainValue,
          addresses: searchValue,
        },
      })
      .then((response) => {
        setsearchData(response.data);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderLinkWithIcon = (platform, IconComponent, url) =>
    url ? (
      <p key={platform} className="">
        <a href={url} target="_blank">
          <IconComponent className="mr-2 text-white" size={20} />
        </a>
      </p>
    ) : null;

  useEffect(() => {
    if (checkTradePackage === true) {
      handleSearch();
      handleSearchSecurityApproval();
      handleSearchData();
    }
  }, [checkTradePackage, searchValue, chainId]);

  const columns = [
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => (
        <Tooltip title={`${address}`}>
          <span>{`${address.slice(0, 4)}...${address.slice(-4)}`}</span>
        </Tooltip>
      ),
    },
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
    },
    {
      title: (
        <Tooltip title="Is Contract">
          <div>IC</div>
        </Tooltip>
      ),
      dataIndex: "is_contract",
      key: "is_contract",
      render: (value) => (value === 1 ? <FcOk /> : <FcCancel />),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => <span>$ {parseFloat(balance).toFixed(4)}</span>,
    },
    {
      title: "Percent",
      dataIndex: "percent",
      key: "percent",
      render: (percent) => <span>{parseFloat(percent).toFixed(4)} %</span>,
    },
    {
      title: (
        <Tooltip title="Is Locked">
          <div>IL</div>
        </Tooltip>
      ),
      dataIndex: "is_locked",
      key: "is_locked",
      render: (value) => (value === 1 ? <FcOk /> : <FcCancel />),
    },
  ];

  const columnsdex = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: (
        <Tooltip title="Liquidity Type">
          <div>Type</div>
        </Tooltip>
      ),
      dataIndex: "liquidity_type",
      key: "liquidity_type",
    },
    {
      title: (
        <Tooltip title="Is Contract">
          <div>Liquidity</div>
        </Tooltip>
      ),
      dataIndex: "liquidity",
      key: "liquidity",
    },
    {
      title: "Pair",
      dataIndex: "pair",
      key: "pair",
      render: (pair) => (
        <Tooltip title={`${pair}`}>
          <span>{`${pair.slice(0, 4)}...${pair.slice(-4)}`}</span>
        </Tooltip>
      ),
    },
  ];

  const FeatureItem = ({ label, value }) => (
    <p className="flex justify-between text-sm items-center gap-5">
      {label}: {value === "1" ? <FcOk /> : <FcCancel />}
    </p>
  );

  const FeatureItem1 = ({ label, value }) => (
    <p className="flex justify-between text-sm items-center gap-5">
      {label}: {value === 1 ? <FcOk /> : <FcCancel />}
    </p>
  );
  const FeatureItem2 = ({ label, value }) => (
    <p className="flex justify-between text-sm items-center gap-5">
      {label}: {value === true ? <FcOk /> : <FcCancel />}
    </p>
  );


  const StatItem = ({ label, value }) => (
    <p className="flex justify-between text-sm items-center">
      {label}: <p>{value || "N/A"}</p>
    </p>
  );

  const chainOptions = [
    { value: "1", label: "Ethereum" },
    { value: "10", label: "Optimism" },
    { value: "25", label: "Cronos" },
    { value: "56", label: "BSC" },
    { value: "66", label: "OKC" },
    { value: "100", label: "Gnosis" },
    { value: "128", label: "HECO" },
    { value: "137", label: "Polygon" },
    { value: "250", label: "Fantom" },
    { value: "321", label: "KCC" },
    { value: "324", label: "zkSync Era" },
    { value: "10001", label: "ETHW" },
    { value: "201022", label: "FON" },
    { value: "42161", label: "Arbitrum" },
    { value: "43114", label: "Avalanche" },
    { value: "59144", label: "Linea Mainnet" },
    { value: "8453", label: "Base" },
    { value: "tron", label: "Tron" },
    { value: "534352", label: "Scroll" },
    { value: "204", label: "opBNB" },
  ];

  const toggleDrawer = (tooltipTexts, title) => {
    setDrawerState({
      visible: !drawerState.visible,
      tooltipTexts: tooltipTexts,
      activeIndex: -1,
      activetabtitle: title,
    });
  };

  if (!searchData || searchData.length === 0) {
    return <div className="flex justify-center items-center h-full mt-3">
      <Spin size="large" />
    </div>;
  }

  const formatNumber = (number) => {
    if (typeof number !== "number") {
      return number;
    }
    return number.toLocaleString(undefined, { maximumFractionDigits: 2 });
  };

  return (
    <>
      <div className=" flex flex-col md:flex-row items-center justify-start gap-6">
        <div>
          <Select
            placeholder="Select Chain ID"
            style={{ width: 200 }}
            onChange={handleSelectChange}
            value={chainId}
            defaultValue="56"
          >
            {chainOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="relative w-full md:w-1/2">
          <input
            type="text"
            placeholder="Contract Address..."
            className="border border-gray-300 rounded-md py-2 px-4 w-full"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <button
            className="absolute right-0 top-0 mt-2 mr-4"
            onClick={() => {
              handleSearch();
              handleSearchSecurityApproval();
              handleSearchData();
            }}
          >
            <IoMdSearch className="h-6 w-6 text-gray-500" />
          </button>
        </div>
      </div>

      <Spin spinning={loading} className="flex items-center">
        {searchResult && Object.keys(searchResult).length > 0 ? (
          <>
            <div className="grid gap-5 mt-5">
              {Object.keys(searchResult).map((contractAddress) => (
                <div
                  key={contractAddress}
                  className="grid grid-cols-1 lg:grid-cols-3 gap-2"
                >
                  <div className="h-auto border border-[#ccc] bg-white rounded-lg pb-2">
                    <div className="">
                      <div className="flex justify-between pb-2  items-center gap-1">
                        <div className="flex  items-center gap-1 px-2 ">

                          <img src={searchData[0].logo} className="w-[30px] " />
                          {/* <div> */}
                          <div className="flex flex-col ">
                            {/* <div className="flex items-center gap-1"> */}
                            <p className="font-semibold text-lg ">
                              {" "}
                              {searchResult[contractAddress].token_name}<span className="font-normal text-xs ">{searchResult[contractAddress].token_symbol}</span>
                            </p>

                            {/* </div> */}
                            {/* <div className="flex justify-start"> */}
                            <p className="font-normal text-start text-[8px]">
                              {searchData[0].categories.length > 0 && searchData[0].categories.map((category, index) => (
                                <span key={index} className="bg-neonPurple/20 px-1 w-auto border rounded-xl border-[#ccc] mr-1">
                                  {category}
                                </span>
                              ))}
                            </p>


                            {/* </div> */}
                          </div>
                        </div>
                        {/* </div> */}
                        {/* <div className="flex justify-between items-center gap-[8px]"> */}
                        <div className="flex justify-center items-center rounded-tr-lg rounded-bl-lg py-1 px-2 bg-neonBlue ">
                          {renderLinkWithIcon(
                            "website",
                            FaGlobe,
                            searchData[0].links.website
                          )}
                          {renderLinkWithIcon(
                            "facebook",
                            MdOutlineFacebook,
                            searchData[0].links.facebook
                          )}
                          {renderLinkWithIcon(
                            "reddit",
                            FaReddit,
                            searchData[0].links.reddit
                          )}
                          {renderLinkWithIcon(
                            "medium",
                            FaMedium,
                            searchData[0].links.medium
                          )}
                          {renderLinkWithIcon(
                            "twitter",
                            FaSquareXTwitter,
                            searchData[0].links.twitter
                          )}
                          {renderLinkWithIcon(
                            "github",
                            FaSquareGithub,
                            searchData[0].links.github
                          )}

                          {renderLinkWithIcon(
                            "telegram",
                            FaTelegram,
                            searchData[0].links.telegram
                          )}
                          {/* Add more social media links here */}
                        </div>
                        {/* </div> */}

                      </div>
                      <div className="mx-2">
                        <p className="flex justify-between items-center text-sm">
                          Total Supply:{" "}
                          <span>
                            {searchResult[contractAddress]?.total_supply
                              ? parseFloat(searchResult[contractAddress]?.total_supply).toLocaleString()
                              : "0"}
                          </span>
                        </p>

                        <StatItem
                          label="Deployed Time"
                          value={new Date(
                            searchResultApproval?.deployed_time * 1000
                          ).toLocaleString()}
                        />

                        <hr className="my-2" />
                        <div className="grid grid-cols-2 gap-2">
                          <div className="text-sm border-r border-[#ccc]">
                            <h2 className="font-semibold text-left mb-2">CREATOR</h2>
                            <p className="flex justify-between items-center">
                              Address:{" "}
                              <Tooltip
                                title={
                                  searchResult[contractAddress].creator_address
                                }
                              >{`${searchResult[
                                contractAddress
                              ].creator_address.slice(0, 4)}...${searchResult[
                                contractAddress
                              ].creator_address.slice(-4)}`}</Tooltip>
                            </p>
                            <p className="flex justify-between items-center">
                              {" "}
                              Balance:{" "}
                              <span>{searchResult[contractAddress].creator_balance}</span>
                            </p>
                            <p className="flex justify-between items-center">
                              {" "}
                              Percent:{" "}
                              <span>  {searchResult[contractAddress].creator_percent}</span>
                            </p>
                          </div>
                          <div className="">
                            <h2 className="font-semibold text-left mb-2">OWNER</h2>
                            <div className="text-sm">
                              <p className="flex justify-between items-center">
                                {" "}
                                Address:{" "}
                                <Tooltip
                                  title={searchResult[contractAddress].owner_address}
                                >{`${searchResult[
                                  contractAddress
                                ].owner_address.slice(0, 4)}...${searchResult[
                                  contractAddress
                                ].owner_address.slice(-4)}`}</Tooltip>
                              </p>
                              <p className="flex justify-between items-center">
                                {" "}
                                Balance:{" "}
                                <span> {searchResult[contractAddress].owner_balance}</span>
                              </p>
                              <p className="flex justify-between items-center">
                                {" "}
                                Percent:{" "}
                                <span>{searchResult[contractAddress].owner_percent}</span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <hr className="my-2" />

                      </div>



                      {searchData.map((token) => (
                        <div key={token.address}>
                          <div className="mx-2">
                            <div className=" justify-between items-center">
                              <p className=" text-sm flex justify-between items-center">
                                {" "}
                                Decimals: <span> {token.decimals}</span>
                              </p>

                              <p className="text-sm flex justify-between items-center">
                                {" "}
                                Block Number:  <span> {token.block_number}</span>
                              </p>
                            </div>

                            <p className="text-sm flex justify-between items-center">
                              {" "}
                              Diluted valuation:{" "} <span>
                                {token.fully_diluted_valuation}</span>
                            </p>

                            <p className="text-sm flex justify-between items-center">
                              {" "}
                              Validated:  <span>{token.validated}</span>
                            </p>

                            <p className="font-semibold text-lg">
                              {token.verified_contract}
                            </p>


                            <FeatureItem2
                              label="Verified Contract"
                              value={token.verified_contract}
                            />

                            {/* //links  */}

                            {/* // */}

                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex gap-1 flex-col ">
                    <div className="h-auto border text-xs border-[#ccc] bg-white rounded-lg px-4 py-1">
                      {/* <div onClick={() => toggleDrawer(tooltips1)} className='flex justify-end items-center'>
                                                <PiInfo
                                                    size={15}
                                                    className="cursor-pointer hover:scale-110 transition-all transition-5s"
                                                />
                                            </div> */}
                      <StatItem
                        label="Buy Tax"
                        value={searchResult[contractAddress].buy_tax}
                      />
                      <StatItem
                        label="LP Total Supply"
                        value={parseFloat(
                          searchResult[contractAddress].lp_total_supply
                        ).toFixed(4)}
                      />
                      <StatItem
                        label="Holder Count"
                        value={searchResult[contractAddress].holder_count}
                      />
                    </div>
                    <div className="h-auto text-xs border border-[#ccc] bg-white rounded-lg px-4 py-2">
                      <div
                        onClick={() => toggleDrawer(tooltips2)}
                        className="flex justify-end items-center"
                      >
                        <PiInfo
                          size={15}
                          className="cursor-pointer hover:scale-110 transition-all transition-5s"
                        />
                      </div>

                      <FeatureItem
                        label="Can Take Back Ownership"
                        value={
                          searchResult[contractAddress].can_take_back_ownership
                        }
                      />
                      <FeatureItem
                        label="Can't Buy"
                        value={searchResult[contractAddress].cannot_buy}
                      />
                      <FeatureItem
                        label="Anti Whale Modifiable"
                        value={
                          searchResult[contractAddress].anti_whale_modifiable
                        }
                      />
                      <FeatureItem
                        label="Can't Sell All"
                        value={searchResult[contractAddress].cannot_sell_all}
                      />
                      <FeatureItem
                        label="External Call"
                        value={searchResult[contractAddress].external_call}
                      />
                      <FeatureItem
                        label="Hidden Owner"
                        value={searchResult[contractAddress].hidden_owner}
                      />
                      <FeatureItem
                        label="Personal Slippage Modifiable"
                        value={
                          searchResult[contractAddress]
                            .personal_slippage_modifiable
                        }
                      />
                      <FeatureItem
                        label="Self Destruct"
                        value={searchResult[contractAddress].selfdestruct}
                      />
                      <FeatureItem
                        label="Owner Change Balance"
                        value={
                          searchResult[contractAddress].owner_change_balance
                        }
                      />
                      <FeatureItem
                        label="Trading Cooldown"
                        value={searchResult[contractAddress].trading_cooldown}
                      />
                    </div>
                  </div>

                  <div className="flex gap-1 flex-col ">
                    <div className="h-auto text-xs border border-[#ccc] bg-white rounded-lg px-4 py-1">
                      {/* <div onClick={() => toggleDrawer(tooltips3)} className='flex justify-end items-center'>
                                                <PiInfo
                                                    size={15}
                                                    className="cursor-pointer hover:scale-110 transition-all transition-5s"
                                                />
                                            </div> */}
                      <StatItem
                        label="Sell Tax"
                        value={searchResult[contractAddress].sell_tax}
                      />
                      <StatItem
                        label="LP Holder Count"
                        value={searchResult[contractAddress].lp_holder_count}
                      />
                      <StatItem
                        label="Honeypot with same Creator"
                        value={
                          searchResult[contractAddress]
                            .honeypot_with_same_creator
                        }
                      />
                    </div>
                    <div className="h-auto text-xs border border-[#ccc] bg-white rounded-lg px-4 py-2">
                      {/* <div onClick={() => toggleDrawer(tooltips4)} className='flex justify-end items-center'>
                                                <PiInfo
                                                    size={15}
                                                    className="cursor-pointer hover:scale-110 transition-all transition-5s"
                                                />
                                            </div> */}
                      <FeatureItem
                        label="Anti Whale"
                        value={searchResult[contractAddress].is_anti_whale}
                      />
                      <FeatureItem
                        label="Blacklisted"
                        value={searchResult[contractAddress].is_blacklisted}
                      />
                      <FeatureItem
                        label="Honeypot"
                        value={searchResult[contractAddress].is_honeypot}
                      />
                      <FeatureItem
                        label="In Dex"
                        value={searchResult[contractAddress].is_in_dex}
                      />
                      <FeatureItem
                        label="Mintable"
                        value={searchResult[contractAddress].is_mintable}
                      />
                      <FeatureItem
                        label="Open Source"
                        value={searchResult[contractAddress].is_open_source}
                      />
                      <FeatureItem
                        label="Proxy"
                        value={searchResult[contractAddress].is_proxy}
                      />
                      <FeatureItem
                        label="Whitelisted"
                        value={searchResult[contractAddress].is_whitelisted}
                      />
                      <FeatureItem
                        label="Transfer Pausable"
                        value={searchResult[contractAddress].transfer_pausable}
                      />
                      <FeatureItem
                        label="Slippage Modifiable"
                        value={
                          searchResult[contractAddress].slippage_modifiable
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* // */}

            {/* // */}
            <div className="grid gap-5 mt-5">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                {Object.keys(searchResult).map((contractAddress) => (
                  <div
                    key={contractAddress}
                    className="col-span-1 h-auto border border-[#ccc] bg-white rounded-lg px-4 py-2"
                  >
                    <div className="mb-2">
                      {/* <div className='flex justify-between items-center my-1'> */}
                      <h3 className="text-center font-semibold text-sm">Dex</h3>
                      {/* <div onClick={() => toggleDrawer(tooltips)} className=''>
                                                    <PiInfo
                                                        size={15}
                                                        className="cursor-pointer hover:scale-110 transition-all transition-5s"
                                                    />
                                                </div> */}
                      {/* </div> */}
                      <div className=" h-[150px] overflow-y-auto">
                        <Table
                          columns={columnsdex}
                          pagination={false}
                          dataSource={searchResult[contractAddress].dex}
                        />
                      </div>
                    </div>
                  </div>
                ))}

                {searchResultApproval && (
                  <>
                    <div className="col-span-2  border border-[#ccc] bg-white rounded-lg px-4 py-2">
                      {/* <div className='flex justify-between items-center my-1'> */}
                      <h1 className="text-center my-2 font-semibold">
                        Approval Security Information
                      </h1>
                      {/* <div onClick={() => toggleDrawer(tooltips)} className='flex justify-end items-center'>
                                                    <PiInfo
                                                        size={15}
                                                        className="cursor-pointer hover:scale-110 transition-all transition-5s"
                                                    />
                                                </div> */}
                      {/* </div> */}
                      <div className="grid grid-cols-2 gap-4 w-full">
                        <div>
                          <StatItem
                            label="Tag"
                            value={searchResultApproval?.tag}
                          />
                          <FeatureItem1
                            label="Contract"
                            value={searchResultApproval?.is_contract}
                          />
                          <FeatureItem1
                            label="Open Source"
                            value={searchResultApproval?.is_open_source}
                          />
                          <FeatureItem1
                            label="Trust List"
                            value={searchResultApproval?.trust_list}
                          />
                          <FeatureItem1
                            label="Doubt List"
                            value={searchResultApproval?.doubt_list}
                          />
                          <FeatureItem1
                            label="Privilege Withdraw"
                            value={
                              searchResultApproval?.contract_scan
                                .privilege_withdraw
                            }
                          />
                        </div>
                        <div>
                          <FeatureItem1
                            label="Withdraw Missing"
                            value={
                              searchResultApproval?.contract_scan
                                .withdraw_missing
                            }
                          />
                          <FeatureItem1
                            label="Blacklist"
                            value={
                              searchResultApproval?.contract_scan.blacklist
                            }
                          />
                          <FeatureItem1
                            label="Selfdestruct"
                            value={
                              searchResultApproval?.contract_scan.selfdestruct
                            }
                          />
                          <FeatureItem1
                            label="Approval Abuse"
                            value={
                              searchResultApproval?.contract_scan.approval_abuse
                            }
                          />
                          <FeatureItem1
                            label="Risky Approval Risk"
                            value={searchResultApproval?.risky_approval.risk}
                          />
                          <FeatureItem1
                            label="Risky Approval Value"
                            value={searchResultApproval?.risky_approval.value}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="grid gap-5 mt-5">
              {Object.keys(searchResult).map((contractAddress) => (
                <div
                  key={contractAddress}
                  className="grid grid-cols-1  lg:grid-cols-2 gap-5"
                >
                  <div className="h-auto border border-[#ccc] bg-white rounded-lg px-4 py-2">
                    {/* <div className='flex justify-between items-center my-1'> */}
                    <h3 className="text-center font-semibold text-sm">
                      Holders
                    </h3>
                    {/* <div onClick={() => toggleDrawer(tooltips)} className='flex justify-end items-center'>
                                                <PiInfo
                                                    size={15}
                                                    className="cursor-pointer hover:scale-110 transition-all transition-5s"
                                                />
                                            </div> */}
                    {/* </div> */}
                    <Table
                      columns={columns}
                      pagination={false}
                      dataSource={searchResult[contractAddress].holders}
                    />
                  </div>

                  <div className="h-auto border border-[#ccc] bg-white rounded-lg px-4 py-2">
                    {/* <div className='flex justify-between items-center my-1'> */}
                    <h3 className="text-center font-semibold text-sm">
                      LP Holders
                    </h3>
                    {/* <div onClick={() => toggleDrawer(tooltips)} className='flex justify-end items-center'>
                          <PiInfo
                              size={15}
                              className="cursor-pointer hover:scale-110 transition-all transition-5s"
                          />
                      </div> */}
                    {/* </div> */}
                    <Table
                      columns={columns}
                      pagination={false}
                      dataSource={searchResult[contractAddress].lp_holders}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="flex  items-center justify-center my-20 w-full h-full">
            <Empty description="No data" />
          </div>
        )}
      </Spin>

      <DrawerComponent toggleDrawer={toggleDrawer} drawerState={drawerState} />
    </>
  );
};

export default ContractAddress;