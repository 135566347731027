import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button,  Typography, Row, Col, message } from "antd";
import axios from "axios";
import Lottie from "lottie-react";
import ant from "../Lotties.json";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";

const { Title } = Typography;

const EmailVerify = () => {

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
 
  const [input, setInput] = useState({ otp: "" });
  const param = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailid = searchParams.get("email");
  const username = searchParams.get("username");
  const connectloctState = location.state;
  const [connect, setConnect] = useState(connectloctState);


  const handleSubmit = () => {
    const postdata = {
      ...connect,
      otp : otp,

    };
   
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "register",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response;
        if (res) {
          toast(res.data.Success);
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.Error);
      });
  };

  const ResetOtp = async () => {

    const formData = new FormData();

      formData.append("project", process.env.REACT_APP_PROJECT);
    formData.append("username", username);
    formData.append("email", emailid);
    formData.append("page", "Email Verification");
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "get_otp",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          toast(res?.Success);
        
        } else {
          toast.error(res?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

   const handleOtpChange = async (otp) => {
    setOtp(otp);
  };
  
  useEffect(() => {
    if (otp.length === 6) {
      handleSubmit();
    }
  }, [otp]);
  

const handleVerify = async () => {
  if (otp.length === 6) {
    await handleSubmit(otp);
  } else {
    console.log("OTP length is not complete");
  }
}


  return (
    <>
      <div className="grid  ">
        <div className="login-register  mx-4  align-items-center">
          <div className="container111 md:bg-colorPrimaryDark  w-full md:rounded-2xl  py-2 px-10 text-black">
            <div className="text-center">
              <h1 className="text-2xl font-semibold text-colorSecondaryDark">
                {" "}
                Check Your Email
              </h1>
              <div className="block md:hidden text-sm text-white my-2">
                <p className="text-2xl font-semibold my-2 text-white">
                  {" "}
                  Check Your Email
                </p>
                <p>
                  We have sent a registration email to&nbsp;
                  <span classname="underline font-bold">
                    {emailid ? emailid : "Sample@gmail.com"},
                  </span>{" "}
                  which you should receive in few minutes. Kindly enter the OTP
                  recieved over the email here..{" "}
                </p>
              </div>

              <div className="flex justify-center">
                <Lottie
                  alignItems="center"
                  style={{ width: "50%" }}
                  animationData={ant}
                />
              </div>
              <p className="hidden md:block">
                We have sent a registration email to{" "}
                {emailid ? emailid : "Sample@gmail.com"}, which you should receive
                in few minutes.<br></br> Kindly enter the OTP recieved over the
                email here..{" "}
              </p>
              <Typography
                className="text-center text-white my-2 md:text-black"
                label={3}
              >
                Enter OTP
              </Typography>
              <div className="flex justify-center">
                <form>
                  <OTPInput
                    value={otp}
                    onChange={handleOtpChange} 
                    numInputs={6}
                    separator={<span></span>}
                    inputStyle={{
                      border: "2px solid #ccc",
                      borderRadius: "5px",
                      padding: "5px 0px",
                      margin: "0px 4px",
                      color: "#000",
                      width: "65px",
                    }}
                    renderInput={(props) => <input {...props} />}
                  />

                  {/* <OTPInput
                    value={otp}
                    type="number"
                    // name="otp"
                    onChange={handleOtpChange}
                    numInputs={6}
                    className="border border-[#ccc] w-full my-5"
                    renderSeparator={<span></span>}
                    inputStyle={{
                      border: "2px solid #ccc",
                      borderRadius: "5px",
                      padding: "5px 0px",
                      margin: "0px 4px",
                      color: "#000",
                      width: "65px",
                    }}
                    // renderInput={(props) => <input {...props} />}
                  /> */}
                </form>
              </div>
              <div className="flex justify-center my-2">
                <button
                  htmlType="submit"
                  // onClick={handleSubmit}
                  onClick={handleVerify}
                  className="rounded-full active shadow-neumorphic1 py-1 text-white  cursor-pointer hover:active/70 hover:text-white px-20 "
                >
                  Verify
                </button>
              </div>
            </div>
            <div className="text-center text-sm my-3 ">
              Didn't recieve code ?{" "}
              <span
               onClick={ResetOtp}
                // onClick={handleSubmit}
                className="font-bold underline cursor-pointer text-neonPurple"
              >
                Resend OTP
              </span>
            </div>
            <div className="text-center ">
              <span className="spam md:text-sm mt-1 text-white  text-xs md:text-black">
                Please check your spam folder if you do not see the email in
                your inbox.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
