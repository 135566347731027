import React, { useEffect, useState } from "react";
import { Modal, Steps, Tooltip } from "antd";
import card1 from "../../src/assets/coins/1.png";
import card2 from "../../src/assets/coins/2.png";
import card3 from "../../src/assets/coins/3.png";
import card4 from "../../src/assets/coins/4.png";
import card5 from "../../src/assets/coins/5.png";
import card6 from "../../src/assets/coins/6.png";
import coin from "../../src/assets/coinpayment.svg";
import logo from "../../src/assets/black.png";
import { FaArrowRight } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import PaymentGateway from "../Components/PaymentGatewayUpdateV";


const Paymentmodel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedImage, setSelectedImage] = useState(card1);
  const [selectedName, setSelectedName] = useState("BNB.BSC");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectCoin, setselectCoin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);

  const jwt = localStorage.getItem("jwt");

  const images = [
    { img: card1, name: "BNB.BSC" },
    { img: card2, name: "BTC" },
    { img: card3, name: "ETH" },
    { img: card4, name: "USDT.BEP20" },
    { img: card5, name: "USDT.TRC20" },
  ];


  const showModal = () => {
    setIsModalOpen(true);
    currentprice()
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCurrentStep(0);
    setAmount("");
    setSearchText("");
    setselectCoin("");
    setConvertPrice("");
  };

  const handleInputChange = (e) => {
    const newValue = parseInt(e.target.value) || 0;
    setAmount(newValue);
  };

  const handleNext = async () => {
    if (amount === "") {
      setMessageError(
        toast.error("Please enter an amount before proceeding to the next step.")
      );
      return;
    }

    if (currentStep === 2) {
      setButtonClicked(true);
    }

    if (currentStep === steps.length - 2) {
      await ViewSubmitPayment();
    }

    setMessageError("");
    setCurrentStep(currentStep + 1);
    Valueprice();
  };


  const handleBack = () => {
    setConvertPrice("");
    setselectCoin("")
    setCurrentStep(currentStep - 1);
  };

  const [searchText, setSearchText] = useState("");

  const [current, setCurrent] = useState("");
  const [Transaction, setTransaction] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [datanew, setDataNew] = useState("");
  const [messageError, setMessageError] = useState("");

  const currentprice = () => {
    const formData = {
      jwt: jwt,
      gateway: "COINPAYMENTS"
    };

    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH_NEW + "/deposit/get_price",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          setCurrent(res?.Success);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Valueprice = (name) => {
    const formData = {
      jwt: jwt,
      gateway: "COINPAYMENTS",
      currency: name || selectedName,
      amount_usdt: amount
    };

    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH_NEW + "/deposit/calculate_value",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          setselectCoin(res?.Success);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.Error);

      });
  };

  // const handleDivClick = (gateway) => {
  //   ViewPackagePayment(gateway)
  // };

  const handleDivClick = (gateway) => {
    setIsLoading(true); // Set loading to true before hitting the API
    setClickedButton(gateway); // Identify which button was clicked
    ViewPackagePayment(gateway)
      .then(() => {
        setIsLoading(false); // Set loading to false after API call is successful
        setClickedButton(null); // Reset clicked button
      })
      .catch(() => {
        setIsLoading(false); // Set loading to false if there's an error
        setClickedButton(null); // Reset clicked button
      });
  };

  const ViewPackagePayment = async (gateway) => {
    const formData = {
      jwt: jwt,
      gateway: gateway,
      currency: selectedName,
      amount_usdt: amount
    };
  
    try {
      const response = await axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_NEW + "/deposit/make_payment",
        data: formData,
      });
  
      const res = response.data.Success;
      toast(res);
  
      if (gateway === "COINPAYMENTS") {
        const newTab = window.open(res.URL, '_blank');
        
        if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
          window.location.href = res.URL;
        } else {
          setIsModalOpen(false);
        }
      } else {
        const transid = res.TransID;
        const walletAddress = res.Address;
        setTransaction(transid);
        setWalletAddress(walletAddress);
        setDataNew(res);
        handleNext();
      }
    } catch (err) {
      toast.error(err?.response?.data?.Error);
    }
  };
  


  // const ViewPackagePayment = async (gateway) => {
  //   const formData = {
  //     jwt: jwt,
  //     gateway: gateway,
  //     currency: selectedName,
  //     amount_usdt: amount
  //   };

  //   const response = await axios({
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     url: process.env.REACT_APP_API_PATH_NEW + "/deposit/make_payment",
  //     data: formData,
  //   })
  //     .then(async function (response) {
  //       const res = response.data.Success;
  //       toast(res);

  //       if (gateway === "COINPAYMENTS") {
  //         // window.open(res.URL, '_blank','noopener,noreferrer');
  //         // setIsModalOpen(false);
  //         // const link = document.createElement('a');
  //         // link.href = res.URL;
  //         // link.target = '_blank'; 
  //         // link.rel = 'noopener noreferrer';
  //         // document.body.appendChild(link);
  //         // link.click();
  //         // document.body.removeChild(link); 
  //         // setIsModalOpen(false);
  //       } else {
  //         const transid = res.TransID;
  //         const walletAddress = res.Address;
  //         setTransaction(transid);
  //         setWalletAddress(walletAddress);
  //         setDataNew(res);
  //         handleNext();
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.Error);
  //     });
  // };

  const [convertPrice, setConvertPrice] = useState("");

  useEffect(() => {
    if (selectCoin["BNB.BSC"] !== undefined) {
      setConvertPrice(selectCoin["BNB.BSC"]);
    }
  }, [selectCoin]);


  const projectName = process.env.REACT_APP_DEMO || "Default Project";

  const handleImageClick = (img, name) => {
    setSelectedImage(img);
    setSelectedName(name);
    Valueprice(name);


    const convertPrice = selectCoin[name];
    if (convertPrice !== undefined) {
      setSelectedImage(convertPrice);
    }
  };

  // const handleAmountButtonClick = (amountToAdd) => {
  //   if (amountToAdd === 0) {
  //     setAmount(0);
  //     setSearchText("");
  //   } else {
  //     const newAmount = amount + amountToAdd;
  //     setAmount(newAmount);
  //     setSearchText(newAmount.toString());
  //   }
  // };

  const handleAmountButtonClick = (amountToAdd) => {
    setAmount((prevAmount) => {
      const newAmount = amountToAdd === 0 ? 0 : Number(prevAmount) + Number(amountToAdd);
      setSearchText(newAmount.toString());
      return newAmount;
    });
  };



  const ViewSubmitPayment = async () => {
    const jwt = localStorage.getItem("jwt");
    const formData = {
      jwt: jwt,
      gateway: "ODECENT",
      transid: Transaction,
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_NEW + "/deposit/submit_payment",
      data: formData,
    })
      .then(async function (response) {
        const res = response.data.Success;
        if (res === true) {
          toast.success("Payment received successfully!");
        }

      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  const currencyMapping = {
    "BNB.BSC": "bsc",
    "ETH": "eth",
    "BTC": "btc",
    "USDT.BEP20": "usdt",
    "USDT.TRC20": "trc20",
  };

  const urlTemplates = {
    bsc: "https://bscscan.com/address/{Address}",
    eth: "https://etherscan.io/address/{Address}",
    btc: "https://www.blockchain.com/explorer/addresses/btc/{Address}",
    usdt: "https://bscscan.com/address/{Address}",
    trc20: "https://tronscan.org/#/address/{Address}",
  }



  const createWalletAddressUrl = (currency, address) => {
    const key = currencyMapping[currency];
    const template = urlTemplates[key];
    if (template) {
      return template.replace("{Address}", address);
    }
    console.warn(`No URL template found for currency: ${currency}`);
    return null;
  };

  const projectNameView = process.env.REACT_APP_PROJECT || "Odecent";



  const steps = [
    {
      title: <div className="text-xs">Enter Amount</div>,
      content: (
        <div className="my-4 text-center">
          <div className="text-2xl font-semibold my-3">SELECT PAYMENT MODE</div>
          <p>
            In the following step, you can choose the cryptocurrency to top up
            your account with
          </p>
          <div className="text-center">
            <input
              type="number"
              className="w-full focus:border-neonPurple active:border-neonPurple p-2 rounded-full border border-neonPurple my-4 text-center"
              placeholder="Amount"
              value={amount}
              onChange={handleInputChange}
            />
          </div>
          <div className="grid grid-cols-3 md:flex  items-center justify-center gap-3">
            <button
              className="w-20 bg-neonPurple/20 border border-neonPurple text-lg rounded-full py-1 text-neonPurple"
              onClick={() => handleAmountButtonClick(100)}
            >
              + 100
            </button>
            <button
              className="w-20 bg-neonPurple/20 border border-neonPurple text-lg rounded-full py-1 text-neonPurple"
              onClick={() => handleAmountButtonClick(500)}
            >
              + 500
            </button>
            <button
              className="w-20 bg-neonPurple/20 border border-neonPurple text-lg rounded-full py-1 text-neonPurple"
              onClick={() => handleAmountButtonClick(1000)}
            >
              + 1000
            </button>
            <button
              className="w-24 bg-neonPurple/20 border border-neonPurple text-lg rounded-full py-1 text-neonPurple"
              onClick={() => handleAmountButtonClick(5000)}
            >
              + 5000
            </button>
            <button
              className="w-24 bg-neonPurple/20 border border-neonPurple text-lg rounded-full py-1 text-neonPurple"
              onClick={() => handleAmountButtonClick(10000)}
            >
              + 10000
            </button>
            <button
              className="w-20 bg-neonRed/20 border border-neonRed text-lg rounded-full py-1 text-neonRed"
              onClick={() => handleAmountButtonClick(0)}
            >
              CLEAR
            </button>
          </div>
        </div>
      ),
    },
    {
      title: <div className="text-xs">Select Coin</div>,
      content: (
        <div className="flex flex-col items-center mt-3">
          <label className="font-bold text-lg">Select your Coin</label>
          <div className="flex justify-center items-center gap-3 mt-4">
            {images.map(({ img, name }, index) => (
              <div key={index} className={selectedImage === img ? 'selected animate-bounce' : ''}>
                <img
                  src={img}
                  alt={name}
                  className="w-10 h-10 md:w-20 md:h-20 transition-all hover:scale-105 transition-10s cursor-pointer"
                  onClick={() => handleImageClick(img, name)}
                />
              </div>
            ))}
          </div>
          <div className=" border border-t-0 border-neonPurple border-dashed w-full md:w-[500px]  h-10"></div>
          <div className="  border border-t-0 border-neonPurple border-dashed h-10"></div>
          {selectedImage && (
            <div className="flex justify-center w-full">
              <div className=" bottom-full  mt-2 px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="flex justify-center md:justify-start items-center">
                    <img
                      src={selectedImage}
                      alt={selectedName}
                      className="w-7 h-7 mr-2"
                    />
                    <div className="font-semibold">{selectedName}</div>
                  </div>
                  <div className="font-semibold ">Amount: <span className="font-normal">${amount}</span></div>
                </div>
                <div className="text-center md:text-end">
                  <span className="font-semibold">Price :&nbsp;</span>
                  $ {(selectedName.startsWith("TRC.")
                    ? 1
                    : parseFloat(current[selectedName]) || 0
                  ).toFixed(2)}
                  <div className="text-center md:text-end">
                    <span className="font-semibold">Convert:</span>{" "}
                    <span style={{ color: "green" }}>
                      {" "}
                      {selectCoin ? selectCoin.toFixed(4) : 0} <span className="text-black  font-semibold">{selectedName}</span>
                    </span>
                  </div>
                </div>
                <hr className="my-5 border-b border-0 border-[#ccc] border-dashed w-full md:w-[500px]  " />

                {/* <div className=" text-center">
                  <div className="font-bold text-md my-2">PAYMENT MODE</div>
                  <p className="text-xs">
                    Multiple Cryptocurrencies Accepted via{" "}
                    <span className="font-bold">CoinPayments</span>
                  </p>
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => handleDivClick("COINPAYMENTS")}
                      className="border border-neonPurple py-1 px-4 rounded-full my-4 font-semibold flex items-center gap-2"
                    >
                      <FaArrowRight />
                      <img src={coin} alt="coin" className="w-28" />
                    </button>
                  </div>

                  <p className="text-xs">
                    Multiple Cryptocurrencies Accepted via{" "}
                    <span className="font-bold">{projectName}</span> Wallet
                  </p>
                  <div
                    onClick={() => handleDivClick("ODECENT")}
                    className="flex items-center justify-center"
                  >
                    <button className="border border-neonPurple py-1 px-4 rounded-full my-4 font-semibold flex items-center gap-2">
                      <FaArrowRight />
                      <img src={logo} alt="coin" className="w-4 h-4" />
                      Odecent
                    </button>
                  </div>
                </div> */}

                <div className="text-center">
                  <div className="font-bold text-md my-2">PAYMENT MODE</div>
                  <p className="text-xs">
                    Multiple Cryptocurrencies Accepted via <span className="font-bold">CoinPayments</span>
                  </p>
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => handleDivClick("COINPAYMENTS")}
                      className="border border-neonPurple py-1 px-4 rounded-full my-4 font-semibold flex items-center gap-2"
                      disabled={isLoading && clickedButton === "COINPAYMENTS"} // Disable button while loading
                    >
                      {isLoading && clickedButton === "COINPAYMENTS" ? (
                        <span>Loading...</span> // Replace with a loading spinner if needed
                      ) : (
                        <>
                          <FaArrowRight />
                          <img src={coin} alt="coin" className="w-28" />
                        </>
                      )}
                    </button>
                  </div>

                  {/* <p className="text-xs">
                    Multiple Cryptocurrencies Accepted via <span className="font-bold">{projectName}</span> Wallet
                  </p>
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => handleDivClick("ODECENT")}
                      className="border border-neonPurple py-1 px-4 rounded-full my-4 font-semibold flex items-center gap-2"
                      disabled={isLoading && clickedButton === "ODECENT"} // Disable button while loading
                    >
                      {isLoading && clickedButton === "ODECENT" ? (
                        <span>Loading...</span> // Replace with a loading spinner if needed
                      ) : (
                        <>
                          <FaArrowRight />
                          <img src={logo} alt="coin" className="w-4 h-4" />
                          Odecent
                        </>
                      )}
                    </button>
                  </div> */}
                </div>


              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: <div className="text-xs">Select Payment Method</div>,
      content: (
        <div>
          <PaymentGateway data={datanew} />
        </div>
      ),
    },
    {
      title: <div className="text-xs">Success</div>,
      content: (
        <>
          <div className="font-semibold mx-10 my-10 text-center">
            If you've deposited into the wallet address shown earlier, you can
            close this window. After one network confirmation, your wallet will
            be automatically credited.
          </div>

          <div className="flex justify-center items-center">
            <a
              href={createWalletAddressUrl(selectedName, walletAddress)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setTimeout(() => setIsModalOpen(false), 1000)}
              className="border border-[#ccc] py-2 px-7 rounded-full hover:text-neonPurple hover:border-neonPurple"
            >
              View Explore
            </a>
          </div>
        </>
      ),
    },
  ];



  return (
    <>
      <button
        onClick={showModal}
        className="shadow-neumorphic hover:border-colorSecondaryDark hover:text-white  font-semibold py-2 px-4 md:px-2 lg:px-4 rounded-lg transition-all duration-300 ease-in-out active text-white"
      >
        TopUP
      </button>
      <Modal
        title={null}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="custom-modalnew"
        footer={false}
        maskClosable={false}
      >
        <Steps current={currentStep} labelPlacement="vertical" items={steps} />
        {steps[currentStep].content}

        <div className=" flex justify-end items-end gap-3">
          {currentStep > 0 && currentStep !== steps.length - 1 && (
            <button
              onClick={handleBack}
              className="border border-[#ccc] py-2 px-7 rounded-full"
            >
              Back
            </button>
          )}
          {currentStep !== 1 && currentStep < steps.length - 1 && (
          //   <button
          //   onClick={handleNext}
          //   className="border border-[#ccc] py-2 px-7 rounded-full"
          // >
          //   {currentStep === steps.length - 2
          //     ? "Amount Transferred to Above Address"
          //     : "Next"}
          // </button>
            // projectNameView === "Odecent_B" ? (
            //   <Tooltip title="Coming Soon">
            //     <button
            //       className="border border-[#ccc] py-2 px-7 rounded-full cursor-not-allowed"
            //     >
            //       {currentStep === steps.length - 2
            //         ? "Amount Transferred to Above Address"
            //         : "Next"}
            //     </button>
            //   </Tooltip>
            // ) : (
            //   <button
            //     onClick={handleNext}
            //     className="border border-[#ccc] py-2 px-7 rounded-full"
            //   >
            //     {currentStep === steps.length - 2
            //       ? "Amount Transferred to Above Address"
            //       : "Next"}
            //   </button>
            // )
            <button
            onClick={handleNext}
            className="border border-[#ccc] py-2 px-7 rounded-full"
          >
            {currentStep === steps.length - 2
              ? "Amount Transferred to Above Address"
              : "Next"}
          </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Paymentmodel;
