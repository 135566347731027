import { Modal, Select, Tabs, Tooltip } from "antd";
import React, { useEffect, useState, useRef } from "react";
import "../assets/css/style.css";
import AllBot1 from "../Components/AllBot1";
import axios from "axios";
import SkeltorLoader from "../Components/SkeltorLoader"
import { FaLock, FaSadTear } from 'react-icons/fa';
import UpdateApi from "../Components/UpdateApi";
import { FaNfcSymbol } from "react-icons/fa6";
import { BiBot } from "react-icons/bi";
import { a } from "react-spring";
import { BsBarChart } from "react-icons/bs";
import { GoShieldCheck } from "react-icons/go";
import { TbFilterCog } from "react-icons/tb";
import StaticImg10 from "../assets/Static/binance.png";


const { Option } = Select;


const { TabPane } = Tabs;

function Botmainpagecopy(props) {
  const [isActive, setIsActive] = useState("");
  const [coinData, setCoinData] = useState({
    Futures_Coin: [],
    Spot_Coin: [],
  });
  const [exchnagelist, setexchnagelist] = useState([]);
  const [filteredBot, setFilteredBot] = useState([]);

  const [botData, setBotData] = useState([]);
  const [filter, setFilter] = useState("ALL");

  const [filterBot, setFilterBot] = useState([]);

  const [filter1, setFilter1] = useState("hightolow");
  const [loading, setLoading] = useState(true);
  const jwt = localStorage.getItem("jwt");
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkBotPackage = checkBotFeature.Bot_Package_Active;
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedBotID, setSelectedBotID] = useState([]);
  const [selectedRisk, setSelectedRisk] = useState([]);

  const handleFilterClick = (filterType) => {
    setFilter(filterType);
    setSelectedOption("");
    setSelectedBotID("");
    setSelectedRisk("")
  };

  // useEffect(() => {
  //   const masterfilter = botData?.filter((item) => {
  //     return (
  //       (filter === "ALL" || item?.Exchange_Type === filter) &&
  //       // (filter1 === "hightolow" || item?.totalprofit === filter1) &&
  //       (!selectedOption?.length || selectedOption?.includes(item?.Symbol)) &&
  //       (!selectedBotID?.length || selectedBotID?.includes(item?.BotName))
  //     );
  //   });

  //   setFilterBot(masterfilter);
  // }, [filter, selectedOption, selectedBotID, botData]);



  const uniqueSymbolsSet = new Set();
  const uniqueSymbolsArray = [];
  const AllBotName = [];
  const AllRiskName = [];

  if (botData) {
    botData.forEach((item) => {
      const { Symbol, BotName, Investment_Risk } = item;
      if (!uniqueSymbolsSet.has(Symbol)) {
        uniqueSymbolsSet.add(Symbol);
        uniqueSymbolsArray.push(Symbol);
      }

      if (!uniqueSymbolsSet.has(BotName)) {
        uniqueSymbolsSet.add(BotName);
        AllBotName.push(BotName);
      }

      if (!uniqueSymbolsSet.has(Investment_Risk)) {
        uniqueSymbolsSet.add(Investment_Risk);
        AllRiskName.push(Investment_Risk);
      }
    });
  }

  useEffect(() => {
    let sortedBotData = [...botData];

    if (filter1 === "hightolow") {
      sortedBotData.sort((a, b) => b.TotalProfitPercent - a.TotalProfitPercent);
    }

    const masterfilter = sortedBotData.filter((item) => {
      return (
        (filter === "ALL" || item?.Exchange_Type === filter) &&
        // (filter1 === "hightolow" || item?.TotalProfitPercent === filter1) &&
        (!selectedOption?.length || selectedOption?.includes(item?.Symbol)) &&
        (!selectedBotID?.length || selectedBotID?.includes(item?.BotName)) &&
        (!selectedRisk?.length || selectedRisk?.includes(item?.Investment_Risk))
      );
    });

    setFilterBot(masterfilter);
  }, [filter, selectedOption, selectedBotID, botData, selectedRisk, filter1]);

  // useEffect(() => {
  //   let sortedBotData = [...botData];
  //   if (filter1 === "hightolow") {
  //     sortedBotData.sort((a, b) => b.totalprofit - a.totalprofit);
  //   }

  //   const masterfilter = sortedBotData.filter((item) => {
  //     return (
  //       (filter === "ALL" || item?.Exchange_Type === filter) &&
  //       (!selectedOption?.length || selectedOption?.includes(item?.Symbol)) &&
  //       (!selectedBotID?.length || selectedBotID?.includes(item?.BotName)) &&
  //       (!selectedRisk?.length || selectedRisk?.includes(item?.Investment_Risk))
  //     );
  //   });

  //   setFilterBot(masterfilter);
  // }, [filter, selectedOption, selectedBotID, botData, selectedRisk,filter1]);
  const filterUniqueSymbols = () => {
    if (filter === "ALL") {
      return uniqueSymbolsArray;
    } else if (filter === "SPOT") {
      return uniqueSymbolsArray.filter((symbol) =>
        botData.some(
          (item) => item.Exchange_Type === "SPOT" && item.Symbol === symbol
        )
      );
    } else if (filter === "FUTURES") {
      return uniqueSymbolsArray.filter((symbol) =>
        botData.some(
          (item) => item.Exchange_Type === "FUTURES" && item.Symbol === symbol
        )
      );
    }
  };

  const filterUniqueRisk = () => {
    if (filter === "ALL") {
      return AllRiskName;
    } else if (filter === "SPOT") {
      return AllRiskName.filter((symbol) =>
        botData.some(
          (item) => item.Exchange_Type === "SPOT" && item.Investment_Risk === symbol
        )
      );
    } else if (filter === "FUTURES") {
      return AllRiskName.filter((symbol) =>
        botData.some(
          (item) => item.Exchange_Type === "FUTURES" && item.Investment_Risk === symbol
        )
      );
    }
  };

  const exchageName = botData.filter((item) => item.Exchange_Type == filter);

  const filtrBotNameSet = new Set();
  const filtrBotName = [];

  if (exchageName.length > 0) {
    exchageName.forEach((item) => {
      const { Name } = item;
      if (!filtrBotNameSet.has(Name)) {
        filtrBotNameSet.add(Name);
        filtrBotName.push(Name);
      }
    });
  }

  const ViewBot = async (e) => {
    const formData = new FormData();
    formData.append("project", process.env.REACT_APP_PROJECT);

    try {
      const response = await axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH + "view_bots",
        data: formData,
      });

      // setBotData(response.data.Data)
      const botDataWithImages = await Promise.all(
        response?.data?.Data?.map(async (botItem) => {
          // const imagePath = `../assets/AllCoin/${botItem?.Symbol}.webp`;
          const imagePath = `/images/AllCoin/${botItem?.Symbol}.webp`;
          return {
            ...botItem,
            images: imagePath,
            // avg_profite: botItem?.Avg_Profit_Percent
          };
        })
      );
      // Check the processed data
      setBotData(botDataWithImages);

      setLoading(false);
    } catch (err) {
      console.error(err?.response?.data?.Error);
      setLoading(false);
    }
  };


const [kucoindatarr, setkucoindatarr] = useState([])


  


  const filterBotNamesByExchange = () => {
    if (filter === "ALL") {
      return AllBotName;
    } else if (filter === "SPOT") {
      return AllBotName.filter((botName) =>
        botData.some(
          (item) => item.Exchange_Type === "SPOT" && item.BotName === botName
        )
      );
    } else if (filter === "FUTURES") {
      return AllBotName.filter((botName) =>
        botData.some(
          (item) => item.Exchange_Type === "FUTURES" && item.BotName === botName
        )
      );
    }
  };

  useEffect(() => {
    if (checkBotPackage === true) {
      ViewBot();
    }
  }, [checkBotPackage]);

  const tradable_asset = async () => {
    try {
      const formData = new FormData();
      formData.append("project", process.env.REACT_APP_PROJECT);
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "tradable_asset",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = response?.data?.Success || {};

      setCoinData(data);
    } catch (err) {
      console.error(err);
    }
  };

  const bot_list = async () => {
    try {
      const formData = new FormData();
      formData.append("project", process.env.REACT_APP_PROJECT);
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "bot_list",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response?.data?.Success || {};
      const allBot = [...new Set([...data?.FUTURES, ...data?.SPOT])];
      setFilteredBot(allBot);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (checkBotPackage === true) {
      tradable_asset();
      bot_list();
    }
  }, [checkBotPackage]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const sortedFilterBotHighToLow = () => {
    setFilter1("hightolow");
    const sortedFilterBot = [...filterBot].sort((a, b) => {
      const profitA = parseFloat(a.TotalProfitS) || 0;
      const profitB = parseFloat(b.TotalProfitS) || 0;
      return profitB - profitA;
    });
    setFilterBot(sortedFilterBot);
  };

  const sortedFilterBotLowToHigh = () => {
    setFilter1("lowtohigh");
    const sortedFilterBot = [...filterBot].sort((a, b) => {
      const profitA = parseFloat(a.TotalProfitS) || 0;
      const profitB = parseFloat(b.TotalProfitS) || 0;
      return profitA - profitB;
    });
    setFilterBot(sortedFilterBot);
  };

  const [showOptions, setShowOptions] = useState(false);
  const [showOptions1, setShowOptions1] = useState(false);
  const [showOptions2, setShowOptions2] = useState(false);

  const handleOptionSelect = (option) => {
    const updatedOptions = [...selectedOption];

    if (updatedOptions.includes(option)) {
      updatedOptions?.splice(updatedOptions?.indexOf(option), 1);
    } else {
      updatedOptions?.push(option);
    }
    setSelectedOption(updatedOptions);
  };

  const handleOptionSelect1 = (option) => {
    const updatedOptions = Array.isArray(selectedBotID) ? [...selectedBotID] : [];

    if (updatedOptions.includes(option)) {
      updatedOptions?.splice(updatedOptions?.indexOf(option), 1);
    } else {
      updatedOptions?.push(option);
    }
    setSelectedBotID(updatedOptions);
  };

  const handleOptionRisk = (option) => {
    const updatedOptions = Array.isArray(selectedRisk) ? [...selectedRisk] : [];

    if (updatedOptions.includes(option)) {
      updatedOptions?.splice(updatedOptions?.indexOf(option), 1);
    } else {
      updatedOptions?.push(option);
    }
    setSelectedRisk(updatedOptions);
  };

  const handleChangeProfit = (updatedProp, index) => {
    const updatedFilter = [...filterBot];
    updatedFilter[index].avg_profite = updatedProp;
    setFilterBot(updatedFilter)
  }

  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef1 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
        setShowOptions1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
        setShowOptions2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const item = [
    {
      name: "Future",
      click: "FUTURES"
    },
    {
      name: "Spot",
      click: "SPOT"
    },

  ]

  useEffect(() => {
    setSelectedBotID(AllBotName);
    setSelectedOption(uniqueSymbolsArray);
    setSelectedRisk(AllRiskName)
    setFilter1("hightolow");

  }, [botData]);

  const showModal1 = () => {
    setIsModalVisible1(true);
  };

  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };
  return (
    <>
      <header className=" container  z-30    ">
        <div className="lg:hidden flex items-center justify-end">
          <div  onClick={showModal1} className="flex justify-end items-center">
           <Tooltip title="Filter">
            <TbFilterCog size={25} className="text-colorSecondaryDark" />
            </Tooltip>
          </div>



          <Modal
            title="Bot Filter"
            visible={isModalVisible1}
            // onOk={false}
            footer={false}
            onCancel={handleCancel1}
          >
            <div className="border-2 border-neonPurple rounded-2xl flex items-center justify-center px-2 py-1">
              <div className="grid grid-cols-1 md:grid-cols-2 align-middle items-center lg:grid-cols-3 2xl:grid-cols-5 lg:mx-5 mb-0 px-2 lg:px-4 ">
                <div className="flex  justify-items-center z-40 flex-col items-center  ">
                  <div
                    htmlFor="selectOption3"
                    className="text-neonPurple  text-md font-semibold"
                  >
                    Exchange
                  </div>
                  <div className="p-2 ">
                    <div className="flex mb-4 items-center justify-end">
                      <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
                          ? "bg-neonPurple text-white border-2 border-white "
                          : "bg-white text-black/60 shadow-neumorphic"
                          }`}
                        onClick={() => handleFilterClick("ALL")}
                      >
                        All
                      </button>
                      <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "SPOT"
                          ? "bg-neonPurple text-white border-2 border-white drop-shadow-xl"
                          : "bg-white text-black/60 shadow-neumorphic"
                          }`}
                        onClick={() => handleFilterClick("SPOT")}
                      >
                        Spot
                      </button>
                      <button
                        className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "FUTURES"
                          ? "bg-neonPurple text-white border-2 border-white drop-shadow-xl"
                          : "bg-white text-black/60 shadow-neumorphic"
                          }`}
                        onClick={() => handleFilterClick("FUTURES")}
                      >
                        Future
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center lg:space-x-4 ">
                  <label
                    htmlFor="selectOption3"
                    className="text-neonPurple font-semibold"
                  >
                    FROM
                  </label>
                  <div className="p-2 ">
                    <div className="flex mb-4 items-center justify-end">
                      <Tooltip title="High to Low">
                        <button
                          className={`mr-2 px-1 2xl:w-20 text-sm py-1 rounded-full w-[100px] shadow-md drop-shadow-xl ${filter1 === "hightolow"
                            ? "bg-neonPurple text-white border-2 border-white "
                            : "bg-white text-black/60"
                            }`}
                          onClick={sortedFilterBotHighToLow}
                        >
                          H to L
                        </button>
                      </Tooltip>
                      <Tooltip title="Low to High">
                        <button
                          className={`px-1 py-1 shadow-neumorphic  rounded-full text-sm w-20 shadow-md ${filter1 === "lowtohigh"
                            ? "bg-neonPurple text-white border-2 border-white drop-shadow-xl"
                            : "bg-white text-black/60 "
                            }`}
                          onClick={sortedFilterBotLowToHigh}
                        >
                          L to H
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>


                <div ref={dropdownRef} className="flex flex-col items-center space-y-2">
                  <div className="relative">
                    <button
                      id="selectedOption"
                      onClick={() => setShowOptions(!showOptions)}
                      className="bg-colorPrimary shadow-neumorphic text-sm mt-1 px-5 py-2 rounded-md transition-all duration-200"
                      onMouseEnter={() => setShowOptions(true)} // Show options on hover
                      onMouseLeave={() => setShowOptions(false)}
                    >
                      <div className="flex text-gray-600 justify-between items-center gap-2">
                        <FaNfcSymbol size={20} /> Select Symbols..
                      </div>
                    </button>

                    {showOptions && (
                      <div
                        className="absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40"
                        onMouseEnter={() => setShowOptions(true)} // Keep showing options while hovering over the dropdown
                        onMouseLeave={() => setShowOptions(false)}
                      >
                        {filterUniqueSymbols()?.map((option, index) => (
                          <label key={index} className="block p-2 cursor-pointer hover:bg-gray-100">
                            <input
                              type="checkbox"
                              checked={selectedOption?.includes(option)}
                              onChange={() => handleOptionSelect(option)}
                              className="mr-2"
                            />
                            {option}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* Bot Names Dropdown */}
                <div ref={dropdownRef1} className="flex flex-col items-center space-y-2">
                  <div className="relative">
                    <button
                      id="selectOption1"
                      onClick={() => setShowOptions1(!showOptions1)}
                      className="bg-colorPrimary shadow-neumorphic text-sm mt-1 px-5 py-2 rounded-md transition-all duration-200"
                      onMouseEnter={() => setShowOptions1(true)} // Show options on hover
                      onMouseLeave={() => setShowOptions1(false)}
                    >
                      <div className="flex text-gray-600 justify-between items-center gap-2">
                        <BiBot size={20} /> Select Bot Name..
                      </div>
                    </button>

                    {showOptions1 && (
                      <div
                        className="absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40"
                        onMouseEnter={() => setShowOptions1(true)} // Keep showing options while hovering over the dropdown
                        onMouseLeave={() => setShowOptions1(false)}
                      >
                        {filterBotNamesByExchange()?.map((option, index) => (
                          <label key={index} className="block p-2 cursor-pointer hover:bg-gray-100">
                            <input
                              type="checkbox"
                              checked={selectedBotID?.includes(option)}
                              onChange={() => handleOptionSelect1(option)}
                              className="mr-2"
                            />
                            {option}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div ref={dropdownRef2} className="flex flex-col items-center space-y-2">
                  <div className="relative">
                    <button
                      id="selectOption2"
                      onClick={() => setShowOptions2(!showOptions2)}
                      className="bg-colorPrimary shadow-neumorphic text-sm mt-1 px-5 py-2 rounded-md transition-all duration-200"
                      onMouseEnter={() => setShowOptions2(true)} // Show options on hover
                      onMouseLeave={() => setShowOptions2(false)}
                    >
                      <div className="flex text-gray-600 justify-between items-center gap-2">
                        <GoShieldCheck size={20} /> Select Risk..
                      </div>
                    </button>

                    {showOptions2 && (
                      <div
                        className="absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40"
                        onMouseEnter={() => setShowOptions2(true)} // Keep showing options while hovering over the dropdown
                        onMouseLeave={() => setShowOptions2(false)}
                      >
                        {filterUniqueRisk()?.map((option, index) => (
                          <label key={index} className="block p-2 cursor-pointer hover:bg-gray-100">
                            <input
                              type="checkbox"
                              checked={selectedRisk?.includes(option)}
                              onChange={() => handleOptionRisk(option)}
                              className="mr-2"
                            />
                            {option}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div>

          </div>

        </div>
       
      </header>

      <div className="border-2 border-neonPurple rounded-2xl py-2 mx-5 mb-2 mt-3 lg:mt-0 hidden lg:block">
        <div className="grid grid-cols-1 md:grid-cols-2 align-middle items-center lg:grid-cols-3 2xl:grid-cols-5 lg:mx-5 mb-0 px-2 lg:px-4 ">
          <div className="flex  justify-items-center z-40 flex-col items-center  ">
            <div
              htmlFor="selectOption3"
              className="text-neonPurple  text-md font-semibold"
            >
              Exchange
            </div>
            <div className="p-2 ">
              <div className="flex mb-4 items-center justify-end">
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20  shadow-md drop-shadow-xl ${filter === "ALL"
                    ? "bg-neonPurple text-white border-2 border-white "
                    : "bg-white text-black/60 shadow-neumorphic"
                    }`}
                  onClick={() => handleFilterClick("ALL")}
                >
                  All
                </button>
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20  shadow-md ${filter === "SPOT"
                    ? "bg-neonPurple text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-black/60 shadow-neumorphic"
                    }`}
                  onClick={() => handleFilterClick("SPOT")}
                >
                  Spot
                </button>
                <button
                  className={`mr-2 xl:px-4 py-1 rounded-full w-20  shadow-md ${filter === "FUTURES"
                    ? "bg-neonPurple text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-black/60 shadow-neumorphic"
                    }`}
                  onClick={() => handleFilterClick("FUTURES")}
                >
                  Future
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center lg:space-x-4 ">
            <label
              htmlFor="selectOption3"
              className="text-neonPurple font-semibold"
            >
              FROM
            </label>
            <div className="p-2 ">
              <div className="flex mb-4 items-center justify-end">
                <Tooltip title="High to Low">
                  <button
                    className={`mr-2 px-1 2xl:w-20 text-sm py-1 rounded-full w-[100px] shadow-md drop-shadow-xl ${filter1 === "hightolow"
                      ? "bg-neonPurple text-white border-2 border-white "
                      : "bg-white text-black/60"
                      }`}
                    onClick={sortedFilterBotHighToLow}
                  >
                    H to L
                  </button>
                </Tooltip>
                <Tooltip title="Low to High">
                  <button
                    className={`px-1 py-1 shadow-neumorphic  rounded-full text-sm w-20 shadow-md ${filter1 === "lowtohigh"
                      ? "bg-neonPurple text-white border-2 border-white drop-shadow-xl"
                      : "bg-white text-black/60 "
                      }`}
                    onClick={sortedFilterBotLowToHigh}
                  >
                    L to H
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>


          <div ref={dropdownRef} className="flex flex-col items-center space-y-2">
            <div className="relative">
              <button
                id="selectedOption"
                onClick={() => setShowOptions(!showOptions)}
                className="bg-colorPrimary shadow-neumorphic text-sm mt-1 px-5 py-2 rounded-md transition-all duration-200"
                onMouseEnter={() => setShowOptions(true)} // Show options on hover
                onMouseLeave={() => setShowOptions(false)}
              >
                <div className="flex text-gray-600 justify-between items-center gap-2">
                  <FaNfcSymbol size={20} /> Select Symbols..
                </div>
              </button>

              {showOptions && (
                <div
                  className="absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40"
                  onMouseEnter={() => setShowOptions(true)} // Keep showing options while hovering over the dropdown
                  onMouseLeave={() => setShowOptions(false)}
                >
                  {filterUniqueSymbols()?.map((option, index) => (
                    <label key={index} className="block p-2 cursor-pointer hover:bg-gray-100">
                      <input
                        type="checkbox"
                        checked={selectedOption?.includes(option)}
                        onChange={() => handleOptionSelect(option)}
                        className="mr-2"
                      />
                      {option}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Bot Names Dropdown */}
          <div ref={dropdownRef1} className="flex flex-col items-center space-y-2">
            <div className="relative">
              <button
                id="selectOption1"
                onClick={() => setShowOptions1(!showOptions1)}
                className="bg-colorPrimary shadow-neumorphic text-sm mt-1 px-5 py-2 rounded-md transition-all duration-200"
                onMouseEnter={() => setShowOptions1(true)} // Show options on hover
                onMouseLeave={() => setShowOptions1(false)}
              >
                <div className="flex text-gray-600 justify-between items-center gap-2">
                  <BiBot size={20} /> Select Bot Name..
                </div>
              </button>

              {showOptions1 && (
                <div
                  className="absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40"
                  onMouseEnter={() => setShowOptions1(true)} // Keep showing options while hovering over the dropdown
                  onMouseLeave={() => setShowOptions1(false)}
                >
                  {filterBotNamesByExchange()?.map((option, index) => (
                    <label key={index} className="block p-2 cursor-pointer hover:bg-gray-100">
                      <input
                        type="checkbox"
                        checked={selectedBotID?.includes(option)}
                        onChange={() => handleOptionSelect1(option)}
                        className="mr-2"
                      />
                      {option}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div ref={dropdownRef2} className="flex flex-col items-center space-y-2">
            <div className="relative">
              <button
                id="selectOption2"
                onClick={() => setShowOptions2(!showOptions2)}
                className="bg-colorPrimary shadow-neumorphic text-sm mt-1 px-5 py-2 rounded-md transition-all duration-200"
                onMouseEnter={() => setShowOptions2(true)} // Show options on hover
                onMouseLeave={() => setShowOptions2(false)}
              >
                <div className="flex text-gray-600 justify-between items-center gap-2">
                  <GoShieldCheck size={20} /> Select Risk..
                </div>
              </button>

              {showOptions2 && (
                <div
                  className="absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40"
                  onMouseEnter={() => setShowOptions2(true)} // Keep showing options while hovering over the dropdown
                  onMouseLeave={() => setShowOptions2(false)}
                >
                  {filterUniqueRisk()?.map((option, index) => (
                    <label key={index} className="block p-2 cursor-pointer hover:bg-gray-100">
                      <input
                        type="checkbox"
                        checked={selectedRisk?.includes(option)}
                        onChange={() => handleOptionRisk(option)}
                        className="mr-2"
                      />
                      {option}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        {checkBotPackage === false ?
          <div className="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
            style={{
              backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/botmain.webp)',
              backgroundSize: 'cover',
              height: "100vh"
            }}>
            <FaLock className="text-gray-600 text-6xl mb-4" />
            <h2 className="text-2xl font-bold mb-2">Access Restricted</h2>
            <p className="mb-4">This feature is only available for BOT package users. Please upgrade to access this feature.</p>
          </div>
          :
          <div>

            {/* -------------------mobile view--------------- */}
            <div>
              <div>
                <div className="grid xl:grid-cols-3 2xl:grid-cols-4 lg:grid-cols-2  grid-cols-1 gap-5 px-5 mt-6 md:mt-12 lg:mt-0">
                  {loading
                    ? Array?.from({ length: 12 })?.map((_, index) => (
                      <div
                        key={index}
                        className={`pt-4 bg-colorPrimary text-black md:h-40 px-6 relative rounded-xl mt-4 shadow-neumorphic`}
                      >
                        <SkeltorLoader />
                      </div>
                    ))
                    : filterBot
                    .filter((botItemMap) => {
                      // Convert the TotalProfitPercent to a fixed 2 decimal value for comparison
                      const totalProfit = parseFloat(botItemMap?.TotalProfitPercent?.toFixed(2)); // Ensure it’s a number
                      return totalProfit > -5.1 && totalProfit !== 0.00;
                      // return totalProfit > -0.01 && totalProfit < -5 && totalProfit !== 0.00;
                      
                    })
                      .map((botItemMap, index) => {
                        return (
                          <AllBot1
                            key={index}
                            myProp={{
                              id: index,
                              exchnagedata: exchnagelist,
                              Name: botItemMap?.BotName,
                              VarBotID: botItemMap?.VarBotID,
                              Trade_Type: botItemMap?.Exchange_Type,
                              Symbol: botItemMap?.Symbol,
                              images: botItemMap?.images,
                              CiEdit: isActive,
                              Investment_Risk: botItemMap?.Investment_Risk,
                              totalprofit: botItemMap?.TotalProfitPercent?.toFixed(2),
                              Days: botItemMap?.Days,
                              Min_Investment: botItemMap?.Total_Investment,
                              Drawdown: botItemMap?.Total_Loss_Limit,
                              TakeProfit: botItemMap?.Total_Profit_Limit,
                              TotalTrades: botItemMap?.TotalTrades,
                              img:StaticImg10

                            }}
                            onPropChange={(updatedProp) =>
                              handleChangeProfit(updatedProp, index)
                            }
                          />
                        );
                      })}
                </div>


                {/* kuicoin rr  */}





              </div>
            </div>
          </div>
        }

      </div>
    </>
  );
}

export default Botmainpagecopy;
