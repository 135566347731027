export function getToken() {
  let tokendetails = null;
  if (localStorage.getItem("JWT")) {
    tokendetails = localStorage.getItem("JWT");
  }
  const jwt = tokendetails;
  return jwt;
}

export function removesession() {
  localStorage.removeItem("JWT");
}

export async function encrypt(data) {
  // Implement your encryption logic here (e.g., using a library like crypto-js)
  // Replace the following line with your actual encryption code
  const encryptedData = crypto.AES.encrypt(data, "secretKey").toString();
  return encryptedData;
}
