import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Switch,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import P4 from "./P4";
import { IoIosLock } from "react-icons/io";

const { Option } = Select;

const P2 = () => {
  const [password, setPassword] = useState("");
  const [strength, setStrength] = useState("");
  const [profile, setProfile] = useState();
  const [networks, setnetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");

  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1); // 1 for initial, 2 for OTP input
  const [address, setaddress] = useState("");

  // create tp section starts
  const [isModaltpOpen, setIsModaltpOpen] = useState(false);
  const [tppass, settppass] = useState("");

  const [tpstrength, settpStrength] = useState("");
  const handleOpenModal = () => {
    setIsModaltpOpen(true);
  };

  const handleCloseModal = () => {
    setIsModaltpOpen(false);
  };

  const handletpChange = (e) => {
    const { value } = e.target;
    settppass(value); // Call the second handler
    checktpPasswordStrength(value);
  };

  const [tphints, settpHints] = useState({
    minLength: false,
    lowercase: false,
    uppercase: false,
    numbers: false,
    specialCharacters: false,
  });

  const checktpPasswordStrength = (pwd) => {
    const minLength = pwd.length >= 6;
    const lowercase = /[a-z]/.test(pwd);
    const uppercase = /[A-Z]/.test(pwd);
    const numbers = /[0-9]/.test(pwd);
    const specialCharacters = /[^A-Za-z0-9]/.test(pwd);

    settpHints({
      minLength,
      lowercase,
      uppercase,
      numbers,
      specialCharacters,
    });

    const strengthLevel =
      minLength + lowercase + uppercase + numbers + specialCharacters;

    switch (strengthLevel) {
      case 0:
        settpStrength("Empty");
        break;
      case 1:
        settpStrength("Weak");
        break;
      case 2:
        settpStrength("Medium");
        break;
      case 3:
        settpStrength("Strong");
        break;
      case 4:
        settpStrength("Very Strong");
        break;
      case 5:
        settpStrength("Super Strong");
        break;
      default:
        settpStrength("");
    }
  };

  const handletpSubmit = () => {
    // Check if all password strength requirements are met
    if (
      tphints.minLength &&
      tphints.lowercase &&
      tphints.uppercase &&
      tphints.numbers &&
      tphints.specialCharacters
    ) {
      // All requirements are met, proceed with API call
      const jwt = localStorage.getItem("jwt");
      const postData = {
        jwt: jwt,
        password: tppass,
      };

      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url:
          process.env.REACT_APP_API_PATH_NEW + "/user/set_transaction_password",
        data: postData,
      })
        .then((res) => {
          toast(res?.data?.Success);
          setIsModaltpOpen(false);
          View_Profile();
        })
        .catch((err) => {
          console.error(err);
          toast.error(err?.response?.data?.Error);
        });
    } else {
      // Show an alert or toast if not all requirements are met
      toast.error(
        "Please ensure your password meets all the required criteria."
      );
    }
  };

  // create tp section ends
  //
  //
  // reset pass section starts

  const [resettppass, setresettpppass] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [resetotp, setresetotp] = useState();
  const [isModalrtpOpen, setIsModalrtpOpen] = useState(false);
  const [isValidOtp, setIsValidOtp] = useState(true);

  const [rtpstrength, setrtpStrength] = useState("");

  const handlerOpenModal = () => {
    setIsModalrtpOpen(true);
  };

  const handlerCloseModal = () => {
    setIsModalrtpOpen(false);
  };

  const handlertpChange = (e) => {
    const { value } = e.target;
    setresettpppass(value); // Call the second handler
    checkrtpPasswordStrength(value);
  };

  const [rtphints, setrtpHints] = useState({
    minLength: false,
    lowercase: false,
    uppercase: false,
    numbers: false,
    specialCharacters: false,
  });

  const checkrtpPasswordStrength = (pwd) => {
    const minLength = pwd.length >= 6;
    const lowercase = /[a-z]/.test(pwd);
    const uppercase = /[A-Z]/.test(pwd);
    const numbers = /[0-9]/.test(pwd);
    const specialCharacters = /[^A-Za-z0-9]/.test(pwd);

    setrtpHints({
      minLength,
      lowercase,
      uppercase,
      numbers,
      specialCharacters,
    });

    const strengthLevel =
      minLength + lowercase + uppercase + numbers + specialCharacters;

    switch (strengthLevel) {
      case 0:
        setrtpStrength("Empty");
        break;
      case 1:
        setrtpStrength("Weak");
        break;
      case 2:
        setrtpStrength("Medium");
        break;
      case 3:
        setrtpStrength("Strong");
        break;
      case 4:
        setrtpStrength("Very Strong");
        break;
      case 5:
        setrtpStrength("Super Strong");
        break;
      default:
        setrtpStrength("");
    }
  };

  const handlertpSubmit = () => {
    // Check if all password strength requirements are met
    if (
      rtphints.minLength &&
      rtphints.lowercase &&
      rtphints.uppercase &&
      rtphints.numbers &&
      rtphints.specialCharacters
    ) {
      // All requirements are met, proceed with API call
      const jwt = localStorage.getItem("jwt");
      const postData = {
        jwt: jwt,
        password: resettppass,
      };

      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url:
          process.env.REACT_APP_API_PATH_NEW +
          "/user/reset_transaction_password",
        data: postData,
      })
        .then((res) => {
          toast(res?.data?.Success);
          setCurrentStep(1);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err?.response?.data?.Error);
        });
    } else {
      // Show an alert or toast if not all requirements are met
      toast.error(
        "Please ensure your password meets all the required criteria."
      );
    }
  };

  const sendOtp = () => {
    if (
      rtphints.minLength &&
      rtphints.lowercase &&
      rtphints.uppercase &&
      rtphints.numbers &&
      rtphints.specialCharacters
    ) {
      handlertpSubmit();
      setCurrentStep(1); // Move to next step
    } else {
      toast.error(
        "Please ensure your password meets all the required criteria."
      );
    }
  };

  const handleresetpass = () => {
    // Check if all password strength requirements are met

    // All requirements are met, proceed with API call
    const jwt = localStorage.getItem("jwt");
    const postData = {
      jwt: jwt,
      password: resettppass,
      otp: resetotp,
    };

    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url:
        process.env.REACT_APP_API_PATH_NEW +
        "/user/verify_transaction_password",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);

        View_Profile();
        setIsModalrtpOpen(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.Error);
      });
  };

  const handleresettpSubmit = async () => {
    if (!resetotp || resetotp.length !== 6) {
      setIsValidOtp(false);
      toast.error("Please enter a valid 6-digit OTP.");
      return;
    }

    const isSuccess = await handleresetpass();
    if (isSuccess) {
      setIsModalrtpOpen(false);
      setresettpppass("")
    }

    // handlerCloseModal()
  };

  const handleOtpChange = (e) => {
    const { value } = e.target;
    if (value.length <= 6 && /^[0-9]*$/.test(value)) {
      setresetotp(value);
      setIsValidOtp(true); // Reset the validation state on OTP change
    }
  };

  //
  //

  const [form] = Form.useForm();

  const handleNetworkChange = (value) => {
    setSelectedNetwork(value);
  };

  const [hints, setHints] = useState({
    minLength: false,
    lowercase: false,
    uppercase: false,
    numbers: false,
    specialCharacters: false,
  });

  const checkPasswordStrength = (pwd) => {
    const minLength = pwd.length >= 6;
    const lowercase = /[a-z]/.test(pwd);
    const uppercase = /[A-Z]/.test(pwd);
    const numbers = /[0-9]/.test(pwd);
    const specialCharacters = /[^A-Za-z0-9]/.test(pwd);

    setHints({
      minLength,
      lowercase,
      uppercase,
      numbers,
      specialCharacters,
    });

    const strengthLevel =
      minLength + lowercase + uppercase + numbers + specialCharacters;

    switch (strengthLevel) {
      case 0:
        setStrength("Empty");
        break;
      case 1:
        setStrength("Weak");
        break;
      case 2:
        setStrength("Medium");
        break;
      case 3:
        setStrength("Strong");
        break;
      case 4:
        setStrength("Very Strong");
        break;
      case 5:
        setStrength("Super Strong");
        break;
      default:
        setStrength("");
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    checkPasswordStrength(value);
  };

  const handleCombinedChange = (e) => {
    handleChange(e); // Call the first handler
    setNewPassword(e.target.value); // Call the second handler
  };

  // new
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [updatePassModalVisible, setUpdatePassModalVisible] = useState(false);
  const [updateotppass, setupdateotppass] = useState("");

  const showupdatepass = (e) => {
    if (oldPassword === newPassword || !oldPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword) {
      toast.error("Please check all the fields");
      return;
    }
    handleSubmit(e);
    setUpdatePassModalVisible(true);
  };

  const handleupdateCancel = () => {
    setUpdatePassModalVisible(false);
  };

  const handleUpdatePass = (values) => {
    const jwt = localStorage.getItem("jwt");

    const postData = {
      jwt: jwt,
      old_password: oldPassword,
      new_password: newPassword,
      con_password: confirmPassword,
      otp: updateotppass,
    };

    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_NEW + "/user/verify_update_password",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        setUpdatePassModalVisible(false)
        setOldPassword("")
        setNewPassword("")
        setConfirmPassword("")
        setupdateotppass("")
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.Error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      old_password: oldPassword,
      new_password: newPassword,
      con_password: confirmPassword,
    };

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      toast.error(
        "Password must contain at least 8 characters, including at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character."
      );
    } else if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password must match.");
    } else {
      const response = axios({
        method: "POST",
        headers: "accept: application/json",
        url: process.env.REACT_APP_API_PATH_USER_ODECENT + "update_password",
        data: postdata,
      })
        .then(async (response) => {
          const res = await response?.data;
          if (res?.Success) {
            toast(res?.Success);

            setError("");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.Error);
        });
    }
  };

  useEffect(() => {
    const requirementsMet = [
      newPassword.length >= 8,
      /[a-z]/.test(newPassword),
      /[A-Z]/.test(newPassword),
      /\d/.test(newPassword),
      /[@$!%*?&]/.test(newPassword),
    ];

    setCheckboxes(requirementsMet);
  }, [newPassword]);

  const [checkboxes, setCheckboxes] = useState([
    false, // 8 characters
    false, // 1 lowercase letter
    false, // 1 uppercase letter
    false, // 1 number
    false, // 1 special character
  ]);

  //checking address and network

  // const onFinish = (values) => {
  //   const jwt = localStorage.getItem("jwt");

  //   setaddress(values.address);

  //   const postData = {
  //     ...values,
  //     jwt: jwt,
  //     network: selectedNetwork,
  //   };

  //   const response = axios({
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     url:
  //       process.env.REACT_APP_API_PATH_NEW +
  //       "/user/latest_update_wallet_address",
  //     data: postData,
  //   })
  //     .then((res) => {
  //       toast(res?.data?.Success);
  //       setStep(2);
  //       // setIsModalOpen(false);
  //       View_Profile();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error(err?.response?.data?.Error);
  //       // message.error(err?.response?.data?.Error);
  //     });
  // };

  const onFinish = async (values) => {
    const jwt = localStorage.getItem("jwt");

    const postData = {
      ...values,
      jwt: jwt,
      network: selectedNetwork,
    };

    try {
      // First API call to validate the wallet address
      const validationResponse = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: "https://api.checkcryptoaddress.com/wallet-checks",
        data: {
          ...values,
          network: selectedNetwork === "BEP.20" ? "eth" : "trx",
        },
      });

      if (validationResponse.data.valid) {
        // Proceed to the original onFinish function
        const response = await axios({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          url:
            process.env.REACT_APP_API_PATH_NEW +
            "/user/latest_update_wallet_address",
          data: postData,
        });

        toast(response?.data?.Success);
        setStep(2);
        View_Profile();
      } else {
        // Show error message from the validation response
        toast.error(validationResponse.data.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.Error || "An error occurred");
    }
  };

  //
  //
  //
  //

  const handleOtpSubmit = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = {
      jwt: jwt,
      address: address,
      network: selectedNetwork,
      otp: otp,
    };

    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url:
        process.env.REACT_APP_API_PATH_NEW +
        "/user/latest_wallet_address_verification",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        setIsModalOpen(false);
        setStep(1);

        View_Profile();
      })
      .catch((err) => {
        console.error(err);
        // toast(err?.response?.data?.Error)
        toast.error(err?.response?.data?.Error);
      });
  };
  //

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const View_Profile = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setProfile(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    View_Profile();
  }, []);

  const Get_Network = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_NEW + "/wallet/wallet_dropdown",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setnetworks(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    Get_Network();
  }, []);

  return (
    <>
      <div class="bg-white h-auto p-3 shadow-sm rounded-sm border-t-4 border-neonPurple">
        <div class="mx-4  max-w-screen-xl sm:mx-8 xl:mx-auto">
          <div class="grid lg:grid-cols-1 xl:grid-cols-2  grid-cols-1 gap-6 pt-3  ">
          <div class=" overflow-hidden grid grid-cols-1  w-full rounded-xl  sm:bg-gray-50 sm:px-8 sm:shadow">
              <p class="py-2 text-xl font-semibold">Change Password</p>
              <div class="flex items-center">
                <div class="w-full">
                  <label for="login-password">
                    <span class="text-sm text-gray-500">Old Password</span>
                    <div class="relative flex overflow-hidden   rounded-md  transition focus-within:border-neonPurple">
                      <Input.Password
                        placeholder="Old Password"
                        className=" bg-colorPrimary focus:border-neonPurple hover:border-neonPurple"
                        id="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </div>
                  </label>
                  <div className="">
                    <div className="flex mb-2">
                      <div className="flex-1">
                        <label for="login-password">
                          <span class="text-sm text-gray-500">
                            New Password
                          </span>
                          <div class="relative flex overflow-hidden rounded-md  transition ">
                            <Input.Password
                              placeholder="New Password"
                              className=" bg-colorPrimary focus:border-neonPurple hover:border-neonPurple"
                              id="oldPassword"
                              onChange={handleCombinedChange}
                              value={newPassword}
                              // onChange={(e) => setNewPassword(e.target.value)}
                              required
                              iconRender={(visible) =>
                                visible ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )
                              }
                            />
                          </div>
                        </label>
                        <div className="flex mt-2 -mx-1">
                          <div
                            className={`h-2 flex-auto rounded-full mx-1 ${
                              strength === "Super Strong"
                                ? "bg-neonPurple opacity-100"
                                : strength === "Very Strong"
                                ? "bg-purple-400 opacity-80"
                                : strength === "Strong"
                                ? "bg-purple-300 opacity-60"
                                : strength === "Medium"
                                ? "bg-purple-200 opacity-50"
                                : strength === "Weak"
                                ? "bg-purple-100 opacity-40"
                                : "bg-yellow-100 opacity-50"
                            }`}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div>
                        <span className="text-sm text-gray-800 ">
                          Password Level:{" "}
                        </span>
                        <span className="text-sm font-semibold text-gray-800 ">
                          {strength}
                        </span>
                      </div>
                    </div>
                  </div>
                  <label for="login-password">
                    <span class="text-sm text-gray-500">Confirm Password</span>
                    <div class="relative flex overflow-hidden rounded-md  transition focus-within:border-blue-600">
                      <Input.Password
                        placeholder="Confirm Password"
                        className=" bg-colorPrimary focus:border-neonPurple hover:border-neonPurple"
                        id="oldPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </div>
                  </label>
                  {/* {/ <h4 className="my-2 text-sm font-semibold text-gray-800">Your password must contain:</h4> /} */}

                  <ul className="space-y-1  text-sm text-gray-500 dark:text-neutral-500">
                    <li
                      className={`flex items-center gap-x-2 ${
                        hints.minLength ? "text-teal-500" : ""
                      }`}
                    >
                      <span className={hints.minLength ? "" : "hidden"}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className={hints.minLength ? "hidden" : ""}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Minimum number of characters is 6.
                    </li>
                    <li
                      className={`flex items-center gap-x-2 ${
                        hints.lowercase ? "text-teal-500" : ""
                      }`}
                    >
                      <span className={hints.lowercase ? "" : "hidden"}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className={hints.lowercase ? "hidden" : ""}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Should contain lowercase.
                    </li>
                    <li
                      className={`flex items-center gap-x-2 ${
                        hints.uppercase ? "text-teal-500" : ""
                      }`}
                    >
                      <span className={hints.uppercase ? "" : "hidden"}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className={hints.uppercase ? "hidden" : ""}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Should contain uppercase.
                    </li>
                    <li
                      className={`flex items-center gap-x-2 ${
                        hints.numbers ? "text-teal-500" : ""
                      }`}
                    >
                      <span className={hints.numbers ? "" : "hidden"}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className={hints.numbers ? "hidden" : ""}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Should contain numbers.
                    </li>
                    <li
                      className={`flex items-center gap-x-2 ${
                        hints.specialCharacters ? "text-teal-500" : ""
                      }`}
                    >
                      <span className={hints.specialCharacters ? "" : "hidden"}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className={hints.specialCharacters ? "hidden" : ""}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Should contain special characters.
                    </li>
                  </ul>
                </div>

                {/* <Input.Password
                    placeholder="Old Password"
                    className=" bg-colorPrimary"
                    id="oldPassword"
                    // value={oldPassword}
                    // onChange={(e) => setOldPassword(e.target.value)}
                    required
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  /> */}
              </div>

              <button
                class="mt-4 rounded-lg bg-neonPurple px-4  text-white"
                onClick={showupdatepass}
              >
                Update Password
              </button>

              <Modal
                title="Enter OTP"
                visible={updatePassModalVisible}
                onCancel={handleupdateCancel}
                footer={null}
              >
                <Form
                  name="otpForm"
                  // onClick={handleUpdatePass}
                  layout="vertical"
                >
                  <Form.Item
                    name="otp"
                    placeholder=""
                    rules={[
                      // { required: true, message: "Please input the OTP!" },
                      { len: 6, message: "OTP must be 6 digits long!" },
                      { pattern: /^[0-9]+$/, message: "OTP must be numeric!" },
                    ]}
                  >
                    <p className="text-red-500 text-center">Check your Email for OTP</p>
                    <Input
                      placeholder="Enter OTP"
                      onChange={(e) => setupdateotppass(e.target.value)}
                      value={updateotppass}
                      className="focus:border-neonPurple hover:border-neonPurple"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={handleUpdatePass} htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>

              <hr class="mt-4 mb-8" />
            </div>

            <div class=" overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">
              {/* <p class="py-2 text-xl font-semibold">Transaction Password</p> */}
              {/* <div class=" bg-white p-3 h-auto shadow-sm rounded-sm">
                <h1>
                  Transaction Password :-{" "}
                  {profile?.Transaction_Password
                    ? "****************"
                    : "No Password"}
                </h1>

                <div className="flex justify-center gap-4">
                  {profile?.Transaction_Password ? (
                    <button
                      class="mt-4 rounded-lg bg-neonPurple px-2 py-2  text-white"
                      onClick={handlerOpenModal}
                    >
                      Reset Password
                    </button>
                  ) : (
                    <button
                      class="mt-4 rounded-lg bg-neonPurple px-4 py-2  text-white"
                      onClick={handleOpenModal}
                    >
                      Create Password
                    </button>
                  )}
                </div>
                <Modal
                  title="Create Transaction Password"
                  visible={isModaltpOpen}
                  onCancel={handleCloseModal}
                  footer={[
                    <Button key="cancel" onClick={handleCloseModal}>
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={handletpSubmit}
                    >
                      Submit
                    </Button>,
                  ]}
                >
                  <div className="flex-1">
                    <label for="login-password">
                      <span class="text-sm text-gray-500">
                        Enter New Password
                      </span>
                      <div class="relative flex overflow-hidden rounded-md  transition ">
                        <Input.Password
                          placeholder="Enter Transaction Password"
                          className=" bg-colorPrimary"
                          id="oldPassword"
                          onChange={handletpChange}
                          value={tppass}
                          // onChange={(e) => setNewPassword(e.target.value)}
                          required
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </div>
                    </label>
                    <div className="flex mt-2 -mx-1">
                      <div
                        className={`h-2 flex-auto rounded-full mx-1 ${tpstrength === "Super Strong"
                            ? "bg-neonPurple opacity-100"
                            : tpstrength === "Very Strong"
                              ? "bg-purple-400 opacity-80"
                              : tpstrength === "Strong"
                                ? "bg-purple-300 opacity-60"
                                : tpstrength === "Medium"
                                  ? "bg-purple-200 opacity-50"
                                  : tpstrength === "Weak"
                                    ? "bg-purple-100 opacity-40"
                                    : "bg-yellow-100 opacity-50"
                          }`}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <div>
                      <span className="text-sm text-gray-800 ">
                        Password Level:{" "}
                      </span>
                      <span className="text-sm font-semibold text-gray-800 ">
                        {tpstrength}
                      </span>
                    </div>
                  </div>

                  <ul className="space-y-1 text-sm text-gray-500 dark:text-neutral-500">
                    <li
                      className={`flex items-center gap-x-2 ${tphints.minLength ? "text-teal-500" : ""
                        }`}
                    >
                      <span className={tphints.minLength ? "" : "hidden"}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className={tphints.minLength ? "hidden" : ""}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Minimum number of characters is 6.
                    </li>
                    <li
                      className={`flex items-center gap-x-2 ${tphints.lowercase ? "text-teal-500" : ""
                        }`}
                    >
                      <span className={tphints.lowercase ? "" : "hidden"}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className={tphints.lowercase ? "hidden" : ""}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Should contain lowercase.
                    </li>
                    <li
                      className={`flex items-center gap-x-2 ${tphints.uppercase ? "text-teal-500" : ""
                        }`}
                    >
                      <span className={tphints.uppercase ? "" : "hidden"}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className={tphints.uppercase ? "hidden" : ""}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Should contain uppercase.
                    </li>
                    <li
                      className={`flex items-center gap-x-2 ${tphints.numbers ? "text-teal-500" : ""
                        }`}
                    >
                      <span className={tphints.numbers ? "" : "hidden"}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span className={tphints.numbers ? "hidden" : ""}>
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Should contain numbers.
                    </li>
                    <li
                      className={`flex items-center gap-x-2 ${tphints.specialCharacters ? "text-teal-500" : ""
                        }`}
                    >
                      <span
                        className={tphints.specialCharacters ? "" : "hidden"}
                      >
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span
                        className={tphints.specialCharacters ? "hidden" : ""}
                      >
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                      Should contain special characters.
                    </li>
                  </ul>
                </Modal>

                <Modal
                  title="Reset Transaction Password"
                  visible={isModalrtpOpen}
                  onCancel={handlerCloseModal}
                  footer={[
                    <Button key="cancel" onClick={handlerCloseModal}>
                      Cancel
                    </Button>,
                    currentStep === 0 ? (
                      <Button key="sendOtp" type="primary" onClick={sendOtp}>
                        Send OTP
                      </Button>
                    ) : (
                      <Button
                        key="submit"
                        type="primary"
                        onClick={handleresettpSubmit}
                      >
                        Submit
                      </Button>
                    ),
                  ]}
                >
                  {currentStep === 0 ? (
                    <div>
                      <div className="flex-1">
                        <label for="login-password">
                          <span class="text-sm text-gray-500">
                            Enter New Password
                          </span>
                          <div class="relative flex overflow-hidden rounded-md  transition ">
                            <Input.Password
                              placeholder="Reset Password"
                              className=" bg-colorPrimary"
                              id="oldPassword"
                              onChange={handlertpChange}
                              value={resettppass}
                              // onChange={(e) => setNewPassword(e.target.value)}
                              required
                              iconRender={(visible) =>
                                visible ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )
                              }
                            />
                          </div>
                        </label>
                        <div className="flex mt-2 -mx-1">
                          <div
                            className={`h-2 flex-auto rounded-full mx-1 ${rtpstrength === "Super Strong"
                                ? "bg-neonPurple opacity-100"
                                : rtpstrength === "Very Strong"
                                  ? "bg-purple-400 opacity-80"
                                  : rtpstrength === "Strong"
                                    ? "bg-purple-300 opacity-60"
                                    : rtpstrength === "Medium"
                                      ? "bg-purple-200 opacity-50"
                                      : rtpstrength === "Weak"
                                        ? "bg-purple-100 opacity-40"
                                        : "bg-yellow-100 opacity-50"
                              }`}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <div>
                          <span className="text-sm text-gray-800 ">
                            Password Level:{" "}
                          </span>
                          <span className="text-sm font-semibold text-gray-800 ">
                            {rtpstrength}
                          </span>
                        </div>
                      </div>

                      <ul className="space-y-1 text-sm text-gray-500 dark:text-neutral-500">
                        <li
                          className={`flex items-center gap-x-2 ${rtphints.minLength ? "text-teal-500" : ""
                            }`}
                        >
                          <span className={rtphints.minLength ? "" : "hidden"}>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </span>
                          <span className={rtphints.minLength ? "hidden" : ""}>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18"></path>
                              <path d="m6 6 12 12"></path>
                            </svg>
                          </span>
                          Minimum number of characters is 6.
                        </li>
                        <li
                          className={`flex items-center gap-x-2 ${rtphints.lowercase ? "text-teal-500" : ""
                            }`}
                        >
                          <span className={rtphints.lowercase ? "" : "hidden"}>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </span>
                          <span className={rtphints.lowercase ? "hidden" : ""}>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18"></path>
                              <path d="m6 6 12 12"></path>
                            </svg>
                          </span>
                          Should contain lowercase.
                        </li>
                        <li
                          className={`flex items-center gap-x-2 ${rtphints.uppercase ? "text-teal-500" : ""
                            }`}
                        >
                          <span className={rtphints.uppercase ? "" : "hidden"}>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </span>
                          <span className={rtphints.uppercase ? "hidden" : ""}>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18"></path>
                              <path d="m6 6 12 12"></path>
                            </svg>
                          </span>
                          Should contain uppercase.
                        </li>
                        <li
                          className={`flex items-center gap-x-2 ${rtphints.numbers ? "text-teal-500" : ""
                            }`}
                        >
                          <span className={rtphints.numbers ? "" : "hidden"}>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </span>
                          <span className={rtphints.numbers ? "hidden" : ""}>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18"></path>
                              <path d="m6 6 12 12"></path>
                            </svg>
                          </span>
                          Should contain numbers.
                        </li>
                        <li
                          className={`flex items-center gap-x-2 ${rtphints.specialCharacters ? "text-teal-500" : ""
                            }`}
                        >
                          <span
                            className={
                              rtphints.specialCharacters ? "" : "hidden"
                            }
                          >
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </span>
                          <span
                            className={
                              rtphints.specialCharacters ? "hidden" : ""
                            }
                          >
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18"></path>
                              <path d="m6 6 12 12"></path>
                            </svg>
                          </span>
                          Should contain special characters.
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="flex-1">
                      <label htmlFor="otp">
                        <div className="">

                          <p className="text-red-500">Check your Email for OTP</p>
                        </div>
                        <div className="relative flex overflow-hidden rounded-md transition">
                          <Input
                            placeholder="Enter OTP"
                            className="bg-colorPrimary"
                            id="otp"
                            onChange={handleOtpChange}
                            style={{
                              borderColor: isValidOtp ? "" : "red",
                              backgroundColor: isValidOtp ? "" : "linen",
                            }}
                            value={resetotp}
                            required
                          />
                        </div>
                      </label>
                    </div>
                  )}
                </Modal>
              </div> */}

              <hr class="mt-4 mb-8" />
              <P4
                setIsModalOpen={setIsModalOpen}
                profile={profile}
                isModalOpen={isModalOpen}
                onFinish={onFinish}
              />

              <Modal
                title="Update Wallet Address"
                open={isModalOpen}
                footer={false}
                onCancel={() => setIsModalOpen(false)}
              >
                <div className="text-red-500 font-medium">
                  Note:- Only TRC.20 & BEP.20 Address Accepted
                </div>

                {step === 1 && (
                  <Form
                    form={form}
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      className="mt-4"
                      label="Network"
                      name="network"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Please select a Network!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a network"
                        onChange={handleNetworkChange}
                        value={selectedNetwork}
                        className="border-2 border-gray-200 rounded-lg"
                      >
                        {networks.map((network) => (
                          <Option key={network} value={network}>
                            {network}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="mt-6"
                      label="Wallet address"
                      name="address"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Wallet address!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Wallet address"
                        value={address}
                        onChange={(e) => setaddress(e.target.value)}
                        className="border-2 border-gray-200 rounded-lg"
                      />
                    </Form.Item>

                    <div className="flex justify-center">
                      <button
                        className="active text-sm font-medium py-1 px-4 rounded-xl"
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </Form>
                )}

                {step === 2 && (
                  <Form
                    name="otpForm"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    onFinish={handleOtpSubmit}
                    autoComplete="off"
                  >
                    <Form.Item
                      className="mt-4"
                      label="OTP"
                      name="otp"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Please input your OTP!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="border-2 border-gray-200 rounded-lg"
                      />
                    </Form.Item>

                    <div className="flex justify-center">
                      <button
                        className="active text-sm font-medium py-1 px-4 rounded-xl"
                        type="submit"
                      >
                        Verify and Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Modal>

              <hr class="mt-4 mb-8" />
            </div>
          </div>
        </div>
        {/* // */}

        {/* // */}
      </div>
    </>
  );
};

export default P2;
