// Tooltip.js
export const tooltips = [
    {
        id: "1",
        title: "Buy Tax",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "2",
        title: "LP Total Supply",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>It is LP token number, NOT a token number.</li>
                </ul>
            </div>,
    },
    {
        id: "1",
        title: "Holder Count",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
];

export const tooltips1 = [
    {
        id: "1",
        title: "Buy Tax",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "2",
        title: "LP Total Supply",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>It is LP token number, NOT a token number.</li>
                </ul>
            </div>,
    },
    {
        id: "1",
        title: "Holder Count",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    // Add more tooltips here if needed
];

export const tooltips2 = [
    {
        id: "1",
        title: "Can Take Back Ownership",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "2",
        title: "Can't Buy",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>It is LP token number, NOT a token number.</li>
                </ul>
            </div>,
    },
    {
        id: "3",
        title: "Anti Whale Modifiable",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "4",
        title: "Can't Sell All",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "5",
        title: "External Call",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "6",
        title: "Hidden Owner",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "7",
        title: "Personal Slippage Modifiable",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "8",
        title: "Self Destruct",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "9",
        title: "Owner Change Balance",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "10",
        title: "Trading Cooldown",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    // Add more tooltips here if needed
];

export const tooltips3 = [
    {
        id: "1",
        title: "Sell Tax",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "2",
        title: "LP Holder Count",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>It is LP token number, NOT a token number.</li>
                </ul>
            </div>,
    },
    {
        id: "3",
        title: "Honeypot with same Creator",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    // Add more tooltips here if needed
];

export const tooltips4 = [
    {
        id: "1",
        title: "Anti Whale",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "2",
        title: "Blacklisted",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>It is LP token number, NOT a token number.</li>
                </ul>
            </div>,
    },
    {
        id: "3",
        title: "Honeypot",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "4",
        title: "In Dex",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "5",
        title: "Mintable",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "6",
        title: "Open Source",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "7",
        title: "Proxy",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "8",
        title: "Whitelisted",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "9",
        title: "Transfer Pausable",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
    {
        id: "10",
        title: "Slippage Modifiable",
        description:
            <div>
                <ul className="list-disc mx-2">
                    <li>When buying a token, a buy tax will cause the actual token value received to be less than the amount paid. An excessive buy tax may lead to heavy losses.</li>
                </ul>
            </div>,
    },
];
