import { Link, NavLink, useLocation } from "react-router-dom";
import {
  FaQuestion,
} from "react-icons/fa";
import {
  AiFillApi,
  AiOutlineHeatMap,
  AiOutlineRobot,
} from "react-icons/ai";
import { BsRobot } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { Avatar, Tooltip } from "antd";
import { toast } from "react-toastify";
import { IoMdLogOut } from "react-icons/io";
import { PiAlignBottomDuotone, PiPackage } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { GiBuyCard } from "react-icons/gi";
import { SiLiquibase, SiMarketo, SiProbot } from "react-icons/si";
import { TbReportAnalytics, TbRowInsertBottom, TbTransfer } from "react-icons/tb";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import logo from "../assets/blackOdecent.png";
import { MdOutlineAnalytics } from "react-icons/md";
import { GoHistory, GoHubot } from "react-icons/go";
import { TiArrowMinimise } from "react-icons/ti";
import { RiAiGenerate } from "react-icons/ri";
import { BsFill0CircleFill } from "react-icons/bs";

const SideBar = ({ children, openSide, setopenSide, profile, View_Profile }) => {
  const [activeLink, setActiveLink] = useState();
  const toggle = () => setopenSide(!openSide);
  const [openSections, setOpenSections] = useState({
    chart: true,
    bots: false,
  });
  const location = useLocation();

  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [profileAvatar, setProfileAvatar] = useState(profile?.Profile_Image);
  const projectViewNameNew = process.env.REACT_APP_PROJECT || "Profitsla";

  useEffect(() => {
    setProfileAvatar(profile?.Profile_Image);
  }, [profile]);

  useEffect(() => {
    View_Profile();
  }, [location.pathname]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const routes = [
    {
      path: "/dashboard/home",
      name: "Dashboard",
      icon: <BsFill0CircleFill size={20} />,
    },
    // ...(projectViewNameNew !== "Odecent_B" ? [
    {
      path: "/dashboard/market",
      name: "Market",
      icon: <SiMarketo size={20} />,
    },
  // ]:[]),
    // {
    //   path: "/dashboard/arbitrage",
    //   name: "ARBITRAGE",
    //   icon: <GiBuyCard size={20} />,
    // },
    {
      path: "/dashboard/arbitrage",
      name: "ARBITRAGE",
      icon: <MdOutlineAnalytics size={20} />,
      subRoutes: [
        {
          path: "/dashboard/multiexchange",
          name: "MULTIEXCHANGE",
          icon: <AiOutlineHeatMap size={20} />,
        },
        {
          path: "/dashboard/intraexchange",
          name: "SAME EXCHANGE",
          icon: <SiLiquibase size={20} />,
        },
        {
          path: "/dashboard/dexarbi",
          name: "DECENTRALIZED",
          icon: <SiLiquibase size={20} />,
        },
      ],
    },
    {
      path: "/dashboard/subscription",
      name: "Subscriptions",
      icon: <PiPackage size={20} />,
    },
    {
      path: "/dashboard/transactiontable",
      name: "Transactions",
      icon: <TbTransfer size={20} />,
    },
    // {
    //   path: "/dashboard/backtest",
    //   name: "Backtest",
    //   icon: <PiPackage size={20} />,
    // },
    // ...(projectViewNameNew !== "Odecent_B" ? [
    {
      path: "/tool",
      name: "Tools",
      icon: <MdOutlineAnalytics size={20} />,
      subRoutes: [
        {
          path: "/dashboard/heatmap",
          name: "Heatmap",
          icon: <AiOutlineHeatMap size={20} />,
        },
        {
          path: "/dashboard/liquidation",
          name: "Liquidation",
          icon: <SiLiquibase size={20} />,
        },
        {
          path: "/dashboard/nft",
          name: "NFT",
          icon: <GiBuyCard size={20} />,
        },
        {
          path: "/dashboard/dex",
          name: "BlockChain",
          icon: <TiArrowMinimise size={20} />,
        },
      ],
    },
  // ]:[]),
    {
      path: "/bots",
      name: <div>Bots <span className="text-[8px] ">(Beta)</span> </div>,
      icon: <BsRobot size={20} />,
      subRoutes: [
        {
          path: "/dashboard/api_settings",
          name: "API Credentials",
          icon: <AiFillApi size={20} />,
        },
      
        // {
        //   path: "/dashboard/createbot",
        //   name: "Create Bot",
        //   icon: <TbRowInsertBottom size={20} />,
          
        // },
        {
          path: "/dashboard/botmain",
          name: "Bots ",
          icon: <AiOutlineRobot size={20} />,
        },
        ...(projectViewNameNew !== "Odecent_B" ? [
          {
            path: "/dashboard/syntaxGenerator",
            name: (
            <span>Tradingview Bot</span>
          ),
            icon: <RiAiGenerate size={20} />,
          },
          {
              path: "/dashboard/createbot",
              name: (
                  // <Tooltip title="Coming Soon">
                      <span>Create Bot</span>
                  // </Tooltip>
              ),
              icon: <TbRowInsertBottom size={20} />,
          },
          {
              path: "/dashboard/arbitragebot",
              name: (
                  // <Tooltip title="Coming Soon">
                      <span>Arbitrage Bot</span>
                  // </Tooltip>
              ),
              icon: <GoHubot size={20} />,
              disabled: true, 
          }
      ] : []),
        {
          path: "/dashboard/activebot",
          name: "Active Bot",
          icon: <SiProbot size={20} />,
        },
        {
          path: "/dashboard/trades",
          name: "Trades",
          icon: <PiAlignBottomDuotone size={20} />,
        },
        {
          path: "/dashboard/Report",
          name: "Report",
          icon: <TbReportAnalytics size={20} />,
        },
      ],
    },
    {
      path: "/dashboard/logs",
      name: "Timeline",
      icon: <GoHistory size={20} />,
    },
    {
      path: "/dashboard/support",
      name: "Support",
      icon: <HiOutlineChatBubbleLeftRight size={20} />,
    },
    {
      path: "/dashboard/FAQ",
      name: "FAQ",
      icon: <FaQuestion size={20} />,
    },
    {
      path: "/dashboard/profile",
      name: "Profile",
      icon: <CgProfile size={20} />,
    },
  ];

 

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const projectName = process.env.REACT_APP_DEMO || "Profitsla";
  const projectViewName = process.env.REACT_APP_PROJECT || "Profitsla";

  const logout = () => {
    localStorage.clear();
    toast("Logout Successfully");
    window.location.href = "/";
  };

  return (
    <>
      <div
        className={`md:flex  md:flex-col shadow-lg shadow-neonBlue shadow-neumorphic flex-row fixed left-0 right-0 bottom-0 z-50 md:h-[101vh] h-[75px] hidden md:bg-colorPrimary border-[#ccc]  rounded-r-2xl  lg:mt-32  ${openSide
          ? "md:w-[224px] "
          : "md:w-[45px] flex items-center justify-center "
          }`}
      >
        <div className="main-container ">
          <motion.div
            animate={{
              width: openSide ? "224px" : "45px",

              transition: {
                duration: 0.5,
                type: "spring",
                damping: 10,
              },
            }}
            className={`sidebar`}
          >
            <div className="top_section ">
              <AnimatePresence>
                {openSide && (
                  <motion.h1
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                  >
                    {/* Odecent */}
                    <Link to="/dashboard/home">
                      <img src="https://imagedelivery.net/_61vLPSGQhg-TYoL9kq0Vg/2b9c0ea3-f2c8-42e9-40c0-e42e826c7c00/public" alt="logo" className="" />
                    </Link>
                  </motion.h1>
                )}
              </AnimatePresence>

              {/* <motion.div
                animate={
                  openSide
                    ? {
                      rotate: -90,
                    }
                    : { rotate: 0 }
                }
              >
                <FaAngleDown onClick={toggle} />
              </motion.div> */}
            </div>

            {/* <div className="border border-[#ccc6]"></div> */}

            <section className="routes">
              {routes.map((route, index) => {
                if (route.subRoutes) {
                  // Determine if the current section is open or closed based on the state
                  const isOpen = openSections[route.name] || false;
                  return (
                    <SidebarMenu
                      key={index}
                      setopenSide={setopenSide}
                      route={route}
                      showAnimation={showAnimation}
                      openSide={openSide}
                      isOpen={isOpen}
                      setActiveLink={setActiveLink}
                    />
                  );
                } else if (route.path === "/guide") {
                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      target="_blank"
                      className={`link ${route.path === activeLink ? "" : "transition-all rounded-tl-lg rounded-bl-lg hover:scale-110 transition-5s cursor-pointer"}`}
                      onClick={() => {
                        setActiveLink(route.path);
                        setopenSide(true);
                      }}
                    >
                      <div className="icon">{route.icon}</div>
                      <AnimatePresence>
                        {openSide && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text uppercase"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                } else {
                  // For individual links, check if it's the "Charts" or "Bots" section and apply default open/close behavior
                  // const isOpen = route.name.toLowerCase() === 'charts' ? openSections.chart : openSections.bots;
                  return (
                    <NavLink
                      to={route.path}
                      key={index}

                      className={`link ${route.path === activeLink ? "" : "transition-all rounded-tl-lg rounded-bl-lg hover:scale-110 transition-5s cursor-pointer"}`}
                      onClick={() => {
                        setActiveLink(route.path);
                        setopenSide(true);
                      }}
                    >
                      <div className="icon">{route.icon}</div>
                      <AnimatePresence>
                        {openSide && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text uppercase"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                }
              })}


            </section>

            <footer className=" xl:relative 2xl:relative lg:relative  sm:flex mt-[164px] p-[10px] flex-col bottom-0 w-[230px]  ">
              <div className="flex items-center justify-center rounded-md py-1  from-neonPurple via-neonPurpleShadow to-neonBlue gap-3 w-full px-1.5">
                {openSide && (
                  <>
                    <Avatar
                      src={profileAvatar}
                      className="rounded-full w-10 h-10"
                    />
                    <div className="flex-grow text-black text-base md:text-sm font-semibold text-center">
                      {profile.Username}!
                    </div>
                  </>
                )}
                <button
                  className={`text-[red] ${openSide ? "" : ""}`}
                  onClick={logout}
                >
                  <IoMdLogOut size={20} className="text-[red]" />
                </button>
              </div>

              <div className="text-center text-xs my-1  flex justify-center items-center w-full flex-col">

                <div>
                  {openSide && (
                    <p className="text-black">
                      © All right reserved

                    </p>
                  )}
                </div>


                <div>
                  <span className="font-bold">@{projectName}</span> 2024
                </div>

              </div>
            </footer>

          </motion.div>

          <main>{children}</main>
        </div>
      </div>
    </>
  );
};

export default SideBar;
