import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CardContent } from "@material-ui/core";
import copy from "copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { Tooltip } from "antd";
import Image1 from "../assets/images/weekly_reward.svg";
import UpdateApi from "../Components/UpdateApi";
import { IoIosLock } from "react-icons/io";

import Apisame from "../Components/Apisame";
import SubStatus from "../Components/SubStatus";

const MainDashboard = () => {
  const jwt = localStorage.getItem("jwt");

  const [subsanalytics, setsubsanalytics] = useState([]);
  const [profile1, setProfile1] = useState("");
  const [lefttable, setLefttable] = useState([]);
  const [righttable, setRighttable] = useState([]);
  const [side, setSide] = useState("0");
  const { checkBotFeature, checkTradeFeature } = UpdateApi();

  console.log("Bot F", checkBotFeature);
  console.log("Trade F", checkTradeFeature);

  const View_Su = async () => {
    const postdata = {
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url:
        process.env.REACT_APP_API_PATH_NEW +
        "/subscription/subscription_analytics",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setsubsanalytics(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    View_Su();
  }, []);

  const [botFeature, setBotFeature] = useState([]);

  const View_bot_sub = async () => {
    const postdata = {
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url:
        process.env.REACT_APP_API_PATH_NEW +
        "/subscription/subscription_bot_features",
      data: postdata,
    })
      .then(async (res) => {
        console.log(res);
        setBotFeature(res.data.Success);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    View_bot_sub();
  }, []);

  const botCard = [
    {
      title: "Subscription Value",
      value: botFeature.Subscription_value ? botFeature.Subscription_value : 0,
      imagePath: Image1,
    },
    {
      title: "Total Live API",
      value: botFeature.Live_API ? botFeature.Live_API : 0,
      imagePath: Image1,
    }, 
     {
      title: "Total Paper API",
      value: botFeature.Paper_API ? botFeature.Paper_API : 0,
      imagePath: Image1,
    },
    {
      title: "Total Live Bots",
      value: botFeature.Live_Bots ? botFeature.Live_Bots : 0,
      imagePath: Image1,
    }, 
     {
      title: "Total Paper Bots",
      value: botFeature.Paper_Bots ? botFeature.Paper_Bots : 0,
      imagePath: Image1,
    },
    {
      title: "Active Bots",
      value: 0,
      imagePath: Image1,
    },
    {
      title: "Bot Allocation Fund",
      value: botFeature.Max_Fund_Allotted ? botFeature.Max_Fund_Allotted : 0,
      imagePath: Image1,
    },
    {
      title: "Total Exchange",
      value: botFeature.Exchange ? botFeature.Exchange : 0,
      imagePath: Image1,
    },
    {
      title: "Active API",
      value: 0,
      imagePath: Image1,
    },
    {
      title: "Avg. Profit (%)",
      value: 0,
      imagePath: Image1,
    },
    {
      title: "Avg. Profit ($)",
      value: 0,
      imagePath: Image1,
    },
    {
      title: "Open Position",
      value: 0,
      imagePath: Image1,
    },
    {
      title: "Open Position ($)",
      value: 0,
      imagePath: Image1,
    },
    {
      title: "Closed Trades",
      value: 0,
      imagePath: Image1,
    },
    {
      title: "Total Profit ($)",
      value: 0,
      imagePath: Image1,
    },
  ];

  const View_Profile1 = async () => {
    const postdata = {
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        if (response) {
          setProfile1(response?.data?.Success);
          setSide(response.data.Success.Current_Referral_Side);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    View_Profile1();
  }, []);

  const allKeys = Object.keys({ ...checkBotFeature, ...checkTradeFeature });
  const halfIndex = Math.ceil(allKeys.length / 2);
  const firstHalfKeys = allKeys.slice(0, halfIndex);
  const secondHalfKeys = allKeys.slice(halfIndex);

  console.log("ssd", secondHalfKeys);


// subs table



const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(20);

  const abbreviatedNames = [
   
    "Subscription_Qualification",
  ];

  const tabNames = [
   
    "Subscription",
   
  ];

  const [pageName, setPageName] = useState(abbreviatedNames[0]); 
 
  const { data, totalPages,setData } = Apisame({ pageName, pageSize, page });

  const handleTabClick = (newIndex, pageName) => {
    setIndex(newIndex);
    setPage(1);
    setPageName(pageName);
    setData([]);
    
  };

  const tabContent = [
    <SubStatus key={1} data={{ subData: data, page, pageSize, totalPages,setData, setPage, setpageSize }} />,
  ];















// subs table





  return (
    <>
      <div className="px-2 md:px-8">
        <div className="container ">
          {/* upper part contents wallets and bots starts  */}
          <div className=" gap-4">
            <div
              // ref={ref2}
              className="relative grid grid-cols-1  h-auto  rounded-2xl mb-2 md:mb-0 text-center gap-8 py-4 "
            >
              <div className="grid">
                <div className="">
                  <div className="w-full rounded-xl grid xl:grid-cols-5 grid-cols-1 sm:grid-cols-2 h-auto  md:max-h-[90vh] lg:max-h-[90vh] xl:max-h-[90vh]  hover:border-2 hover:border-neonPurple  p-4 border-2 bg-white  gap-3  border-gray-100 ">
                    {botCard.map((item) => (
                      <div class="div border-2 py-1 px-3   border-gray-200 w-full    rounded-[1em] relative group  z-0 overflow-hidden">
                        <div class="z-[-1] h-full w-[200%] rounded-[1em]     absolute bottom-full right-0  "></div>
                        <div className=" grid grid-cols-1 w-full text-center md:text-left gap-1">
                          <div className="flex justify-start items-center gap-5">
                            <div>
                              <img
                                className="w-[30px]"
                                src={item?.imagePath}
                                alt=""
                              />
                            </div>

                            <h5 class="text-[#94a3b8] uppercase font-bold text-xs">
                              {item?.title}
                            </h5>
                          </div>
                          <div className="flex justify-end">
                            <span class="font-bold text-2xl">
                              {item?.value}
                            </span>
                          </div>
                          <div className="flex justify-end">
                            <p className="text-sm  text-neonPurple "></p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* // */}

              {/* <div className="w-full grid grid-cols-1 h-full gap-2  bg-gradient-to-r from-gray-50 to-gray-100">
                <div className="w-full flex justify-center">
                  <div className="w-full  bg-white rounded-xl  overflow-hidden  hover:border-2 hover:border-neonPurple border-2">
                    <header className="text-center leading-tight bg-neonPurple text-white">
                      <h2 className="uppercase tracking-widest font-bold text-xl">
                        Bot Features Allowed
                      </h2>
                    </header>
                    <div className="grid  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1  border-t border-gray-200 bg-gray-50">
                      {Object.keys(checkBotFeature).map((key) => (
                        <div className="flex p-1 items-center mb-4" key={key}>
                          <div
                            className={`mr-1 w-2 h-2 rounded-full ${
                              checkBotFeature[key]
                                ? "bg-green-500"
                                : "bg-red-500"
                            }`}
                          ></div>
                          <div className="flex">
                            {key.replace(/_/g, " ")} -{" "}
                            <span className="">
                              {checkBotFeature[key] ? (
                                <span className="text-green-700">Active</span>
                              ) : (
                                <span className=" flex items-center">
                                  <IoIosLock className="h-5 w-5 mr-1" />
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="w-full flex justify-center">
                  <div className="w-full  bg-white  shadow-l  rounded-xl overflow-hidden hover:border-2 hover:border-neonPurple border-2">
                    <header className="text-center leading-tight  bg-neonPurple text-white">
                      <h2 className="uppercase tracking-widest font-bold text-xl">
                        Trade Features Allowed
                      </h2>
                    </header>
                    <div className="grid xl:grid-cols-2 lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-2 grid-cols-1  border-t border-gray-200 bg-gray-50">
                      {Object.keys(checkTradeFeature).map((key) => (
                        <div className="flex p-1 items-center mb-4" key={key}>
                          <div
                            className={`mr-1 w-2 h-2 rounded-full ${
                              checkTradeFeature[key]
                                ? "bg-green-500"
                                : "bg-red-500"
                            }`}
                          ></div>
                          <div className="flex">
                            {key.replace(/_/g, " ")} -{" "}
                            <span className="">
                              {checkTradeFeature[key] ? (
                                <span className="text-green-700">Active</span>
                              ) : (
                                <span className=" flex items-center">
                                  <IoIosLock className="h-5 w-5 mr-1" />
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
            
            {/* // */}

            {/* <div className="w-full grid grid-cols-1 h-full gap-2 bg-gradient-to-r from-gray-50 to-gray-100">
              <div className="w-full flex  justify-center text-sm">
                <div className="w-full bg-white rounded-xl overflow-hidden border-2 hover:border-neonPurple">
                  <header className="text-center leading-tight bg-neonPurple text-white">
                    <h2 className="uppercase tracking-widest font-bold text-xl">
                      Features Allowed
                    </h2>
                  </header>

                  <div className="flex flex-col sm:flex-row">
                    <div className="flex-1 grid grid-cols-3 border-b border-t border-[#ccc] bg-gray-50">
                      <div className="text-start font-bold p-2 border-r border-gray-200 mr-2">
                        Title
                      </div>
                      <div className="text-center font-bold p-2 border-r border-gray-200 mr-2">
                        Bots
                      </div>
                      <div className="text-center font-bold p-2">Trade</div>

                      {firstHalfKeys.map((key) => (
                        <React.Fragment key={key}>
                          <div className="flex p-1 items-center border-r border-gray-200 mr-2">
                            {key.replace(/_/g, " ")}
                          </div>
                          <div className="flex p-1 items-center justify-center border-r border-gray-200 mr-2">
                            {checkBotFeature[key] !== undefined ? (
                              <>
                                <div
                                  className={`mr-1 w-2 h-2 rounded-full ${
                                    checkBotFeature[key]
                                      ? "bg-green-500"
                                      : "bg-red-500"
                                  }`}
                                ></div>
                                {checkBotFeature[key] ? (
                                  <span className="text-green-700">Active</span>
                                ) : (
                                  <span className="flex items-center">
                                    <IoIosLock className="h-5 w-5 mr-1" />
                                  </span>
                                )}
                              </>
                            ) : (
                              <span className="text-gray-400">N/A</span>
                            )}
                          </div>
                          <div className="flex p-1 items-center justify-center">
                            {checkTradeFeature[key] !== undefined ? (
                              <>
                                <div
                                  className={`mr-1 w-2 h-2 rounded-full ${
                                    checkTradeFeature[key]
                                      ? "bg-green-500"
                                      : "bg-red-500"
                                  }`}
                                ></div>
                                {checkTradeFeature[key] ? (
                                  <span className="text-green-700">Active</span>
                                ) : (
                                  <span className="flex items-center">
                                    <IoIosLock className="h-5 w-5 mr-1" />
                                  </span>
                                )}
                              </>
                            ) : (
                              <span className="text-gray-400">N/A</span>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>

                    <div className="mx-4 w-1 border-l border-gray-300"></div>

                    <div className="flex-1 grid grid-cols-3 border-t border-gray-200 bg-gray-50">
                      <div className="text-start font-bold p-2 border-r border-gray-200 mr-2">
                        Title
                      </div>
                      <div className="text-center font-bold p-2 border-r border-gray-200 mr-2">
                        Bots
                      </div>
                      <div className="text-center font-bold p-2">Trade</div>

                      {secondHalfKeys
  .filter((key) => key !== "Bot_Package_Active" && key !== "Trade_Package_Active")
  .map((key) => (
    <React.Fragment key={key}>
      <div className="flex p-1 items-center border-r border-gray-200 mr-2">
        {key.replace(/_/g, " ")}
      </div>
      <div className="flex p-1 items-center justify-center border-r border-gray-200 mr-2">
        {checkBotFeature[key] !== undefined ? (
          <>
            <div
              className={`mr-1 w-2 h-2 rounded-full ${
                checkBotFeature[key] ? "bg-green-500" : "bg-red-500"
              }`}
            ></div>
            {checkBotFeature[key] ? (
              <span className="text-green-700">Active</span>
            ) : (
              <span className="flex items-center">
                <IoIosLock className="h-5 w-5 mr-1" />
              </span>
            )}
          </>
        ) : (
          <span className="text-gray-400">N/A</span>
        )}
      </div>
      <div className="flex p-1 items-center justify-center">
        {checkTradeFeature[key] !== undefined ? (
          <>
            <div
              className={`mr-1 w-2 h-2 rounded-full ${
                checkTradeFeature[key] ? "bg-green-500" : "bg-red-500"
              }`}
            ></div>
            {checkTradeFeature[key] ? (
              <span className="text-green-700">Active</span>
            ) : (
              <span className="flex items-center">
                <IoIosLock className="h-5 w-5 mr-1" />
              </span>
            )}
          </>
        ) : (
          <span className="text-gray-400">N/A</span>
        )}
      </div>
    </React.Fragment>
  ))}

{(checkBotFeature["Bot_Package_Active"] !== undefined ||
  checkTradeFeature["Trade_Package_Active"] !== undefined) && (
  <React.Fragment key="Package_Active">
    <div className="flex p-1 items-center border-r border-gray-200 mr-2">
      Package Active
    </div>
    <div className="flex p-1 items-center justify-center border-r border-gray-200 mr-2">
      {checkBotFeature["Bot_Package_Active"] !== undefined ? (
        <>
          <div
            className={`mr-1 w-2 h-2 rounded-full ${
              checkBotFeature["Bot_Package_Active"] ? "bg-green-500" : "bg-red-500"
            }`}
          ></div>
          {checkBotFeature["Bot_Package_Active"] ? (
            <span className="text-green-700">Active</span>
          ) : (
            <span className="flex items-center">
              <IoIosLock className="h-5 w-5 mr-1" />
            </span>
          )}
        </>
      ) : (
        <span className="text-gray-400">N/A</span>
      )}
    </div>
    <div className="flex p-1 items-center justify-center">
      {checkTradeFeature["Trade_Package_Active"] !== undefined ? (
        <>
          <div
            className={`mr-1 w-2 h-2 rounded-full ${
              checkTradeFeature["Trade_Package_Active"] ? "bg-green-500" : "bg-red-500"
            }`}
          ></div>
          {checkTradeFeature["Trade_Package_Active"] ? (
            <span className="text-green-700">Active</span>
          ) : (
            <span className="flex items-center">
              <IoIosLock className="h-5 w-5 mr-1" />
            </span>
          )}
        </>
      ) : (
        <span className="text-gray-400">N/A</span>
      )}
    </div>
  </React.Fragment>
)}

                    </div>
                  </div>
                </div>
              </div>
            </div> */}



{/* // subs  */}
<div className="bg-colorPrimary mx-2 rounded-3xl ">
        <div className="mt-5">
          <div className="flex justify-center items-center ">
          <h1 className="font-semibold text-2xl text-center text-neonPurple uppercase">Subscription </h1>
          </div>
        </div>
     
          {tabContent}
       
      </div>

{/* //  */}
</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainDashboard;
