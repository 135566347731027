import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import WalletAddressTrans from "../Components/WalletAddressTrans";
import WalletToken from "../Components/WalletToken";

const DexTable = ({searchResult,chainId}) => {
  const [index, setIndex] = useState(0);

  const abbreviatedNames = [
    "Wallet_address_transactions",
    "Wallet_address_tokens",
  ];

  const tabNames = [
    "Wallet Address Transaction",
    "Wallet Address Tokens",
  ];

  const handleTabClick = (newIndex) => {
    setIndex(newIndex);
  };

  const tabContent = [
    <WalletAddressTrans key={0} searchResult={searchResult}/>,
    <WalletToken key={1} searchResult={searchResult}/>,
  ];

  return (
    <>
     <div className="bg-colorPrimary mx-2 rounded-3xl">
        <div className="mt-5">
          <div className="flex border-b-2 border-[#ccc6] mobilescroll mx-5">
            {tabNames?.map((tabName, tabIndex) => (
              <div
                key={tabIndex}
                className={`tab py-1 w-auto min-w-fit uppercase px-6 mx-3 text-center cursor-pointer text-sm ${index === tabIndex
                    ? "active-tab border-gradientbottom text-black font-semibold"
                    : "text-[#666666] "
                  }`}
                onClick={() => handleTabClick(tabIndex, abbreviatedNames[tabIndex])}
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          className="mt-3 mx-4"
        >
          {tabContent}
        </SwipeableViews>
      </div>
    </>
  );
};

export default DexTable;