import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const ComposeMail = (props) => {
    const { handleCancel, isModalOpen } = props.data;
    const [editorValue, setEditorValue] = useState('');
    

    const handleChange = (value) => {
        setEditorValue(value);
    };

    const onFinish = (values) => {
        // console.log('Success:', values);
    };

    return (
        <>
            <Modal
                title="New Message"
                visible={isModalOpen}
                onCancel={handleCancel}
                footer={false}
                style={{
                    top: '22%',
                }}
            >
                <div>
                    <Form
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        className=' mt-4'
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            // label="Username"
                            name="to"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter To Input!',
                                },
                            ]}
                        >
                            <Input placeholder='To' className='py-2 focus:border-red-500 hover:border-red-500 focus:ring-red-500 Textplaceholder ' />
                        </Form.Item>

                        <Form.Item
                            // label="Password"
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Subject Input!',
                                },
                            ]}
                        >
                            <Input placeholder='Subject' className='py-2 focus:border-red-500 hover:border-red-500 focus:ring-red-500 Textplaceholder ' />
                        </Form.Item>

                        <ReactQuill
                            className=' h-[200px]'
                            value={editorValue}
                            onChange={handleChange}
                        />

                        <Form.Item className=' mb-0 '>
                            <div className=' mt-16 flex justify-end gap-4'>
                                <button className=' bg-gray-500 py-2 px-4 rounded-xl text-white font-medium'>
                                    close
                                </button>
                                <button type="primary" htmlType="submit" className=' bg-neonBlueShadow py-2 px-4 rounded-xl  text-white font-medium'>
                                    Submit
                                </button>
                            </div>
                        </Form.Item>
                    </Form>

                    {/* <div>
                        <h2>Editor Content:</h2>
                        <div dangerouslySetInnerHTML={{ __html: editorValue }} />
                    </div> */}
                </div>
            </Modal>
        </>
    );
};

export default ComposeMail;
