import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthWrapper from "../Layout/AuthWrapper";
import Signup from "../Pages/Signup";
import Login from "../Pages/Login";
import Forgetpassword from "../Pages/Forgetpassword";
import Forgetusername from "../Pages/Forgetusername";
import Api_Setting from "../Pages/Api_Setting";
import Preffred_exchanges from "../Components/Preffred_exchanges";
import SelectedExchangs from "../Components/SelectedExchange";
import Spotconnectiondetails from "../Pages/Spotconnectiondetails";
import ConnectionLoder from "../Pages/ConnectionLoder";
import APi_details from "../Pages/APi_details";
import ChangePassword from "../Components/ChangePAssword";
import ConfirmForgetpassword from "../Pages/ConfirmForgetpassword";
import EmailVerify from "../Pages/EmailVerify";
import ConfirmUsername from "../Pages/ConfirmUsername";
import Dashboard from "../Layout/Dashborad";
import Profile from "../Pages/Profile";
import Transactionnew from "../Components/Transactionnew";
import Logs from "../Pages/Logs";
import Trades from "../Pages/Trades";
import Reportcopy from "../Components/ReportSwipeable";
import ProtectedRoute from "./ProtectRouter";
import PaymentGateway from "../Components/PaymentGateway";
import FAQ from "../Components/FAQ";
import SupportPage from "../Pages/SupportPage";
import IncomeChart from "../Pages/IncomeChart";
import ActiveSwitch from "../Components/ActiveBotSwipeable";
import Paymentmodel from "../Pages/Paymentmodel";
import PagenotFound from "../Pages/PagenotFound";
import MainDashboard from "../Pages/MainDashboard";
import SupportFinal from "../Pages/SupportFinal";
import Calculator from "../Pages/Calculator";
import Tools from "../Pages/Tools";
import Liquidation from "../Components/Liquidation";
import Botmainpagecopy from "../Pages/Botmainpagecopy";
import Heapmap from "../Components/Heapmap";
import SyntaxGenerator from "../Components/SyntaxGenerator";
import Dex from "../Pages/Dex";
import Market from "../Pages/Market";
import Backtest from "../Pages/Backtest";
import CreateBot from "../Pages/CreateBot";
import ArbitrageTransaction from "../Pages/ArbitrageTransaction";
import LoginAsLogin from "../Layout/LoginAsLogin";
import NFT from "../Pages/NFT";
import MultiDetail from '../Pages/MultiExchangeDetail'
import TradesCopy from "../Pages/TradesCopy";
import Packagesnew from "../Pages/Packagesnew";
import MultiExchange from "../Components/MultiExchange";
import IntraExchange from "../Components/IntraExchange";
import DexArbi from "../Components/Dex";
import ProfilePage from '../Pages/ProfilePage'
import Paperconnectiondetails from "../Pages/Paperconnectiondetails";
import Binancecopy from "../Pages/Binancecopy";
import TradesNewV from "../Pages/TradesNewV";

import BotSwipe from '../Components/BotsSwipeable'

const Routerpage = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const hasUrlParams = urlParams.toString().length > 0;

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<AuthWrapper />}>

            <Route
              index
              element={hasUrlParams ? <LoginAsLogin /> : <Login />}
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/404" element={<PagenotFound />} />
            <Route path="/forgetpassword" element={<Forgetpassword />} />
            <Route path="/forgetusername" element={<Forgetusername />} />
            <Route path="/email_verify" element={<EmailVerify />} />
            <Route path="/submit" element={<ConfirmUsername />} />

            <Route
              path="/update_password"
              element={<ConfirmForgetpassword />}
            />
          </Route>

          <Route
            path="/dashboard"
            // element={<Dashboard />}
            element={<ProtectedRoute Component={Dashboard} />}
          >

            {/* <Route
              index
              // element={<Mainpage />}
              element={<ProtectedRoute Component={Mainpage} />}
            /> */}
            <Route
              path="/dashboard/home"
              element={<ProtectedRoute Component={MainDashboard} />}
            />
            {/* <Route
              exact
              path="/dashboard/profile"
              element={<ProtectedRoute Component={Profile} />}
            />
            <Route
              exact
              path="/dashboard/profiles"
              element={<ProtectedRoute Component={Profile} />}
            /> */}
            <Route
              exact
              path="/dashboard/profile"
              element={<ProtectedRoute Component={ProfilePage} />}
            />
            <Route
              exact
              path="/dashboard/tradehistory"
              element={<ProtectedRoute Component={TradesCopy} />}
            />
            {/* 
            <Route
              exact
              path="/dashboard/trades"
              element={<ProtectedRoute Component={Trades} />}
            /> */}

            <Route
              exact
              path="/dashboard/trades"
              element={<ProtectedRoute Component={TradesNewV} />}
            />
            <Route
              exact
              path="/dashboard/nft"
              element={<ProtectedRoute Component={NFT} />}
            />
            <Route
              exact
              path="/dashboard/botmain"
              element={<ProtectedRoute Component={BotSwipe} />}
            // element={<ProtectedRoute Component={Botmainpagecopy} />}
            // element=<Botmainpagecopy />
            />
            <Route
              exact
              path="/dashboard/APi_details"
              element={<ProtectedRoute Component={APi_details} />}
            />
            <Route
              exact
              path="/dashboard/dex"
              element={<ProtectedRoute Component={Dex} />}
            />
            <Route
              exact
              path="/dashboard/FAQ"
              element={<ProtectedRoute Component={FAQ} />}
            />
            <Route
              exact
              path="/dashboard/liquidation"
              element={<ProtectedRoute Component={Liquidation} />}
            />
            <Route
              exact
              path="/dashboard/heatmap"
              element={<ProtectedRoute Component={Heapmap} />}
            />
            <Route
              exact
              path="/dashboard/report"
              element={<ProtectedRoute Component={Reportcopy} />}
            />
            <Route
              exact
              path="/dashboard/transactiontable"
              element={<ProtectedRoute Component={Transactionnew} />}
            />
            <Route
              exact
              path="/dashboard/logs"
              element={<ProtectedRoute Component={Logs} />}
            />
            <Route
              exact
              path="/dashboard/support"
              element={<ProtectedRoute Component={SupportFinal} />}
            // element=<Support />
            />
            <Route
              exact
              path="/dashboard/incomechart"
              element={<ProtectedRoute Component={IncomeChart} />}
            // element=<Support />
            />
            <Route
              exact
              path="/dashboard/supportpage"
              element={<ProtectedRoute Component={SupportPage} />}
            // element=<Support />
            />

            <Route
              exact
              path="/dashboard/subscription"
              element={<ProtectedRoute Component={Packagesnew} />}
            // element=<Subscirptions />
            />
            <Route
              exact
              path="/dashboard/subscriptions"
              element={<ProtectedRoute Component={Packagesnew} />}
            // element=<Subscirptions />
            />
            {/* <Route
              exact
              path="/dashboard/profile"
              element={<ProtectedRoute Component={Setting} />}
            // element=<Setting />
            /> */}
            <Route
              exact
              path="/dashboard/change_password"
              element={<ProtectedRoute Component={ChangePassword} />}
            // element=<ChangePassword />
            />
            <Route
              exact
              path="/dashboard/activebot"
              element={<ProtectedRoute Component={ActiveSwitch} />}
            // element=<ActiveBot />
            />
            <Route
              exact
              path="/dashboard/calculator"
              element={<ProtectedRoute Component={Calculator} />}
            // element=<ActiveBot />
            />
            <Route
              exact
              path="/dashboard/payment"
              element={<ProtectedRoute Component={PaymentGateway} />}
            // element=<ActiveBot />
            />
            {/* <Route
              exact
              path="/dashboard/arbitrage"
              element={<ProtectedRoute Component={Tools} />}
            // element=<ActiveBot />
            /> */}
            <Route
              exact
              path="/dashboard/multiExchange"
              element={<ProtectedRoute Component={MultiExchange} />}
            />
            <Route
              exact
              path="/dashboard/intraexchange"
              element={<ProtectedRoute Component={IntraExchange} />}
            />
            <Route
              exact
              path="/dashboard/dexarbi"
              element={<ProtectedRoute Component={DexArbi} />}
            />
            <Route
              exact
              path="/dashboard/orderbook"
              element={<ProtectedRoute Component={MultiDetail} />}
            // element=<ActiveBot />
            />

            <Route
              exact
              path="/dashboard/arbitragebot"
              element={<ProtectedRoute Component={ArbitrageTransaction} />}
            // element=<ActiveBot />
            />
            <Route
              exact
              path="/dashboard/syntaxGenerator"
              element={<ProtectedRoute Component={SyntaxGenerator} />}

            />
            <Route
              exact
              path="/dashboard/createbot"
              element={<ProtectedRoute Component={CreateBot} />}

            />
            <Route
              exact
              path="/dashboard/market"
              element={<ProtectedRoute Component={Market} />}

            />
            <Route
              exact
              path="/dashboard/backtest"
              element={<ProtectedRoute Component={Backtest} />}
            />
            {/* <Route
              exact
              path="/dashboard/Unstructured"
              element={<ProtectedRoute Component={UnstructuredTreeDemo} />}
            // element=<Fuel />
            /> */}
            <Route
              exact
              path="/dashboard/api_settings"
              element={<ProtectedRoute Component={Api_Setting} />}
            // element=<Api_Setting />
            >
              <Route
                index
                exact
                element={<ProtectedRoute Component={Preffred_exchanges} />}
              // element=<Preffred_exchanges />
              />

              <Route
                exact
                path="/dashboard/api_settings/Paperfutureconnectiondetails"
                // element={
                //   <ProtectedRoute Component={PaperSpotconnectiondetails} />
                // }
                element={<Paperconnectiondetails />}
              />
              <Route
                exact
                path="/dashboard/api_settings/SelectedExchange"
                element={<ProtectedRoute Component={SelectedExchangs} />}
              // element=<SelectedExchangs />
              />
              <Route
                exact
                path="/dashboard/api_settings/Spotconnectiondetails"
                element={<ProtectedRoute Component={Spotconnectiondetails} />}
              // element=<Spotconnectiondetails />
              />
              <Route
                exact
                path="/dashboard/api_settings/ConnectionLoder"
                element={<ProtectedRoute Component={ConnectionLoder} />}
              // element=<ConnectionLoder />
              />
            </Route>
            <Route
              exact
              path="/dashboard/deposit"
              element={<ProtectedRoute Component={Paymentmodel} />}
            />


            <Route
              exact
              path="/dashboard/binance"
              element={<ProtectedRoute Component={Binancecopy} />}
            />


          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default Routerpage;
