import React, { useState, useEffect } from 'react'
import { Select, Spin, Table, Tooltip, message } from 'antd'
import axios from 'axios'
import { TfiReload } from "react-icons/tfi";


const OpenPosition = (props) => {
  const { username, option, newOption } = props?.data

  const [futureData, setFutureData] = useState([])
  const [loading, setLoading] = useState(false);
  const [option2, setOption2] = useState(option)
  const [Api, setApi] = useState(option[0]?.label)

  useEffect(() => {
    setFutureData([])
    setOption2(option)
    const fisrtLabel = option[0]?.label
    setApi(fisrtLabel)
  }, [option])

  const openPositionFut = () => {
    setFutureData([])
    setLoading(true)
    const jwt = localStorage.getItem('jwt')
    const postData = {
      jwt: jwt,
      username: username,
      api_name: newOption
    }
    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "open_position_futures",
      data: postData
    }).then(res => {
      const resposneData = res.data.Success
      const formattedData = resposneData.map(item => ({
        ...item,
        Update_Time: new Date(item?.Update_Time).toLocaleDateString('en-GB') + ' ' + new Date(1701540808180).toLocaleTimeString()
      }))
      setFutureData(formattedData)
      setLoading(false)
    })
      .catch(err => {
        setLoading(false)
        // message.error(err?.response?.data?.Error)
      })
  }

  useEffect(() => {
    openPositionFut()
  }, [newOption])

  const dataSource = []

  if (futureData) {
    for (let i = 0; i < futureData.length; i++) {
      dataSource.push({
        Symbol: <p className={`p-1 rounded-lg  border-l-4 flex ${futureData[i]?.Position_Side == "LONG" ? " border-green-500" : "border-red-500 "}`}>{futureData[i]?.Symbol} <span className=' text-yellow-500 '>{futureData[i]?.Leverage}x</span></p>,
        Entry_Price: <p className='p-1 rounded-lg '>{futureData[i]?.Entry_Price}</p>,
        Mark_Price: <p className='p-1 rounded-lg '>{futureData[i]?.Mark_Price}</p>,
        Unrealized_Profit: <p className={`p-1 rounded-lg  ${futureData[i]?.Unrealized_Profit > 0 ? "text-green-500" : 'text-red-900'}`}>{futureData[i]?.Unrealized_Profit}</p>,
        Liquidation_Price: <p className='p-1 rounded-lg '>{futureData[i]?.Liquidation_Price}</p>,
        Leverage: <p className='p-1 rounded-lg '>{futureData[i]?.Leverage}</p>,
        Margin_Type: <p className='p-1 rounded-lg '>{futureData[i]?.Margin_Type}</p>,
        Position_Side: <p className='p-1 rounded-lg '>{futureData[i]?.Position_Side}</p>,
        Notional: <p className={`p-1 rounded-lg  ${futureData[i]?.Notional > 0 ? "text-green-500" : 'text-red-900'}`}>{futureData[i]?.Notional} USDT</p>,
        Update_Time: <p className='p-1 rounded-lg '>{futureData[i]?.Update_Time}</p>,
        PosiAmoun: <p className={`p-1 rounded-lg  ${futureData[i]?.Size > 0 ? "text-green-500" : 'text-red-900'}`}>{futureData[i]?.Size}</p>
      })
    }
  }

  const columns = [
    // {
    //   title: <p className='p-1 text-center rounded-lg table-cell'>Update Time</p>,
    //   dataIndex: 'Update_Time',
    //   key: 'Update_Time',
    //   width : 200
    // },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Symbol</p>,
      dataIndex: 'Symbol',
      key: 'Symbol',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Size</p>,
      dataIndex: 'Notional',
      key: 'Notional',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Entry Price</p>,
      dataIndex: 'Entry_Price',
      key: 'Entry_Price',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Mark Price</p>,
      dataIndex: 'Mark_Price',
      key: 'Mark_Price',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Liq.price</p>,
      dataIndex: 'Liquidation_Price',
      key: 'Liquidation_Price',
    },
    // {
    //   title: <p className='p-1 text-center rounded-lg table-cell'>Leverage</p>,
    //   dataIndex: 'Leverage',
    //   key: 'Leverage',
    // },
    // {
    //   title: <p className='p-1 text-center rounded-lg table-cell'>MarginType</p>,
    //   dataIndex: 'Margin_Type',
    //   key: 'Margin_Type',
    // },
    {
      title: <Tooltip title="Position Side" placement="top"><p className='p-1 text-center rounded-lg table-cell'>PS</p></Tooltip>,
      dataIndex: 'Position_Side',
      key: 'Position_Side',
    },
    {
      title: <Tooltip title="Position Amount" placement="top"><p className='p-1 text-center rounded-lg table-cell'>PA</p></Tooltip>,
      dataIndex: 'PosiAmoun',
      key: 'PosiAmoun',
    },
    // {
    //   title: <p className='p-1 text-center rounded-lg table-cell'>Notional</p>,
    //   dataIndex: 'Notional',
    //   key: 'Notional',
    // },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Unrealized Pnl</p>,
      dataIndex: 'Unrealized_Profit',
      key: 'Unrealized_Profit',
    },
  ]


  const handleChange = (value) => {
    setApi(value)
  }

  return (
    <div>
      {/* <span className=' text-base font-semibold'>API Name : </span>
      <Select
        value={Api}
        className='focus:border-colorAccent hover:border-colorAccent'
        style={{
          width: 180,
        }}
        placeholder="Select API Name"
        onChange={handleChange}
        options={option2}
      /> */}
      <div className='my-3 flex flex-col md:flex-row md:items-center gap-2 px-2'>
        <p>Data may be displayed with a delay of 1-2 minutes, please click Refresh to update the data </p>
        <button className=' flex items-center gap-2  text-colorSecondaryDark font-medium' onClick={openPositionFut}><TfiReload className='  ' /> Refresh</button>
      </div>
      <div className=' mt-4 '>
        <Spin spinning={loading} >
          <Table columns={columns} dataSource={dataSource}  scroll={{
            x: 1500,
          }}  />
        </Spin>
      </div>
    </div>
  )
}

export default OpenPosition