import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import OpenTrades from "../Components/OpenTrades";
import TradesHistroy from "../Components/TradesHistroy";
import TradesCopy from "../Pages/TradesCopy";
import { Activebotnew } from "./Activebotnew";
import { KucoinApis } from "./KucoinApis";
import Botmainpagecopy from "../Pages/Botmainpagecopy";
import KucoinMain from "../Pages/KucoinMain";



const SwipeableTabs = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(20);

  const abbreviatedNames = [
    "Kucoin",
    "Binance",
  ];

  const tabNames = [
    "Kucoin",
    "Binance",
   
  ];

  const [pageName, setPageName] = useState(abbreviatedNames[0]); 
 

  const handleTabClick = (newIndex, pageName) => {
    setIndex(newIndex);
    setPage(1);
    setPageName(pageName);    
  };

  const tabContent = [
    <KucoinMain key={0} />,
    <Botmainpagecopy key={1}/>,
  
  ];

  return (
    <>
     <div className="bg-colorPrimary mx-2 rounded-3xl">
        <div className="mt-5">
          <div className="flex border-b-2 border-[#ccc6] mobilescroll mx-5">
            {tabNames?.map((tabName, tabIndex) => (
              <div
                key={tabIndex}
                className={`tab py-1 w-auto min-w-fit uppercase px-6 mx-3 text-center cursor-pointer text-sm ${index === tabIndex
                    ? "active-tab border-gradientbottom text-black font-semibold"
                    : "text-[#666666] "
                  }`}
                onClick={() => handleTabClick(tabIndex, abbreviatedNames[tabIndex])}
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          className="mt-3 mx-4"
        >
          {tabContent}
        </SwipeableViews>
      </div>
    </>
  );
};

export default SwipeableTabs;
