import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin, Table, Input, Select, Button } from "antd";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import Apisame from "./Apisame";
import UpdateApi from "./UpdateApi";

const { Option } = Select;

const NFTLowestPrice = ({ refresh }) => {
  const [datas, setDatas] = useState(null);
  const [address, setAddress] = useState("0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D");
  const [chain, setChain] = useState("eth");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.NFT_Analyitics;

  const fetchData = () => {
    setLoading(true);
    const url = `https://deep-index.moralis.io/api/v2.2/nft/${address}/lowestprice?chain=${chain}&marketplace=opensea`;
    const headers = {
      Accepts: "application/json",
      "X-API-Key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MjI2MTZmLWM4MzItNDJmZS04YzI1LTA4NGZiNTAyY2ZmOCIsIm9yZ0lkIjoiMzcwMjAxIiwidXNlcklkIjoiMzgwNDY5IiwidHlwZUlkIjoiYTM5MmYyMTItZGQ1OC00NjY2LWEyMGMtN2E2MDdlZGU3YmZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDM5MzEwMjMsImV4cCI6NDg1OTY5MTAyM30.1ik9oveoExEKuAyXdSIWTKWo3i22Yp59_37ZDIJl8ew",
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const result = response.data;
        const dataArray = Array.isArray(result) ? result : [result];
        setDatas(dataArray);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (checkTradePackage === true) {
      fetchData();
    }
  }, [checkTradePackage, refresh]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleChainChange = (value) => {
    setChain(value);
  };

  const columns = [
    {
      title: "Token ID",
      dataIndex: ["token_ids", 0],
      key: "token_id",
    },
    {
      title: "Collection Name",
      dataIndex: "collection_name",
      key: "collection_name",
    },
    {
      title: "Collection Logo",
      dataIndex: "collection_logo",
      key: "collection_logo",
      render: (collection_logo) => (
        <img src={collection_logo} alt="Collection Logo" style={{ width: 20, height: 20 }} />
      ),
    },
    {
      title: "Marketplace Logo",
      dataIndex: "marketplace_logo",
      key: "marketplace_logo",
      render: (marketplace_logo) => (
        <img src={marketplace_logo} alt="Marketplace Logo" style={{ width: 20, height: 20 }} />
      ),
    },
    {
      title: "Last Sale Price (ETH)",
      dataIndex: "price_formatted",
      key: "last_sale_price",
    },
    {
      title: "Last Sale USD Value",
      dataIndex: "current_usd_value",
      key: "last_sale_usd_value",
    },
  ];

  return (
    <>
      <div className="mx-5">
        <div className='flex justify-between items-center'>
          <h2 className="text-xl font-bold mb-4">Filter Form</h2>
          <div>
            <button
              onClick={fetchData}
              className="bg-neonPurple text-white px-4 py-1 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
            >
              Save
            </button>
            <button

              className="bg-gray-400 text-white px-4 py-1 rounded-md shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Clear Filters
            </button>
          </div>
        </div>
        <Input value={address} onChange={handleAddressChange} style={{ width: 250 }} />
        <Select value={chain} onChange={handleChainChange} style={{ width: 120 }}>
          <Option value="eth">ETH</Option>
          <Option value="bsc">BSC</Option>
          {/* Add more options as needed */}
        </Select>

        {loading ? (
          <Spin
            size="large"
            style={{ display: "block", margin: "100px auto" }}
          />
        ) : (
          <Table dataSource={datas} columns={columns} pagination={false} rowKey="transaction_hash" />
        )}
      </div>
    </>
  );
};

export default NFTLowestPrice;
