import { Col, Modal, Row } from 'antd'
import React from 'react'

function WalletDetails({setIsModalOpen,profile }) {
    if (!profile) {
        return <div>Loading Wallet...</div>;
      }
    return (
        <div className='w-full px-7' >
            <div className="block md:flex w-full  lg:justify-between text-sm items-center border border-[#ccc] border-dashed py-1 px-2 rounded-md mt-10">
                <div className="flex items-center gap-2">
                    <div className=" font-semibold text-sm md:text-lg">Wallet Address:</div>
                    <div>
                        {profile?.Wallet ? profile.Wallet.Address : "No wallet address available"}
                        {/* {profile.Wallet ? profile.Wallet.Address.slice(0, 4) + "****" + profile.Wallet.Address.slice(-5, -1) : ""} */}
                    </div>
                   
                </div>
                <div className='flex justify-center items-center md:block'>
                    <button onClick={() => setIsModalOpen(true)} className=" text-sm font-semibold text-white active rounded-xl py-1 px-4 ">
                        Update wallet
                    </button>
                </div>
            </div>

            <div className='mt-4 px-2'>
                  <span className='font-semibold text-sm md:text-lg'>Network: </span> {profile.Wallet.Network}
                    </div>

           
            </div>
    )
}

export default WalletDetails