import React, { useState, useEffect } from 'react';
import { Table, Tooltip, message, Spin, Empty } from 'antd';
import { FaRegCopy } from 'react-icons/fa';
import UpdateApi from './UpdateApi';

const ExchangeDex = () => {
    const [apiResponse, setApiResponse] = useState('');
    const [apiResponse1, setApiResponse1] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeImage, setActiveImage] = useState("https://1inch.io/");
    const { checkBotFeature, checkTradeFeature } = UpdateApi();
    const checkTradePackage = checkTradeFeature.Blockchain_Analytics;

    useEffect(() => {
        if (checkTradePackage === true) {
            handleClick('https://1inch.io/');
        }
    }, [checkTradePackage]);

    const handleClick = async (url) => {
        try {
            setLoading(true);
            const response = await fetch(`https://api.gopluslabs.io/api/v1/dapp_security?url=${url}`);
            const data = await response.json();
            setApiResponse(data);
            setActiveImage(url);
            if (data.result) {
                setApiResponse1(data.result.contracts_security);
            } else {
                setApiResponse1([]);
            }
        } catch (error) {
            console.error('Error fetching API:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        message.success('Copied to clipboard');
    };

    const images = [
        { name: 'SushiSwap', link: 'https://www.sushi.com/' },
        { name: 'pancakeSwap-logo', link: 'https://pancakeswap.finance/' },
        { name: 'UNISWAP', link: 'https://app.uniswap.org/' },
        // { name: 'apeswap', link: 'https://apeswap.finance/' },
        { name: '1inch', link: 'https://1inch.io/' },
        { name: 'openOcean', link: 'https://openocean.finance/' },
        { name: 'Balancer', link: 'https://balancer.fi/' },
        { name: 'curve', link: 'https://www.curve.fi/' },
        { name: 'DODO', link: 'https://dodoex.io/' },
        { name: 'Quickwap', link: 'https://quickswap.exchange/' },
    ];

    const chainOptions = [
        { value: "1", label: "Ethereum" },
        { value: "10", label: "Optimism" },
        { value: "25", label: "Cronos" },
        { value: "56", label: "BSC" },
        { value: "66", label: "OKC" },
        { value: "100", label: "Gnosis" },
        { value: "128", label: "HECO" },
        { value: "137", label: "Polygon" },
        { value: "250", label: "Fantom" },
        { value: "321", label: "KCC" },
        { value: "324", label: "zkSync Era" },
        { value: "10001", label: "ETHW" },
        { value: "201022", label: "FON" },
        { value: "42161", label: "Arbitrum" },
        { value: "43114", label: "Avalanche" },
        { value: "59144", label: "Linea Mainnet" },
        { value: "8453", label: "Base" },
        { value: "tron", label: "Tron" },
        { value: "534352", label: "Scroll" },
        { value: "204", label: "opBNB" },
        { value: "1666600000", label: "Harmony" },
        { value: "1666600001", label: "Harmony" }
    ];

    return (
        <div className="mx-4">
            <div className=' bg-white shadow-md p-4 mb-4 flex justify-between items-center '>
                {images.map((image, index) => (
                    <a key={index} onClick={() => handleClick(image.link, image.name)}>
                        <Tooltip title="Click this Exchange">
                            <img
                                src={`/images/ExchangeDex/${image.name}.svg`}
                                alt={image.name}
                                className={`w-28 border-2 rounded-md px-1 ${activeImage === image.link ? 'bg-white shadow-md shadow-neonPurple ' : 'border-gray-200'}`}
                            />
                        </Tooltip>
                    </a>
                ))}
            </div>

            <div className="">
                {loading ? (
                    <Spin />
                ) : (
                    apiResponse && apiResponse.result ? (
                        <div className='grid grid-cols-1  md:grid-cols-2 gap-5 bg-white shadow-md p-4 mb-4'>
                            <div>
                                <h1 className='text-sm'> <span className='font-semibold'>Project Name: </span>{apiResponse.result.project_name}</h1>
                                <p className='text-sm'> <span className='font-semibold'>URL:</span> <a className="text-blue-500"
                                    target="_blank"
                                    rel="noopener noreferrer">{apiResponse.result.url} </a></p>
                                <p className='text-sm'> <span className='font-semibold'>Audit: </span>{apiResponse.result.is_audit === 1 ? "Yes" : "No"}</p>
                                <p className='text-sm'> <span className='font-semibold'>Trust List:</span> {apiResponse.result.trust_list === 1 ? "Yes" : "No"} </p>
                            </div>
                            <div>
                                <div className="">
                                    <p className='text-sm'> <span className='font-semibold'>Audit Time: </span>{apiResponse.result.audit_info[0].audit_time}</p>
                                    <p className='text-sm'> <span className='font-semibold'>Audit Firm:</span> {apiResponse.result.audit_info[0].audit_firm}</p>
                                    <p className='text-sm'> <span className='font-semibold'>Audit Link:{' '}</span>
                                        <a
                                            href={apiResponse.result.audit_info[0].audit_link}
                                            className="text-blue-500"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {apiResponse.result.audit_info[0].audit_link}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='empty-container mt-20 h-full w-full flex justify-center items-center'>
                            <Empty />
                        </div>
                    )
                )}
            </div>

            {apiResponse && apiResponse1.length > 0 && (
                <div className='bg-white rounded-lg shadow-md px-6 py-2'>
                    {apiResponse1.map((contractsGroup, index) => (
                        <div key={index}>
                            <div className='flex justify-between items-center my-2'>
                                <h2 className='font-semibold'>Contracts Security </h2>
                                <p><span className='font-semibold'>Chain ID: </span> {contractsGroup.chain_id}({chainOptions.find(option => option.value === contractsGroup.chain_id)?.label})</p>
                            </div>
                            <Table
                                dataSource={contractsGroup.contracts}
                                columns={[
                                    {
                                        title: 'Deployment Time',
                                        dataIndex: 'deployment_time',
                                        key: 'deployment_time',
                                        render: (text) => new Date(text * 1000).toLocaleString(),
                                    },
                                    {
                                        title: 'Contract Address',
                                        dataIndex: 'contract_address',
                                        key: 'contract_address',
                                        render: (text, record) => (
                                            <Tooltip title={<span className=''>{record.contract_address} <FaRegCopy onClick={() => handleCopy(record.contract_address)} /></span>}>
                                                <span >
                                                    {`${record.contract_address?.slice(0, 4)}...${record.contract_address?.slice(-4)}`}
                                                </span>
                                            </Tooltip>
                                        ),
                                    },
                                    {
                                        title: 'Malicious Contract',
                                        dataIndex: 'malicious_contract',
                                        key: 'malicious_contract',
                                        render: (text) => (text ? 'Yes' : 'No'),
                                    },
                                    {
                                        title: 'Creator Address',
                                        dataIndex: 'creator_address',
                                        key: 'creator_address',
                                        render: (text, record) => (
                                            <Tooltip title={<span className=''>{record.creator_address} <FaRegCopy onClick={() => handleCopy(record.creator_address)} /></span>}>
                                                <span >
                                                    {`${record.creator_address?.slice(0, 4)}...${record.creator_address?.slice(-4)}`}
                                                </span>
                                            </Tooltip>
                                        ),
                                    },
                                    {
                                        title: 'Malicious Creator',
                                        dataIndex: 'malicious_creator',
                                        key: 'malicious_creator',
                                        render: (text) => (text ? 'Yes' : 'No'),
                                    },
                                    {
                                        title: 'Open Source',
                                        dataIndex: 'is_open_source',
                                        key: 'is_open_source',
                                        render: (text) => (text ? 'Yes' : 'No'),
                                    },
                                ]}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ExchangeDex;
