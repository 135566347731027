import React, { useEffect } from "react";
import { Button, Col, Input, Row} from "antd";
// import { Label } from "@material-ui/icons";
// import { FormLabel } from "@mui/material";
import { Form, InputNumber, Popconfirm, Table, Typography } from "antd";
import { useState } from "react";
// import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
// import { Grid, TextField } from "@material-ui/core";
// import "../../styles/ui/calculator.less"
// import BinanceService from "../../services/binance.service";
import { useParams } from "react-router-dom";
// import {  Typography } from "@material-ui/core";
// import Test1 from "../Test1";
// import Test from "../../services/Test"
import  { useContext, useRef } from "react";
// import "../Calc/style.css"



const { Title } = Typography;




const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "0",
    marginTop: "3%",
    marginBottom: "2%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10%",
    },
  },
  table: {
    minWidth: 700,
  },
  color: {
    color: "#fff",
    fontWeight: 600,
    marginTop: "2% ",
    [theme.breakpoints.down("sm")]: {
      margin: "30px 0px",
    },
  },
  color1: {
    color: "#fff",
  },
  marginbox: {
    margin: "1rem ",
  },
}));

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const Index = ({data}) => {
  const classes = useStyles();

  var { symbol, userId, apiId } = useParams();


  var [openPositiontrade, setopenPositiontrade] = useState([]);
  const [Walletusdt, setWalletusdt] = useState(0);
  const [Availableusdt, setAvailableusdt] = useState(0);



  //  const fetchopenPosition = async () => {
  //    const payload = {
  //      api_id: apiId,
  //      user_id: userId,
  //    };

  //    const response = await BinanceService.subtraderopenPosition(payload);
  //    const filteredOpenPositions = response?.data?.openposition?.filter(
  //      (position) => position.symbol === symbol
  //    );
  //    setopenPositiontrade(filteredOpenPositions);

  //    var response12 = await BinanceService.subtradergetAccount(payload);
  //    setWalletusdt(response12.data.balance);
  //    setAvailableusdt(response12.data.availableBalance);
  //  };

  //  useEffect(() => {
  //    fetchopenPosition();
  //  }, [userId, apiId]);

   var directionorder = openPositiontrade[0]?.positionAmt > 0 ? 1 : -1;
   
  var margin =
  parseFloat(openPositiontrade[0]?.notional) / parseFloat(openPositiontrade[0]?.leverage);
margin = Math.abs(margin.toFixed(2));

  var initialData = [
    {
      key: "0",
      entry_price: openPositiontrade[0]?.entryPrice,
      exit_price: "",
      quantity: openPositiontrade[0]?.positionAmt,
      leverage: openPositiontrade[0]?.leverage,
      // profit: "",
      // Liquidation_Price: openPositiontrade[0]?.liquidationPrice,
      // requiredFund: openPositiontrade[0]?.markPrice,
      // usdtWalletFund: margin,
      // profitLoss: openPositiontrade[0]?.markPrice,
      // tradingFees: openPositiontrade[0]?.markPrice,
    },
  ];
  // // let initialRows = [];
  // // some code that populates the initialRows array
  const [dataSource, setDataSource] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);

  


  const [count, setCount] = useState(1);
  const [count2, setCount2] = useState(1);



  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  
const handleExitPriceChange = (value, key) => {
  console.log("handlePriceChange called with value:", value, "and key:", key);

  const newData = [...dataSource];
  const index = newData.findIndex((item) => key === item.key);
  if (index > -1) {
    newData[index].exit_price = value;

    /////// PNL for buying asset
    let pnl = Number(
      (newData[index].quantity *
        directionorder *
        Number(newData[index].exit_price - newData[index].entry_price)) /
        Number(newData[index].leverage)
    );

    pnl = pnl * Number(newData[index].leverage);
    console.log(newData[index].entry_price, "pnl");

    newData[index].profit = pnl;
    console.log(newData[index].entry_price);
    setDataSource(newData);

    // Update input element with updated buying value
    const inputElement = document.querySelector(
      `input[data-row-key="${key}"][data-row-name="entry_price"]`
    );
    if (inputElement) {
      inputElement.defaultValue = newData[index].entry_price;
    }
  }
};

const handleEntryPriceChange = (value, key) => {
  const newData = [...dataSource];
  const index = newData.findIndex((item) => key === item.key);
  if (index > -1) {
    newData[index].entry_price = value;

    // Update profit/loss based on new entry_price
    let pnl = Number(
      (newData[index].quantity *
        directionorder *
        Number(newData[index].exit_price - newData[index].entry_price)) /
        Number(newData[index].leverage)
    );
    pnl = pnl * Number(newData[index].leverage);
    newData[index].profit = pnl;

    
         let IMR = Number(directionorder / newData[index].leverage);

         let intialmargin = Number(
           newData[index].quantity * value * IMR
         );

         newData[index].usdtWalletFund = Math.abs(intialmargin);

    setDataSource(newData);
  }
};

const handleQuantityChange = (value, key) => {


  const newData = [...dataSource];
  const index = newData.findIndex((item) => key === item.key);
  if (index > -1) {
    newData[index].quantity = value;

    // PNL for buying asset
    let pnl = Number(
      (newData[index].quantity *
        directionorder *
        Number(newData[index].exit_price - newData[index].entry_price)) /
        Number(newData[index].leverage)
    );

    pnl = pnl * Number(newData[index].leverage);

         let IMR = Number(directionorder / newData[index].leverage);

         let intialmargin = Number(
           value * newData[index].entry_price * IMR
         );

                 newData[index].usdtWalletFund = Math.abs(intialmargin);

    newData[index].profit = pnl;
    setDataSource(newData);

    // Update input element with updated quantity value
    const inputElement = document.querySelector(
      `input[data-row-key="${key}"][data-row-name="quantity"]`
    );
    if (inputElement) {
      inputElement.defaultValue = newData[index].quantity;
    }
  }
};


const handleLeverageChange = (value, key) => {


    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      newData[index].leverage = value;

      // PNL for buying asset
      let pnl = Number(
        (newData[index].quantity *
          directionorder *
          Number(newData[index].exit_price - newData[index].entry_price)) /
          1
      );

    
        pnl =  pnl * value;

          let IMR = Number(directionorder / value)
          
          let intialmargin = Number(
            newData[index].quantity * newData[index].entry_price * IMR
          );

            
        newData[index].usdtWalletFund = Math.abs(intialmargin);

      newData[index].profit = pnl;
      setDataSource(newData);

   const inputElement = document.querySelector(
     `input[data-row-key="${key}"][data-row-name="leverage"]`
   );
   if (inputElement) {
     inputElement.defaultValue = newData[index].leverage;
   }

    }
};

const handleLiquidationPrice = (value,key) => {
  console.log(
    "handleLiquidationPrice called with value:",
    value,
    "and key:",
    key
  );

  const newData = [...dataSource];
  const index = newData.findIndex((item) => key === item.key);
  if (index > -1) {
    newData[index].Liquidation_Price = value;
    setDataSource(newData);

    let pnl = Number(
      (newData[index].quantity *
        1 *
        Number(newData[index].exit_price - newData[index].entry_price)) /
        Number(newData[index].leverage)
    );

    pnl = pnl * newData[index].leverage;
    console.log(newData[index].entry_price, "pnl");

    newData[index].profit = pnl;
    console.log(newData[index].entry_price);
    setDataSource(newData);

    const inputElement = document.querySelector(
      `input[data-row-key="${key}"][data-row-name="Liquidation_Price"]`
    );
    if (inputElement) {
      inputElement.defaultValue = newData[index].Liquidation_Price;
    }
  }
};


const handleMarginChange = (value,key) => {


  const newData = [...dataSource];
  const index = newData.findIndex((item) => key === item.key);
  if (index > -1) {
    newData[index].usdtWalletFund = Math.abs(value);
    setDataSource(newData);

    let size = Number(value / newData[index].entry_price) * Number(newData[index].leverage)
    let pnl = Number(
      (size *
        directionorder *
        Number(newData[index].exit_price - newData[index].entry_price)) /
        Number(newData[index].leverage)
    );

    pnl = pnl * newData[index].leverage;
    newData[index].profit = pnl;

    // console.log(newData[index].entry_price, "pnl");

     newData[index].quantity = size;
    // console.log(newData[index].entry_price);
    setDataSource(newData);

    const inputElement = document.querySelector(
      `input[data-row-key="${key}"][data-row-name="usdtWalletFund"]`
    );
    if (inputElement) {
      inputElement.defaultValue = Math.abs(newData[index].usdtWalletFund);
    }
  }
};





const [editingKey, setEditingKey] = useState("");
const [form] = Form.useForm();


  const columns = [
    {
      title: "Entry Price ($)",
      dataIndex: "entry_price",
      key: "entry_price",
      render: (text, record) => (
        <InputNumber
          min={0}
          value={record.entry_price}
          onChange={(value) => handleEntryPriceChange(value, record.key)}
          data-row-key={record.key}
          data-row-name="entry_price"
        />
      ),
    },

    {
      title: "Required Margin ($)",
      dataIndex: "usdtWalletFund",
      key: "usdtWalletFund",
      editable: true,
      render: (text, record) => (
        <InputNumber
          min={0}
          value={record.usdtWalletFund}
          onChange={(value) => handleMarginChange(value, record.key)}
          data-row-key={record.key}
          data-row-name="usdtWalletFund"
        />
      ),

     
    },

    {
      title: "Requried Size",
      dataIndex: "quantity",
      key: "quantity",
      editable: true,
      render: (text, record) => (
        <InputNumber
          min={0}
          value={record.quantity}
          onChange={(value) => handleQuantityChange(value, record.key)}
          data-row-key={record.key}
          data-row-name="quantity"
        />
      ),
    },


    {
      title: "Exit Price ($)",
      dataIndex: "exit_price",
      key: "exit_price",
      editable: true,
      render: (text, record) => (
       
        <InputNumber
          min={0}
          defaultValue={record.exit_price}
          onChange={(value) => handleExitPriceChange(value, record.key)}
          data-row-key={record.key} 
        />
       
      ),
    },

    {
      title: "Leverage (x)",
      dataIndex: "leverage",
      key: "leverage",
      editable: true,
      render: (text, record) => (
        <InputNumber
          min={0}
          value={record.leverage}
          onChange={(value) => handleLeverageChange(value, record.key)}
          data-row-key={record.key}
          data-row-name="leverage"
        />
      ),
    },

    {
      title: "Expected Take Profit ($)",
      dataIndex: "profit",
      key: "profit",
      editable: true,
      render: (text, record) => <div>{Number(record.profit).toFixed(2)}</div>,
    },


   

    

    {
      title: "Liquidation Price",
      dataIndex: "Liquidation_Price",
      key: "Liquidation_Price",
      editable: true,
      render: (text, record) => (
        <InputNumber
          min={0}
          value={record.Liquidation_Price}
          onChange={(value) => handleLiquidationPrice(value, record.key)}
          data-row-key={record.key}
          data-row-name="Liquidation_Price"
        />
      ),
    },
    // {
    //   title: "Required Fund ($)",
    //   dataIndex: "requiredFund",
    //   key: "requiredFund",
    //   editable: true,
    // },
   
    // {
    //   title: "Profit/Loss %",
    //   dataIndex: "profitLoss",
    //   key: "profitLoss",
    //   editable: true,
    // },
    // {
    //   title: "Total Trading Fees",
    //   dataIndex: "tradingFees",
    //   key: "tradingFees",
    //   editable: true,
    // },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <>
            {/* <Button style={{ marginBottom: 0 }}>Buy</Button> */}
            <Popconfirm
              title="Sure to delete?"
              onClick={() => handleDelete(record.key)}
            >
              <Button style={{ marginBottom: 0 }}>Delete</Button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];

   const columns1 = [
     {
       title: "Avg Price if subsequent order",
       dataIndex: "entry_price",
       key: "entry_price",
       editable: true,

    
     },

     {
       title: "Expected Profit/loss ($) / (%) (if subsequent order done)",
       dataIndex: "pnl",
       key: "pnl",
       editable: true,
       
     },

     {
       title: "Remaining Capital ($)",
       dataIndex: "required_fund",
       key: "required_fund",
       editable: true,
       
     },

     {
       title: "Total Quantity Requried (Wallet TOKEN+Sub Orders Token)",
       dataIndex: "quantity",
       key: "quantity",
       editable: true,
       
     },

     {
       title: "Total Trading Fees (Buyiing+Selling) $",
       dataIndex: "profit",
       key: "profit",
       editable: true,
       
     },

   ];

  

    var exitPriceSum = dataSource.reduce((acc, trade) => {
      return acc + parseFloat(trade.exit_price);
    }, 0);

    var exitPriceAverage = exitPriceSum / dataSource.length;


    var totalQuantity = dataSource.reduce((acc, trade) => {
      return acc + parseFloat(Math.abs(trade.quantity));
    }, 0);
    totalQuantity = totalQuantity + Math.abs(openPositiontrade[0]?.positionAmt)


    

    var totalavgprice = dataSource.reduce((acc, trade) => {
      return acc + parseFloat(trade.entry_price * trade.quantity);
    }, 0);
    
    totalavgprice = parseFloat(totalavgprice) + parseFloat(openPositiontrade[0]?.entryPrice * Math.abs(openPositiontrade[0]?.positionAmt))
    totalavgprice = parseFloat(totalavgprice) / parseFloat(totalQuantity);




    var totalUsdt = dataSource.reduce((acc, trade) => {
      return acc + parseFloat(trade.usdtWalletFund);
    }, 0);
    totalUsdt = totalUsdt + parseFloat(margin)
    
    var totalFundrequired = dataSource.reduce((acc, trade) => {
      return acc + parseFloat(trade.usdtWalletFund);
    }, 0);
    totalFundrequired =  parseFloat(Availableusdt) - parseFloat(totalFundrequired);

    var totalpnl = dataSource.reduce((acc, trade) => {
      return acc + parseFloat(trade.profit);
    }, 0);
    if(directionorder == 1){
      totalpnl =  (exitPriceAverage - totalavgprice) * totalQuantity ;

    }else{
      totalpnl =  (totalavgprice - exitPriceAverage) * totalQuantity ;

    }
    totalpnl = totalpnl.toFixed(2)

    var totalpnlpercent = parseFloat(totalpnl / totalUsdt) * 100;
    totalpnlpercent = totalpnlpercent.toFixed(2);

    const dataSource1 = [
      {
        key: 1,
        entry_price: totalavgprice,
        pnl: '$ ' + totalpnl + ' / ' + totalpnlpercent + '%',
        required_fund: totalFundrequired,
        quantity: totalQuantity,
        profit: 5,
      },   
    ];

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };


  const handleAdd = () => {
    const newData = {
      key: count.toString(),
      entry_price: openPositiontrade[0]?.entryPrice,
      exit_price: "",
      quantity: Math.abs(openPositiontrade[0]?.positionAmt),
      leverage: openPositiontrade[0]?.leverage,
      profit: "",
      Liquidation_Price: openPositiontrade[0]?.liquidationPrice,
      requiredFund: openPositiontrade[0]?.markPrice,
      usdtWalletFund: Math.abs(margin),
      profitLoss: openPositiontrade[0]?.markPrice,
      tradingFees: openPositiontrade[0]?.markPrice,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  
  

  
  


  
 

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

 


  return (
    <>
      <div className={classes.marginbox}>
        <div justify="center" align="middle" style={{ margin: "25px 0" }}>
          <Title level={3}>Calculator</Title>
        </div>
        <div className="container">
          <Row
            gutter={16}
            justify="center"
            align="middle"
            className="flex row-mobile-margin-top"
            style={{ marginTop: "15px" }}
          >
            <Col xs={12} sm={12} md={4}>
              <label>Coin</label>
              <Input
                placeholder="Coin"
                value={openPositiontrade[0]?.symbol}
                disabled={true}
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <label>Total Capital in USDT ($)</label>

              <Input
                // onChange={handleTotalAvailableusdt}
                readOnly={false}
                placeholder="Total Capital in USDT ($)"
                value={Availableusdt}
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <label>Live Price</label>

              <Input
                placeholder="Live Price"
                readOnly={true}
                // onChange={handleTotalCapitalChange}
                value={openPositiontrade[0]?.markPrice}
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <label>Holding Size /Quantity</label>

              <Input
                // onChange={handleTotapositionAmt}
                placeholder="Holding Size /Quantity"
                readOnly={false}
                value={openPositiontrade[0]?.positionAmt}
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <label>Margin (Capital blocked) ($)</label>

              <Input placeholder="(Capital blocked)-USD$" value={margin} />
            </Col>
            {/* </Row>

        <Row
          gutter={16}
          justify="center"
          align="middle"
          className="flex"
          style={{ marginTop: "15px" }}
        > */}
            <Col xs={12} sm={12} md={4}>
              <label>Average Price ($)</label>

              <Input
                placeholder="Average Price"
                type="number"
                value={openPositiontrade[0]?.entryPrice}
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <label>Leverage (x)</label>

              <Input
                placeholder="leverage"
                // onChange={handleTotalChangeleverage}
                readOnly={false}
                value={openPositiontrade[0]?.leverage}
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <label>Liquidation Price</label>

              <Input
                placeholder="Liquidation Price"
                type="number"
                value={openPositiontrade[0]?.liquidationPrice}
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <label>unRealizedProfit ($)</label>
              <Input
                placeholder="Input 4"
                type="number"
                value={openPositiontrade[0]?.unRealizedProfit}
              />
            </Col>

         

            <Col xs={12} sm={12} md={4}>
              <label>Overall Holding Size</label>
              <Input
                placeholder="Input 4"
                type="number"
                value={totalQuantity}
              />
            </Col>

            <Col xs={12} sm={12} md={4}>
              <label>Overall used Holding USDT ($)</label>
              <Input placeholder="Input 4" type="number" value={totalUsdt} />
            </Col>
          </Row>
        </div>

        <div className={classes.marginbox}>
          <div className="marginmobile">
            <Row>
              <Col xs={24} sm={24}>
                <div bordered>
                  <Table
                    bordered
                    rowKey="key"
                    components={components}
                    rowClassName={() => "editable-row"}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                  />
                </div>
                <Button
                  onClick={handleAdd}
                  type="primary"
                  style={{
                    marginBottom: 16,
                  }}
                >
                  Add a row
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <div className={classes.marginbox}>
          <div className="marginmobile">
            <Row>
              <Col xs={24} sm={24}>
                <div bordered>
                  <Table
                    bordered
                    rowKey="key"
                    columns={columns1}
                    dataSource={dataSource1}
                    pagination={false}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        

      </div>
    </>
  );
};

export default Index;