import React, { useState, useEffect, useRef, memo } from 'react';
import UpdateApi from './UpdateApi';
import { FaLock } from 'react-icons/fa';

const SelectInput = ({ label, options, value, onChange }) => (
  <label className='test-md'>
    {label}:
    <select className='w-full mx-3 p-1 rounded-lg border border-[#ccc] ' value={value} onChange={(e) => onChange(e.target.value)}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </label>
);

const TradingViewWidget = () => {
  const containerRef = useRef(null);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.Liquidation_Heatmap;

  const [settings, setSettings] = useState({
    dataSource: "Crypto",
    blockSize: "market_cap_calc",
    blockColor: "change",
  });

  const updateWidget = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.innerHTML = '';

      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-crypto-coins-heatmap.js';
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = JSON.stringify({
        ...settings,
        locale: 'en',
        symbolUrl: '',
        colorTheme: 'light',
        hasTopBar: false,
        isDataSetEnabled: true,
        isZoomEnabled: true,
        hasSymbolTooltip: true,
        isMonoSize: false,
        borderColor: 'rgba(196, 196, 196, 0.2)',
        width: '100%',
        height: '800',
      });

      container.appendChild(script);
    }
  };

  useEffect(() => {
    updateWidget();
  }, []);

  const handleApply = () => {
    updateWidget();
  };

  return (
    <>
     <div className="bg-colorPrimary mx-2 rounded-3xl">
     <div className="relative">
    {checkTradePackage === false ?
      <div className="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
        style={{
          backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/NFT.webp)',
          backgroundSize: 'cover',
          // backgroundPosition: 'center',
          height: "100vh"
        }}>
        <FaLock className="text-gray-600 text-6xl mb-4" />
        <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
        <p className="mb-4">To access this feature, please upgrade your Package.
        </p>
      </div>
      :
    <div className="mx-5">
      <div style={{ marginBottom: '20px' }} className="grid grid-cols-1 sm:grid-cols-4 justify-between items-center">
        <SelectInput
          label="Data Source"
          options={[
            { value: "Crypto", label: "Crypto Coins" },
            { value: "CryptoWithoutBTC", label: "Crypto Coins (Excluding Bitcoin)" },
            { value: "CryptoWithoutStable", label: "Crypto Coins (Excluding Stablecoins)" },
            { value: "CryptoDeFi", label: "Coins DeFi" },
          ]}
          value={settings.dataSource}
          onChange={(value) => setSettings((prev) => ({ ...prev, dataSource: value }))}
        />
        <SelectInput
          label="Size Parameter"
          options={[
            { value: "market_cap_calc", label: "Market Cap" },
            { value: "market_cap_diluted_calc", label: "FD Market Cap" },
            { value: "24h_vol_cmc", label: "Volume in USD 24h" },
            { value: "tvl", label: "Total Value Locked" },
            { value: "24h_vol_to_market_cap", label: "Volume 24h/Market Cap" },
            { value: "market_cap_to_tvl", label: "Market Cap/TVL" },
          ]}
          value={settings.blockSize}
          onChange={(value) => setSettings((prev) => ({ ...prev, blockSize: value }))}
        />
        <SelectInput
          label="Color Parameter"
          options={[
            { value: "change|60", label: "Performance 1h, %" },
            { value: "change|240", label: "Performance 4h, %" },
            { value: "change", label: "Performance D, %" },
            { value: "Perf.W", label: "Performance W, %" },
            { value: "Perf.1M", label: "Performance M, %" },
            { value: "Perf.3M", label: "Performance 3M, %" },
            { value: "Perf.6M", label: "Performance 6M, %" },
            { value: "Perf.YTD", label: "Performance YTM, %" },
            { value: "Perf.Y", label: "Performance Y, %" },
            { value: "24h_vol_change_cmc", label: "Volume Change 24h, %" },
            { value: "24h_vol_cmc", label: "Volume in USD 24h" },
            { value: "Volatility.D", label: "Volatility D, %" },
            { value: "gap", label: "Gap, %" },
          ]}
          value={settings.blockColor}
          onChange={(value) => setSettings((prev) => ({ ...prev, blockColor: value }))}
        />
      </div>
        <button className='bg-neonPurple text-white font-semibold p-1 2xl:p-2  rounded-lg text-sm' onClick={handleApply}>Apply</button>
      <div className="tradingview-widget-container border border[#ccc]" ref={containerRef} style={{ width: '100%', height: '600px', border: '1px solid red' }}>
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
            <span className="blue-text">Track all markets on TradingView</span>
          </a>
        </div>
      </div>
    </div>
  }
  </div>
  </div>
  </>
  );
};

export default memo(TradingViewWidget);
