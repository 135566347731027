import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin, Table, Tooltip } from "antd";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import UpdateApi from "./UpdateApi";

const NftFirst = ({ refresh }) => {
  const [datas, setDatas] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.NFT_Analyitics;

  const fetchData = () => {
    setLoading(true);
    const url =
      "https://deep-index.moralis.io/api/v2.2/market-data/nfts/top-collections";
    const headers = {
      Accepts: "application/json",
      "X-API-Key":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MjI2MTZmLWM4MzItNDJmZS04YzI1LTA4NGZiNTAyY2ZmOCIsIm9yZ0lkIjoiMzcwMjAxIiwidXNlcklkIjoiMzgwNDY5IiwidHlwZUlkIjoiYTM5MmYyMTItZGQ1OC00NjY2LWEyMGMtN2E2MDdlZGU3YmZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDM5MzEwMjMsImV4cCI6NDg1OTY5MTAyM30.1ik9oveoExEKuAyXdSIWTKWo3i22Yp59_37ZDIJl8ew",
    };

    axios
      .get(url, { headers })
      .then((response) => {
        setDatas(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (checkTradePackage === true) {
      fetchData();
    }
  }, [checkTradePackage, refresh]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // if (!data) {
  //     return <div>Loading...</div>;
  // }

  const isPositive = (value) => value > 0;

  return (
    <>
      <div className="mx-5">
        {loading ? (
          <Spin
            size="large"
            style={{ display: "block", margin: "100px auto" }}
          />
        ) : (
          <Table dataSource={datas} pagination={false} rowKey="token_symbol">
            <Table.Column
              title="Collection Name"
              dataIndex="collectionname"
              key="collectionname"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={record.collection_image}
                    alt="collection Logo"
                    style={{
                      width: "30px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                  <h1>{record.collection_title}</h1>
                </div>
              )}
            />
            <Table.Column
              title="Floor Price"
              dataIndex="floorprice"
              key="floorprice"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1>{record.floor_price}</h1>
                </div>
              )}
            />
            <Table.Column
              title="Floor Price USD"
              dataIndex="floorpriceusd"
              key="floorpriceusd"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1>{record.floor_price_usd}</h1>
                </div>
              )}
            />
            <Table.Column
              title="Market Cap"
              dataIndex="marketcap"
              key="marketcap"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1>{record.market_cap_usd}</h1>
                </div>
              )}
            />
            <Table.Column
              title={
                <Tooltip title="Floor Price 24h Change">Floor Price</Tooltip>
              }
              dataIndex="floorprice24thchange"
              key="floorprice24thchange"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <h1>{record.floor_price_24hr_percent_change}</h1> */}
                  <h1
                    className={` mb-1 flex items-center ${isPositive(record.floor_price_24hr_percent_change)
                      ? "text-green-500"
                      : "text-red-500"
                      }`}
                  >
                    {record.floor_price_24hr_percent_change} %{" "}
                    <div className="ml-[10px]">
                      {isPositive(record.floor_price_24hr_percent_change) ? (
                        <FaArrowUp />
                      ) : (
                        <FaArrowDown />
                      )}
                    </div>
                  </h1>
                </div>
              )}
            />
            <Table.Column
              title={
                <Tooltip title="Market Cap 24h Change">Market Cap</Tooltip>
              }
              dataIndex="marketcap24hchange"
              key="marketcap24hchange"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1
                    className={` mb-1 flex items-center ${isPositive(record.market_cap_24hr_percent_change)
                      ? "text-green-500"
                      : "text-red-500"
                      }`}
                  >
                    <div className="ml-[10px]">
                      {Number(record.market_cap_24hr_percent_change).toFixed(4)}{" "}
                      %{" "}
                    </div>
                    <div className="ml-[10px]">
                      {isPositive(record.market_cap_24hr_percent_change) ? (
                        <FaArrowUp />
                      ) : (
                        <FaArrowDown />
                      )}
                    </div>
                  </h1>
                </div>
              )}
            />
            <Table.Column
              title={<Tooltip title=" Volume 24h Change">Volume</Tooltip>}
              dataIndex="volume_24hr_percent_change"
              key="volume_24hr_percent_change"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1
                    className={` mb-1 flex items-center ${isPositive(record.volume_24hr_percent_change)
                      ? "text-green-500"
                      : "text-red-500"
                      }`}
                  >
                    <div className="ml-[10px]">
                      {isNaN(Number(record.volume_24hr_percent_change))
                        ? "N/A"
                        : Number(record.volume_24hr_percent_change).toFixed(
                          4
                        )}{" "}
                      %{" "}
                    </div>
                    <div className="ml-[10px]">
                      {isPositive(record.volume_24hr_percent_change) ? (
                        <FaArrowUp />
                      ) : (
                        <FaArrowDown />
                      )}
                    </div>
                  </h1>
                </div>
              )}
            />

            <Table.Column
              title="Volume USD"
              dataIndex="volume usd"
              key="volume usd"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1>{record.volume_usd}</h1>
                </div>
              )}
            />
            <Table.Column
              title="Rank"
              dataIndex="rank"
              key="rank"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1>#{record.rank}</h1>
                </div>
              )}
            />
          </Table>
        )}
      </div>
    </>
  );
};

export default NftFirst;
