import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Modal,
  Select,
  Switch,
  Tooltip,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import binanceLogo from "../assets/coins/BINANCESPOT.png";
import bybitLogo from "../assets/coins/BYBITSPOT.png";
import SkeltorLoader from "./SkeltorLoader";
import FormField from "./FormField";
import axios from "axios";
import { toast } from "react-toastify";
import Apisame from "./Apisame";
import { FaLock } from "react-icons/fa";
import UpdateApi from "./UpdateApi";
import { FaCopy } from "react-icons/fa";
import copy from "copy-to-clipboard";
const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

//
const DataModal = ({
  visible,
  onClose,
  buySymbol,
  sellSymbol,
  loading,
  buyData,
  sellData,
}) => {
  const columns = [
    { title: "Price", dataIndex: "price", key: "price" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
  ];

  const combineData = (data, type) => {
    if (!data) return [];

    return data.map(([price, quantity], index) => ({
      key: index,
      price,
      quantity,
      total: price * quantity,
      type, // Add type (Bid/Ask) for differentiation
    }));
  };

  const calculateAverages = (data, type) => {
    const filteredData = data.filter((item) => item.type === type);

    if (filteredData.length === 0)
      return { averagePrice: 0, averageQuantity: 0 };

    // Convert string values to numbers
    const totalPrice = filteredData.reduce(
      (sum, item) => sum + parseFloat(item.price),
      0
    );
    const totalQuantity = filteredData.reduce(
      (sum, item) => sum + parseFloat(item.quantity),
      0
    );

    return {
      averagePrice: totalPrice / filteredData.length || 0,
      averageQuantity: totalQuantity / filteredData.length || 0,
    };
  };

  const combinedBuyData = combineData(buyData?.bids || [], "Bid").concat(
    combineData(buyData?.asks || [], "Ask")
  );
  const combinedSellData = combineData(sellData?.bids || [], "Bid").concat(
    combineData(sellData?.asks || [], "Ask")
  );

  const buyAverages = calculateAverages(combinedBuyData, "Bid");
  const sellAverages = calculateAverages(combinedSellData, "Ask");

  return (
    <Modal
      title="Symbol Data"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={1500}
    >
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="grid grid-cols-3">
          {/* Buy Symbol Data Table */}
          <div >
            <h3>Buy Symbol Data ({buySymbol})</h3>
            <Table
              columns={[
                ...columns,
                { title: "Total($)", dataIndex: "total", key: "total" },
                { title: "Type", dataIndex: "type", key: "type" },
              ]}
              dataSource={combinedBuyData}
              pagination={false}
              rowClassName={(record) =>
                record.type === "Bid" ? "bid-row" : "ask-row"
              }
              style={{ marginBottom: 20 }}
            />
          </div>

          {/* Average Table for Buy */}
          <div className="grid grid-cols-1 shadow-lg items-center">
            <div className="flex items-center justify-center flex-col">
              <h3 className="text-green-400 font-bold">BUY</h3>
             
                <p>
                  <strong>Average Price:</strong> $
                  {buyAverages.averagePrice.toFixed(8)}
                </p>
                <p>
                  <strong>Average Quantity:</strong>{" "}
                  {buyAverages.averageQuantity.toFixed(8)}
                </p>
             

              

            </div>

            <div className="flex items-center justify-center flex-col">
                <h3 className="text-red-400 font-bold">SELL</h3>

                <p>
                  <strong>Average Price:</strong> $
                  {sellAverages.averagePrice.toFixed(8)}
                </p>
                <p>
                  <strong>Average Quantity:</strong>{" "}
                  {sellAverages.averageQuantity.toFixed(8)}
                </p>
              </div>


          </div>

          {/* Sell Symbol Data Table */}
          <div>
            <h3>Sell Symbol Data ({sellSymbol})</h3>
            <Table
              columns={[
                ...columns,
                { title: "Total($)", dataIndex: "total", key: "total" },
                { title: "Type", dataIndex: "type", key: "type" },
              ]}
              dataSource={combinedSellData}
              pagination={false}
              rowClassName={(record) =>
                record.type === "Bid" ? "bid-row" : "ask-row"
              }
            />
          </div>

         
        </div>
      )}
    </Modal>
  );
};
//

const IntraExchange = () => {
  const projectNameView = process.env.REACT_APP_PROJECT || "Odecent";
  const [websocketData, setWebsocketData] = useState([]);
  const [previousWebsocketData, setPreviousWebsocketData] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState("binance");
  const [selectedExchange1, setSelectedExchange1] = useState([]);
  const [selectedblacklist, setSelectedblacklist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formConditions, setFormConditions] = useState([]);
  const [api_name, setApi_Name] = useState("");
  const jwt = localStorage.getItem("jwt");
  const [selectCoin, setSelectCoin] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalData, setModalData] = useState({ buyData: null, sellData: null });
  const [loadingData, setLoadingData] = useState(false);

  const openModal = async (item, index) => {
    setLoadingData(true);
    setSelectedItem(item);

    // Fetch data for the selected symbols
    try {
      const buyData = await axios.get(
        `https://api.binance.com/api/v3/depth?symbol=${item.Buy_symbol}&limit=3`
      );
      const sellData = await axios.get(
        `https://api.binance.com/api/v3/depth?symbol=${item.Sell_symbol}&limit=3`
      );
      setModalData({ buyData: buyData.data, sellData: sellData.data });
    } catch (error) {
      console.error("Error fetching modal data:", error);
    } finally {
      setLoadingData(false);
    }

    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedItem(null);
    setModalData({ buyData: null, sellData: null });
  };

  const columns = [
    { title: "Price", dataIndex: "price", key: "price" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
  ];

  const [formData, setFormData] = useState({
    api_name: "",
    take_profit: "2",
    trade_value: "25",
    trade_type: "MARKET",
    blacklist_symbol: [],
    buy_supporting: [],
    sell_supporting: [],
  });
  const [ws, setWs] = useState(null);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkBotPackage = checkBotFeature.Arbitrage_Same_Exchange;
  const [update, setUpdate] = useState(true);

  const createWebSocketConnection = () => {
    if (ws) {
      ws.close();
    }
    if (selectedExchange) {
      setLoading(true);
      const websocket = new WebSocket(
        `wss://tools.skywealth.club/binance_arbitrage/${selectedExchange}`
      );

      websocket.onopen = () => {
        console.log("WebSocket connection established.");
      };

      websocket.onmessage = (event) => {
        const correctedDataString = event.data.replace(/'/g, '"');
        try {
          const data = JSON.parse(correctedDataString);
          setPreviousWebsocketData(websocketData);
          setWebsocketData(data);
          setLoading(false);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }
      };

      websocket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      websocket.onclose = () => {
        console.log("WebSocket connection closed.");
      };

      setWs(websocket);

      return () => {
        websocket.close();
      };
    }
  };

  useEffect(() => {
    if (checkBotPackage === true && update) {
      createWebSocketConnection();
    } else if (ws) {
      ws.close();
    }
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [checkBotPackage, selectedExchange, update]);

  useEffect(() => {
    if (websocketData.length === 0 && previousWebsocketData.length > 0) {
      setWebsocketData(previousWebsocketData);
    }
  }, [websocketData, previousWebsocketData]);

  const toggleUpdate = () => {
    setUpdate((prevUpdate) => !prevUpdate);
    if (update && ws) {
      ws.close();
    }
    setUpdate(!update);
  };

  const handleChange = (value) => {
    setSelectedExchange(value);
  };

  function formatPair(pair) {
    const currencies = pair.match(/[A-Z][a-z]*/g);
    if (currencies.length !== 2) {
      return pair;
    } else {
      return `${currencies[0]}/${currencies[1]}`;
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formattedOptions = selectedblacklist.map((item) => ({
    value: item,
    label: item,
  }));

  const formattedOptions1 = selectCoin.map((item) => ({
    value: item,
    label: item,
  }));

  const blacklist = () => {
    axios
      .post(process.env.REACT_APP_API_TOOL + "/arbitrage_trade/get_coins_list")
      .then((response) => {
        setSelectedblacklist(response.data.Success);
      })
      .catch((error) => {
        console.error("Error fetching blacklist options:", error);
      });
  };

  const handlebinance = () => {
    axios
      .post(process.env.REACT_APP_API_TOOL + "/arbitrage_trade/get_pairs_list")
      .then((response) => {
        setSelectedExchange1(response.data.Success);
      })
      .catch((error) => {
        console.error("Error fetching blacklist options:", error);
      });
  };

  const handlesymbol = () => {
    axios
      .post(
        process.env.REACT_APP_API_TOOL + "/arbitrage_trade/get_buy_sell_symbols"
      )
      .then((response) => {
        setSelectCoin(response.data.Success);
      })
      .catch((error) => {
        console.error("Error fetching blacklist options:", error);
      });
  };

  const onFinish = () => {
    const defaultOptions = formattedOptions1.map((option) => option.value); // Extract all available options

    const postData = {
      ...formData,
      buy_supporting:
        formData.buy_supporting.length === 0
          ? defaultOptions
          : formData.buy_supporting,
      sell_supporting:
        formData.sell_supporting.length === 0
          ? defaultOptions
          : formData.sell_supporting,
      jwt: jwt,
    };

    axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_TOOL + "/arbitrage_trade/get_trade_data",
      data: postData,
    })
      .then((res) => {
        toast(res?.data?.Success);
        setIsModalVisible(false);
        setFormData({
          api_name: "",
          take_profit: "",
          trade_value: "",
          trade_type: "",
          blacklist_symbol: [],
          buy_supporting: [],
          sell_supporting: [],
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (name, selectedValues) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedValues,
    }));
  };

  const exchage_bot_list = async () => {
    try {
      const postdata = {
        project: process.env.REACT_APP_PROJECT,
        jwt: jwt,
        exchange_type: "SPOT",
        exchange: "BINANCE",
      };
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_API + "apiname_options",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setApi_Name(response?.data?.Success);
    } catch (err) {
      console.error(err);
    }
  };
  const handleClick = () => {
    if (projectNameView === "Odecent_B") {
      // Optionally, handle disabled state or show a message
      return;
    }
    // Navigate to the specified link
    window.location.href = "/dashboard/arbitragebot";
  };

  useEffect(() => {
    if (checkBotPackage === true) {
      exchage_bot_list();
      handlebinance();
      blacklist();
      handlesymbol();
    }
  }, [checkBotPackage, update]);

  useEffect(() => {
    const formConditions = [
      {
        label: "Api Name :",
        type: "select",
        name: "api_name",
        value: formData.api_name,
        options: api_name,
        required: true,
      },
      {
        label: "Take Profit :",
        type: "number",
        name: "take_profit",
        value: formData.take_profit,
        unit: "%",
        required: true,
      },
      {
        label: "Trade Amount :",
        type: "number",
        name: "trade_value",
        value: formData.trade_value,
        unit: "$",
        required: true,
      },
      {
        label: "Trade Type :",
        type: "select",
        name: "trade_type",
        value: formData.trade_type,
        options: ["MARKET", "LIMIT"],
        required: true,
      },
      {
        label: "Blacklist Ticker : ",
        type: "multi-select",
        name: "blacklist_symbol",
        value: formData.blacklist_symbol,
        options: formattedOptions,
      },
      {
        label: "Buy Whitelist : ",
        type: "multi-select",
        name: "buy_supporting",
        value: formData.buy_supporting,
        options: formattedOptions1,
      },
      {
        label: "Sell Whitelist :",
        type: "multi-select",
        name: "sell_supporting",
        value: formData.sell_supporting,
        options: formattedOptions1,
      },
    ];

    setFormConditions(formConditions);
  }, [formData, selectedblacklist, selectCoin]);

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange1 = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };

  const handleSwitchChange = (checked) => {
    setUpdate(checked);
  };

  const onChange = (key) => {
    // console.log(key);
  };

  const copyToClipboard = (index) => {
    const price = websocketData[index]?.Initial_buy_price
      ? websocketData[index].Initial_buy_price
      : "";
    copy(price.toString());
    toast("You Copied Buy");
  };

  const copyToClipboard2 = (index) => {
    const price = websocketData[index]?.Initial_sell_price
      ? websocketData[index].Initial_sell_price
      : "";
    copy(price.toString());
    toast("You Copied Sell");
  };

  const items = [
    {
      key: "1",
      label: (
        <div>
          Advance Options{" "}
          <span className="text-sm bg-neonPurple text-white rounded-lg px-2  animate-pulse">
            Coming soon..
          </span>
        </div>
      ),
      children: (
        <div>
          <form>
            <div className="flex items-center justify-between gap-2">
              <p className="text-sm font-semibold">Convert : </p>
              <Select
                defaultValue=""
                placeholder="Select "
                style={{ width: 120 }}
                onChange={handleChange1}
                options={selectedExchange1.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={selectedValue}
              />

              <input
                type="number"
                className="w-28 border border-[#ccc] rounded-md py-1 px-3"
                placeholder="0.00000000"
              />
              <Tooltip className="cursor-pointer" title="Coming Soon..">
                {" "}
                <button
                  type="submit"
                  className="bg-neonPurple text-white px-4 py-1 rounded-md"
                >
                  Convert
                </button>
              </Tooltip>
            </div>
          </form>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="relative">
        {checkBotPackage === false ? (
          <div
            className="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
            style={{
              backgroundImage:
                "url(https://cdn.odecents.com/Lock-bg/sameexchange.webp)",
              backgroundSize: "cover",
              // backgroundPosition: 'center',
              height: "100vh",
            }}
          >
            <FaLock className="text-gray-600 text-6xl mb-4" />
            <h2 className="text-2xl font-bold mb-2">Access Restricted</h2>
            <p className="mb-4">
              This feature is only available for BOT package users. Please
              upgrade to access this feature.
            </p>
          </div>
        ) : (
          <div>
            <div className="mainDiv w-full justify-center md:justify-between items-center gap-2 mb-4 lg:mx-0 px-5">
              <div className=" flex items-center justify-between gap-2">
                <div>
                  <p className="text-sm font-semibold">Select Exchange : </p>
                  <Select
                    defaultValue="binance"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    options={[
                      {
                        value: "binance",
                        label: (
                          <div className="flex items-center gap-2">
                            <img
                              src={binanceLogo}
                              alt=""
                              className="w-4 h-4 "
                            />{" "}
                            Binance
                          </div>
                        ),
                      },
                      // {
                      //     value: 'bybit',
                      //     label: <div className='flex items-center gap-2'><img src={bybitLogo} alt='' className='w-4 h-4 ' /> Bybit</div>,
                      // },
                    ]}
                  />
                </div>
                <div className="justify-self-end flex mt-2 items-center gap-4 ">
                  <p className="font-semibold text-sm">
                    {update ? "Update: " : "Do not Update: "}
                  </p>
                  <Switch
                    defaultChecked
                    onChange={toggleUpdate}
                    className="bg-[#777777]"
                  />
                  {/* <button onClick={toggleUpdate} className='gap-1 border border-[#ccc] px-3 py-1 rounded-lg text-sm'>
                                    {update ? 'Do not Update' : 'Update'}
                                    </button> */}
                  {/* {projectNameView === "Odecent_B" ? (
                    <Tooltip
                      title="Coming Soon"
                      style={{ cursor: "not-allowed" }}
                    >
                      <Button
                        type="primary"
                        className="bg-neonPurple text-white font-semibold rounded-2xl text-[14px] py-1"
                        onClick={handleClick}
                        disabled={projectNameView === "Odecent_B"}
                        style={{ cursor: "not-allowed" }}
                      >
                        Auto Trade
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      type="primary"
                      className="bg-neonPurple text-white font-semibold rounded-2xl text-[14px] py-1"
                      onClick={handleClick}
                      style={{ cursor: "pointer" }}
                    >
                      Auto Trade
                    </Button>
                  )} */}
                </div>
              </div>
              <div className="mainDiv mt-2">
                <div className="gap-3 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mb-[3%] md:gap-2 2xl:gap-5 w-full">
                  {loading
                    ? Array.from({ length: 12 }).map((_, index) => (
                        <Card className="bg-white shadow-md text-black hover:shadow-lg transition duration-100 border drop gap-2">
                          <SkeltorLoader />
                        </Card>
                      ))
                    : websocketData?.map((item, index) => (
                        <div className="bg-white  xl:h-auto w-full shadow-lg border-2 border-black rounded-md py-2 text-black hover:shadow-lg transition duration-100  drop gap-2">
                          <div className="flex justify-between items-center px-2">
                            <Col xs={10}>
                              <div className="flex items-center gap-1">
                                <img
                                  src={
                                    selectedExchange === "binance"
                                      ? binanceLogo
                                      : bybitLogo
                                  }
                                  alt="logo"
                                  className="w-7 h-auto"
                                />
                                <div className="">
                                  {/* <p className='text-xs lg:text-sm 2xl:text-[17px] text-[green] font-medium '>Buy</p> */}
                                  <h2 className="font-normal text-xs md:text-sm ">
                                    {item.Buy_symbol}
                                  </h2>
                                </div>
                              </div>
                            </Col>

                            <Col xs={10}>
                              <div className="">
                                <p className="text-xs font-semibold lg:text-sm ">
                                  {item.Buy_price.toFixed(9)} USDT
                                </p>
                                <p className="text-xs font-semibold lg:text-sm ">
                                  {item.Initial_buy_price.toFixed(9)}{" "}
                                  {item.Buy_symbol.endsWith("USDT")
                                    ? "USDT"
                                    : item.Buy_symbol.split("/")
                                        .pop()
                                        .slice(-3)}
                                  <button
                                    onClick={() => copyToClipboard(index)}
                                    className="border-none mx-3 hover:bg-active"
                                  >
                                    <FaCopy className="text-neonPurple" />
                                  </button>
                                </p>
                              </div>
                            </Col>
                            <Col xs={4}>
                              <div className="">
                                {selectedExchange === "binance" ? (
                                  <a
                                    href={`https://www.binance.com/en/trade/${item.Buy_symbol}?type=spot`}
                                    className="border border-white text-[green] font-semibold px-2 py-1  rounded-md "
                                    target="_blank"
                                  >
                                    BUY
                                  </a>
                                ) : (
                                  <a
                                    href={`https://www.bybit.com/en/trade/spot/${formatPair(
                                      item.Buy_symbol
                                    )
                                      .replace("USDT", "/USDT")
                                      .replace("BTC", "/BTC")
                                      .replace("BNB", "/BNB")
                                      .replace("ETH", "/ETH")}`}
                                    className="border border-white text-white font-semibold px-2 py-1 bg-[green] rounded-md "
                                    target="_blank"
                                  >
                                    BUY
                                  </a>
                                )}
                              </div>
                            </Col>
                          </div>
                          <div className="border-t flex gap-2 justify-center  items-center bg-neonPurple/80 text-white  py-2 my-1 px-2">
                            <div className="">
                              <p className="text-xs lg:text-sm md:text-sm  2xl:text-lg font-semibold">
                                Arbitrage :
                              </p>
                            </div>
                            <div className="flex gap-1 2xl:gap-2">
                              <p className="text-xs lg:text-sm md:text-xs  2xl:text-lg font-semibold ">
                                {item.Profit_percent.toFixed(9)} %{" "}
                              </p>
                              <p className="text-xs lg:text-sm md:text-xs  2xl:text-lg font-semibold ">
                                ({"$" + item.Price_difference.toFixed(9)})
                              </p>
                            </div>
                            {/* <button
                              onClick={() => openModal(item, index)}
                              className="bg-white text-neonPurple rounded-lg py-1 px-1"
                            >
                              View More
                            </button> */}
                          </div>

                          <div className="flex justify-between border-t border-[#ffffff33]  items-center px-2">
                            <Col xs={10} className="mt-2">
                              <div className="flex items-center gap-1">
                                <img
                                  src={
                                    selectedExchange === "binance"
                                      ? binanceLogo
                                      : bybitLogo
                                  }
                                  alt="logo"
                                  className="w-7 h-auto"
                                />
                                <div className="">
                                  {/* <p className='text-xs lg:text-sm 2xl:text-[17px] text-red-500 font-medium'>Sell</p> */}
                                  <h2 className="font-normal text-xs md:text-sm ">
                                    {item.Sell_symbol}
                                  </h2>
                                </div>
                              </div>
                            </Col>
                            <Col xs={10} className="mt-2">
                              <div className="">
                                <p className="text-xs font-semibold lg:text-sm ">
                                  {item.Sell_price.toFixed(9)} USDT
                                </p>
                                <p className="text-xs font-semibold lg:text-sm ">
                                  {item.Initial_sell_price.toFixed(9)}{" "}
                                  {item.Sell_symbol.endsWith("USDT")
                                    ? "USDT"
                                    : item.Sell_symbol.split("/")
                                        .pop()
                                        .slice(-3)}
                                  <button
                                    onClick={() => copyToClipboard2(index)}
                                    className="border-none mx-3 hover:bg-active"
                                  >
                                    <FaCopy className="text-neonPurple" />
                                  </button>
                                </p>
                              </div>
                            </Col>
                            <Col xs={4}>
                              <div className="">
                                {selectedExchange === "binance" ? (
                                  <a
                                    href={`https://www.binance.com/en/trade/${item.Sell_symbol}?type=spot`}
                                    className="text-xs lg:text-sm 2xl:text-md border border-white text-[red] font-semibold px-2 py-1 rounded-md "
                                    target="_blank"
                                  >
                                    SELL
                                  </a>
                                ) : (
                                  <a
                                    href={`https://www.bybit.com/en/trade/spot/${formatPair(
                                      item.Sell_symbol
                                    )
                                      .replace("USDT", "/USDT")
                                      .replace("BTC", "/BTC")
                                      .replace("BNB", "/BNB")
                                      .replace("ETH", "/ETH")}`}
                                    className="text-xs lg:text-sm 2xl:text-md border border-white text-white font-semibold px-2 py-1 bg-[red] rounded-md "
                                    target="_blank"
                                  >
                                    SELL
                                  </a>
                                )}
                              </div>
                            </Col>
                          </div>
                        </div>
                      ))}

                  <DataModal
                    visible={modalVisible}
                    onClose={closeModal}
                    buySymbol={selectedItem?.Buy_symbol}
                    sellSymbol={selectedItem?.Sell_symbol}
                    loading={loadingData}
                    buyData={modalData.buyData}
                    sellData={modalData.sellData}
                  />
                </div>
              </div>
              <Modal
                title={<div className="flex justify-center">Auto Trade</div>}
                visible={isModalVisible}
                footer={false}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <div>
                  <Form onFinish={onFinish}>
                    {formConditions.map((field, index) => (
                      <Form.Item
                        key={index}
                        // label={field.label}
                        // name={field.name}
                        rules={[
                          {
                            required: field.required,
                            message: `Please input your ${field.label.toLowerCase()}`,
                          },
                        ]}
                      >
                        <FormField
                          label={field.label}
                          type={field.type}
                          name={field.name}
                          value={field.value}
                          onChange={
                            field.type === "multi-select"
                              ? handleSelectChange
                              : handleInputChange
                          }
                          options={field.options}
                          unit={field.unit}
                          required={field.required}
                        />
                      </Form.Item>
                    ))}
                    <Form.Item className="flex justify-center items-center my-2 gap-2">
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>

                  <div>
                    <Collapse items={items} onChange={onChange} />
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default IntraExchange;
