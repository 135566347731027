import React, { useEffect, useState } from 'react'
import { Input, message, Select, Tabs } from 'antd'
import { FaSearch } from "react-icons/fa";
import axios from 'axios';

import LiveTransactioncopy from '../Components/Binance/LiveTransactioncopy'
import MainFuture from '../Components/Binance/MainFuture';
import Mainspot from '../Components/Binance/MainSpot';
import UpdateApi from '../Components/UpdateApi';

const Binancecopy =  () => {
    const {profile} =  UpdateApi()
    
    
    
    const [userdata, setUserdata] = useState("")
    const [option, setOption] = useState([])
    const [spotData, setSpotData] = useState([])
    const [futureData, setFutureData] = useState([])

    const getData = () => {
        
        const jwt = localStorage.getItem('jwt');
        const user = profile?.Username;
        const postData = {
            jwt: jwt,
            username: user,
        };

        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'user_api_list',
            data: postData
        }).then(res => {
            const responseData = res?.data?.Success
            const transformedOptions = responseData?.map(item => ({ ...item, label: item?.API_Name?.split('_').join(' '), value: item?.API_Name }));
            const future = transformedOptions.filter(item => item?.Exchange_Type == "FUTURES")
            const spot = transformedOptions.filter(item => item?.Exchange_Type == "SPOT")
            setOption(transformedOptions)
            setSpotData(spot)
            setFutureData(future)
        }).catch(err => {})
    };

    useEffect(() => {
        getData()
    }, [profile])

    useEffect(() => {
        const container = document.getElementById('scrollContainer');
        if (container) {
            container.style.scrollBehavior = 'smooth';
        }
    }, []);

    
    const items = [
        {
            key: '1',
            label: <div className='px-4 md:px-10 text-xs md:text-sm'>SPOT</div>,
            children: <Mainspot data={{ username: profile?.Username, option: spotData }} />,
        },
        {
            key: '2',
            label: <div className='px-4 md:px-10 text-xs md:text-sm'>FUTURES</div>,
            children: <MainFuture data={{ username: profile?.Username, option: futureData }} />,
        },
        {
            key: '3',
            label: <div className='px-4 md:px-10 text-xs md:text-sm'>TRANSACTION</div>,
            children: <LiveTransactioncopy data={{ username: profile?.Username, option: option }} />,
        },
    ];

    return (
        <>
           
            <div className='container '>
                <Tabs className='' defaultActiveKey="1" items={items}  />
            </div>
        </>
    )
}
export default Binancecopy