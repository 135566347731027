import { Empty, Spin, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { HandleSession } from "./HandleSession";
import axios from "axios";
import UpdateApi from "./UpdateApi";

function WalletAddressTrans({ searchResult, chainId }) {

  const [subData, setSubData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.Blockchain_Analytics;

  const API_KEY = "AW2RHK94J425FKQFFIBJG9EJ41YTPR8RNV"

  const getData = () => {
    setLoading(true);
    axios({
      method: 'GET',
      url: `https://deep-index.moralis.io/api/v2.2/${searchResult}?chain=${chainId}&order=DESC`,
      headers: {
        "accept": "application/json",
        "X-API-Key": API_KEY
      },
    }).then(res => {
      const responseData = res?.data?.result || [];
      setSubData(responseData);
      setLoading(false);
    }).catch(err => {
      HandleSession(err);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (checkTradePackage === true) {
      getData()
    }
  }, [checkTradePackage])

  const columns = [
    {
      title: "DATE",
      dataIndex: "Datetime",
      key: "Datetime",
      render: (text) => {
        const date = new Date(text * 1000);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
        return formattedDate;
      },
    },
    {
      title: " Trans Hash ",
      dataIndex: "hash",
      key: "hash",
      render: (text, record) => {
        return (
          <Tooltip title={text}>
            <div className="text-ellipsis cursor-pointer">
              {text.length > 10 ? `${text.slice(0, 6)}...${text.slice(-4)}` : text}
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: "Block",
      dataIndex: "blockNumber",
      key: "blockNumber",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (text, record) => {
        return (
          <Tooltip title={text}>
            <div className="text-ellipsis cursor-pointer">
              {text.length > 10 ? `${text.slice(0, 6)}...${text.slice(-4)}` : text}
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (text, record) => {
        return (
          <Tooltip title={text}>
            <div className="text-ellipsis cursor-pointer">
              {text.length > 10 ? `${text.slice(0, 6)}...${text.slice(-4)}` : text}
            </div>
          </Tooltip>
        );
      }
    },

    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) => {
        const value = parseFloat(text);
        if (!isNaN(value)) {
          const formattedValue = value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return formattedValue;
        }
        return text;
      },
    }


  ];

  const rows = [];

  if (subData) {
    for (let i = 0; i < subData.length; i++) {

      const row = {
        key: i,
        Datetime: subData[i]?.timeStamp,
        hash: subData[i]?.hash,
        from: subData[i]?.from,
        to: subData[i]?.to,
        blockNumber: subData[i]?.blockNumber,
        value: subData[i]?.value,
      };

      rows.push(row);
    }
  }

  const onChange = (pagination, filters, sorter, extra) => { };

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  if (isMobile) {
    return (
      <>
        {rows.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-1 lg:gap-4 mx-auto w-full">
            {rows.map((record) => (
              <div
                key={record.Trans_ID}
                className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
              >

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Date Time:</div>
                  <div className="card-data">{record.Datetime}</div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">Trans Hash:</div>
                  <Tooltip title={record.hash}><div className="card-data"> {record.hash.length > 10 ? `${record.hash.slice(0, 6)}...${record.hash.slice(-4)}` : record.hash}</div></Tooltip>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm ">
                  <div className="card-title font-semibold ">Block:</div>
                  <div className="card-data">{record.blockNumber}</div>
                </div>

                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">From:</div>
                  <div className="card-data">
                    <Tooltip title={record.from}><div className="card-data"> {record.from.length > 10 ? `${record.from.slice(0, 6)}...${record.from.slice(-4)}` : record.from}</div></Tooltip>
                  </div>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">To:</div>
                  <Tooltip title={record.to}><div className="card-data"> {record.to.length > 10 ? `${record.to.slice(0, 6)}...${record.to.slice(-4)}` : record.to}</div></Tooltip>
                </div>
                <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                  <div className="card-title font-semibold">Value:</div>
                  <div className="card-data">{record.value}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
            <Empty />
          </div>
        )}
      </>
    );
  }


  return (
    <div>
      <div className="w-full bg-colorPrimary ">
        <Spin spinning={loading} tip="Loading...">
          <Table
            className="table-newres my-2"
            columns={columns}
            dataSource={rows}
            onChange={onChange}
          />
        </Spin>
      </div>
    </div>
  );
}

export default WalletAddressTrans;