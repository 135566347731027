import React, { useEffect, useState } from "react";
import {
  UserOutlined,
} from "@ant-design/icons";
import {
  BsRobot,
} from "react-icons/bs";

import {
  Menu,
  Space,
  Dropdown,
  Avatar,
  Tooltip,
} from "antd";
import { FaQuestion } from "react-icons/fa";
import { Drawer } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BiLogOutCircle } from "react-icons/bi";
import { toast } from "react-toastify";
import Paymentmodel from "../Pages/Paymentmodel";
import logo from "../assets/whiteOdecent.png";
import UpdateApi from "../Components/UpdateApi";
import { PiAlignBottomDuotone, PiPackage } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { GiBuyCard } from "react-icons/gi";
import { SiLiquibase, SiMarketo, SiProbot } from "react-icons/si";
import { TbTransfer, TbRowInsertBottom, TbReportAnalytics } from "react-icons/tb";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { MdOutlineAnalytics } from "react-icons/md";
import { GoHistory, GoHubot } from "react-icons/go";
import { TiArrowMinimise } from "react-icons/ti";
import { RiAiGenerate } from "react-icons/ri";
import { BsFill0CircleFill } from "react-icons/bs";
import {
  AiFillApi,
  AiOutlineHeatMap,
  AiOutlineRobot,
} from "react-icons/ai";
import { } from "react-icons/tb";

function Navbar({ wallet, View_Wallet, View_Profile }) {
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const [botImage, setBotImage] = useState("");
  const [maleImage, setMaleImage] = useState("");
  const [femaleImage, setFemaleImage] = useState("");
  const Navigate = useNavigate();
  const { profile } = UpdateApi();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [walletBalance, setWalletBalance] = useState(wallet?.Wallet_Balance);
  const [ProfileUpdate, setWProfileUpdate] = useState(profile?.Profile_Image);

  const projectName = process.env.REACT_APP_DEMO || "Profitsla";
  const projectViewNameNew = process.env.REACT_APP_PROJECT || "Profitsla";

  useEffect(() => {
    setWalletBalance(wallet?.Wallet_Balance);
  }, [wallet]);

  useEffect(() => {
    setWProfileUpdate(profile?.Profile_Image);
  }, [profile]);

  const showDrawer = () => {
    setOpen(true);
  };

  useEffect(() => {
    View_Wallet();
    View_Profile()
  }, [location.pathname]);

  const customCloseIcon = <span style={{ color: "red" }}>X</span>;

  const sidebarItems = [
    {
      path: "/dashboard/home",
      title: "Dashboard",
      icon: <BsFill0CircleFill size={20} />,
    },
    // ...(projectViewNameNew !== "Odecent_B" ? [
    {
      path: "/dashboard/market",
      title: "Market",
      icon: <SiMarketo size={20} />,
    },
  // ]:[]),
    // {
    //   path: "/dashboard/arbitrage",
    //   title: "ARBITRAGE",
    //   icon: <GiBuyCard size={20} />,
    // },
    {
      // path: "/dashboard/arbitrage",
      title: "ARBITRAGE",
      icon: <MdOutlineAnalytics size={20} />,
      subItems: [
        {
          path: "/dashboard/multiexchange",
          title: "MULTIEXCHANGE",
          icon: <AiOutlineHeatMap size={20} />,
        },
        {
          path: "/dashboard/intraexchange",
          title: "SAME EXCHANGE",
          icon: <SiLiquibase size={20} />,
        },
        {
          path: "/dashboard/dexarbi",
          title: "DECENTRALIZED",
          icon: <SiLiquibase size={20} />,
        },
      ],
    },
    {
      path: "/dashboard/subscription",
      title: "Subscriptions",
      icon: <PiPackage size={20} />,
    },
    {
      path: "/dashboard/transactiontable",
      title: "Transactions",
      icon: <TbTransfer size={20} />,
    },
    // {
    //   path: "/dashboard/backtest",
    //   title: "Backtest",
    //   icon: <PiPackage size={20} />,
    // },
    // ...(projectViewNameNew !== "Odecent_B" ? [
    {
      // path: "/tool",
      title: "Tools",
      icon: <MdOutlineAnalytics size={20} />,
      subItems: [
        {
          path: "/dashboard/heatmap",
          title: "Heatmap",
          icon: <AiOutlineHeatMap size={20} />,
        },
        {
          path: "/dashboard/liquidation",
          title: "Liquidation",
          icon: <SiLiquibase size={20} />,
        },
        {
          path: "/dashboard/nft",
          title: "NFT",
          icon: <GiBuyCard size={20} />,
        },
        {
          path: "/dashboard/dex",
          title: "BlockChain",
          icon: <TiArrowMinimise size={20} />,
        },
      ],
    },
  // ]:[]),
    {
      // path: "/bots",
      title: <div>Bots <span className="text-[8px] ">(Beta)</span> </div>,
      icon: <BsRobot size={20} />,
      subItems: [
        {
          path: "/dashboard/api_settings",
          title: "API Credentials",
          icon: <AiFillApi size={20} />,
        },
      
        // {
        //   path: "/dashboard/createbot",
        //   title: "Create Bot",
        //   icon: <TbRowInsertBottom size={20} />,
          
        // },
        {
          path: "/dashboard/botmain",
          title: "Bots ",
          icon: <AiOutlineRobot size={20} />,
        },
        ...(projectViewNameNew !== "Odecent_B" ? [
          {
            path: "/dashboard/syntaxGenerator",
            title: (
            <span>Tradingview Bot</span>
          ),
            icon: <RiAiGenerate size={20} />,
          },
          {
              path: "/dashboard/createbot",
              title: (
                  // <Tooltip title="Coming Soon">
                      <span>Create Bot</span>
                  // </Tooltip>
              ),
              icon: <TbRowInsertBottom size={20} />,
          },
          {
              path: "/dashboard/arbitragebot",
              title: (
                  // <Tooltip title="Coming Soon">
                      <span>Arbitrage Bot</span>
                  // </Tooltip>
              ),
              icon: <GoHubot size={20} />,
              disabled: true, 
          }
      ] : []),
    //     ...(projectViewNameNew !== "Odecent2" ? [
    //       {
    //           path: "/dashboard/createbot",
    //           title: (
    //               <Tooltip title="Coming Soon">
    //                   <span>Create Bot</span>
    //               </Tooltip>
    //           ),
    //           icon: <TbRowInsertBottom size={20} />,
    //       },
         
    //   ] : []),
    //   ...(projectViewNameNew !== "Odecent2" ? [
        
    //     {
    //         path: "/dashboard/arbitragebot",
    //         title: (
    //             <Tooltip title="Coming Soon">
    //                 <span>Arbitrage Bot</span>
    //             </Tooltip>
    //         ),
    //         icon: <GoHubot size={20} />,
    //         disabled: true, 
    //     }
    // ] : []),
        // projectViewNameNew === "Odecent_B" ? {
        //   path: "/dashboard/createbot",
        //   title: "Create Bot",
        //   icon: <TbRowInsertBottom size={20} />,
        // } : {
        //   // path: "/dashboard/createbot",
        //   title: (
        //     <Tooltip title="Coming Soon">
        //       <span>Create Bot</span>
        //     </Tooltip>
        //   ),
        //   icon: <TbRowInsertBottom size={20} />,
        // },
      
        // {
        //   path: "/dashboard/arbitragebot",
        //   title: "Arbitrage Bot",
        //   icon: <GoHubot size={20} />,
        // },
        // projectViewNameNew === "Odecent_B" ? {
        //   path: "/dashboard/arbitragebot",
        //   title: "Arbitrage Bot",
        //   icon: <GoHubot size={20} />,
        // } : {
        //   // path: "/dashboard/arbitragebot",
        //   title: (
        //     <Tooltip title="Coming Soon">
        //       <span>Arbitrage Bot</span>
        //     </Tooltip>
        //   ),
        //   icon: <GoHubot size={20} />,
        // },
        {
          path: "/dashboard/activebot",
          title: "Active Bot",
          icon: <SiProbot size={20} />,
        },
        {
          path: "/dashboard/trades",
          title: "Trades",
          icon: <PiAlignBottomDuotone size={20} />,
        },
        {
          path: "/dashboard/Report",
          title: "Report",
          icon: <TbReportAnalytics size={20} />,
        },
        // {
        //   path: "/dashboard/trades",
        //   title: "Trades",
        //   icon: <PiAlignBottomDuotone size={20} />,
        // },

      ],
    },
    {
      path: "/dashboard/logs",
      title: "Timeline",
      icon: <GoHistory size={20} />,
    },
    {
      path: "/dashboard/support",
      title: "Support",
      icon: <HiOutlineChatBubbleLeftRight size={20} />,
    },
    {
      path: "/dashboard/FAQ",
      title: "FAQ",
      icon: <FaQuestion size={20} />,
    },
    {
      path: "/dashboard/profile",
      title: "Profile",
      icon: <CgProfile size={20} />,
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.clear();
    toast("Logout Successfully.");
    window.location.href = "/";
    // Navigate('/')
  };

  const items = [
    {
      label: (
        <Link to="/dashboard/profile" className="flex items-center">
          <UserOutlined className="text-lg mr-2" />
          My Profile
        </Link>
      ),
      key: "0",
    },

    {
      type: "divider",
    },
    {
      label: (
        <button
          className="w-full flex items-center justify-between rounded-lg px-2 py-1 active text-colorPrimary"
          onClick={logout}
        >
          <BiLogOutCircle size={25} className="mr-2" />
          Logout
        </button>
      ),
      key: "3",
    },
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawer = () => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  };

  const telegram = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT_TELE + "generate_telegram_link",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data.Success;
        window.open(res, "_blank");
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
      });
  };

  const { avatar } = UpdateApi()


  let avatarImages = [];
  if (avatar === "Female") {
    avatarImages = [femaleImage];
  } else if (profile?.Avatar === "Bot") {
    avatarImages = [botImage];
  } else if (profile?.Avatar === "Male") {
    avatarImages = [maleImage];
  }



  return (
    <>
      <div>
        <nav className=" z-50 h-[58.2px]  w-full shadow-sm shadow-neonPurpleShadow border-gray-200 ">
          <div className=" flex flex-wrap md:block md:flex-nowrap w-full  md:flex-row items-center justify-between ">
            {/* mobileview--------------->>>>>>>>>>>> */}

            <div
              className={`md:hidden flex active w-36 md:w-56 justify-center  items-center text-black   
             `}
            >
              <div className="text-lg font-bold">   <Link to="/dashboard/home"><img src={logo} alt="logo" className="" /></Link></div>

            </div>

            <div className=" md:hidden flex justify-center items-center py-1  gap-2">
              <div className="text-center mt-auto "></div>
              {/* {!profile?.TelegramID ? (
                <div>
                  <button
                    onClick={telegram}
                    className="text-sm text-neonPurple "
                  >
                    <TbBrandTelegram
                      size={35}
                      className="text-neonPurple border-2 border-neonPurple/20 px-1 py-0 rounded-lg"
                    /> 
                  </button>
                </div>
              ) : null} */}

              <Paymentmodel />

              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a
                  onClick={(e) => e.preventDefault()}
                  className="md:w-fit cursor-pointer mx-auto hidden items-center justify-between rounded-full px-2 py-1 bg-white border border-solid hover:bg-gray-100 text-colorSecondaryDark"
                >
                  <Space>
                    <Avatar
                      src={ProfileUpdate}
                      className="rounded-full w-10 h-10"
                    />
                  </Space>
                </a>
              </Dropdown>
            </div>

            <button
              data-collapse-toggle="navbar-default"
              type="button"
              onClick={showDrawer}
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>

            <div className=" grid grid-cols-12  w-auto min-w-fit 2xl:w-full items-center ">
              <div
                className="hidden col-span-6  md:flex justify-between items-center"
                id="navbar-default"
              >
                <div className="ml-10 text-base md:text-md lg:text-base font-semibold text-colorSecondaryDark">
                  {/* Welcome {profile?.Username}! */}
                </div>

              </div>

              <div className="hidden col-span-6 md:flex justify-end items-center py-2 md:gap-3">
                <div className="text-center mt-auto "></div>
                <>
                  <div className="ml-12 text-base md:text-md lg:text-base font-semibold text-colorSecondaryDark">
                    Sponsor: {profile?.Sponsor}
                  </div>
                  <div className="border-2 border-neonPurple rounded-lg px-2 py-1">
                    <div className="text-sm  md:text-xs lg:text-sm text-neonPurple font-semibold">
                      Deposit :{" "}
                      <span className="text-black font-normal">
                        ${" "}
                        {typeof walletBalance === 'number' ? Number(walletBalance).toFixed(2) : ''}
                      </span>

                    </div>
                  </div>
                </>
                {/* {!profile?.TelegramID ? (
                  <div>
                    <button
                      onClick={telegram}
                      className="text-sm  text-neonPurple border-2 border-neonPurple px-2 py-1 rounded-lg"
                    >
                      <div className="flex justify-center items-center gap-1">
                        <TbBrandTelegram className="text-neonPurple" />
                        Telegram
                      </div>
                    </button>
                  </div>
                ) : null} */}

                <Paymentmodel />
                <div className="">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={["click"]}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="md:w-fit cursor-pointer mx-auto flex items-center justify-between  px-2  bg-white hover:bg-gray-100 text-colorSecondaryDark"
                    >
                      <Space>
                        <Avatar
                          src={ProfileUpdate == "" ? avatarImages[0] : profile?.Profile_Image}
                          className="rounded-full w-10 h-10"
                        />
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>

          </div>
        </nav>

        {/* Drawer component */}

        <Drawer
          title={<div className="text-sm  my-3 text-center flex-none text-white lg:flex-grow font-semibold  md:hidden block">
            Welcome {profile?.Username}!
          </div>}
          headerStyle={{ backgroundColor: "#892dd8", color: "#fff" }}
          placement="right"
          onClose={onClose}
          // closeIcon={customCloseIcon}
          open={open}
          className="justify-end"
        >

          <Menu
            selectedKeys={[location?.pathname]}
            mode="vertical"
            theme="dark"
            className="w-full p-0 h-screen"
          >
            <div className="sidebar-wrapper">
              <div className="sidebar text-white">
                <ul>
                  {sidebarItems?.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item  ${location.pathname === item.path ? "active" : ""
                        }`}
                    >
                      <Link to={item.path} onClick={handleCloseDrawer}>
                        <div
                          onClick={() => {
                            if (item.subItems) {
                              if (openSubMenu === index) {
                                setOpenSubMenu(null);
                                // onClose();
                              } else {
                                setOpenSubMenu(index);
                              }
                            }

                            if (!item.subItems) {
                              setTimeout(() => {
                                onClose();
                              }, 300);
                            }
                          }}
                          className={`items-center flex w-full justify-start px-5 ${location.pathname === item.path
                            ? `md:rounded-xl rounded-none hover:md:shadow-[0px_0px_2px_#eeeeee] md:shadow-md md md:shadow-colorPrimary/50 md:border-1 border-transparent md:hover:border-colorPrimary cursor-pointer  md:h-12 h-12 transition-all flex justify-center items-center w-full mx-auto md:bg-transparent bg-colorPrimary/30`
                            : ""
                            }`}
                        >
                          <div className="flex items-center justify-between  w-full">
                            <div className="flex items-center  justify-evenly py-3 ">
                              <span className="item-icon  text-black mb-1">
                                {item.icon}
                              </span>
                              <span className=" uppercase item-title ml-5">
                                {item.title}
                              </span>
                            </div>
                            <div>
                              {item.subItems && (
                                <span
                                  className={`arrow-icon  ${openSubMenu === index ? "open" : ""
                                    }`}
                                >
                                  {/* &#9660; */}
                                  {openSubMenu === index ? "\u25B2" : "\u25BC"}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                      {item.subItems && (
                        <ul
                          className={`sub-menu border-gradient text-center ${openSubMenu === index ? "open" : ""
                            }`}
                        >
                          {item.subItems.map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              className={`items-center flex w-full justify-start p-5 h-5 hover:text-neonPurple cursor-pointer ${location.pathname === subItem.path
                                ? `md:rounded-xl rounded-none hover:md:shadow-[0px_0px_2px_#eeeeee] md:shadow-md md md:shadow-colorPrimary/50 md:border-1 border-transparent md:hover:border-colorPrimary cursor-pointer  md:h-12 h-12 transition-all flex  items-center w-full mx-auto md:bg-transparent bg-colorPrimary/30`
                                : ""
                                }`}
                            >
                              <div className="flex items-center justify-around gap-5 py-3 ">
                                <span className="item-icon  text-black mb-1">
                                  {subItem.icon}
                                </span>
                                <Link to={subItem.path} className="hover:text-neonPurple" onClick={onClose}>
                                  {subItem.title}
                                </Link>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
                <div className="footer bottom-4 fixed flex flex-col w-[80%] justify-center items-center -mt-14  text-center">
                  <div className="text-center mx-auto py-4">
                    <button
                      className="active font-semibold text-white py-5 px-4 rounded-md mx-auto"
                      onClick={logout}
                    >
                      Logout
                    </button>
                  </div>
                  <div className="text-center">
                    <p className="text-black">
                      © All right reserved <span className="font-semibold">@{projectName}</span> 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Menu>
        </Drawer>
      </div>
    </>
  );
}

export default Navbar;
