import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Table, Spin, message } from 'antd';
import { TfiReload } from "react-icons/tfi";
import axios from 'axios';
import { HandleSession } from './HandleSession';
import UpdateApi from '../Components/UpdateApi';
import { useHistory } from 'react-router-dom';

const BalanceSpotcopy = (props) => {
    // const { username, option, newOption } = props?.data

    const [CheckBal, setCheckBal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [option, setOption] = useState([])
    const [option2, setOption2] = useState(option)
    const [Api, setApi] = useState()
    const [username, setUsername] = useState()
    const [userdata, setUserdata] = useState("")
    const [spotData, setSpotData] = useState([])
    const [futureData, setFutureData] = useState([])
    const { profile } = UpdateApi();

    const getData = () => {
        const token = localStorage.getItem('jwt');
        const postData = {
            jwt: token,
            username: profile?.Username,
        };
        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'user_api_list',
            data: postData
        }).then(res => {
            const responseData = res?.data?.Success
            const transformedOptions = responseData?.map(item => ({ ...item, label: item?.API_Name?.split('_').join(' '), value: item?.API_Name }));
            const future = transformedOptions.filter(item => item?.Exchange_Type == "FUTURES")
            const spot = transformedOptions.filter(item => item?.Exchange_Type == "SPOT")
            setOption(transformedOptions)
            setSpotData(spot)
            setFutureData(future)
        }).catch(err => {
            // HandleSession(err)
        })
    };

    useEffect(() => {
        getData()
    }, [profile?.Username])

    useEffect(() => {
        setOption2(option)
        const fisrtLabel = option[0]?.label
        setApi(fisrtLabel)
    }, [option])

    const handleChange = (value) => {
        setApi(value)
    }

    const checkBalance = () => {
        setCheckBal()
        setLoading(true)
        const token = localStorage.getItem('jwt');
        const postData = {
            jwt: token,
            username: username,
            api_name: option2
        };
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'balance_spot',
            data: postData
        }).then((res) => {
            setLoading(false)
            setCheckBal(res.data.Success.Assets);
        }).catch(err => {
            setLoading(false)
            HandleSession(err)
            //  message.error(err?.response?.data?.Error)
        });
    };

    useEffect(() => {
        if (option2) {
            checkBalance();
        }
    }, [option2]);

    const columns = [
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Assets</p>,
            dataIndex: 'asset',
            key: 'asset',
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Free
            </p>,
            dataIndex: 'free',
            key: 'free',
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Locked
            </p>,
            dataIndex: 'locked',
            key: 'locked',
        },
    ]

    const dataSource = []
    if (CheckBal?.length > 0) {
        for (let i = 0; i < CheckBal.length; i++) {
            dataSource.push({
                asset: <p className='p-1 rounded-lg text-center'>{CheckBal[i].asset}</p>,
                free: <p className='p-1 rounded-lg text-center'>{CheckBal[i].free}</p>,
                locked: <p className='p-1 rounded-lg text-center'>{CheckBal[i].locked}</p>,
            })
        }
    }

    return (
        <div>
            {/* <Select
                value={Api}
                className='focus:border-colorAccent hover:border-colorAccent'
                style={{
                    width: 180,
                }}
                placeholder="Select API Name"
                onChange={handleChange}
                options={option2}
            /> */}
            <div className=' px-5 pb-4'>
                <span className=' text-sm md:text-base font-semibold'>API Name : </span>
                <Select
                    value={Api}
                    className='focus:border-colorAccent hover:border-colorAccent'
                    style={{
                        width: 180,
                    }}
                    placeholder="Select API Name"
                    onChange={handleChange}
                    options={option2}
                />
            </div>

            <div className='my-3 flex flex-col md:flex-row md:items-center gap-2 px-2'>
                <p>Data may be displayed with a delay of 1-2 minutes, please click Refresh to update the data </p>
                <button className=' flex items-center gap-2 text-colorSecondaryDark font-medium' onClick={checkBalance}><TfiReload className='  ' /> Refresh</button>
            </div>

            <div>
                <Row>
                    <Col xs={24}>
                        <Spin spinning={loading} >
                            <Table
                                size="medium"
                                columns={columns}
                                className="newtable"
                                dataSource={dataSource}
                                pagination={false}
                            />
                        </Spin>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default BalanceSpotcopy
