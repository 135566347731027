import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin, Button, Dropdown, Menu, Tooltip } from "antd";
import { RxUpdate } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import { IoArrowBackCircleSharp } from "react-icons/io5";

const MultiExchangeDetail = () => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const jwt = localStorage.getItem("jwt");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const coin = searchParams.get("coin");
  const buyExchange = searchParams.get("buyExchange");
  const sellExchange = searchParams.get("sellExchange");
  const itembuyprice = searchParams.get("itembuyprice");
  const itemsellprice = searchParams.get("itemsellprice");
  const perdiff = searchParams.get("perdiff");
  const pricediff = searchParams.get("pricediff");

  const [orderBook1, setOrderBook1] = useState(null);
  const [orderBook2, setOrderBook2] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState(null);

  const navigate = useNavigate();

  const [symbolInfo1, setsymbolInfo1] = useState(null);
  const [symbolInfo2, setsymbolInfo2] = useState(null);

  const fetchSymbolInfo = (exchange, setsymbolinfo) => {
    setLoading1(true);

    axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },

      url: `https://tools.skywealth.club/symbol_info/get_symbol_info?exchange=${exchange}&symbol=${coin}`,
    })
      .then((res) => {
        if (res?.data?.Success) {
          setsymbolinfo(res.data.Success);
        } else {
          setsymbolinfo(null);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  const fetchOrderBookData = (exchange, setOrderBook) => {
    setLoading(true);
    const postData = {
      // jwt: jwt,
      symbol: coin,
      exchange: exchange,
    };
    console.log(postData);

    axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      data: postData,
      url: "https://tools.skywealth.club/orderbook_data/get_order_book",
    })
      .then((res) => {
        if (res?.data?.Success) {
          setOrderBook(res.data.Success);
        } else {
          setOrderBook(null);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOrderBookData(buyExchange, setOrderBook1);
    fetchOrderBookData(sellExchange, setOrderBook2);
    fetchSymbolInfo(buyExchange, setsymbolInfo1);
    fetchSymbolInfo(sellExchange, setsymbolInfo2);
  }, [buyExchange, sellExchange]);

  const combineOrderBooks = (orderBook1, orderBook2) => {
    if (!orderBook1 || !orderBook2) return [];

    const combinedOrderBook = [];

    // Combine bid orders
    for (
      let i = 0;
      i < Math.max(orderBook1.bids.length, orderBook2.bids.length);
      i++
    ) {
      if (orderBook1.bids[i]) {
        combinedOrderBook.push({
          price1: orderBook1.bids[i].price,
          size1: orderBook1.bids[i].size,
          price2: orderBook2.bids[i] ? orderBook2.bids[i].price : null,
          size2: orderBook2.bids[i] ? orderBook2.bids[i].size : null,
          type: "bid",
        });
      }
      if (orderBook2.bids[i] && !orderBook1.bids[i]) {
        combinedOrderBook.push({
          price1: null,
          size1: null,
          price2: orderBook2.bids[i].price,
          size2: orderBook2.bids[i].size,
          type: "bid",
        });
      }
    }

    // Add spread and spread percentage row
    combinedOrderBook.push({
      spread1: orderBook1.spread?.toFixed(2),
      spreadPercentage1: (orderBook1.spread_percentage * 100)?.toFixed(2),
      spread2: orderBook2.spread?.toFixed(2),
      spreadPercentage2: (orderBook2.spread_percentage * 100)?.toFixed(2),
      type: "spread",
    });

    // Combine ask orders
    for (
      let i = 0;
      i < Math.max(orderBook1.asks.length, orderBook2.asks.length);
      i++
    ) {
      if (orderBook1.asks[i]) {
        combinedOrderBook.push({
          price1: orderBook1.asks[i].price,
          size1: orderBook1.asks[i].size,
          price2: orderBook2.asks[i] ? orderBook2.asks[i].price : null,
          size2: orderBook2.asks[i] ? orderBook2.asks[i].size : null,
          type: "ask",
        });
      }
      if (orderBook2.asks[i] && !orderBook1.asks[i]) {
        combinedOrderBook.push({
          price1: null,
          size1: null,
          price2: orderBook2.asks[i].price,
          size2: orderBook2.asks[i].size,
          type: "ask",
        });
      }
    }

    return combinedOrderBook;
  };

  const menu = (
    <Menu
      onClick={(e) =>
        setSelectedInterval(e.key === "none" ? null : parseInt(e.key))
      }
    >
      <Menu.Item key="none">Do Not Update</Menu.Item>
      <Menu.Item key="10">10 Seconds</Menu.Item>
      <Menu.Item key="15">15 Seconds</Menu.Item>
      <Menu.Item key="5">20 Seconds</Menu.Item>
    </Menu>
  );

  const backtoarbitage = () => {
    navigate("/dashboard/arbitrage");
  };

  const exchangeUrls = {
    binance: "https://www.binance.com/en/trade/{coin}USDT?type=spot",
    bybit: "https://www.bybit.com/trade/usdt/{coin}USDT",
    gateio: "https://www.gate.io/trade/{coin}_USDT",
    kucoin: "https://www.kucoin.com/trade/{coin}-USDT",
    hitbtc: "https://hitbtc.com/{coin}-to-usdt",
    bitfinex: "https://trading.bitfinex.com/t/{coin}:USD?type=exchange",
    kraken: "https://pro.kraken.com/app/trade/{coin}-usdt",
    okx: "https://www.okx.com/trade-spot/{coin}-usdt",
    mexc: "https://www.mexc.com/exchange/{coin}_USDT",
    htx: "https://www.htx.com/en-in/trade/{coin}_usdt",
  };

  const keysToDisplay = [
    "base",
    "quote",
    "baseId",
    "quoteId",
    "active",
    "type",
    "linear",
    "inverse",
    "swap",
    "margin",
    "maker",
    "feeSide",
    "contract",
    "optionType",
    "contractSize",
    "expiry",
    "expiryDatetime",
    "option",
    "id",
    "future",
    "strike",
    "settle",
    "spot",
    "symbol",
    "taker",
    "tierBased",
    "settleId",
    "precision",
    "limits",
    // "info",
  ];

  const isObject = (val) =>
    val && typeof val === "object" && !Array.isArray(val);

  const renderNestedRows = (
    key,
    value1,
    value2,
    padding = 20,
    parentKey = ""
  ) => {
    const nestedKeys = new Set([
      ...Object.keys(value1 || {}),
      ...Object.keys(value2 || {}),
    ]);

    return (
      <>
        <tr style={{ backgroundColor: "#f0f0f0" }}>
          <td
            className="capitalize"
            colSpan="3"
            style={{ paddingLeft: `${padding}px` }}
          >
            <Tooltip
              title={
                tooltipMessages[parentKey + key] ||
                `Custom text for ${parentKey + key}`
              }
            >
              {key}
            </Tooltip>
          </td>
        </tr>
        {Array.from(nestedKeys)
          .filter(
            (nestedKey) => nestedKey !== "fees" && nestedKey !== "fees_maker"
          ) // Filter out "Fees" and "Fees_maker"
          .map((nestedKey) => {
            const nestedValue1 = value1 ? value1[nestedKey] : null;
            const nestedValue2 = value2 ? value2[nestedKey] : null;
            const newKey = `${parentKey}${key}.${nestedKey}`;

            return isObject(nestedValue1) || isObject(nestedValue2) ? (
              renderNestedRows(
                nestedKey,
                nestedValue1,
                nestedValue2,
                padding + 20,
                `${parentKey}${key}.`
              )
            ) : (
              <tr key={nestedKey}>
                <td
                  className="border-2 capitalize"
                  style={{ paddingLeft: `${padding + 20}px` }}
                >
                  <Tooltip
                    title={
                      tooltipMessages[newKey] || `Custom text for ${newKey}`
                    }
                  >
                    {formatKey(nestedKey)}
                  </Tooltip>
                </td>
                <td className="border-2">
                  {nestedValue1 !== null && nestedValue1 !== ""
                    ? nestedValue1?.toString()
                    : "NA"}
                </td>
                <td className="border-2">
                  {nestedValue2 !== null && nestedValue2 !== ""
                    ? nestedValue2?.toString()
                    : "NA"}
                </td>
              </tr>
            );
          })}
      </>
    );
  };

  if (!symbolInfo1 || !symbolInfo2) {
    return (
      <div className="grid md:grid-cols-2 grid-cols-1 gap-3 mb-[30px]">
        <div className=" ">
          {/* //chaneing */}
          <div className="rounded-lg p-4 shadow-lg  ">
            <div className="flex items-center gap-4">
              <img
                src={`/images/50x50/${coin.split("/")[0]}.svg`}
                alt={coin.split("/")[0]}
                className="w-9 h-9"
              />
              <h1 className="text-xl font-bold">{coin}</h1>
            </div>

            <div className="grid xl:grid-cols-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 my-4 gap-4">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-4">
                  <img
                    src={`/images/exchange/${buyExchange}.svg`}
                    alt={buyExchange}
                    className="w-8 h-8"
                  />
                  <p className="font-semibold">{buyExchange}</p>
                </div>

                <div className="flex items-center gap-4">
                  <img
                    src={`/images/exchange/${sellExchange}.svg`}
                    alt={sellExchange}
                    className="w-8 h-8"
                  />
                  <p className="font-semibold">{sellExchange}</p>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <p className="text-lg font-semibold">
                  {itembuyprice} {coin.split("/")[1]}
                </p>
                <p className="text-lg font-semibold">
                  {itemsellprice} {coin.split("/")[1]}
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <p className="text-lg font-semibold">
                  Price Diff: $ {parseFloat(pricediff).toFixed(4)}
                </p>
                <p className="text-lg font-semibold">
                  Per Diff: {parseFloat(perdiff).toFixed(4)} %
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <a
                  href={exchangeUrls[buyExchange].replace(
                    "{coin}",
                    coin.split("/")[0].toUpperCase()
                  )}
                  target="_blank"
                  className="bg-green-400 text-white text-lg  text-center  px-1 rounded-lg shadow hover:bg-green-500 transition duration-300 ease-in-out"
                >
                  Buy
                </a>
                <a
                  href={exchangeUrls[sellExchange].replace(
                    "{coin}",
                    coin.split("/")[0].toUpperCase()
                  )}
                  target="_blank"
                  className="bg-red-500 text-white text-lg text-center   px-1 rounded-lg shadow hover:bg-red-600 transition duration-300 ease-in-out "
                >
                  Sell
                </a>
              </div>
            </div>
          </div>

          {/* changing  */}

          <div className=" rounded-lg p-4 shadow-lg  ">
            <div className="flex justify-around my-4 ">
              <div className="flex flex-col items-center">
                <h1 className="font-bold ">{coin}</h1>
                <h1 className="font-bold ">{buyExchange}</h1>
              </div>

              <div className="flex flex-col items-center ">
                <h1 className="font-bold">{coin}</h1>
                <h1 className="font-bold">{sellExchange}</h1>
              </div>
            </div>

            {loading ? (
              <Spin
                size="large"
                style={{ display: "block", margin: "100px auto" }}
              />
            ) : (
              <div>
                <table className="table-auto w-full border-2 ">
                  <thead className="  uppercase bg-gray-50 ">
                    <tr className="bg-white border-b ">
                      <th className="font-bold text-left px-1  py-3">Price </th>
                      <th className="font-bold text-left border-r-2   py-3">
                        Size{" "}
                      </th>
                      <th className="font-bold text-left px-1  py-3">Price</th>
                      <th className="font-bold text-left  py-3">Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {combineOrderBooks(orderBook1, orderBook2).map(
                      (order, index) =>
                        order.type === "spread" ? (
                          <tr key={index} className="bg-yellow-100">
                            <td className="font-bold text-left text-xs md:text-base">
                              Spread : $ {order.spread1}
                            </td>
                            <td className="font-bold text-left border-r-2  text-xs md:text-base">
                              Spread : {order.spreadPercentage1} %
                            </td>
                            <td className="font-bold text-left text-xs md:text-base">
                              Spread : $ {order.spread2}
                            </td>
                            <td className="font-bold text-left text-xs md:text-base">
                              Spread : {order.spreadPercentage2} %
                            </td>
                          </tr>
                        ) : (
                          <tr
                            key={index}
                            className={`${
                              order.type === "bid"
                                ? "bg-red-200"
                                : "bg-green-200"
                            }`}
                          >
                            <td className="text-left text-xs md:text-base ">
                              {order.price1}
                            </td>
                            <td className="text-left text-xs md:text-base border-r-2 ">
                              {order.size1}
                            </td>
                            <td className="text-left text-xs md:text-base">
                              {order.price2}
                            </td>
                            <td className="text-left text-xs md:text-base">
                              {order.size2}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        <div className="overflow-x-auto shadow-md p-4 rounded-lg">
          {loading1 ? (
            <Spin
              size="large"
              style={{ display: "block", margin: "100px auto" }}
            />
          ) : (
            <div style={{ maxHeight: "785px", overflowY: "scroll" }}>
              <table className="table-auto w-full border-2">
                <thead className="uppercase bg-gray-50">
                  <tr className="bg-white border-b">
                    <th className=" border-r-2 text-left px-1 py-3">DETAIL</th>
                    <th className=" border-r-2 text-left py-3">
                      <div className="flex  justify-start gap-2 items-center">
                        <img
                          src={`/images/exchange/${buyExchange}.svg`}
                          alt={buyExchange}
                          className="w-8 h-8"
                        />
                        <div> {buyExchange}</div>
                      </div>
                    </th>
                    <th className=" text-left py-3">
                      <div className="flex justify-start gap-2 items-center">
                        <img
                          src={`/images/exchange/${sellExchange}.svg`}
                          alt={sellExchange}
                          className="w-8 h-8"
                        />
                        <div> {sellExchange}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* <tbody className="border-2">{renderTableRows()}</tbody> */}
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }

  const tooltipMessages = {
    baseId: "Identifier for the base currency",
    quoteId: "Identifier for the quote currency",
    active: "Indicates if the trading pair is active",
    type: "Market type",
    linear: "Indicates if the market is linear",
    inverse: "Indicates if the market is inverse",
    swap: "Indicates if the market is a swap",
    margin: "Indicates if the market allows margin trading",
    contract: "Indicates if the market is a contract",
    optionType: "Indicates type of option market",
    contractSize: "size of the contract",
    expiryDatetime: "Indicates expiry datetime",
    option: "Option Contract",
    id: "ID of the market",
    future: "Indicates if the market is a future",
    strike: "Indicates strike price",
    settle: "Indicates settlement details",
    spot: "Confirms it is a spot trading pair",
    symbol: "Trading symbol",
    taker: "Taker fee",
    settleId: "Indicates the settlement ID",
    precision: "The precision details",
    "precision.amount": "Precision of amount",
    "precision.price": "Precision of price",
    "precision.base": "Precision for the base currency",
    "precision.quote": "Precision for the quote currency",
    limits: "The limits for trading",
    "limits.amount": "Limits for amount",
    "limits.amount.min": "Minimum limit for amount",
    "limits.amount.max": "Maximum limit for amount",
    "limits.price": "Limits for price",
    "limits.price.min": "Minimum limit for price",
    "limits.price.max": "Maximum limit for price",
    "limits.cost.min": "Minimum cost of a trade",
    "limits.cost.max": "Maximum cost of a trade",
    "limits.leverage.min": "No minimum leverage specified",
    "limits.leverage.max": "No maximum leverage specified",

    info: "Additional market info",
    "info.id": "Info ID",
    "info.baseCurrency": "Info Base Currency",
    "info.symbol": "Trading symbol",
    "info.status": "Current status of the trading pair",
    "info.baseAsset": "Base asset",
    "info.baseAssetPrecision": "Precision for the base asset",
    "info.quoteAsset": "Quote asset",
    "info.quotePrecision": "Precision for the quote asset",
    "info.quoteAssetPrecision": "Precision for the quote asset",
    "info.baseCommissionPrecision": "Commission precision for the base asset",
    "info.quoteCommissionPrecision": "Commission precision for the quote asset",

    baseCurrency: "Indicates Base Currency",
    expiry: "Indicates Expiry Date",
    base: "Base Value",
    maker: "Maker Fee",
    tierBased: "No tier-based fee structure",
    percentage: "Fees are percentage-based",
    quote: "quote currency",
    cost: "Minimum cost of a trade",
    status: "Current status of the trading pair",
    baseAsset: "Base asset",
    baseAssetPrecision: "Precision for the base asset",
    orderTypes: "Allowed order types",
    quoteAsset: "Quote asset",
    quotePrecision: "Precision for the quote asset",
    quoteAssetPrecision: "Precision for the quote asset",

    icebergAllowed: "Iceberg orders are allowed or not",
    ocoAllowed: "OCO (One Cancels the Other) orders are allowed",
    otoAllowed: "OTO (One Triggers the Other) orders are not allowed",
    quoteOrderQtyMarketAllowed: "Market orders by quote quantity are allowed",
    allowTrailingStop: "Trailing stop orders are allowed",
    cancelReplaceAllowed: "Cancel-replace orders are allowed",
    isSpotTradingAllowed: "Spot trading is allowed",
    isMarginTradingAllowed: "Margin trading is allowed",
    filters: "Various trading rules and limits",
    "filters[0].filterType": "Price filter settings",
    allowedSelfTradePreventionModes: "Allowed modes for self-trade prevention",
    feeSide: "Fee is taken on the quote side",
    lowercaseId: "The lowercase identifier for the trading pair",
    market: "Market Details",
    "market.min": "Minimum market order amount",
    "market.max": "Maximum market order amount",
  };
  const formatKey = (key) => {
    return key
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/([A-Z])/g, " $1") // Add space before each capital letter
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  const renderTableRows = () => {
    return keysToDisplay.map((key) => {
      const value1 = symbolInfo1[key];
      const value2 = symbolInfo2[key];

      return isObject(value1) || isObject(value2) ? (
        renderNestedRows(key, value1, value2)
      ) : (
        <tr key={key} className="capitalize">
          <td className="">
            {" "}
            <Tooltip title={tooltipMessages[key]}>{formatKey(key)}</Tooltip>
          </td>
          <td className="">
            {value1 !== null && value1 !== "" ? value1?.toString() : "NA"}
          </td>
          <td className="">
            {value2 !== null && value2 !== "" ? value2?.toString() : "NA"}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="m-[20px] mb-[30px]">
      <div>
        <h1 className="text-center text-sm">
          Kindly check the respective exchanges for coin withdrawal and deposit
          status.
        </h1>
      </div>
      <div className="flex justify-end ">
        {/* <div className="cursor-pointer" onClick={backtoarbitage}>
          <IoArrowBackCircleSharp size={40} />
        </div> */}
        {/* <div>
<Title level={3}>{`Order Book`}</Title>
</div> */}

        <div className="lg:block flex justify-end">
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button className="flex items-center gap-1 border border-[#ccc] px-3 py-1 rounded-lg text-sm">
              <RxUpdate />
              {selectedInterval !== null
                ? `${selectedInterval} Seconds`
                : "Do Not Update"}
              <IoIosArrowDown />
            </Button>
          </Dropdown>
        </div>
      </div>

      <div className="grid md:grid-cols-2 grid-cols-1 gap-3 mb-[30px]">
        {/* column1 starts  */}
        <div className=" ">
          {/* //chaneing */}
          <div className="rounded-lg p-4 shadow-lg  ">
            <div className="flex items-center gap-4">
              <img
                src={`/images/50x50/${coin.split("/")[0]}.svg`}
                alt={coin.split("/")[0]}
                className="w-9 h-9"
              />
              <h1 className="text-xl font-bold">{coin}</h1>
            </div>

            <div className="grid xl:grid-cols-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 my-4 gap-4">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-4">
                  <img
                    src={`/images/exchange/${buyExchange}.svg`}
                    alt={buyExchange}
                    className="w-8 h-8"
                  />
                  <p className="font-semibold">{buyExchange}</p>
                </div>

                <div className="flex items-center gap-4">
                  <img
                    src={`/images/exchange/${sellExchange}.svg`}
                    alt={sellExchange}
                    className="w-8 h-8"
                  />
                  <p className="font-semibold">{sellExchange}</p>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <p className="text-lg font-semibold">
                  {itembuyprice} {coin.split("/")[1]}
                </p>
                <p className="text-lg font-semibold">
                  {itemsellprice} {coin.split("/")[1]}
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <p className="text-lg font-semibold">
                  Price Diff: $ {parseFloat(pricediff).toFixed(4)}
                </p>
                <p className="text-lg font-semibold">
                  Per Diff: {parseFloat(perdiff).toFixed(4)} %
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <a
                  href={exchangeUrls[buyExchange].replace(
                    "{coin}",
                    coin.split("/")[0].toUpperCase()
                  )}
                  target="_blank"
                  className="bg-green-400 text-white text-lg  text-center  px-1 rounded-lg shadow hover:bg-green-500 transition duration-300 ease-in-out"
                >
                  Buy
                </a>
                <a
                  href={exchangeUrls[sellExchange].replace(
                    "{coin}",
                    coin.split("/")[0].toUpperCase()
                  )}
                  target="_blank"
                  className="bg-red-500 text-white text-lg text-center   px-1 rounded-lg shadow hover:bg-red-600 transition duration-300 ease-in-out "
                >
                  Sell
                </a>
              </div>
            </div>
          </div>

          {/* changing  */}

          <div className=" rounded-lg p-4 shadow-lg  ">
            <div className="flex justify-around my-4 ">
              <div className="flex flex-col items-center">
                <h1 className="font-bold ">{coin}</h1>
                <h1 className="font-bold ">{buyExchange}</h1>
              </div>

              <div className="flex flex-col items-center ">
                <h1 className="font-bold">{coin}</h1>
                <h1 className="font-bold">{sellExchange}</h1>
              </div>
            </div>

            {loading ? (
              <Spin
                size="large"
                style={{ display: "block", margin: "100px auto" }}
              />
            ) : (
              <div>
                <table className="table-auto w-full  ">
                  <thead className="  uppercase bg-gray-50 ">
                    <tr className="bg-white  ">
                      <th className="font-bold text-left px-1  py-3">Price </th>
                      <th className="font-bold text-left border-r-2   py-3">
                        Size{" "}
                      </th>
                      <th className="font-bold text-left px-1  py-3">Price</th>
                      <th className="font-bold text-left  py-3">Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {combineOrderBooks(orderBook1, orderBook2).map(
                      (order, index) =>
                        order.type === "spread" ? (
                          <tr key={index} className="bg-yellow-100">
                            <td className="font-bold text-left text-xs md:text-base">
                              Spread : $ {order.spread1}
                            </td>
                            <td className="font-bold text-left border-r-2  text-xs md:text-base">
                              Spread : {order.spreadPercentage1} %
                            </td>
                            <td className="font-bold text-left text-xs md:text-base">
                              Spread : $ {order.spread2}
                            </td>
                            <td className="font-bold text-left text-xs md:text-base">
                              Spread : {order.spreadPercentage2} %
                            </td>
                          </tr>
                        ) : (
                          <tr
                            key={index}
                            className={`${
                              order.type === "bid"
                                ? "bg-red-200"
                                : "bg-green-200"
                            }`}
                          >
                            <td className="text-left text-xs md:text-base ">
                              {order.price1}
                            </td>
                            <td className="text-left text-xs md:text-base border-r-2 ">
                              {order.size1}
                            </td>
                            <td className="text-left text-xs md:text-base">
                              {order.price2}
                            </td>
                            <td className="text-left text-xs md:text-base">
                              {order.size2}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {/* colun1 ends  */}

        {/* column2 starts  right side */}
        <div className="overflow-x-auto shadow-md p-4 rounded-lg">
          {loading1 ? (
            <Spin
              size="large"
              style={{ display: "block", margin: "100px auto" }}
            />
          ) : (
            <div style={{ maxHeight: "785px", overflowY: "scroll" }}>
              <table className="table-auto w-full border-2">
                <thead className="uppercase bg-gray-50">
                  <tr className="bg-white border-b">
                    <th className=" border-r-2 text-left px-1 py-3">DETAIL</th>
                    <th className=" border-r-2 text-left py-3">
                      <div className="flex  justify-start gap-2 items-center">
                        <img
                          src={`/images/exchange/${buyExchange}.svg`}
                          alt={buyExchange}
                          className="w-8 h-8"
                        />
                        <div> {buyExchange}</div>
                      </div>
                    </th>
                    <th className=" text-left py-3">
                      <div className="flex justify-start gap-2 items-center">
                        <img
                          src={`/images/exchange/${sellExchange}.svg`}
                          alt={sellExchange}
                          className="w-8 h-8"
                        />
                        <div> {sellExchange}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="border-2">{renderTableRows()}</tbody>
              </table>
            </div>
          )}
        </div>

        {/* column2 ends right side   */}
      </div>
    </div>
  );
};

export default MultiExchangeDetail;
