import React, { useEffect, useState } from "react";
import { Empty, Table } from "antd";
import { useMediaQuery } from "react-responsive";

function Tmeline(props) {
  const { depositData } = props?.data;
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    if (depositData && depositData.length > 0) {
      setActiveItem(depositData[0].DateTime.split(" ")[0]);
    }
  }, [depositData]);

  const handleClick = (itemId) => {
    setActiveItem(activeItem === itemId ? null : itemId);
  };

  const sourceDataTwo = depositData?.map((item, index) => ({
    key: index,
    DateTime: item.DateTime,
    Status: item.Status,
    TransID: item.TransID,
    IP: item.IP,
  })) || [];

  const resSourceDataTwo = [...sourceDataTwo].reverse();

  const handleDateClick = (date) => {
    setActiveItem(date);
  };

  const uniqueDates = [...new Set(depositData.map((item) => item.DateTime.split(" ")[0]))];

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  return (
    <div>
      <ul id="timeline">
        {uniqueDates.map((date) => (
          <li key={date} className="work">
            <input
              className="radio"
              id={date}
              name="works"
              type="radio"
              checked={activeItem === date}
              onChange={() => handleClick(date)}
            />
            <div className="relative" onClick={() => handleDateClick(date)}>
              <label className="labeltime mx-2 -z-50" htmlFor={date}>
                {date} Transaction
              </label>
              <span className="circle"></span>
            </div>
            <div className="content">
              {activeItem === date && (
                <div className="tablee-container">
                  {isMobile ? (
                    <div className="grid grid-cols-1 gap-4  w-full">
                      {resSourceDataTwo
                        .filter((dataItem) => dataItem.DateTime.split(" ")[0] === date)
                        .map((record) => (
                          <div
                            key={record.TransID}
                            className="card-container text-xs my-2  shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
                          >
                            <div className="card-content flex justify-between items-center ">
                              <div className="card-title font-semibold">Date Time:</div>
                              <div className="card-data">{record.DateTime}</div>
                            </div>


                            {/* <div className="card-content flex justify-between items-center ">
                              <div className="card-title font-semibold">Name:</div>
                              <div className="card-data">{record && record.TransID ? record.TransID.slice(0, 8) + '...' + record.TransID.slice(-4) : ''}</div>
                            </div> */}
                            <div className="card-content flex justify-between items-center ">
                              <div className="card-title font-semibold">IP Address:</div>
                              <div className="card-data">{record.IP}</div>
                            </div>
                            <div className="card-content flex justify-between items-center ">
                              <div className="card-title font-semibold">Status:</div>
                              <div className="card-data">
                                <span style={{ color: parseFloat(record.Status) > 100 ? "red" : "green" }}>
                                  {record.Status}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <Table
                      dataSource={resSourceDataTwo.filter((dataItem) => dataItem.DateTime.split(" ")[0] === date)}
                      columns={[
                        { title: "Date", dataIndex: "DateTime", key: "DateTime" },
                        // { title: "Name", dataIndex: "TransID", key: "TransID" },
                        { title: "IP Address", dataIndex: "IP", key: "IP" },
                        { title: "Status", dataIndex: "Status", key: "Status" },
                      ]}
                      pagination={false}
                      scroll={{ x: true }}
                    />
                  )}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Tmeline;
