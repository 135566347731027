import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import ModelEnter from "../Components/ModelEnter";
import NftFirst from "../Components/NftFirst";
import WalletNFT from "../Components/WalletNFT";
import SalesPrices from "../Components/SalesPrices";
import NFTLowestPrice from "../Components/NFTLowestPrice";
import CollectionWallet from "../Components/CollectionWallet";
import Apisame from "../Components/Apisame";
import { FaLock } from 'react-icons/fa';
import UpdateApi from "../Components/UpdateApi";

const NFT = () => {
  const [index, setIndex] = useState(0);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkTradePackage = checkTradeFeature.NFT_Analyitics;

  const tabNames = [
    "Top Collections",
    "Sales Prices",
    "NFT Wallet",
    // "NFTLowestPrice",
    "Collection Wallet",
    // "Collection Metadata"
  ];

  const handleTabChange = (index) => {
    setIndex(index);
  };

  const tabContent = [
    <NftFirst key={0} />,
    <SalesPrices key={1} />,
    <WalletNFT key={2} />,
    // <NFTLowestPrice key={3} />,
    <CollectionWallet key={4} />,
    // <CollectionMetadata key={5}/>
  ];

  return (
    <>
      <ModelEnter />
      <div className="bg-colorPrimary mx-2 rounded-3xl">
        <div className="relative">
          {checkTradePackage === false ?
            <div className="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
              style={{
                backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/NFT.webp)',
                backgroundSize: 'cover',
                // backgroundPosition: 'center',
                height: "100vh"
              }}>
              <FaLock className="text-gray-600 text-6xl mb-4" />
              <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
              <p className="mb-4">To access this feature, please upgrade your Package.
              </p>
            </div>
            :
            <div>
              <div className="mt-5">
                <div className="flex border-b-2 border-[#ccc6] mobilescroll mx-5">
                  {tabNames?.map((tabName, tabIndex) => (
                    <div
                      key={tabIndex}
                      className={`tab py-1 w-auto min-w-fit uppercase px-6 mx-3 text-center cursor-pointer text-sm ${index === tabIndex
                        ? "active-tab border-gradientbottom text-black font-semibold"
                        : "text-[#666666] "
                        }`}
                      onClick={() => handleTabChange(tabIndex)}
                    >
                      {tabName}
                    </div>
                  ))}
                </div>
                {/* <p className="text-center  text-[#9b9999] text-xs">Please double check the data on exchanges!</p> */}
              </div>

              <SwipeableViews
                index={index}
                onChangeIndex={handleTabChange}
                className="mb-3 mx-2 md:mx-4"
              >
                <div className="tab-content">
                  <NftFirst />
                </div>
                <div className="tab-content">
                  <SalesPrices />
                </div>
                <div className="tab-content">
                  <WalletNFT />
                </div>
                {/* <div className="tab-content">
                  <NFTLowestPrice />
                </div> */}
                <div className="tab-content">
                  <CollectionWallet />
                </div>
              </SwipeableViews>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default NFT;