// src/components/DexPriceChange.js

import React from 'react';
import { Spin, Table } from 'antd';

const DexPriceChange = ({ loading, data }) => {

  return (
    <>
      <div className='mx-5'>
        {loading ? (
          <Spin size="large" style={{ display: 'block', margin: '100px auto' }} />
        ) : (
          <Table
            dataSource={data}
            pagination={false}
            rowKey="token_symbol"
          >
            <Table.Column
              title="Token"
              key="token"
              render={(_, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={record.token_logo} alt="Token Logo" style={{ width: '30px', borderRadius: '50%', marginRight: '10px' }} />
                  <span>{record.token_symbol}</span>
                </div>
              )}
            />

            <Table.Column title="Token Name" dataIndex="token_name" key="token_name" />
            <Table.Column
              title="Price USD"
              dataIndex="price_usd"
              key="price_usd"
              render={change => {
                const fixedChange = parseFloat(change).toFixed(4);
                return <span style={{ color: parseFloat(change) >= 0 ? 'green' : 'red' }}>$ {fixedChange}</span>;
              }}
            />
            <Table.Column
              title="24h Change (%)"
              dataIndex="price_24h_percent_change"
              key="price_24h_percent_change"
              render={change => {
                const fixedChange = parseFloat(change).toFixed(4);
                return <span style={{ color: parseFloat(change) >= 0 ? 'green' : 'red' }}>{fixedChange} %</span>;
              }}
            />
            <Table.Column
              title="7d Change (%)"
              dataIndex="price_7d_percent_change"
              key="price_7d_percent_change"
              render={change => {
                const fixedChange = parseFloat(change).toFixed(4);
                return <span style={{ color: parseFloat(change) >= 0 ? 'green' : 'red' }}>{fixedChange} %</span>;
              }}
            />

            <Table.Column
              title="Market Cap USD"
              dataIndex="market_cap_usd"
              key="market_cap_usd"
              render={marketCap => {
                const formattedMarketCap = new Intl.NumberFormat().format(marketCap);
                return <span>$ {formattedMarketCap}</span>;
              }}
            />
          </Table>
        )}
      </div>
    </>
  );
};

export default DexPriceChange;
