import { Button, Card, Dropdown, Menu, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import SkeltorLoader from "./SkeltorLoader";
import axios from "axios";
import DetailsArb from "./DetailsArb";
import polygon from "../assets/Dex/polygon.svg";
import BNB from "../assets/Dex/WBNB.svg";
import { FaCopy } from "react-icons/fa6";
import { toast } from "react-toastify";
import { RxUpdate } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import Apisame from "./Apisame";
import { FaLock } from 'react-icons/fa';
import UpdateApi from "./UpdateApi";


import copy from "copy-to-clipboard";


const exchanges = [
  "binance",
  "bybit",
  "gateio",
  "kucoin",
  "hitbtc",
  "bitfinex",
  "kraken",
  "okx",
  "mexc",
  "htx",
];

const Dex = () => {
  const [websocketData, setWebsocketData] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedExchange, setSelectedExchange] = useState("poly");
  const [loading, setLoading] = useState(false);
  const [autoUpdate, setAutoUpdate] = useState(true);
  const [updateInterval, setUpdateInterval] = useState(40000);
  const [showOptions, setShowOptions] = useState(false);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const checkBotPackage = checkBotFeature.Arbitrage_Dex_Exchange;

  const View_Pack = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://tools.skywealth.club/dex_arbitrage/dex_arbitrage_${selectedExchange}`);
      setWebsocketData(response.data?.Success); // Assuming Success is the data structure
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleIntervalChange = (interval) => {
    setUpdateInterval(interval);
    setShowOptions(false);
  };

  const fetchData = async () => {
    try {
      await View_Pack();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (checkBotPackage === true) {
      fetchData();

      const intervalId = setInterval(() => {
        if (autoUpdate) {
          fetchData();
        }
      }, updateInterval);

      return () => clearInterval(intervalId);
    }
  }, [checkBotPackage, selectedExchange, autoUpdate, updateInterval]);

  const handleChange = (value) => {
    setSelectedExchange(value);
  };

  const sortedData = [...websocketData].sort((a, b) => {
    const priceA = a && a.percentage_diff ? a.percentage_diff : 0;
    const priceB = b && b.percentage_diff ? b.percentage_diff : 0;
    return priceB - priceA;
  });

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setDrawer(true);
  };

  const exchangeUrls = {
    apeswap: "https://apeswap.finance/swap",
    dfyn: "https://exchange.dfyn.network/swap",
    quickswap:
      "https://quickswap.exchange/0x90bc3E68Ba8393a3Bf2D79309365089975341a43#/swap?currency0=ETH&currency1=",
    uniswap: "https://app.uniswap.org/swap?outputCurrency=",
    sushiswap: "https://www.sushi.com/swap?outputCurrency=",
  };

  function formatPrice(price) {
    const priceStr = price?.toFixed(10);
    const [, fractionalPart] = priceStr.split(".");

    let firstNonZeroIndex = 0;
    for (let i = 1; i < fractionalPart.length; i++) {
      if (fractionalPart[i] !== "0") {
        firstNonZeroIndex = i;
        break;
      }
    }

    if (firstNonZeroIndex === 0) {
      return { leadingZerosCount: 0, significantDigits: fractionalPart };
    }

    const significantDigits = fractionalPart.slice(firstNonZeroIndex);

    return { leadingZerosCount: firstNonZeroIndex - 1, significantDigits };
  }

  const formatAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 3)}...${address.slice(-4)}`;
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleIntervalChange(5000)}>5 Seconds</Menu.Item>
      <Menu.Item key="2" onClick={() => handleIntervalChange(10000)}>10 Seconds</Menu.Item>
      <Menu.Item key="3" onClick={() => handleIntervalChange(15000)}>15 Seconds</Menu.Item>
    </Menu>
  );

  const copyToClipboard = (index) => {
    const price = sortedData[index]?.Buy_Price
      ? sortedData[index].Buy_Price
      : "";
    copy(price.toString());
    toast("You Copied Buy");
  };

  const copyToClipboard2 = (index) => {
    const price = sortedData[index]?.Sell_Price
      ? sortedData[index].Sell_Price
      : "";
    copy(price.toString());
    toast("You Copied Sell");
  };

  return (
    <>
      <div className="relative container">
        {checkBotPackage === false ?
          <div className="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-30"
            style={{
              backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/dexexchange.webp)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: "100vh"
            }}>
            <FaLock className="text-gray-600 text-6xl mb-4" />
            <h2 className="text-2xl font-bold mb-2">Access Restricted</h2>
            <p className="mb-4">This feature is only available for BOT package users. Please upgrade to access this feature.</p>
          </div>
          :
          <div>
            <div className="justify-center md:justify-start items-center gap-2 mb-4 mx-2 lg:mx-7">
              <div className="w-full lg:flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-semibold">Select Network : </p>
                  <Select
                    defaultValue="poly"
                    // style={{ width: 250 }}
                    className="w-full sm:w-56"
                    onChange={handleChange}
                    options={[
                      {
                        value: "poly",
                        label: (
                          <div className="flex items-center gap-2">
                            {" "}
                            <img src={polygon} alt="" className="w-4 h-4 " />
                            Polygon Chain
                          </div>
                        ),
                      },
                      {
                        value: "bsc",
                        label: (
                          <div className="flex items-center gap-2">
                            {" "}
                            <img src={BNB} alt="" className="w-4 h-4 " />
                            Binance Chain
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="lg:block flex justify-end">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="flex  items-center gap-1 border border-[#ccc] px-3 py-1 rounded-lg text-sm">
                      <RxUpdate />Do Not Update<IoIosArrowDown />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="mainDiv h-auto max-h-screen my-4 ">
              <div
                className=" grid grid-cols-1 md:grid-cols-1 mb-[3%] lg:grid-cols-2 xl:grid-cols-3 gap-2 2xl:gap-5 "
                justify="center"
              >
                {loading
                  ? Array.from({ length: 12 }).map((_, index) => (
                    <Card className="bg-white shadow-md text-black hover:shadow-lg transition duration-100 border drop gap-2">
                      <SkeltorLoader />
                    </Card>
                  ))
                  : sortedData.map((item, index) => (
                    <div className="bg-white shadow-lg py-2 border-2 border-black rounded-lg hover:shadow-lg transition duration-100">
                      <div className="text-gray-800">
                        {item ? (
                          <>
                            <div className="flex justify-between items-center px-2">
                              <div>
                                <div className="flex items-center gap-2">
                                  <img
                                    src={`/images/Icon/${item.Symbol.split("/")[0]
                                      }.svg`}
                                    alt={item.Symbol.split("/")[0]}
                                    className="w-7 h-7"
                                  />
                                  <div>
                                    <h4 className="text-xs font-bold ">
                                      {item.Symbol}
                                    </h4>

                                    <div className="flex justify-between items-center  ">
                                      <p className="text-xs">
                                        <Tooltip title={item.Buy_Address} className="cursor-pointer">{formatAddress(item.Buy_Address)}</Tooltip>
                                        <FaCopy
                                          className="inline ml-1 cursor-pointer"
                                          onClick={() => {
                                            navigator.clipboard.writeText(item.Buy_Address);
                                            toast.success("Copied!");
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="flex text-sm flex-col justify-center items-center">
                                <div className="flex items-center gap-1">
                                  <a
                                    target="_blank"
                                    href={`${exchangeUrls[item.Buy_Exchange]}`}
                                    className="text-center font-semibold text-[green]"
                                  >
                                    Buy
                                  </a>
                                </div>
                                <div className="flex"> 
                                {item.Buy_Price && item.Buy_Price.toString().length > 8 ? (
                                  <Tooltip title={item.Buy_Price} className="cursor-pointer text-xs"> <p className="font-semibold text-xs ">
                                    $ 0.0<sub>{formatPrice(parseFloat(item.Buy_Price)).leadingZerosCount}</sub>
                                    {formatPrice(parseFloat(item.Buy_Price)).significantDigits}
                                  </p></Tooltip>
                                ) : (
                                  <Tooltip title={item.Buy_Price} className="cursor-pointer text-xs"> <p className='font-semibold text-xs '>$ {item.Buy_Price}</p></Tooltip>
                                )}
                                  <button
                                    onClick={() => copyToClipboard(index)}
                                    className="border-none mx-3 hover:bg-active"
                                  >
                                    <FaCopy className="text-neonPurple" />
                                  </button>

</div>
                              </div>


                            


                              <div className="flex items-center flex-col">

                                <div className="flex justify-center items-center gap-2">
                                  <img
                                    src={`/images/Icon/${item.Buy_Exchange}.svg`}
                                    alt={item.Buy_Exchange}
                                    className="w-7 h-7 "
                                  />
                                  <p className="text-sm">
                                    {item.Sell_Version.length > 0
                                      ? item.Sell_Version[0]
                                      : ""}
                                  </p>
                                </div>
                                <div className="text-xs font-semibold">
                                  {item.Buy_Exchange}
                                </div>
                              </div>
                            </div>

                            <div
                              className="border-t flex justify-center gap-2 items-center bg-neonPurple/80 text-white  py-2 my-1 px-2"
                              justify={"end"}
                            >
                              <div className="">
                                <p className="text-xs lg:text-sm md:text-sm lg:text-md  2xl:text-lg font-semibold">
                                  Arbitrage :
                                </p>
                              </div>
                              <div className="flex gap-1 2xl:gap-2">
                                <p className="text-xs lg:text-sm md:text-xs lg:text-md 2xl:text-lg font-semibold ">
                                  {item.Difference_percentage.toFixed(4)} %{" "}
                                </p>
                                <p className="text-xs lg:text-sm md:text-xs lg:text-md 2xl:text-lg font-semibold ">
                                  ({"$" + item.Difference.toFixed(4)})
                                </p>
                              </div>
                              <button
                                onClick={() => handleCardClick(item)}
                                className="text-[8px] text-neonPurple bg-white font-semibold p-1 rounded-md  text-end  cursor-pointer"
                              >
                                View More{" "}
                              </button>
                            </div>

                            <div className="flex justify-between items-center px-2">
                              <div>
                                <div className="flex items-center gap-2">
                                  <img
                                    src={`/images/Icon/${item.Symbol.split("/")[0]
                                      }.svg`}
                                    alt={item.Symbol.split("/")[0]}
                                    className="w-7 h-7"
                                  />

                                  <div>
                                    <h4 className="text-xs font-bold ">
                                      {item.Symbol}
                                    </h4>

                                    <div className="flex justify-between items-center  ">
                                      <p className="text-xs">
                                        <Tooltip title={item.Buy_Address} className="cursor-pointer"> {formatAddress(item.Sell_Address)}</Tooltip>
                                        <FaCopy
                                          className="inline ml-1 cursor-pointer"
                                          onClick={() => {
                                            navigator.clipboard.writeText(item.Sell_Address);
                                            toast.success("Copied!");
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-col justify-center items-center">
                                <div className="flex text-sm items-center gap-1">
                                  <a
                                    target="_blank"
                                    href={`${exchangeUrls[item.Sell_Exchange]}`}
                                    className="text-center font-semibold text-[red]"
                                  >
                                    Sell
                                  </a>
                                </div>

                                <div className="flex">
                                {item.Sell_Price && item.Sell_Price.toString().length > 8 ? (
                                  <Tooltip title={item.Sell_Price} className="cursor-pointer"> <h2 className="font-semibold text-xs ">
                                    $ 0.0<sub>{formatPrice(parseFloat(item.Sell_Price)).leadingZerosCount}</sub>
                                    {formatPrice(parseFloat(item.Sell_Price)).significantDigits}
                                  </h2></Tooltip>
                                ) : (
                                  <Tooltip title={item.Sell_Price} className="cursor-pointer"> <h2 className='font-semibold text-xs '>$ {item.Sell_Price}</h2></Tooltip>
                                )}
    <button
                                    onClick={() => copyToClipboard2(index)}
                                    className="border-none mx-3 hover:bg-active"
                                  >
                                    <FaCopy className="text-neonPurple" />
                                  </button>
</div>
                              </div>
                          

                              <div className="flex items-center flex-col">
                                <div className="flex justify-center items-center gap-2">
                                  <img
                                    src={`/images/Icon/${item.Sell_Exchange}.svg`}
                                    alt={item.Sell_Exchange}
                                    className="w-7 h-7"
                                  />
                                  <p className="text-sm">
                                    {item.Sell_Version.length > 0
                                      ? item.Sell_Version[0]
                                      : ""}
                                  </p>
                                </div>
                                <div className="text-xs font-semibold">
                                  {item.Sell_Exchange}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <SkeltorLoader />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              <DetailsArb
                setDrawer={setDrawer}
                drawer={drawer}
                selectedItem={selectedItem}
              />
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Dex;