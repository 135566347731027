import React, { useState } from "react";
import { Checkbox, Form, Input, Modal, Select, Slider, Tooltip } from "antd";
import { BsPencil, BsRobot } from "react-icons/bs";
import { Switch } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import TelegramModel from "./TelegramModel";
import { FaInfoCircle } from "react-icons/fa";

function AllBot1({ myProp }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRisk, setSelectedRisk] = useState("");
  const [botData, setBotData] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState("");
  const [optionsForSelect2, setOptionsForSelect2] = useState([]);
  const [exchangeError, setExchangeError] = useState(false);
  const [optionError, setOptionError] = useState(false);
  const [countError, setCountError] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [internalState, setInternalState] = useState(myProp.avg_profite);
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [botPercentError, setBotPercentError] = useState(false);
  const [PercentError, setPercentError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [riskLevels, setRiskLevels] = useState([]);

  const [dollar, setDollar] = useState("");

  const [botdollar, setBotDollar] = useState("");

  if (internalState !== myProp.avg_profite) {
    setInternalState(myProp.avg_profite);
  }

  

  const { Name, VarBotID, Trade_Type, Symbol, images, id, Investment_Risk, totalprofit, Days, Min_Investment, Drawdown, TakeProfit, TotalTrades,img } = myProp;
  const [percent, setPercent] = useState(30);


  
  

  const [botpercent, setBotPercent] = useState(30);

  // useEffect(() => {
  //   if (myProp.Drawdown !== undefined) {
  //     handlePercentChange({ target: { value: myProp.Drawdown } });
  //   }
  // }, [myProp.Drawdown]);

  useEffect(() => {
    if (myProp.TakeProfit !== undefined) {
      handleBotPercentChange({ target: { value: myProp.TakeProfit } });
    }
  }, [myProp.TakeProfit]);

  const showModal = (id) => {
    setCount(investmentValue)
    setIsModalVisible(true);
    clearAllSelectOptions();
    exchage_bot_list();
    ViewBotprice();
  };

  const handleOk = () => {
    setIsModalVisible(false);
    clearAllSelectOptions();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    clearAllSelectOptions();
  };

  riskLevels.forEach((level) => {
    marks[level] = level;
  });

  useEffect(() => {
    if (!isModalVisible) {
      setIsTermsModalVisible(false);
    }
  }, [isModalVisible]);

  const jwt = localStorage.getItem("jwt");
  const [exchnagelist, setexchnagelist] = useState([]);

  const ViewBotprice = async (sliderValue) => {
    const postData = {
      project: process.env.REACT_APP_PROJECT,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "investment_matrix",
        postData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setBotData(response?.data?.Success);
    } catch (err) {
      console.error(err);
    }
  };

  const exchage_bot_list = async () => {
    try {
      const postdata = {
        project: process.env.REACT_APP_PROJECT,
        jwt: jwt,
        exchange_type: Trade_Type,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_API + "exchange_api_options",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setexchnagelist(response?.data?.Success);
    } catch (err) {
      console.error(err);
    }
  };

  const exchage_bot_list_view = async (selectedValue) => {
    try {
      const postdata = {
        project: process.env.REACT_APP_PROJECT,
        jwt: jwt,
        exchange_type: Trade_Type,
        exchange: selectedValue,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_API + "apiname_options",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setOptionsForSelect2(response.data.Success);
    } catch (err) {
      console.error(err);
    }
  };

  const clearAllSelectOptions = () => {
    setSelectedExchange("");
    setSelectedOption("");
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;

    if (event.target.id === "selectOption") {
      setSelectedExchange(selectedValue);

      exchage_bot_list_view(selectedValue);
    } else if (event.target.id === "selectOption2") {
      setSelectedOption(selectedValue);
    }
  };
  const [error, setError] = useState(null);

  const view_modelbot = async (e) => {
    e.preventDefault();
    setError(null);

    if (!selectedExchange) {
      setExchangeError(true);
    } else {
      setExchangeError(false);
    }

    if (!selectedOption) {
      setOptionError(true);
    } else {
      setOptionError(false);
    }

    if (!count || isNaN(count)) {
      setCountError(true);
    } else {
      setCountError(false);
    }

    if (!exchangeError && !optionError && !countError) {
      const postData = {
        jwt: jwt,
        project: process.env.REACT_APP_PROJECT,
        bot_id: VarBotID,
        api_name: selectedOption,
        invest_type: selectedRisk,
        amount: parseInt(count),
        base: 0,
        basel: 0,
        bases: 0,
        order_count: 0,
        order_countl: 0,
        order_counts: 0,
        vol_mul: 0,
        vol_mull: 0,
        vol_muls: 0,
        lev: 0,
        order_type_open: "",
        order_type_close: "",
        order_type_openl: "",
        order_type_closel: "",
        order_type_opens: "",
        order_type_closes: "",
        exchange: selectedExchange,
        exchange_type: Trade_Type,
        take_profit_percent: 0,
        loss_percent: 0,
        loss_percent_mul: 0,
        take_profit_percentl: 0,
        loss_percentl: 0,
        loss_percent_mull: 0,
        take_profit_percents: 0,
        loss_percents: 0,
        loss_percent_muls: 0,
        telegram_alert: isChecked,
      };

      if (botpercent) {
        postData.total_profit_limit = Number(botpercent);
      }

      if (percent) {
        postData.total_loss_limit = Number(percent);
      }
      axios({
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_PATH_BOT + "create",
        data: postData,
      })
        .then(async function (response) {
          setIsTermsModalVisible(false);
          const res = await response.data;
          if (res?.Success === "Bot activated successfully!") {
            // setCount(res?.Success);
            setIsTermsModalVisible(false);
            setIsModalVisible(false);
            toast(res?.Success);
          } else {
            setError(res?.Error);
            toast.error(res?.Error);
            setIsTermsModalVisible(false);
          }
        })
        .catch((err) => {
          setError(err.response.data.Error);
          toast.error(err.response.data.Error);
          setIsTermsModalVisible(false);
        });
    }
  };

  

  const sliderValues = {
    SPOT: { LOW: 1270, MEDIUM: 1135, HIGH: 250 },
    FUTURES: { LOW: 127, MEDIUM: 113.5, HIGH: 25 },
  };

  const marks = {
    0: {
      label: "LOW",

      style: {
        color: "#032B43",
      },
    },
    50: {
      label: "MEDIUM",

      style: {
        color: "#032B43",
      },
    },
    100: {
      label: "HIGH",

      style: {
        color: "#f00",
      },
    },
  };

  useEffect(() => {
    setSliderValue(0);
    setSelectedRisk("LOW");
  }, [Trade_Type]);

  
  const investmentValue = sliderValues[Trade_Type][Investment_Risk];

  
  

  const handleSliderChange = (value) => {
    setSliderValue(value);

    if (value === 50) {
      setSelectedRisk("MEDIUM");
    } else if (value === 100) {
      setSelectedRisk("HIGH");
    } else {
      setSelectedRisk("LOW");
    }
  };


  
  const [count, setCount] = useState();

  


  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   const updatedValue = sliderValues[Trade_Type][selectedRisk];
  //   setCount(updatedValue);
  // }, [selectedRisk, Trade_Type]);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  const showModal1 = () => {
    setIsModalOpen(true);
  };
  const handleOk1 = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen(false);
  };

  const showModalNew = () => {
    let hasErrors = false;

    if (!selectedExchange) {
      setExchangeError(true);
      hasErrors = true;
    } else {
      setExchangeError(false);
    }

    if (botpercent) {
      const botpercentValue = parseFloat(botpercent);
      if (isNaN(botpercentValue) || botpercentValue <= 0) {
        setBotPercentError(true);
        toast.error("Take Profit Limit must be a valid number greater than 0");
        hasErrors = true;
      } else {
        setBotPercentError(false);
      }
    } else {
      setBotPercentError(true);
    }

    if (percent) {
      const percentValue = parseFloat(percent);
      if (isNaN(percentValue) || percentValue <= 0) {
        setPercentError(true);
        toast.error("Drawdown Limit must be a valid number greater than 0");
        hasErrors = true;
      } else {
        setPercentError(false);
      }
    } else {
      setPercentError(true);
    }

    if (!selectedOption) {
      setOptionError(true);
      hasErrors = true;
    } else {
      setOptionError(false);
    }

    if (!count || isNaN(count)) {
      setCountError(true);
      hasErrors = true;
    } else {
      setCountError(false);
    }

    if (!hasErrors) {
      setIsTermsModalVisible(true);
    } else {
      setError("Please fill in all the required fields.");
    }
  };

  const TermsModal = ({ visible, onCancel }) => {
    const [isAgreed, setIsAgreed] = useState(false);

    const handleCheckboxChange = (e) => {
      setIsAgreed(e.target.checked);
    };

    const handleSuccessSubmit = () => {
      onCancel();
    };

    return (
      <Modal
        title={
          <div className="text-colorSecondaryDark text-center">
            Terms & Conditions For Copy Bot
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
      >
        <div className="border-2 border-colorSecondary my-2 px-2 py-1 rounded-lg border-dashed">
          <div className="flex justify-between items-center">
            <div className="font-semibold">
              <span className="font-normal">Bot Name: </span>
              {Name}
            </div>
            <div className="font-semibold">
              {" "}
              <span className="font-normal">Symbol: </span>
              {Symbol}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="font-semibold">
              {" "}
              <span className="font-normal">Min Investment:</span> ${" "}
              {/* {sliderValues[Trade_Type]?.LOW} */}
              {count}
            </div>
            {/* <div className="font-semibold">
              {" "}
              <span className="font-normal">Select Exchange:</span>{" "}
              {selectedExchange}
            </div> */}
            <div className="font-semibold">
              {" "}
              <span className="font-normal">Select API:</span> {selectedOption}
            </div>
          </div>
        </div>
        <div className="font-semibold my-1">Minimum Balance Requirement:</div>
        <ul className="list-disc ml-4">
          <li>
            Users must maintain a minimum balance as specified by the platform.
          </li>
          <li>
            Falling below this minimum may result in the suspension of bot
            services.
          </li>
          <li>
            The company bears no responsibility for losses and liquidation if
            the balance is not maintained according to the allocated funds.
          </li>
          <li>
            Trades depend on exchange approval. If trades are restricted or fail
            to execute, responsibility lies with the exchange.
          </li>
        </ul>
        <div className="font-semibold my-1">No Manual Trading:</div>
        <ul className="list-disc ml-4">
          <li>
            Users are strictly prohibited from engaging in manual trading.
          </li>
          <li>
            All trading activities should be exclusively conducted through the
            automated bot.
          </li>
        </ul>

        <div className="border-2 border-colorSecondary  my-2 px-2 py-1 rounded-lg border-dashed">
          <Checkbox
            className={isAgreed ? "red-checkbox" : ""}
            onChange={handleCheckboxChange}
          />
          &nbsp; By utilizing our services, you confirm your agreement with
          these terms and conditions. Failure to comply may result in the
          suspension of services and liquidation of assets, and the company will
          not be held responsible for resulting losses.
        </div>

        <div className="flex justify-end items-center">
          <button
            onClick={() => setIsTermsModalVisible(false)}
            className="active hover:border-neonPurple  text-white w-28 font-bold px-5 py-2 text-sm rounded-2xl ml-5"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={(e) => {
              if (isAgreed) {
                view_modelbot(e);
                handleSuccessSubmit();
              } else {
                alert("Please agree to the terms before submitting.");
              }
            }}
            className={`bg-neonPurple  hover:bg-neonPurple text-white w-28 font-bold px-5 py-2 text-sm rounded-2xl ml-5 ${!isAgreed ? "opacity-50 cursor-not-allowed" : ""
              }`}
            disabled={!isAgreed}
          >
            Submit
          </button>
        </div>
      </Modal>
    );
  };

  const [percentError1, setPercentError1] = useState('');
  const [percentBot1, setPercentBot1] = useState('');


  const minInvestment = sliderValues[Trade_Type][selectedRisk];

  // const handlePercentChange = (event) => {
  //   const newPercent = event.target.value;
  //   if (
  //     newPercent === "" ||
  //     (!isNaN(newPercent) && newPercent >= 0 && newPercent <= 100)
  //   ) {
  //     setPercent(newPercent);
  //     setPercentError(false);
  //     calculateDollarValue(count, newPercent);
  //   } else {
  //     setPercentError(true);
  //   }
  // };






  const handlePercentChange = (event) => {



    const newPercent = event.target.value;

    // Reset error message initially
    let errorMessage = '';

    // Check if input is empty or a valid number
    if (newPercent === "" || (!isNaN(newPercent) && newPercent >= 0 && newPercent <= 100)) {
      if (newPercent < 10) {
        errorMessage = 'Minimum requirement is 10%';
      } else if (newPercent > 30) {
        errorMessage = 'Maximum requirement is 30%';
      }

      setPercent(newPercent);
      setPercentError1(errorMessage);
      setPercentError(false);

      if (errorMessage === '') {
        calculateDollarValue(count, newPercent);
      }
    } else {
      setPercentError('Please enter a valid number between 0 and 100');
    }
  };


  const handleDollarChange = (event) => {
    const newDollar = event.target.value;
    if (
      newDollar === "" ||
      (!isNaN(newDollar) &&
        newDollar >= 0 &&
        parseFloat(newDollar) <= parseFloat(count))
    ) {
      setDollar(newDollar);
      calculatePercentValue(count, newDollar);
    }
  };

  const handleBotPercentChange11 = (event) => {
    const newBotPercent = event.target.value;
    if (
      newBotPercent === "" ||
      (!isNaN(newBotPercent) && newBotPercent > 0 && newBotPercent <= 100)
    ) {
      setBotPercent(newBotPercent);
      setBotPercentError(false);
      calculateBotDollarValue(count, newBotPercent);
    } else {
      setBotPercentError(true);
    }
  };

  const handleBotPercentChange = (event) => {
    const newBotPercent = event.target.value;

    // Reset error message initially
    let errorMessage = '';

    // Check if input is empty or a valid number
    if (newBotPercent === "" || (!isNaN(newBotPercent) && newBotPercent >= 0 && newBotPercent <= 100)) {
      if (newBotPercent < 10) {
        errorMessage = 'Minimum requirement is 10%';
      } else if (newBotPercent > 30) {
        errorMessage = 'Maximum requirement is 30%';
      }

      setBotPercent(newBotPercent);
      setBotPercentError(false);
      // calculateBotDollarValue(count, newBotPercent);
      setPercentBot1(errorMessage);

      if (errorMessage === '') {
        calculateBotDollarValue(count, newBotPercent);
      }
    } else {
      setBotPercentError('Please enter a valid number between 0 and 100');
    }
  };

  const handleBotDollarChange = (event) => {
    const newBotDollar = event.target.value;
    if (newBotDollar === "" || (!isNaN(newBotDollar) && newBotDollar >= 0)) {
      setBotDollar(newBotDollar);
      calculateBotPercentValue(count, newBotDollar);
    }
  };

  const calculateDollarValue = (count, percent) => {
    if (!count || !percent) {
      setDollar("");
      return;
    }

    const adjustedAmount = parseFloat(count) * (1 - parseFloat(percent) / 100);
    setDollar(adjustedAmount.toFixed(2));
  };
//
//
//
  //
//
// by default value of both dollar
useEffect(() => {
  calculateBotDollarValue(count,botpercent)
}, [])


useEffect(() => {
  calculateDollarValue(count,percent)
}, [])

//


  const calculatePercentValue = (count, dollar) => {
    if (!count || !dollar) {
      setPercent("");
      return;
    }

    const drawdownPercent =
      ((parseFloat(count) - parseFloat(dollar)) / parseFloat(count)) * 100;
    setPercent(drawdownPercent.toFixed(2));
  };

  const calculateBotDollarValue = (count, botpercent) => {
    if (!count || !botpercent) {
      setBotDollar("");
      return;
    }

    const adjustedAmount =
      parseFloat(count) * (1 + parseFloat(botpercent) / 100);
    setBotDollar(adjustedAmount.toFixed(2));
  };

  const calculateBotPercentValue = (count, botdollar) => {
    if (!count || !botdollar) {
      setBotPercent("");
      return;
    }

    const takeProfitPercent =
      ((parseFloat(botdollar) - parseFloat(count)) / parseFloat(count)) * 100;
    setBotPercent(takeProfitPercent.toFixed(2));
  };

  // const handleCountChange = (event) => {
  //   const newValue = event.target.value;
  //   updateValues(newValue);
  // };



  const updateValues = (newValue) => {
    setCount(newValue);
    setSliderValue(newValue);
    calculateDollarValue(newValue, percent);
    // calculatePercentValue(newValue, dollar);
    calculateBotDollarValue(newValue, botpercent);
    // calculateBotPercentValue(newValue, botdollar);
  };

  const [amounterror, setamounterror] = useState()

  const handleCountChange = (event) => {
    const newValue = event.target.value;
    setCount(newValue);
    updateValues(newValue);
    validateAmount(newValue);
  };


  // Function to validate the entered amount
  const validateAmount = (value) => {
    // Convert the value to a number
    const numericValue = parseFloat(value);

    // Retrieve the corresponding slider value
    const riskLevel = Investment_Risk.toUpperCase();
    const investmentType = Trade_Type; // or 'FUTURES', based on your context
    const thresholdValue = sliderValues[investmentType][riskLevel];

    // Check if the entered value is less than the slider value
    if (numericValue < thresholdValue) {
      setamounterror(`The amount must be not less than ${thresholdValue} for ${riskLevel} risk.`);
    } else {
      setamounterror('');
    }
  };


  return (
    <>
      <div className="contents mb-5">
        <div>
          <div>
            <div className="relative bg-white shadow-neumorphic rounded-lg p-2 overflow-hidden">
              <div class="absolute right-0 top-0 ">
                <div
                  class="absolute transform rotate-45 bg-neonPurple text-center text-white font-semibold py-1 right-[-53px] top-[14px] w-[170px]">
                  {Investment_Risk}
                </div>
              </div>
              <div className="flex gap-2">
                <img
                  src={myProp.images}
                  alt={myProp.Symbol}
                  className="w-12 h-12"
                />
                <div className="">
                  <div className="text-xl my-1 font-semibold">{Symbol}</div>




                  <div className="flex  items-center gap-2">
                    <div className="bg-[#f2f2f2] text-xs px-3 rounded-md ">
                      {Trade_Type}
                    </div>
                    <div className="bg-neonPurple/20 text-neonPurple font-semibold text-xs px-3 rounded-md">
                      {Name}
                    </div>


                    {/* <div className="bg-neonPurple/20 text-red-500 font-semibold text-xs px-3 rounded-md">
                      Low
                    </div> */}



                  </div>
                </div>


                {/* <div class="flex justify-center items-center ">
                <div class="">
                    <a href="#" title="Quicktoolz On Facebook"
                        class=" bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-red-600 hover:border-blue-600 hover:bg-blue-600 hover:text-white shadow-md  inline-flex items-center">
                        <span class="mx-auto">Low</span>
                    </a>
                </div>
                <div class="">
                    <a href="#" title="Quicktoolz On Twitter"
                        class=" bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-yellow-500 hover:border-blue-500 hover:bg-blue-500 hover:text-white shadow-md  inline-flex items-center">
                        <span class="mx-auto">Medium</span>
                    </a>
                </div>
                <div class="">
                    <a href="#" title="Quicktoolz On Pinterest"
                        class=" bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-green-600 hover:border-red-600 hover:bg-red-600 hover:text-white shadow-md  inline-flex items-center">
                        <span class="mx-auto">High</span>
                    </a>
                </div>
               
            </div> */}


              </div>
              <div className="mx-3">
                <div className="flex justify-between items-center my-4 ">
                  <div>
                    <div
                      className={`text-2xl font-semibold ${totalprofit >= 0 ? "text-[#26a17b]" : "text-red-500"
                        }`}
                    >
                      {totalprofit || 0} %
                    </div>
                    <div className="text-sm">Total Profit</div>
                  </div>

                  <div>
                    <div>
                      <div className="font-semibold ">{Days} days</div>
                      <div className="text-xs">Runtime</div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="my-2 text-sm flex justify-between items-center">
                  {/* <div className="">
                    <p className="text-[#7c7a7a]">Drawdown</p>
                    <p className="font-semibold ">{Drawdown} %</p>
                  </div>
                  <div>
                    <p className="text-[#7c7a7a]">TakeProfit</p>
                    <p className="font-semibold ">{TakeProfit} %</p>
                  </div> */}
                   <div>
                    <p className="text-sm text-[#7c7a7a]">Executed Trade</p>
                    <p className="font-semibold">{TotalTrades}</p>
                  </div>
                  <div>
                    <p className="text-[#7c7a7a]">Min.Balance</p>
                    <p className="font-semibold ">{investmentValue} USDT</p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-2">
                <img
                  src={myProp.img}
                  alt={myProp.img}
                  className="w-24 "
                />
                  <button
                    onClick={() => showModal(id)}
                    className="bg-neonBlue shadow-neumorphic1 text-lg py-1 px-4 text-white  rounded-full"
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {isModalOpen && (
          <TelegramModel
            isModalOpen={isModalOpen}
            showModal1={showModal1}
            handleOk1={handleOk1}
            handleCancel1={handleCancel1}
          />
        )} */}
        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          closeIcon={null}
          className="bg-neonPurple rounded-3xl border-4  border-white px-0 text-white"
          title={
            <>
              <div className="flex items-start justify-start border-dashed border border-black p-2 rounded-lg mb-2  gap-4 ">
                <FaInfoCircle size={20} />
                <p className="text-justify text-xs font-medium">You are using a shared parameter. As market conditions differ, these parameters cannot guarantee the same results.</p>
              </div>
              <div className="rounded-lg pb-1  font-bold flex items-center justify-between">
                <div>
                  <div className="flex items-end mb-2 justify-start gap-2">
                    <BsRobot className="text-colorSecondaryDark text-4xl" />
                    <div className="text-2xl font-bold "> {Name}</div>
                  </div>
                  <div className="flex mt-2">
                    <div className="text-black shadow-sm border-2 border-neonPurple text-[10px] px-3 font-bold py-1 rounded-full ">
                      {VarBotID}
                    </div>
                    <div className="text-black shadow-sm border-2 border-neonPurple text-[10px] px-3 font-bold py-1 rounded-full ml-2 ">
                      {Trade_Type}
                    </div>
                    <div className="text-black shadow-sm border-2 border-neonPurple text-[10px] px-3 font-bold py-1 rounded-full ml-2 ">
                      {Investment_Risk}
                    </div>
                  </div>
                </div>
                <div className="text-xs text-gray-500">
                  <img src={images} alt="coin" className="w-14" />
                  <p>{Symbol}</p>
                </div>
              </div>
            </>
          }
        >
          <div className="relative  overflow-hidden">
            <div className="md:border-l-2 md:border-t-0 md:border-r-0 border-b-0 md:border-dashed md:border-colorSecondary ml-2 md:pl-6 justify-start mt-5 items-center flex flex-col w-full md:gap-5">
              <div className="w-11/12 mr-auto ">
                {/* <div className=" text-black  font-medium text-xs p-2 text-justify">
                <p>You are using a shared parameter. As market conditions differ, these parameters cannot guarantee the same results.</p>
                </div> */}

                <div className="grid grid-cols-2 gap-6  rounded-lg justify-items-center items-end md:items-center p-[20px]  ">
                  <div>
                    <p className=" text-black text-center text-sm">
                      Select Exchange
                    </p>
                    <select
                      id="selectOption"
                      className="bg-colorPrimary  boder-2 text-black shadow-neumorphic1 mt-1 px-2 md:px-4 py-1 rounded-full"
                      onChange={handleOptionChange}
                      value={selectedExchange}
                    >
                      <option value="">Select...</option>
                      {exchnagelist?.map((option) => (
                        <option
                          className="rounded-3xl"
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                    {exchangeError && (
                      <div className="text-red-500 text-xs">
                        Please select an exchange.
                      </div>
                    )}
                  </div>

                  <div>
                    <p className=" text-black text-center">Select API</p>

                    <select
                      id="selectOption2"
                      className="bg-colorPrimary text-black shadow-neumorphic1 mt-1 px-2 md:px-4  py-1 rounded-full"
                      onChange={handleOptionChange}
                      value={selectedOption}
                    >
                      <option value="">Select...</option>
                      {optionsForSelect2?.map((option) => (
                        <option
                          className="rounded-3xl"
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                    {optionError && (
                      <div className="text-red-500 text-xs">
                        Please select an API.
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="my-4 bg-white px-3  md:px-4 py-2 rounded-lg"> */}
                {/* <div className="flex items-center justify-between">
                    <div className="text-colorSecondaryDark">Risks Matrix</div>
                    <div className="flex items-center">
                      <Tooltip title="Coming Soon">
                        <label className="mr-2 text-black text-xs">
                          Telegram Alert:{" "}
                        </label>

                        <Switch
                          type="checkbox"
                          checked={isChecked}
                          // onChange={toggleSwitch}
                          className={isChecked ? "off" : "on"}
                          checkedChildren="On"
                          unCheckedChildren="Off"
                        />
                      </Tooltip>
                    </div>
                  </div>

                  <Slider
                    value={sliderValue}
                    onChange={(value) => {
                      handleSliderChange(value);
                      setCount(value);
                      updateValues(value);
                    }}
                    step={null}
                    defaultValue={0}
                    marks={marks}
                  /> */}
                {/* <div className="text-black flex justify-center items-center">
                    <div
                      className={`border-2 text-sm md:text-lg rounded-3xl px-6 py-1 md:p-0 md:px-7  ${
                        count < minInvestment
                          ? "border-red-500"
                          : "border-[#ccc]/30"
                      } `}
                    >
                      USDT
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="text-[red] text-center">
                      Once Invested, amount cannot be changed
                    </p>
                  </div> */}
                {/* </div> */}
                <div className="text-start my-4">
                  <Input
                    id="count"
                    name="count"
                    type="text"
                    suffix="USDT"
                    className="modalinpu"
                    pattern="\d*"
                    value={count}
                    onChange={handleCountChange}
                    placeholder="Enter amount"
                  />
                  {countError && (
                    <div className="text-red-500 text-xs">
                      Please enter a amount.
                    </div>
                  )}

                  {amounterror && <div style={{ color: 'red', marginTop: '8px' }}>{amounterror}</div>}
                </div>

                <div className="flex justify-center gap-4">
                  <div>
                    <div className="flex flex-col sm:flex-row items-center justify-center text-colorSecondaryDark">
                      <div className="text-center w-[90px]">Drawdown</div>
                      <Tooltip title={<div>
                        <div>Drawdown in trading is the decline from a peak in the value of your investment or trading account to a lower point.</div>
                        <br />
                        <div>If the drawdown is less than or equal to the specified threshold, the bot will automatically stop trading to prevent further losses</div>
                      </div>
                      }>
                        <FaInfoCircle className="ml-2 cursor-pointer" />

                      </Tooltip>
                    </div>{" "}
                    <div className="text-start grid grid-cols-1 sm:grid-cols-2 gap-1 mt-4">
                      <div className="mb-1 sm:mb-0">
                        <Input
                          id="percent"
                          name="percent"
                          type="text"
                          suffix="%"
                          className="modalinput"
                          value={percent}
                          onChange={handlePercentChange}
                          placeholder="Enter percentage"
                          pattern="\d*"
                        />
                      </div>
                      <div>
                        <Input
                          id="dollar"
                          name="dollar"
                          type="text"
                          suffix="$"
                          className="modalinput"
                          value={dollar}
                          onChange={handleDollarChange}
                          placeholder="Enter dollar amount"
                          pattern="\d*"
                        />
                      </div>

                    </div>
                    {percentError1 && <p className="text-[red] text-[11px] text-center my-2">{percentError1}</p>}
                  </div>

                  <div className="border-l-2 border-black border-dashed"> </div>

                  <div className=" ">
                    <div className="flex flex-col sm:flex-row items-center justify-center text-colorSecondaryDark">
                      <div className="text-center w-[100px]">Take Profit</div>
                      <Tooltip title={<div>
                        <div>Take profit is a preset profit limit where the bot automatically closes a trade to lock in gains.</div>
                        <br />
                        <div>If the profit is more than or equal to the preset limit, the bot will automatically close the trade to lock in gains</div>
                      </div>
                      }>
                        <FaInfoCircle className="ml-2 cursor-pointer" />
                      </Tooltip>
                    </div>
                    <div className="text-start grid grid-cols-1 sm:grid-cols-2 my-4 gap-1">
                      <div className="mb-1 sm:mb-0">
                        <Input
                          id="botpercent"
                          name="botpercent"
                          type="text"
                          suffix="%"
                          className="modalinput"
                          value={botpercent}
                          onChange={handleBotPercentChange}
                          placeholder="Enter percentage"
                          pattern="\d*"
                        />
                      </div>
                      <div>
                        <Input
                          id="botdollar"
                          name="botdollar"
                          type="text"
                          suffix="$"
                          className="modalinput"
                          value={botdollar}
                          onChange={handleBotDollarChange}
                          placeholder="Enter dollar amount"
                          pattern="\d*"
                        />
                      </div>
                      {percentBot1 && <p className="text-[red] text-[11px] text-center my-2">{percentBot1}</p>}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <p className="text-black">
                    Bot stops when drawdown or take profit is reached.
                  </p>
                </div>
                <div className="flex items-center justify-around">
                  <div
                    className="bg-white border-solid cursor-pointer border border-neonPurple text-black font-bold px-5 py-2 text-sm rounded-2xl"
                    onClick={handleCancel}
                  >
                    Cancel
                  </div>
                  <button
                    className="bg-neonPurple hover:bg-neonPurple text-white w-24 font-bold px-5 py-2 text-sm rounded-2xl ml-5"
                    onClick={showModalNew}
                  >
                    Next
                  </button>
                  <TermsModal
                    visible={isTermsModalVisible}
                    onCancel={() => setIsTermsModalVisible(false)}
                    onSubmit={view_modelbot}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default AllBot1;
