import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BiSupport } from "react-icons/bi";
// import SideBar from "./Sidebar";
import SideBar from "./SideBar";
import useUpdateApi from "../Components/UpdateApi";

const Dashboard = ({ selectedImage }) => {
  const [open, setOpen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (open == true) {
      setOpen(false);
    }
  }, []);

  const [openSide, setOpenSide] = useState(true);
  const { wallet, profile, View_Wallet, View_Profile } = useUpdateApi();

  const pathToHideIcon = "/dashboard/support1";
  const pathsToApplyBottom15 = [
    "/dashboard/api_settings1",
    "/dashboard/support1",
  ];
  useEffect(() => {
    View_Wallet()
    // This useEffect will trigger whenever wallet changes
    // You can add any logic here that needs to be executed when wallet updates
  }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js';
  //   script.async = true;
  //   script.innerHTML = JSON.stringify({
  //     "symbols": [
  //       { "description": "", "proName": "BINANCE:BTCUSDT" },
  //       { "description": "", "proName": "BINANCE:ETHUSDT" },
  //       { "description": "", "proName": "BINANCE:ADAUSDT" },
  //       { "description": "", "proName": "BINANCE:SOLUSDT" },
  //       { "description": "", "proName": "BINANCE:DOGEUSDT" },
  //       { "description": "", "proName": "BINANCE:MANAUSDT" },
  //       { "description": "", "proName": "BINANCE:BNBUSDT" },
  //       { "description": "", "proName": "BINANCE:OCEANUSDT" }
  //     ],
  //     "isTransparent": false,
  //     "showSymbolLogo": true,
  //     "colorTheme": "light",
  //     "locale": "en"
  //   });

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <div>
      <div className=" lg:h-screen  bg-colorPrimary">
        <Navbar
          openSide={openSide}
          setOpenSide={setOpenSide}
          selectedImage={selectedImage}
          className="z-50"
          wallet={wallet}
          profile={profile}
          View_Profile={View_Profile}
          View_Wallet={View_Wallet}
        />
        <div
          className={`  h-auto max-h-screen ${openSide
              ? "xl:ml-56 md:ml-56 md:mr-3  "
              : "md:pl-16 md:pr-4 lg:ml-0  w-full"
            } `}
        >
          {/* <div
            className={` ${
              pathsToApplyBottom15.includes(window.location.pathname)
                ? ""
                : "mb-[1%]"
            }`}
          > */}
          <SideBar openSide={openSide} setopenSide={setOpenSide} profile={profile}
          View_Profile ={View_Profile } />
          {/* <Navbar
            openSide={openSide}
            setOpenSide={setOpenSide}
            selectedImage={selectedImage}
            className="z-50 mb-5 "
          /> */}
          {/* <div className="mt-20 ">
            <Outlet
              className={`bottom-2  h-full${
                pathsToApplyBottom15.includes(window.location.pathname)
                  ? ""
                  : "mb-[1%] mt-[5%]"
              }`}
            />
          </div> */}

          <div
            className={` mt-5  ${pathsToApplyBottom15.includes(window.location.pathname)
                ? ""
                : "mb-[3%]"
              }`}
          >
            {/* <Sidebar openSide={openSide} setOpenSide={setOpenSide} /> */}
            <Outlet
              className={`bottom-12 ${pathsToApplyBottom15.includes(window.location.pathname)
                  ? ""
                  : "mb-[5%]"
                }`}
            />
          </div>
          {/* </div> */}

          <div className="md:block hidden">
            <Link
              to="/dashboard/support"
              className={`bottom-10 right-0 z-40 fixed p-2 rounded-full shadow-neumorphic bg-neonPurple mx-2 ${location.pathname === pathToHideIcon ? "hidden" : ""
                } `}
            >
              <BiSupport size={25} className="text-white" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
