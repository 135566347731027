import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerField = ({ label, name, selected, onChange }) => {
  return (
    <div className='grid grid-cols-2 items-center'>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <DatePicker
        selected={selected}
        onChange={(date) => onChange({ target: { name, value: date } })}
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        dateFormat="yyyy/MM/dd"
      />
    </div>
  );
};

export default DatePickerField;

// import React from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { FaRegCalendarAlt } from "react-icons/fa";

// const DatePickerField = ({ label, name, selected, onChange }) => {
//   return (
//     <div className='grid grid-cols-2 items-center'>
//       <label className="block text-sm font-medium text-gray-700">{label}</label>
//       <div className="relative flex items-center gap-4">
//         <DatePicker
//           selected={selected}
//           onChange={(date) => onChange({ target: { name, value: date } })}
//           className="mt-1 block w-full p-2 border border-gray-300 rounded-md pl-8"
//           dateFormat="yyyy/MM/dd"
//         />
//         <div className="absolute inset-y-0 left-0 pl-3 gap-5 flex items-center pointer-events-none">
//           <FaRegCalendarAlt className="h-5 w-5 text-gray-400" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DatePickerField;
