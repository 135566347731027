import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import Reportcopy from "../Pages/Reportcopy";
import KucoinReport from "../Pages/KucoinReport";

const ReportSwipeable = () => {
  const [index, setIndex] = useState(0);
  


  const tabNames = [
    "Binance",
    "Kucoin",
   
  ];

  
 
  

  const handleTabClick = (newIndex) => {
    setIndex(newIndex);
    
   
  
    
  };

  const tabContent = [
    <Reportcopy key={0}/>,
    <KucoinReport key={1}/>
  
  ];

  return (
    <>
     <div className="bg-colorPrimary mx-2 rounded-3xl">
        <div className="mt-5">
          <div className="flex border-b-2 border-[#ccc6] mobilescroll mx-5">
            {tabNames?.map((tabName, tabIndex) => (
              <div
                key={tabIndex}
                className={`tab py-1 w-auto min-w-fit uppercase px-6 mx-3 text-center cursor-pointer text-sm ${index === tabIndex
                    ? "active-tab border-gradientbottom text-black font-semibold"
                    : "text-[#666666] "
                  }`}
                onClick={() => handleTabClick(tabIndex)}
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          className="mt-3 mx-4"
        >
          {tabContent}
        </SwipeableViews>
      </div>
    </>
  );
};

export default ReportSwipeable;