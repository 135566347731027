import { Col, Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FcApproval } from 'react-icons/fc';
import { toast } from 'react-toastify';
import Male from "../assets/Profile/ProfileMale/1.webp";
import Female from "../assets/Profile/Profilefemale/1.webp";
import Bot from "../assets/Profile/Profilebull/1.webp";

function ModelEnter() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [avatarStatus, setAvatarStatus] = useState("");
    const [selectedImage, setSelectedImage] = useState(-1);

    const jwt = localStorage.getItem("jwt");
    const View_Avatar = async () => {

        const postdata = {
            jwt: jwt,
        };
        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_avatar",
            data: postdata,
        })
            .then(async (res) => {
                const response = await res.data.Success;
                setAvatarStatus(response);
                if (response === "") {
                    setIsModalOpen(true)
                }
            })
            .catch((err) => {
                console.log(err?.response?.data?.Error);
            });
    };

    const View_ProfileImage = async (imageName) => {
        setSelectedImage(imageName);

        const jwt = localStorage.getItem("jwt");
        const postdata = {
            jwt: jwt,
            avatar: imageName,
        };
        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PATH_USER_ODECENT + "set_avatar",
            data: postdata,
        })
            .then(async (res) => {
                const response = await res;
                if (response) {
                    toast(response?.data?.Success);
                    setIsModalOpen(false);
                } else {
                    toast.error(response?.data?.Error);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.Error);
            });
    };

    useEffect(() => {
        View_Avatar()
    }, [avatarStatus]);

    const images = [
        { name: "Male", url: Male },
        { name: "Female", url: Female },
        { name: "Bot", url: Bot },
    ];

  return (
    <div>
         <Modal title="Select Avatar" footer={false} open={isModalOpen} className=''>
                <div className=''>
                    <div className='flex '>
                        {images?.map((image, index) => (
                            <Col span={8} key={index}>
                                <div className="image-option">
                                    <img
                                        className="images-avatar"
                                        src={image.url}
                                        alt={`Image ${index + 1}`}
                                        onClick={() => View_ProfileImage(image.name)}
                                    />
                                    {selectedImage === image.name && (
                                        <div className='absolute top-0 right-0 text-green-500'>
                                            <FcApproval size={24} />
                                        </div>
                                    )}
                                    <div className='text-center'>{image.name}</div>
                                </div>
                            </Col>
                        ))}
                    </div>
                </div>
            </Modal>
    </div>
  )
}

export default ModelEnter