import { Avatar, Empty, Modal, Table } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import CustomPagination from "./Pagination";
import Support from "../Pages/Support";
import { FaEye } from "react-icons/fa";
import UpdateApi from "./UpdateApi";

function SupportTable(props) {

    const { rankqualData } =
        props.data;


    const { profile } = UpdateApi();

    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (record) => {
        setSelectedRecord(record);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: "DATE",
            dataIndex: "Created_At",
            key: "Created_At",
        },
        {
            title: "SUPPORT ID",
            dataIndex: "SupportID",
            key: "SupportID",
        },
        {
            title: "SUBJECT",
            dataIndex: "Subject",
            key: "Subject",
        },
        {
            title: "MESSAGE",
            dataIndex: "Message",
            key: "Message",
            render: (text, record) => (
                <span>{record.Message[0]?.Message}</span>
            ),
        },
        {
            title: "STATUS",
            dataIndex: "Status",
            key: "Status",
            render: (text, record) => (
                <span className="flex items-center gap-2" style={{ color: text === "OPEN" ? "red" : "green" }}>
                    {text}<FaEye size={18} onClick={() => showModal(record)} />
                </span>
            ),
        },
    ];

    const isMobile = useMediaQuery({ maxWidth: 1050 });


    if (isMobile) {
        return (
            <>
                <div className="mt-2">
                    {rankqualData && rankqualData !== "No tickets raised yet!" ? (
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1 lg:gap-4 mx-auto w-full">
                            {rankqualData.map((record) => (
                                <div
                                    key={record.SupportID}
                                    className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg"
                                >
                                    <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                                        <div className="card-title font-semibold">Date Time:</div>
                                        <div className="card-data">{record.Created_At}</div>
                                    </div>
                                    <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                                        <div className="card-title font-semibold">Ticket ID:</div>
                                        <div className="card-data">{record.SupportID}</div>
                                    </div>
                                    <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                                        <div className="card-title font-semibold">Subject:</div>
                                        <div className="card-data">{record.Subject}</div>
                                    </div>
                                    <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                                        <div className="card-title font-semibold">Message:</div>
                                        <div className="card-data">{record.Message[0]?.Message}</div>
                                    </div>
                                    <div className="card-content flex justify-between items-center md:text-xs lg:text-md text-sm">
                                        <div className="card-title font-semibold">Status:</div>
                                        <div className="card-data">
                                            <span style={{ color: record.Status === "OPEN" ? "red" : "green" }}>
                                                {record.Status}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
                            <Empty />
                        </div>
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="w-full bg-colorPrimary">
                {rankqualData && rankqualData !== "No tickets raised yet!" ? (
                    <Table
                        className="table-newres my-2"
                        columns={columns}
                        dataSource={rankqualData}
                        pagination={false}
                    />
                ) : (
                    <div className="card-container lg:my-2 my-4 border-2 shadow-neumorphic bg-colorPrimary p-2 rounded-lg" style={{ textAlign: "center", margin: "20px 0" }}>
                        <Empty />
                    </div>
                )}

                <Modal title="Support Details" footer={false} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    {selectedRecord && (
                        <div>
                            <div className="w-full flex justify-center"><p className="bg-[#f2f2f2] px-2 rounded-xl text-center mb-4 text-xs ">{selectedRecord.Created_At}</p></div>

                            <div className="flex justify-end gap-2 items-center my-1 ">
                                <div className="bg-neonPurple/40 px-2 py-1 font-semibold rounded-tl-lg rounded-br-lg">
                                    <p className="">Support ID: {selectedRecord.SupportID}</p>
                                    <p className="">Message: {selectedRecord.Message[0]?.Message}</p>
                                    <p className="">Subject: {selectedRecord.Subject}</p>
                                </div>
                                <Avatar src={profile.Profile_Image} size={30} alt="profile" className="" />
                            </div>

                            <hr className="my-2" />

                            <div className="w-full flex justify-center"><p className="bg-[#f2f2f2] px-2 rounded-xl text-center mb-4 text-xs ">{selectedRecord.Closed_On.replace("", "Pending....")}</p></div>

                            {/* <div className="flex my-1 justify-start">
                                <div className="bg-[#032b43] text-white px-2 py-1 rounded-bl-lg rounded-t-lg">DateTime: {selectedRecord.Message[0]?.DateTime}</div>
                            </div> */}

                            <div className="flex my-1 justify-start">
                                <div className="bg-[#032b43] text-white px-2 py-1 rounded-bl-lg rounded-tr-lg">Reply: {selectedRecord.Closed_On.replace("", "Pending....")}</div>
                            </div>


                        </div>
                    )}
                </Modal>
            </div>
        </>
    );



}

export default SupportTable;
