import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HandleSession } from './HandleSession';
import { Navigate, useNavigate } from 'react-router-dom';

function UpdateApi() {
    const [wallet, setWallet] = useState("");
    const [profile, setProfile] = useState("");
    const [avatar, setAvatar] = useState();

    const View_Wallet = async () => {
        const jwt = localStorage.getItem("jwt");
        const postdata = {
            jwt: jwt,
        };

        try {
            const response = await axios({
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                url: process.env.REACT_APP_API_Wallet + "wallet_details",
                data: postdata,
            });
            const res = await response.data;
            setWallet(res.Success);
        } catch (err) {
            HandleSession(err)
        }
    };

    useEffect(() => {
        View_Wallet();
    }, []);

    const View_Profile = async () => {
        const jwt = localStorage.getItem("jwt");
        const postdata = {
            project: process.env.REACT_APP_PROJECT,
            jwt: jwt,
        };

        try {
            const response = await axios({
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
                data: postdata,
            });
            const responseData = await response.data;
            if (responseData?.Success) {
                setProfile(responseData?.Success);
                Navigate('/dashboard/profile');
            } else {
                toast.error(responseData.Error);
            }
        } catch (err) {
            toast.error(err?.response?.data?.Error);
            HandleSession(err)
        }
    };

    const [checkBotFeature, setCheckBotFeature] = useState([])
    const [checkTradeFeature, setCheckTradeFeature] = useState([])

    const checkSubscription = () => {
        const jwt = localStorage.getItem('jwt')
        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_SUB + 'subscription_features',
            data: { jwt: jwt }
        }).then(res => {
            setCheckBotFeature(res.data.Success.Bot_features_allowed)
            setCheckTradeFeature(res.data.Success.Trade_features_allowed)
        })
            .catch(err => {
                console.log("getIndicator", err)
            })
    }

    useEffect(() => {
        checkSubscription();
    }, []);

    const View_Avatar = async () => {
        const jwt = localStorage.getItem("jwt");
        const postdata = {
            jwt: jwt,
        };

        try {
            const response = await axios({
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                url: process.env.REACT_APP_API_PATH_NEW + "/user/view_avatar",
                data: postdata,
            });
            const responseData = await response.data;
            if (responseData.Success) {
                setAvatar(responseData.Success);
            } else {
                toast.error(responseData.Error);
            }
        } catch (err) {
            toast.error(err.response.data.Error);
        }
    };

    const refreshInterval = 3600000;

    useEffect(() => {
        View_Profile();
        View_Avatar()
    }, []);


    return { avatar, wallet, profile, View_Wallet, View_Profile, checkBotFeature, checkTradeFeature };
}

export default UpdateApi;
