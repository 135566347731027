import { Select } from 'antd';
import React from 'react';
import makeAnimated from 'react-select/animated';
// import Select from 'react-select';

const animatedComponents = makeAnimated();
const MultiSelectInput = ({ label, options, selectedOptions, handleChange }) => {
    
    
    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
            <Select
                isMulti
                // closeMenuOnSelect={false}
                // components={animatedComponents}
                // options={options}
                // value={selectedOptions}
                // onChange={handleChange}
                // className="mt-1 "
                // classNamePrefix="custom-select"
                mode="tags"
                style={{
                  width: '100%',
                }}
                placeholder="Search..."
                onChange={handleChange}
                value={selectedOptions}
                options={options}
                className='custom-select'
            />
        </div>
    );
};

export default MultiSelectInput;
