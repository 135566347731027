import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Modal, Checkbox, Button, Tabs, Form } from "antd";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const { TabPane } = Tabs;

const CustomLoader = () => {
  return (
    <div className="custom-loader">
      <div className="loader"></div>
    </div>
  );
};


function Login({ handleSubmit: originalHandleSubmit }) {
  const [value, setValue] = useState({ username: "", password: "", totp: "" });
  const [isLabelAbove, setIsLabelAbove] = useState({
    username: false,
    password: false,
    totp: false,
  });
  const Navigate = useNavigate();
  const [ip, setIP] = useState("");
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    handletotp();
  }, [value.username]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "username") {
      if (value.length > 0) {

      } else {
        // setTotp(false);
      }
    }

    setValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFocus = (name) => {
    setIsLabelAbove((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleBlur = (name) => {
    setIsLabelAbove((prevState) => ({
      ...prevState,
      [name]: value[name] !== "",
    }));
  };

  const [totp, setTotp] = useState(false);

  const totalPages = 10;

  const handletotp = async (e) => {
    const postdata = {
      project: process.env.REACT_APP_PROJECT,
      username: value.username,
    };
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "status_totp",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data;
        setTotp(res?.Success);
      })
      .catch((err) => {
        console.error(err, "Error while fetching totp:");
        setTotp(false);
      });
  };

  const handleSubmit = (event) => {
    // if (!turnstileToken) {
    //   toast('Please complete the CAPTCHA');
    //   return;
    // }

    // if (
    //   !turnstileToken &&
    //   (window.location.hostname !== "localhost" && window.location.hostname !== "192.168.0.131" ||
    //     (window.location.port !== "3000" && window.location.port !== "3001")
    //   )
    // ) {
    //   toast("Please complete the CAPTCHA");
    //   return;
    // }

    setisLoading(true);
    // event.preventDefault();
    const postdata = {
      ip: ip,
      username: value.username.toLowerCase(),
      password: value.password,
      project: process.env.REACT_APP_PROJECT,
      totp: value.totp,
    };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "login",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response.data;

        localStorage.setItem("jwt", response?.data?.JWT);
        localStorage.setItem("avatar", response?.data?.Avatar);

        if (res?.Success === "Login successfull!") {
          setValue(res);
          Navigate("/dashboard/home");
          toast(res?.Success);
          setisLoading(false);
        } else {
          toast.error(res.Error);
        }
        if (res?.Success === "Email not verified!!") {
          toast(res?.Success);
          Navigate(
            `/email_verify?email=${res.Email}&username=${value.username}`
          );
        }
        const successValue = res.Success;
        if (res?.Success === "Consent Form not verified!!") {
          toast(res?.Success);
          Navigate(`/dashboard/botmain`);
          setisLoading(false);
          originalHandleSubmit();
          localStorage.setItem("jwt", response?.data?.JWT);
        }
        // setisLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [turnstileToken, setTurnstileToken] = useState(null);
  const projectKey = process.env.REACT_APP_KEY || "Default Project";


  // useEffect(() => {
  //   const loadTurnstileScript = () => {
  //     const script = document.createElement("script");
  //     script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
  //     script.async = true;
  //     script.defer = true;

  //     script.onload = () => {
  //       const intervalId = setInterval(() => {
  //         const widgetContainer = document.querySelector("#myWidget");
  //         if (widgetContainer) {
  //           clearInterval(intervalId);
  //           window.turnstile.render(widgetContainer, {
  //             sitekey: projectKey,
  //             theme: "light",
  //             callback: (token) => setTurnstileToken(token),
  //           });
  //         }
  //       }, 100);

  //       return () => {
  //         document.head.removeChild(script);
  //       };
  //     };

  //     document.head.appendChild(script);
  //   };

  //   loadTurnstileScript();
  // }, [projectKey]);

  // useEffect(() => {
  //   const loadTurnstileScript = () => {
  //     const script = document.createElement("script");
  //     script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
  //     script.async = true;
  //     script.defer = true;

  //     script.onload = () => {
  //       const intervalId = setInterval(() => {
  //         const widgetContainer = document.querySelector("#myWidget");
  //         if (widgetContainer) {
  //           clearInterval(intervalId);
  //           window.turnstile.render(widgetContainer, {
  //             sitekey: projectKey,
  //             theme: "light",
  //             callback: (token) => setTurnstileToken(token),
  //           });
  //         }
  //       }, 100);

  //       return () => {
  //         document.head.removeChild(script);
  //       };
  //     };

  //     document.head.appendChild(script);
  //   };

  //   // Load script only if not on localhost
  //   if (window.location.hostname !== "localhost" && window.location.hostname !== "192.168.0.131" ||
  //     (window.location.port !== "3000" && window.location.port !== "3001")) {
  //     loadTurnstileScript();
  //   }
  // }, [projectKey]);

  return (
    <>
      <div className="container mx-auto mt-10 h-auto max-h-screen">
        <div className="flex justify-center items-center h-full">
          <div className="flex justify-center w-full md:bg-colorPrimaryDark   relative rounded-2xl  overflow-hidden">
            <div className="md:mx-auto mt-2 justify-center h-full items-center w-full">
              <div className="text-center">
                <div className="text-3xl font-semibold mb-3 text-neonPurple">
                  Log in
                </div>
                {/* <div className="text-white md:text-black">
                  Don't have an account ?&nbsp;
                  <Link
                    to="/signup"
                    className="text-neonPurple font-bold underline"
                  >
                    Sign up
                  </Link>
                </div> */}
                <div className="px-5 py-5  rounded-md mt-5  lg:w-9/12 mx-auto">
                  <Form>
                    <div className="relative my-4">
                      <input
                        id="username"
                        placeholder="Username"
                        name="username"
                        type="text"
                        className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                        // className="py-2 px-4 border border-none rounded-full focus:outline-none bg-colorPrimaryDark shadow-neumorphic1  focus:border-none w-full"
                        value={value.username}
                        onChange={handleChange}
                        onFocus={() => handleFocus("username")}
                        onBlur={() => handleBlur("username")}
                        autoComplete="off"
                      />
                    </div>

                    <div className="relative">
                      <input
                        id="password"
                        placeholder="Password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                        // className="py-2 px-4 border border-none rounded-full focus:outline-none bg-colorPrimaryDark shadow-neumorphic1  focus:border-none w-full"
                        value={value.password}
                        onChange={handleChange}
                        onFocus={() => handleFocus("password")}
                        onBlur={() => handleBlur("password")}
                        autoComplete="off"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                        {showPassword ? (
                          <FaEyeSlash
                            onClick={handleTogglePasswordVisibility}
                            className="h-6 text-gray-700"
                          />
                        ) : (
                          <FaEye
                            onClick={handleTogglePasswordVisibility}
                            className="h-6 text-gray-700"
                          />
                        )}
                      </div>
                    </div>

                    {/* <div className="relative my-4">
                    <input
                      id="password"
                      placeholder="Password"
                      name="password"
                      type="password" // Change this from 'text' to 'password'
                      className="py-2 px-4 border border-slate-900 rounded-full focus:outline-none focus:border-colorSecondary w-full"
                      value={value.password}
                      onChange={handleChange}
                      onFocus={() => handleFocus("password")}
                      onBlur={() => handleBlur("password")}
                      autoComplete="off"
                    />
                  </div> */}
                    {totp ? (
                      <div className="relative my-4">
                        <input
                          id="totp"
                          name="totp"
                          placeholder="2FA"
                          className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                          value={value.totp}
                          type={showPassword ? "text" : "password"}
                          onChange={handleChange}
                          onFocus={() => handleFocus("totp")}
                          onBlur={() => handleBlur("totp")}
                          autoComplete="off"
                        />
                      </div>
                    ) : null}

                    {/* <Checkbox
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                  >
                    Remember me
                  </Checkbox> */}
                    <div className="checkbox my-4 flex justify-center items-center">
                      <div id="myWidget"></div>
                    </div>
                    {/* <Button
                    // type="submit"
                      htmlType="submit"
                      className="rounded-full active hover:text-white shadow-neumorphic1 py-1 text-white mt-4 cursor-pointer hover:active/70 px-20 "
                      onClick={handleSubmit}
                    // disabled={!isCheckboxChecked || isLoading}
                    >
                      Login
                    </Button> */}
                    <button
                      htmlType="submit"
                      className="rounded-full active shadow-neumorphic1 py-1 text-white mt-4 cursor-pointer hover:active/70 hover:text-white px-20"
                      onClick={handleSubmit}
                    >
                      <span className="mb-5 p-0">
                        {/* {isLoading ? <CustomLoader /> : "Login"} */}
                        Login
                      </span>
                    </button>
                  </Form>
                  {/* <div className="flex justify-center items-center gap-4">
                      <button className="rounded-full active shadow-neumorphic1 py-1 text-white mt-4 cursor-pointer hover:active/70 hover:text-white px-5 md:w-full ">
                        <Link to="/forgetusername">Forgot Username</Link>
                      </button>
                      <button className="rounded-full active shadow-neumorphic1 py-1 text-white mt-4 cursor-pointer hover:active/70 hover:text-white px-5 md:w-full ">
                        <Link to="/forgetpassword">Forgot Password</Link>
                      </button>
                    </div> */}
                    <div className="mt-5  flex   justify-between">
                      <div className="hover:underline hover:hover:underline hover:">
                        <Link
                          to="/forgetusername"
                          className="text-neonPurple my-2 md:my-0" 
                        >
                          Forgot <span className="">Username</span>{" "}
                          
                        </Link>
                      </div>
                      <div className="hover:underline hover:hover:underline hover:">
                        <Link
                          to="/forgetpassword"
                          className="text-neonPurple "
                        >
                          Forgot <span className="">Password</span>{" "}
                          
                        </Link>
                      </div>

                    </div>

                 
                  {/* 
                  <div className="mt-1">
                    <Link
                      to="/forgetpassword"
                      className="text-neonPurple  hover:underline"
                    >
                      
                      Password?
                    </Link>
                  </div>
                  <div className="mt-1">
                    <Link
                      to="/forgetpassword"
                      className="text-neonPurple  hover:underline"
                    >
                      
                      Password?
                    </Link>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
          {/* {isPdfViewerOpen && <PdfViewerMain response={response} />} */}
        </div>
      </div>
    </>
  );
}

export default Login;
