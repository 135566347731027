import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Typography, Row, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import ant from "../Lotties.json";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const { Title } = Typography;

const EmailVerify = () => {

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [checkboxes, setCheckboxes] = useState([
   
    false, // 8 characters
    false, // 1 lowercase letter
    false, // 1 uppercase letter
    false, // 1 number
    false, // 1 special character
  ]);

  
  const navigate = useNavigate();
  const { email: emailParam } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.state);
  const username = searchParams.get("username");
  const email = searchParams.get("email");

 
  const maskEmail = (email) => {
    if (!email) return "";
    const [username, domain] = email.split("@");
    const maskedUsername =
      username.charAt(0) +
      "*".repeat(username.length - 2) +
      username.charAt(username.length - 1);
    return maskedUsername + "@" + domain;
  };


  const getverifyotp = async (e) => {
    const params = new URLSearchParams(window.location.search);
    const uname = params.get("username");
    const formData = new FormData();
    formData.append("username", username.toLowerCase());
    formData.append("otp", otp);
    formData.append("password", newpassword);
    formData.append("con_password", confirmpassword);
   
    
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER_ODECENT + "reset_password ",
      data: formData,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res.Success) {
          toast(res.Success);
          window.location.href = "/";
        } else {
          toast.error(res.Error);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.Error);
      });
    setLoading(false);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    const checkPasswordRequirements = () => {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      const requirementsMet = [
        newpassword.length >= 8,
        /[a-z]/.test(newpassword),
        /[A-Z]/.test(newpassword),
        /\d/.test(newpassword),
        /[@$!%*?&]/.test(newpassword),
      ];

      setCheckboxes(requirementsMet); 

      return regex.test(newpassword) && requirementsMet.every((req) => req);
    };

    checkPasswordRequirements();
  }, [newpassword]); 

 const areAllCheckboxesChecked = () => {
   return checkboxes.every((checkbox) => checkbox === true);
 };

 const [showPassword, setShowPassword] = useState(false);

 const handleTogglePasswordVisibility = () => {
   setShowPassword(!showPassword);
 };

  return (
    <>
      <div className="container mx-auto mt-10 px-2 flex justify-center items-center">
        <div className="w-full bg-colorPrimaryDark  relative rounded-2xl py-3  overflow-hidden">
          <div className="text-center">
            <div className="text-lg lg:text-2xl  font-semibold mb-3 text-neonPurple ">
              Forgot Password
            </div>

            <p className="text-white md:text-black text-sm md:text-base my-3">
              Please Enter the OTP received on your email.
          
            </p>
            <div>
              <Form>
                <div className="justify-content-center">
                  <Form.Item
                    name="OTP"
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                    className="px-5 py-1 rounded-lg mt-5  md:w-9/12 mx-auto"
                  >
                    <input
                      placeholder="OTP"
                      name="otp"
                       className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                      //  className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                      type="otp"
                      id="otp"
                      value={otp}
                      onChange={handleOtpChange}
                    />
                    {/* <Input
                      //   prefix={<ArrowForwardIcon />}
                      placeholder="OTP"
                      style={{ width: "70%" }}
                      value={otp}
                      onChange={handleOtpChange}
                      name="otp"
                    /> */}
                  </Form.Item>
                  {/* <Button onClick={getverifyotp} className={classes.topupemail}>
                  Verify
                </Button> */}
                </div>

                <div className=" justify-content-center">
                  <Form.Item
                    name="New Passowrd"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your new password",
                      },
                    ]}
                    className="px-5 py-1 rounded-lg mt-5  md:w-9/12 mx-auto"
                  >
                    <input
                      placeholder="New Password"
                       className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                      //  className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                      type="newpassword"
                      id="newpassword"
                      value={newpassword}
                      onChange={handleNewPasswordChange}
                      name="newpassword"
                    />
                    {/* <Input.Password
                      //   prefix={<LockPersonIcon />}
                      placeholder="New Password"
                      style={{ width: "70%" }}
                      value={newpassword}
                      onChange={handleNewPasswordChange}
                      name="newpassword"
                    /> */}
                    <div className="checkboxess mt-2 flex flex-wrap justify-evenly text-black md:text-black">
                      {[
                        "8 characters long",
                        "1 lowercase letter",
                        "1 uppercase letter",
                        "1 number digit",
                        "1 special character",
                      ].map((requirement, index) => (
                        <Row key={index}>
                          <Col xl={24}>
                            <Checkbox checked={checkboxes[index]} disabled />
                            <span style={{ marginLeft: 8 }}>{requirement}</span>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Form.Item>
                  {/* <Button onClick={getverifyotp} className={classes.topupemail}>
                  Verify
                </Button> */}
                </div>

                <div className=" justify-content-center">
                  <Form.Item
                    name="Confirm Password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your confirm password",
                      },
                    ]}
                    className="px-5 py-1 rounded-lg mt-5  md:w-9/12 mx-auto"
                  >
                    <div className="relative">
                      <input
                        id="confirmpassword"
                        placeholder="Confirm Password"
                        name="confirmpassword"
                        type={showPassword ? "text" : "password"}
                         className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                        //  className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                        value={confirmpassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                        {showPassword ? (
                          <FaEyeSlash
                            onClick={handleTogglePasswordVisibility}
                            className="h-6 text-gray-700"
                          />
                        ) : (
                          <FaEye
                            onClick={handleTogglePasswordVisibility}
                            className="h-6 text-gray-700"
                          />
                        )}
                      </div>
                    </div>

                    {/* <Input.Password
                      //   prefix={<LockPersonIcon />}
                      placeholder="Confirm Password"
                      style={{ width: "70%" }}
                      value={confirmpassword}
                      onChange={handleConfirmPasswordChange}
                      name="confirmpassword"
                    /> */}
                  </Form.Item>
                  {/* <Button onClick={getverifyotp} className={classes.topupemail}>
                  Verify
                </Button> */}
                </div>
                {/* <Button
                //   className={classes.topupemail}
                >
                  Verify OTP to Reset Password
                </Button> */}
                <button
                  // onClick={handleSubmit}
                  // disabled={!areAllCheckboxesChecked()}
                  onClick={getverifyotp}
                  // disabled={!isCheckboxChecked || isLoading}
                  className="rounded-full active shadow-neumorphic1 py-1 text-white mt-2 mb-4 cursor-pointer hover:active/70 hover:text-white px-20 "
                >
                  Verify OTP to Reset Password
                </button>
              </Form>
            </div>

            <span className="spam mt-4 text-white md:text-black">
              Already Have An Account ?&nbsp;
              <Link to="/" className="text-neonPurple underline ">
                Sign In Here
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
