import React, { useEffect, useState } from "react";
import APi_detailsComponent from "../Components/ApiDetailsComponent";

function APi_details() {
  return (
    <>
      <APi_detailsComponent/>
    </>
  );
}

export default APi_details;
