import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import logo from "../assets/black.png";

const NeoDesign = () => {
  const logoRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();

    tl.set(".cir", {
      scale: 0,
      transformOrigin: "center",
    })
      .set("#logo", {
        scale: 0.27,
        transformOrigin: "center",
        opacity: 0,
      })
      .to(".cir", {
        ease: "back.out(3)",
        duration: 4,
        scale: gsap.utils.distribute({
          base: 1,
          amount: 3,
          from: "end",
        }),
        stagger: {
          each: 0.4,
        },
      })
      .to(
        "#logo",
        {
          scale: 0.3,
          transformOrigin: "center",
          opacity: 1,
          duration: 3,
        },
        "-=1.5"
      );

    logoRef.current.addEventListener("click", () => {
      tl.restart();
    });

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        background: "#fff",
        width: "100%",
        height: "100vh",
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          className="cir"
          style={{
            position: "absolute",
            width: "230px",
            height: "230px",
            borderRadius: "50%",
            boxShadow:
              "10px 10px 10px 10px rgba(136, 165, 191, 0.1) inset, inset -10px -10px 10px  rgba(136, 165, 191, 0.1)",
          }}
        ></div>
        <div
          className="cir"
          style={{
            position: "absolute",
            width: "230px",
            height: "230px",
            borderRadius: "50%",
            boxShadow:
              "10px 10px 10px 10px rgba(136, 165, 191, 0.1) inset, inset -10px -10px 10px  rgba(136, 165, 191, 0.1)",
            // marginLeft: "150px",
            // marginTop: "150px",
            marginLeft: "-28px",
            marginTop: "20px",
          }}
        ></div>
        <div
          className="cir"
          style={{
            position: "absolute",
            width: "230px",
            height: "230px",
            borderRadius: "50%",
            boxShadow:
              "10px 10px 10px 10px rgba(136, 165, 191, 0.1) inset, inset -10px -10px 10px  rgba(136, 165, 191, 0.1)",
            marginLeft: "-19px",
            marginTop: "4px",
            // marginLeft: "-150px",
            // marginTop: "-150px",
          }}
        ></div>
        <div
          className="cir"
          style={{
            position: "absolute",
            width: "230px",
            height: "230px",
            borderRadius: "50%",
            boxShadow:
              "10px 10px 10px 10px rgba(136, 165, 191, 0.1) inset, inset -10px -10px 10px  rgba(136, 165, 191, 0.1)",
            // marginLeft: "-150px",
            // marginTop: "150px",
            marginLeft: "-27px",
            marginTop: "16px",
          }}
        ></div>
        <div
          className="cir"
          style={{
            position: "absolute",
            width: "230px",
            height: "230px",
            borderRadius: "50%",
            boxShadow:
              "10px 10px 10px 10px rgba(136, 165, 191, 0.1) inset, inset -10px -10px 10px  rgba(136, 165, 191, 0.1)",
            //   marginLeft: "150px",
            //   marginTop: "-150px",
            marginLeft: "-20px",
            marginTop: "-3px",
          }}
        ></div>

        <img
          id="logo"
          ref={logoRef}
          src={logo}
          alt="Logo"
          style={{
            width: "50%",
            height: "50%",
            opacity: 0,
          }}
        />
      </div>
    </div>
  );
};

export default NeoDesign;
