import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import ModelEnter from "../Components/ModelEnter";
import OverviewMarket from "../Components/Overviewmarket";
import axios from "axios";
import DexPrice from "../Components/DexPrice";
import DexPriceChange from "../Components/DexPriceChange";
import { FaLock } from 'react-icons/fa';
import UpdateApi from "../Components/UpdateApi";

const Market = () => {
  const [index, setIndex] = useState(0);
  const [isRendered, setIsRendered] = useState([true, false, false, false, false, false, false]);
  const [data, setData] = useState(null);
  const [dataloaser, setDataLoaser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { checkBotFeature, checkTradeFeature } = UpdateApi();
  const marketDataAnalysis = checkTradeFeature.Market_Data_Analysis;
  const marketAnalysisTool = checkTradeFeature.Market_Analysis_Tools;

  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    const url = 'https://deep-index.moralis.io/api/v2.2/market-data/erc20s/top-movers';
    const headers = {
      'Accepts': 'application/json',
      'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MjI2MTZmLWM4MzItNDJmZS04YzI1LTA4NGZiNTAyY2ZmOCIsIm9yZ0lkIjoiMzcwMjAxIiwidXNlcklkIjoiMzgwNDY5IiwidHlwZUlkIjoiYTM5MmYyMTItZGQ1OC00NjY2LWEyMGMtN2E2MDdlZGU3YmZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDM5MzEwMjMsImV4cCI6NDg1OTY5MTAyM30.1ik9oveoExEKuAyXdSIWTKWo3i22Yp59_37ZDIJl8ew',
    };

    axios.get(url, { headers })
      .then(response => {
        setData(response.data.gainers);
        setDataLoaser(response.data.losers);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 500) {
          window.location.reload();
        } else {
          setError(error);
          setLoading(false);
        }
      });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const tabNames = [
    "Dex Price",
    "Top 50 Coins Gainers",
    "Top 50 Coins Losers",
    "Overview",
    "Performance",
    "Oscillators",
    "Trend-Following"
  ];

  const defaultColumns = ["overview", "performance", "oscillators", "moving_averages"];

  const handleTabClick = (newIndex) => {
    setIndex(newIndex);
    setIsRendered(prev => {
      const newRenderedState = [...prev];
      newRenderedState[newIndex] = true;
      return newRenderedState;
    });
  };

  const handleRefresh = () => {
    fetchData();
    setRefresh(prev => !prev);
  };

  console.log("data>>>>>", data)
  console.log("dataLoser>>>>>", dataloaser)

  return (
    <>
      <ModelEnter />
      <div className="bg-colorPrimary mx-2 rounded-3xl ">
        <div className="mt-2">
          <div className="flex border-b-2 border-[#ccc6] mobilescroll mx-5">
            {tabNames.map((tabName, tabIndex) => (
              <div
                key={tabIndex}
                className={`tab py-1 w-auto min-w-fit uppercase px-6 mx-3 text-center cursor-pointer text-sm ${index === tabIndex
                  ? "active-tab border-gradientbottom text-black font-semibold"
                  : "text-[#666666] "
                  }`}
                onClick={() => handleTabClick(tabIndex)}
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={handleTabClick}
          className="mb-3 mx-2 md:mx-4"
        >
          <div className="pt-2">
            {index === 0 &&
              <div className="relative">
                {marketDataAnalysis === false ?
                  <div className="inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
                    style={{
                      backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/liquidation.webp)',
                      backgroundSize: 'cover',
                      height: "100vh"
                    }}
                  >
                    <FaLock className="text-gray-600 text-6xl mb-4" />
                    <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
                    <p className="mb-4">To access this feature, please upgrade your Package.
                    </p>
                  </div>
                  :
                  <DexPrice key={0} refresh={refresh} />
                }
              </div>
            }
          </div>
          <div className="pt-2">
            {index === 1 &&
              <div className="relative">
                {marketDataAnalysis === false ?
                  <div className="inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
                    style={{
                      backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/liquidation.webp)',
                      backgroundSize: 'cover',
                      height: "100vh"
                    }}
                  >
                    <FaLock className="text-gray-600 text-6xl mb-4" />
                    <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
                    <p className="mb-4">To access this feature, please upgrade your Package.
                    </p>
                  </div>
                  :
                  <DexPriceChange key={1} data={data} loaFding={loading} />
                }
              </div>
            }
          </div>
          <div className="pt-2">
            {index === 2 &&
              <div className="relative">
                {marketDataAnalysis === false ?
                  <div className="inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
                    style={{
                      backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/liquidation.webp)',
                      backgroundSize: 'cover',
                      height: "100vh"
                    }}
                  >
                    <FaLock className="text-gray-600 text-6xl mb-4" />
                    <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
                    <p className="mb-4">To access this feature, please upgrade your Package.
                    </p>
                  </div>
                  :
                  <DexPriceChange key={2} data={dataloaser} loading={loading} />
                }
              </div>
            }
          </div>
          <div className="pt-2">
            {isRendered[3] &&
              <div className="relative">
                {marketAnalysisTool === false ?
                  <div className="inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
                    style={{
                      backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/liquidation.webp)',
                      backgroundSize: 'cover',
                      height: "100vh"
                    }}
                  >
                    <FaLock className="text-gray-600 text-6xl mb-4" />
                    <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
                    <p className="mb-4">To access this feature, please upgrade your Package.
                    </p>
                  </div>
                  :
                  <OverviewMarket key={3} defaultColumn={defaultColumns[0]} />
                }
              </div>
            }
          </div>
          <div className="pt-2">
            {isRendered[4] &&
              <div className="relative">
                {marketAnalysisTool === false ?
                  <div className="inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
                    style={{
                      backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/liquidation.webp)',
                      backgroundSize: 'cover',
                      height: "100vh"
                    }}
                  >
                    <FaLock className="text-gray-600 text-6xl mb-4" />
                    <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
                    <p className="mb-4">To access this feature, please upgrade your Package.
                    </p>
                  </div>
                  :
                  <OverviewMarket key={4} defaultColumn={defaultColumns[1]} />
                }
              </div>
            }
          </div>
          <div className="pt-2">
            {isRendered[5] &&
              <div className="relative">
                {marketAnalysisTool === false ?
                  <div className="inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
                    style={{
                      backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/liquidation.webp)',
                      backgroundSize: 'cover',
                      height: "100vh"
                    }}
                  >
                    <FaLock className="text-gray-600 text-6xl mb-4" />
                    <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
                    <p className="mb-4">To access this feature, please upgrade your Package.
                    </p>
                  </div>
                  :
                  <OverviewMarket key={5} defaultColumn={defaultColumns[2]} />
                }
              </div>
            }
          </div>
          <div className="pt-2">
            {isRendered[6] &&
              <div className="relative">
                {marketAnalysisTool === false ?
                  <div className="inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex flex-col justify-center items-center z-50"
                    style={{
                      backgroundImage: 'url(https://cdn.odecents.com/Lock-bg/liquidation.webp)',
                      backgroundSize: 'cover',
                      height: "100vh"
                    }}
                  >
                    <FaLock className="text-gray-600 text-6xl mb-4" />
                    <h2 className="text-2xl font-bold mb-2">Upgraded Package Required</h2>
                    <p className="mb-4">To access this feature, please upgrade your Package.
                    </p>
                  </div>
                  :
                  <OverviewMarket key={6} defaultColumn={defaultColumns[3]} />
                }
              </div>
            }
          </div>
        </SwipeableViews>
      </div >
    </>
  );
};

export default Market;
